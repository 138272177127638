import { v4 as uuidv4 } from 'uuid';

export default function surveyVarietyFormatter(variety, questions, name) {
  switch (variety) {
    // clearscreen
    case 0:
      return formatClearScreenSurvey(questions, name);
    case 1:
      return formatPerformanceReview(questions);
    case 2:
      return formatEngagement(questions);
    case 3:
      return formatHol(questions);
    case 4:
      return format360(questions);
    case 5:
      return formatCorpAsst(questions);
    default:
      return [];
  }
}

function format360(questions) {
  return questions
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
}

function formatCorpAsst(questions) {
  return questions
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
}

function formatHol(questions) {
  return questions
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
}

function formatEngagement(questions) {
  return questions
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
}

function formatPerformanceReview(questions) {
  return questions
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
}

function formatClearScreenSurvey(questions, name) {
  const personalityQuestions = questions
    .filter((q) => q.descriptor === 0)
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 1 }));
  const cognitiveQuestions = questions
    .filter((q) => q.descriptor === 1)
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((q) => ({ ...q, position: q.position + 3 }));

  const personalitySampleQuestions = [
    {
      header: '',
      instruction_text:
        'For each of the assessment items, indicate your level of agreement by clicking on your response. The assessment will automatically progress to the next item.',
      id: uuidv4(),
      position: personalityQuestions[0].position - 2,
      sample: true,
      text: 'Example: I like summer weather.',
      a: 'Strongly Disagree',
      b: 'Disagree',
      c: 'Neutral',
      d: 'Agree',
      e: 'Strongly Agree',
      multiple_choice: true,
      selected: null
    },
    {
      id: uuidv4(),
      position: personalityQuestions[0].position - 1,
      sample: true,
      text: 'Example: I consider myself a great basketball player.',
      a: 'Strongly Disagree',
      b: 'Disagree',
      c: 'Neutral',
      d: 'Agree',
      e: 'Strongly Agree',
      multiple_choice: true,
      selected: null,
      last_sample: true
    }
  ];

  const cognitiveSampleQuestions = [
    {
      header: `You're almost done, ${name}!`,
      instruction_text: `The last ten questions of this assessment will evaluate your problem-solving skills. For each item, indicate the correct answer by clicking your response.`,
      id: uuidv4(),
      position: cognitiveQuestions[0].position - 2,
      text: 'Example: 3 + 5 =',
      sample: true,
      a: '8',
      b: '12',
      c: '4',
      d: '3',
      e: '99',
      multiple_choice: true,
      selected: null
    },
    {
      id: uuidv4(),
      position: cognitiveQuestions[0].position - 1,
      text: 'Example: Plane is to sky as boat is to ___.',
      sample: true,
      a: 'Dirt',
      b: 'Sky',
      c: 'Water',
      d: 'Air',
      e: 'Lava',
      multiple_choice: true,
      selected: null,
      last_sample: true
    }
  ];

  return [
    ...personalitySampleQuestions,
    ...personalityQuestions,
    ...cognitiveSampleQuestions,
    ...cognitiveQuestions
  ];
}
