import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';

export default function Donut({ value, title }) {
  const data = [
    { x: 1, y: value },
    { x: 2, y: 100 - value }
  ];

  return (
    <div className="profile-donut">
      <svg viewBox="0 0 200 200" width="125px" height="125px">
        <VictoryPie
          standalone={false}
          width={200}
          height={200}
          data={data}
          innerRadius={60}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                const color = datum.y > 68 ? '#37bcc8' : '#ff6666';
                return datum.x === 1 ? color : 'transparent';
              }
            }
          }}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          x={100}
          y={100}
          text={`${Math.round(value)}%`}
          style={{ fontSize: 20, fontFamily: 'Open Sans', fontWeight: 700 }}
        />
      </svg>
      <p>{title}</p>
    </div>
  );
}

Donut.defaultProps = {
  value: '',
  title: 'Donut'
};
