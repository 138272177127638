import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';

export default function TagSearch({ bannedIds, profileId, fetchData }) {
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagId, setTagId] = useState(null);

  useEffect(() => {
    fetchTags();
  }, [bannedIds]);

  function fetchTags() {
    axios
      .get(`/op-tags/`)
      .then(({ data }) => {
        setTags(
          data
            .filter((t) => !bannedIds.includes(t.id))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((t) => ({
              key: t.id,
              text: t.name,
              value: t.id
            }))
        );
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
      });
  }

  function onChange(e, d) {
    setTagId(d.value);

    const postData = {
      occupational_profile_id: profileId,
      tag_id: d.value
    };

    axios
      .post(`/tag-op/`, postData)
      .then(() => fetchData())
      .then(() => setTagId(null))
      .catch((err) => {});
  }

  if (!profileId) return null;

  return (
    <div style={{ marginBottom: '10px' }}>
      <Form size="large">
        <Form.Select
          placeholder="Search for tags..."
          options={tags}
          search
          loading={loading}
          onChange={onChange}
          value={tagId}
        />
      </Form>
    </div>
  );
}

TagSearch.defaultProps = {
  bannedIds: [],
  profileId: null
};
