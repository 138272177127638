import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

export default function TableLinkButton(props) {
  return (
    <Link
      className="table-link-button"
      to={props.url}><FaEye /></Link>
  );
}

TableLinkButton.defaultProps = {
  url: '/',
}
