import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Button } from 'semantic-ui-react';
import axios from 'axios';

class AvailableSurveys extends Component {
  state = {
    // added, not added,
    added: true,
    companySurveys: [],
    loading: true,
    pages: null,
    tableData: [],
    total: 0,
    variety: 0
  };

  table = React.createRef();

  componentDidMount = () => {
    this.fetchCompanySurveys();
  };

  fetchCompanySurveys = () => {
    if (!this.props.companyId) return;

    this.setState({ loading: true });

    axios
      .get(`/companies/${this.props.companyId}/surveys/`)
      .then(({ data }) => {
        this.setState({ companySurveys: data.surveys });
      })
      .catch(() => this.setState({ loading: false }));
  };

  onAddedClick = (added) => {
    if (this.state.added !== added) {
      this.setState({ added }, () =>
        this.onFetchData(this.table.current.state, null)
      );
    }
  };

  onVarietyButtonClick = (filterValue) => {};

  onFetchData = (state, instance, variety = this.state.variety) => {
    const { added } = this.state;
    const { page, pageSize, sorted, filtered } = state;

    filtered.forEach((filter) => {
      filter.object = 'self';
      filter.value = filter.value.toLowerCase();
      // const filterOption = this.props.filters.find(f => f.name === filter.id);

      // filter.object = filterOption.obj;
      // filter.object = self;
    });

    this.setState({ loading: true });

    let postData = {
      page: page * pageSize,
      page_size: pageSize,
      sorted,
      filtered,
      status: 0,
      variety
    };

    axios
      .post(`/retrieve-surveys/`, postData)
      .then(({ data }) => {
        let pages = 0;
        if (data.total < pageSize) {
          pages = 1;
        } else {
          pages = Math.ceil(data.total / pageSize);
        }

        this.setState({
          loading: false,
          pages,
          tableData: data.data,
          total: data.total ? data.total : 0
        });
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        sortable: false
      },
      {
        Header: 'Variety',
        accessor: 'variety_formatted',
        filterable: false,
        width: 150,
        sortable: false
      },
      {
        Header: 'Created',
        id: 'created',
        width: 180,
        sortable: false,
        accessor: (d) =>
          d.created_at ? new Date(d.created_at).toLocaleString() : ''
      },
      {
        Header: 'Versions',
        accessor: 'version_count',
        width: 80,
        sortable: false
      },
      {
        Header: '',
        id: 'actions',
        sortable: false,
        accessor: (d) =>
          !this.props.addedSurveyIds.includes(d.id) ? (
            <Button
              icon="plus"
              size="tiny"
              basic
              circular
              onClick={() => this.props.onAddClick(d.id)}
            />
          ) : null,
        width: 50
      }
    ];

    return (
      <ReactTable
        className="-ui -striped -table"
        columns={columns}
        data={this.state.tableData}
        defaultPageSize={20}
        // defaultSorted="name"
        loading={this.state.loading}
        loadingText={`Loading Surveys`}
        manual
        minRows={5}
        multiSort={false}
        noDataText={`No Surveys Available`}
        onFetchData={this.onFetchData}
        pages={this.state.pages}
        ref={this.table}
        rowsText="Surveys"
      />
    );
  }
}

AvailableSurveys.defaultProps = {
  addedSurveyIds: []
};

export default AvailableSurveys;
