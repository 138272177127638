import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';

import {
  Comments,
  Intro,
  Meta,
  SkillImportance,
  TraitSection
} from './components';

import { formatComposites, skills } from './helpers';

import './index.scss';

export const Context360 = createContext(null);

export default function Provider360({ surveyContainerId }) {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState({
    comments: [],
    company: {},
    targetName: '',
    surveyName: '',
    comments: [],
    questions: [],
    raterTypes: [],
    responses: []
  });

  useEffect(() => {
    document.body.className += ' body-360';
    fetchData();

    return () => {
      document.body.className = document.body.className.replace('body-360', '');
    };
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/360/${surveyContainerId}/output/`)
      .then(({ data }) => {
        const formattedResponses = scoreResponses(data.responses);

        setLoading(false);
        setReportData({
          company: data.company,
          comments: data.comments
            .filter((f) => f.comments)
            .map((c) => ({
              text: c.comments,
              id: c.survey_id
            })),
          opportunityComments: data.comments
            .filter((f) => f.comments_2)
            .map((c) => ({
              text: c.comments_2,
              id: c.survey_id
            })),
          targetName: data.target_name,
          surveyName: data.survey_name,
          questions: data.questions,
          responses: formattedResponses,
          raterTypes: data.responses.reduce((acc, cur) => {
            if (!acc.includes(cur.rater_type)) {
              acc.push(cur.rater_type);
            }

            return acc;
          }, [])
        });
      })
      .catch((err) => {});
  }

  return (
    <Context360.Provider value={reportData}>
      {loading && (
        <div>
          <Loader active content="Loading 360" size="massive" inverted />
        </div>
      )}
      {!loading && <Report360 />}
    </Context360.Provider>
  );
}

function Report360() {
  const reportData = useContext(Context360);

  const { comments, opportunityComments, questions, responses } = reportData;

  const composites = formatComposites(
    questions.map((q, i) => ({ ...q })),
    responses
  );

  const compositeSkills = composites
    .map((m) => m.section_skills)
    .reduce((acc, cur) => acc.concat(cur), []);

  return (
    <div className="report-360">
      <Meta />
      <Intro />
      <SkillImportance title="Skills by Importance" />
      {composites.length > 0 && (
        <React.Fragment>
          {composites.map((c, i) => (
            <TraitSection
              key={c.id}
              id={`${i + 1}`}
              title={c.title}
              icon={c.icon}
              subtitle={c.subtitle}
              sections={c.sections}
              questions={c.questions}
            />
          ))}
        </React.Fragment>
      )}
      <Comments comments={comments} title="Strengths" />
      <Comments
        comments={opportunityComments}
        title="Opportunities for Improvement"
      />
    </div>
  );
}

function scoreResponses(res) {
  const letters = ['a', 'b', 'c', 'd', 'e'];
  return res.map((r) => {
    const obj = { ...r };

    for (var i = 0; i < letters.length; i++) {
      if (r[`response_${letters[i]}`]) {
        obj.response = i + 1;
      }

      if (r[`response_${letters[i]}_importance`]) {
        obj.importance = i + 1;
      }
    }

    if (obj.response && obj.importance) {
      obj.gap = obj.importance - obj.response;
    }

    return obj;
  });
}
