import React from 'react';

export default function BasicButton(props) {
  return (
    <button
      onClick={props.onClick}
      className="button basic"
      type="button"
      title={props.title || props.text}
      disabled={props.disabled}
      className={`button basic${
        props.icon ? ' with-icon' : ''
      }${` ${props.cls}`}`}
    >
      {props.text && props.text}
      {props.icon && props.icon}
    </button>
  );
}

BasicButton.defaultProps = {
  cls: '',
  disabled: false,
  icon: null,
  text: '',
  title: ''
};
