import React, { useState } from 'react';
import { ProfileForm, Table } from 'components/Profiles';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

export default function ProfileIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Profiles"
        button={
          <ToggleButton
            closedText="Add Profile"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {isOpen ? (
          <ProfileForm
            onSuccess={() => setOpen(false)}
            onClose={() => setOpen(false)}
            showCancel
          />
        ) : (
          <Table />
        )}
      </Wrapped>
    </div>
  );
}
