import React from 'react';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

export default function PersonaGraph({ candidate, data, op, lcl }) {
  let seriesData = [];

  // ! if compare report
  if (data) {
    seriesData = [
      ...data,
      {
        name: 'HPL',
        color: '#E1BEE7',
        lineWidth: 1,
        data: hplPoints
      }
    ];
  } else {
    seriesData = [
      {
        name: candidate.name,
        data: [[candidate.x, candidate.y, candidate.z]],
        marker: { symbol: 'diamond', radius: 9 }
      },
      {
        name: op.name,
        color: '#FFB300',
        data: [[op.x, op.y, op.z]],
        marker: { symbol: 'triangle' }
      },
      {
        name: lcl.likeness,
        color: '#009688',
        data: [[lcl.x, lcl.y, lcl.z]],
        marker: { symbol: 'circle' }
      },
      {
        name: 'HPL',
        color: '#E1BEE7',
        lineWidth: 1,
        data: hplPoints
      }
    ];
  }

  const options = {
    chart: {
      margin: 100,
      width: 700,
      type: 'scatter3d',
      animation: false,
      options3d: {
        enabled: true,
        alpha: 10,
        beta: 30,
        depth: 250,
        viewDistance: 5,
        fitToPlot: false,
        frame: {
          bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
          back: { size: 1, color: 'rgba(0,0,0,0.04)' },
          side: { size: 1, color: 'rgba(0,0,0,0.06)' }
        }
      }
    },
    title: '',
    subtitle: '',
    credits: false,
    plotOptions: {
      scatter: {
        width: 10,
        height: 10,
        depth: 10,
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      min: -4.3,
      max: 3.3,
      gridLineWidth: 1,
      title: {
        text: 'PEOPLE'
      },
      labels: {
        enabled: false
      }
    },
    yAxis: {
      min: -4.1,
      max: 3.9,
      title: {
        text: 'PROCESS'
      },
      labels: {
        enabled: false
      }
    },
    zAxis: {
      min: -4.5,
      max: 4,
      title: {
        text: 'INTUITION'
      },
      labels: {
        enabled: false
      }
    },
    legend: {
      enabled: true
    },
    series: seriesData
  };

  return (
    <div className="persona-graph">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={(chart) => addDragging(chart)}
      />
    </div>
  );
}

PersonaGraph.defaultProps = {
  candidate: null,
  data: null,
  op: null,
  lcl: null
};

const anchorPoints = {
  x: -2,
  x2: 1.2,
  y: -1.95,
  y2: 3,
  z: -4.5,
  z2: 4
};

const hplPoints = [
  [anchorPoints.x, anchorPoints.y, anchorPoints.z], // bl
  [anchorPoints.x, anchorPoints.y2, anchorPoints.z], // tl
  [anchorPoints.x2, anchorPoints.y2, anchorPoints.z], // tr
  [anchorPoints.x2, anchorPoints.y, anchorPoints.z], // br
  [anchorPoints.x, anchorPoints.y, anchorPoints.z], // bl to connect final line

  [anchorPoints.x, anchorPoints.y, anchorPoints.z2], // bl
  [anchorPoints.x, anchorPoints.y2, anchorPoints.z2], // tl
  [anchorPoints.x2, anchorPoints.y2, anchorPoints.z2], // tr
  [anchorPoints.x2, anchorPoints.y, anchorPoints.z2], // br
  [anchorPoints.x, anchorPoints.y, anchorPoints.z2], // bl to connect final line

  [anchorPoints.x, anchorPoints.y2, anchorPoints.z2], // tl
  [anchorPoints.x, anchorPoints.y2, anchorPoints.z], // tl
  [anchorPoints.x2, anchorPoints.y2, anchorPoints.z], // tr
  [anchorPoints.x2, anchorPoints.y2, anchorPoints.z2], // tr
  [anchorPoints.x2, anchorPoints.y, anchorPoints.z2], // br
  [anchorPoints.x2, anchorPoints.y, anchorPoints.z] // br
];

function addDragging(chart) {
  function dragStart(eStart) {
    eStart = chart.pointer.normalize(eStart);

    var posX = eStart.chartX,
      posY = eStart.chartY,
      alpha = chart.options.chart.options3d.alpha,
      beta = chart.options.chart.options3d.beta,
      sensitivity = 5; // lower is more sensitive

    function drag(e) {
      // Get e.chartX and e.chartY
      e = chart.pointer.normalize(e);

      chart.update(
        {
          chart: {
            options3d: {
              alpha: alpha + (e.chartY - posY) / sensitivity,
              beta: beta + (posX - e.chartX) / sensitivity
            }
          }
        },
        undefined,
        undefined,
        false
      );
    }

    chart.unbindDragMouse = Highcharts.addEvent(document, 'mousemove', drag);
    chart.unbindDragTouch = Highcharts.addEvent(document, 'touchmove', drag);

    Highcharts.addEvent(document, 'mouseup', chart.unbindDragMouse);
    Highcharts.addEvent(document, 'touchend', chart.unbindDragTouch);
  }
  Highcharts.addEvent(chart.container, 'mousedown', dragStart);
  Highcharts.addEvent(chart.container, 'touchstart', dragStart);
}
Highcharts;
