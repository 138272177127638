import React, { useState } from 'react';

import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

import { CompanyEngagementInvite } from './';
import { EngagementList } from 'components/Engagement';

export default function CompanyEngagementManagement({ companyId }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Engagement Surveys"
      button={
        <ToggleButton
          closedText="Add Engagement"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen && (
        <div className="segment">
          <CompanyEngagementInvite
            companyId={companyId}
            onSuccess={() => setOpen(!isOpen)}
          />
        </div>
      )}
      {!isOpen && (
        <div className="segment">
          <EngagementList companyId={companyId} />
        </div>
      )}
    </Wrapped>
  );
}
