import React from 'react';

import { derailers as derailerLib } from 'components/Reports/helpers/derailerDataFormatter';
import { boxData } from 'components/Reports/components/PersonaBoxes';

import { BasicGauge } from 'components/Reports/components/Gauges';
import { WorkMotivesGraph } from 'components/Reports/components';
import workMotivesDataFormatter from 'components/Reports/helpers/workMotivesDataFormatter';

export default function PartnerResultInfo({
  cls,
  coachability,
  cog,
  derailers,
  fpf,
  name,
  persona,
  workMotives
}) {
  const formattedWorkMotives = workMotives
    ? workMotivesDataFormatter({ calculation_container: { ...workMotives } })
    : null;

  const formattedDerailers = derailers.map((m) => {
    const lib = derailerLib[m.toLowerCase()];

    if (derailerLib) {
      return {
        ...lib,
        title: m
      };
    } else {
      return {
        title: m
      };
    }
  });

  const personaObj = persona
    ? boxData.find((f) => f.title.toLowerCase() === persona.toLowerCase())
    : null;

  const personaTraits = personaObj
    ? new Intl.ListFormat('en', { style: 'long', type: 'conjunction' }).format(
        personaObj.traits.split(', ')
      )
    : null;

  return (
    <div className="partner-result-info">
      <div className="section">
        <div className="persona">
          {personaObj && <React.Fragment>{personaObj.icon}</React.Fragment>}
          <h2>
            {name} is a {persona},
          </h2>
          <p>
            {personaTraits && (
              <React.Fragment>{personaTraits}. </React.Fragment>
            )}
            <span style={{ textTransform: 'capitalize' }}>{name}</span>{' '}
            demonstrates <b>{coachability}</b>
            {formattedDerailers.length > 0 ? (
              <React.Fragment>
                {' '}
                and exhibits the following{' '}
                <b>
                  derailer
                  {formattedDerailers.length > 1 ? 's' : ''}:
                </b>
              </React.Fragment>
            ) : (
              '.'
            )}
          </p>
          {formattedDerailers.length > 0 && (
            <div className="derailers">
              {formattedDerailers.length > 1 ? (
                <React.Fragment>
                  <h3 className="red">
                    {formattedDerailers.map((m, i) => (
                      <span key={i + 1}>
                        {m.icon} {m.title}
                      </span>
                    ))}
                  </h3>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h3 className="red">
                    <span>
                      {formattedDerailers[0].icon} {formattedDerailers[0].title}
                    </span>
                  </h3>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="section">
        <div className="gauge-section">
          <BasicGauge total={cls} description="Chance of Leadership Success" />
          <BasicGauge total={fpf * 10} description="Function Position Fit" />
          <div className="cog">
            <h1>{cog}/10</h1>
            <p>Cognitive</p>
          </div>
        </div>
      </div>
      {formattedWorkMotives && (
        <div className="section">
          <h2>The work motives of {name} are</h2>
          <WorkMotivesGraph data={formattedWorkMotives} />
        </div>
      )}
    </div>
  );
}

PartnerResultInfo.defaultProps = {
  derailers: [],
  name: 'Name'
};
