import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.customer_focused_factors) return [];

  const best = output.customer_focused_factors.best
    ? Object.keys(output.customer_focused_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.customer,
        value: output.customer_focused_factors.best[b]
      }))
    : [];
  const worst = output.customer_focused_factors.worst
    ? Object.keys(output.customer_focused_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.customer,
        value: output.customer_focused_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Customer-Focused',
      value: output.customer_focused,
      behavior_value: output.pan_result.customer_focused_score,
      cls: groupClasses.customer,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Listening',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Resourcing',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Mentoring',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Partnering',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Team Talent',
    //   cls: groupClasses.customer,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Customer-Focused-Courses.aspx'
  }));
}
