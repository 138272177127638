import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';

export default function EngagementInvite({
  companyId,
  surveyId,
  sendSurvey,
  onSurveyChange,
  onSendSurveyChange
}) {
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  if (!companyId) return null;

  useEffect(() => {
    fetchSurveys();
  }, [sendSurvey]);

  function fetchSurveys() {
    if (!sendSurvey) return;

    setLoading(true);

    axios
      .get(`/companies/${companyId}/approved-engagement-invites/`)
      .then(({ data }) => {
        setLoading(false);
        setSurveyOptions(
          data.map((s) => ({
            key: s.invite_id,
            text: s.name,
            value: s.invite_id,
            description: `Invite ID: ${s.invite_id}`
          }))
        );
      })
      .catch((err) => {
        setLoading(false);
        setSurveyOptions([]);
      });
  }

  return (
    <div>
      <p style={{ fontSize: '1.05em' }}>
        <b>Add employees from CSV to Engagement Survey after import?</b>
      </p>
      <Form size="small">
        <Form.Radio
          label="No"
          checked={!sendSurvey}
          onChange={() => onSendSurveyChange(false)}
        />
        <Form.Radio
          label="Yes, add employees after import"
          checked={sendSurvey}
          onChange={() => onSendSurveyChange(true)}
        />
        {sendSurvey && (
          <Form.Select
            label="Engagement Survey"
            options={surveyOptions}
            onChange={(e, d) => onSurveyChange(d.value)}
            loading={loading}
            value={surveyId}
            placeholder="Select one..."
          />
        )}
      </Form>
    </div>
  );
}

EngagementInvite.defaultProps = {
  companyId: null,
  surveyId: null
};
