import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import Results from '../Results';

export default function Item({ name, profile, tags, children }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="profile-item">
      <div className="meta">
        <h2>{name}</h2>
        <div className="actions">
          {children}
          <Button
            size="tiny"
            basic
            icon={isOpen ? 'caret up' : 'caret down'}
            onClick={() => setOpen(!isOpen)}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          <Results
            exValue={profile.ex}
            anValue={profile.an}
            tmValue={profile.tm}
            inValue={profile.in}
            scValue={profile.sc}
            aValue={profile.a}
            bValue={profile.b}
            cValue={profile.c}
            eValue={profile.e}
            fValue={profile.f}
            gValue={profile.g}
            hValue={profile.h}
            iValue={profile.i}
            lValue={profile.l}
            mValue={profile.m}
            nValue={profile.n}
            oValue={profile.o}
            q1Value={profile.q1}
            q2Value={profile.q2}
            q3Value={profile.q3}
            q4Value={profile.q4}
          />
          <div className="tags">
            {tags.map(({ name, id }) => (
              <div className="profile-tag" key={id}>
                {name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

Item.defaultProps = {
  name: '',
  profile: null,
  tags: []
};
