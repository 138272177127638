import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import BasicForm from 'components/shared/Forms/BasicForm';

import { SET_TOAST } from 'duck/toast';

function TagForm({ id, profileId, name, dispatch, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    if (id) {
      return onUpdate(formData);
    }

    return onCreate(formData);
  }

  function onCreate(formData) {
    const postData = { ...formData };

    axios
      .post(`/op-tags/`, postData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Tag Created',
            positive: true
          }
        });
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onUpdate(formData) {
    const postData = {
      ...formData
    };

    axios
      .put(`/tags/${id}/`, postData)
      .then(() => {
        setLoading(false);
      })
      .then(() =>
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Tag Updated!'
          }
        })
      )
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  const fields = [
    {
      name: 'name',
      label: ' Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required')
    }
  ];

  return (
    <div className="segment">
      <BasicForm
        buttonText="Save"
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

TagForm.defaultProps = {
  id: null,
  profileId: null,
  name: ''
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(TagForm);
