const leaderMarkers = {
  candidateColors: [
    '#2196F3',
    '#3F51B5',
    '#9C27B0',
    '#00BCD4',
    '#009688',
    '#FF5722',
    '#795548',
    '#607D8B',
    '#F44336',
    '#004D40'
  ],
  lclColors: [
    '#EF9A9A',
    '#CE93D8',
    '#9FA8DA',
    '#90CAF9',
    '#80DEEA',
    '#80CBC4',
    '#A5D6A7',
    '#C5E1A5',
    '#E6EE9C',
    '#FFCC80'
  ]
};

export default function compareDataFormatter(employees) {
  if (!employees.length) return null;

  let arData = [];
  let estimateData = [];
  let leaderData = [];
  let sliderData = [];
  let clsData = [];
  let fpfData = [];
  let derailerData = [];

  leaderData.push({
    name: employees[0].profile_name,
    color: '#FFB300',
    data: [[employees[0].x_axis, employees[0].y_axis, employees[0].z_axis]],
    marker: {
      symbol: 'triangle'
    }
  });

  employees.forEach((employee, i) => {
    let lclLabel = employee.calculation_container.lcl_likeness;
    lclLabel = lclLabel.charAt(0).toUpperCase() + lclLabel.slice(1);
    let derailersArr = employee.calculation_container.derailers;

    let derailers = derailersArr.filter(
      (derailer) => derailer.triggered === true
    );

    let derailerText = '';

    if (derailers.length > 0) {
      derailers.forEach((derailer) => (derailerText += `${derailer.name}, `));
      derailerText = derailerText.replace(/,\s*$/, '');
    } else {
      derailerText = 'n/a';
    }

    arData.push({
      name: employee.employee_info.last_name,
      assurance: employee.calculation_container.assurance,
      receptivity: employee.calculation_container.receptivity
    });

    estimateData.push({
      name: employee.employee_info.last_name,
      cls: employee.cos_total / 10,
      fpf: employee.steq_score
    });

    leaderData.push({
      name: employee.employee_info.last_name,
      color: leaderMarkers.candidateColors[i],
      data: [
        [
          employee.calculation_container.x_axis,
          employee.calculation_container.y_axis,
          employee.calculation_container.z_axis
        ]
      ],
      marker: {
        radius: 9,
        symbol: 'diamond'
      }
    });
    leaderData.push({
      name: lclLabel,
      color: leaderMarkers.lclColors[i],
      data: [
        [
          employee.calculation_container.lcl_likeness_x,
          employee.calculation_container.lcl_likeness_y,
          employee.calculation_container.lcl_likeness_z
        ]
      ],
      marker: {
        symbol: 'circle'
      }
    });
    sliderData.push({
      name: employee.employee_info.full_name,
      result: employee.calculation_container.cog_score,
      adjusted_result: 5,
      color: leaderMarkers.candidateColors[i]
    });
    clsData.push({
      name: employee.employee_info.full_name,
      value: employee.cos_total
    });
    fpfData.push({
      name: employee.employee_info.full_name,
      value: employee.steq_score * 10
    });
    derailerData.push({
      name: employee.employee_info.full_name,
      present: derailers.length > 0 ? 'present' : 'not present',
      derailers: derailerText
    });
  });

  // sort cls & fpf data
  clsData = clsData.sort((a, b) => b.value - a.value);
  fpfData = fpfData.sort((a, b) => b.value - a.value);

  const filteredLeaderData = leaderData.filter(function (a) {
    return !this[a.name] && (this[a.name] = true);
  }, Object.create(null));

  return {
    arData,
    estimateData,
    leaderData,
    sliderData,
    clsData,
    fpfData,
    derailerData
  };
}
