import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button, Divider } from 'semantic-ui-react';

import {
  EvaluatorManagement,
  RemoveButton,
  MinBlock,
  formatEvaluator,
  getMinBlocks
} from './AddForm';

export default function Employee360s({ employeeId, showClosed, isAdmin }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .post(`/employees/${employeeId}/retrieve-360s/`, {
        company_employee_id: employeeId
      })
      .then(({ data }) => {
        setItems(data.sort((a, b) => b.closed - a.closed));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div className="list-360">
      {!loading && items.length === 0 && (
        <p>This employee has not been the target of a 360 yet.</p>
      )}
      {items.map((item) => (
        <div key={item.id} className="list-item-360">
          <div className="meta">
            <h3>{item.name}</h3>
            <p>Created {new Date(item.created_at).toLocaleString()}</p>
            <span className={`label${item.closed ? ' closed' : ''}`}>
              {item.closed && 'Ready to view'}
              {!item.closed &&
                item.survey_invite_approval_status === 2 &&
                'In progress'}
              {item.survey_invite_approval_status === 0 && 'Waiting'}
            </span>
          </div>
          {[0, 2].includes(item.survey_invite_approval_status) &&
            !item.closed && (
              <EditModal
                onUpdate={fetchData}
                containerId={item.id}
                inviteId={item.survey_invite_id}
              />
            )}
          {item.closed && (
            <Button
              basic
              icon="eye"
              circular
              title="View 360"
              size="mini"
              as={Link}
              to={`/360/${item.id}`}
            />
          )}
          {isAdmin && (
            <Button
              basic
              icon="mail outline"
              circular
              size="mini"
              as={Link}
              to={`/requests/${item.survey_invite_id}`}
              title="View Request"
            />
          )}
        </div>
      ))}
    </div>
  );
}

Employee360s.defaultProps = {
  isAdmin: false,
  showClosed: true
};

const EditModal = ({ containerId, inviteId, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);

  if (!containerId || !inviteId) return null;

  function onToggle() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
  }, [isOpen]);

  function fetchData() {
    axios
      .get(`/survey-requests/${inviteId}/`)
      .then(({ data }) => {
        setSurveys(data.surveys_info);
        setApprovalStatus(data.approval_status);
        if (data.requested_for_formatted) {
          setCompanyId(data.requested_for_formatted.id);
        }
      })
      .catch((err) => {
        setSurveys([]);
      });
  }

  function onSurveyAdd(person) {
    const req = {
      ...formatEvaluator(person),
      company_id: companyId,
      survey_container_id: containerId
    };

    axios
      .post(`/survey-container/${containerId}/add-survey/`, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {});
  }

  const targetSurvey = surveys.find((s) => s.target);

  const minBlocks = getMinBlocks(surveys);

  return (
    <React.Fragment>
      <Button
        icon="pencil"
        circular
        basic
        title="Edit"
        size="mini"
        onClick={onToggle}
      />
      <Modal open={isOpen} onClose={onToggle}>
        <Modal.Content>
          <div className="create-360">
            <div className="current-evals" style={{ marginTop: '0' }}>
              <div className="eval-grid">
                {surveys.map((s) => (
                  <div className="eval-item">
                    <div className="name">
                      <p>{s.company_employee_name}</p>
                      {!s.target && approvalStatus === 0 && (
                        <RemoveSurveyButton
                          surveyId={s.id}
                          surveyContainerId={containerId}
                          onSuccess={fetchData}
                        />
                      )}
                    </div>
                    <div className="relation">
                      <p>
                        {s.rater_type}
                        {s.other_rater_context && ` (${s.other_rater_context})`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {companyId && targetSurvey && approvalStatus === 0 && (
            <div className="create-360">
              <Divider />
              <div style={{ margin: '1.5em 0' }}>
                <p>These are the required minimums:</p>
                <div className="min-blocks">
                  {minBlocks.map((m) => (
                    <MinBlock
                      key={m.key}
                      title={m.text}
                      currentCount={m.current_count}
                      requiredCount={m.required_count}
                    />
                  ))}
                </div>
              </div>
              <EvaluatorManagement
                targetName={
                  targetSurvey.company_employee_name ||
                  targetSurvey.company_manager_name
                }
                companyId={companyId}
                onAdd={onSurveyAdd}
                // evaluators={surveys}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={onToggle} basic />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

const RemoveSurveyButton = ({ surveyId, surveyContainerId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  if (!surveyId) return null;

  function onClick() {
    setLoading(true);

    const req = {
      survey_id: surveyId,
      survey_container_id: surveyContainerId
    };

    axios
      .post(`/survey-container/${surveyContainerId}/remove-survey/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return <RemoveButton onClick={onClick} />;
};
