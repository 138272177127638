import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'semantic-ui-react';

import { EmployeeForm } from './';

import { BasicButton } from 'components/shared/Buttons';

export default function EmployeeCreateModal({
  companyId,
  companyName,
  onSuccess
}) {
  const [isOpen, setOpen] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const [managerId, setManagerId] = useState(null);

  useEffect(() => {
    fetchManagers();
  }, [isOpen]);

  function fetchManagers() {
    if (!isOpen) return;

    axios
      .get(`/companies/${companyId}/managers/`)
      .then(({ data }) => {
        setManagerOptions(
          data.company_managers
            .filter((f) => f.status === 0)
            .map((m) => ({
              key: m.id,
              text: `${m.first_name} ${m.last_name}`,
              value: m.id
            }))
        );
      })
      .catch((err) => {
        setManagerOptions([]);
      });
  }

  function onClose() {
    setOpen(false);
  }

  function onEmployeeCreate() {
    setOpen(false);
    onSuccess();
  }

  return (
    <div>
      <BasicButton text="Manually Add Employee" onClick={() => setOpen(true)} />
      <Modal onClose={onClose} open={isOpen}>
        <Modal.Header content={`Add ${companyName} Employee`} />
        <Modal.Content>
          <EmployeeForm
            companyId={companyId}
            onSubmit={onEmployeeCreate}
            showCompany={false}
            isAdmin
            managerOptions={managerOptions}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            basic
            size="small"
            onClick={() => onClose()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}
