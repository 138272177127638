import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Fade from "react-reveal/Fade";
import { Form, Search } from 'semantic-ui-react';

import { SET_TOAST } from 'duck/toast';

import { BasicButton, SubmitButton } from 'components/shared/Buttons';

class AddForm extends Component {
  state = {
    searchTerm: '',
    employeeId: null,
    employees: [],
    loading: false,
    cancelToken: null,
    surveys: [],
    surveyId: null,
  }

  componentDidMount = () => {
    this.fetchSurveys()
  }

  fetchSurveys = () => {
    axios.post(`/retrieve-surveys/`, {
      page_size: 10000,
      page: 0,
      status: 0,
      filtered: [],
      sorted: [],
    })
      .then(({ data }) => {
        this.setState({
          loading: false,
          surveys: data.data.map((s) => ({
            text: s.name,
            value: s.id,
          }))
        });
      })
      .catch((err) => {

      })
  }

  onEmployeeSearchChange = (e, d) => {
    const { value } = d;

    if (this.state.cancelToken) {
      this.state.cancelToken.cancel('REQUEST CANCELLED');
    }

    if (d.value === '') {
      this.setState({ employees: [] });
    }

    this.setState({ searchTerm: value });

    if (value.length < 3) return;

    const cancelToken = axios.CancelToken;
    const call1 = cancelToken.source();

    this.setState({ cancelToken: call1, loading: true });

    axios.post(`/company-employee-omnisearch/`, {
      search: value.toLowerCase(),
    }, { cancelToken: call1.token })
      .then(({ data }) => {
        this.setState({
          employees: data.company_employees.slice(0, 100).map((d) => ({
            title: d.full_name,
            value: d.id,
            description: d.email,
            key: d.id,
          })),
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          employees: [],
          loading: false,
        });
      })
  }

  onSubmit = (e) => {
    const { surveyId } = this.state;

    e.preventDefault();

    const postData = {
      company_employee_id: this.state.employeeId,
      survey_id: surveyId,
    };

    this.setState({ loading: true });

    axios.post(`/surveys/${surveyId}/create-employee-survey/`, postData)
      .then(({ data }) => {
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Request Sent!'
          }
        })

        return data.id;
      })
      .then((id) => this.props.onSuccess(id))
      // .then(() => {
      //   this.setState({
      //     employeeId: null,
      //     searchTerm: '',
      //     employees: [],
      //     surveyId: null,
      //     loading: false
      //   });
      // })
      .catch((err) => {
        this.setState({ loading: false });

        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message,
          }
        })
      })
  }

  setEmployee = (e, d) => {
    if (!d.result) return

    this.setState({ employeeId: d.result.value, searchTerm: d.result.title });
  }

  setSurvey = (surveyId) => this.setState({ surveyId })

  render() {
    return (
      <Fade>
        <div className="segment">
          <form className="ui form">
            <Form.Select
              value={this.state.surveyId}
              onChange={(e, d) => this.setSurvey(d.value)}
              label="Survey"
              placeholder="Select a survey..."
              options={this.state.surveys}
              // loading={this.state.loading}
              disabled={this.state.loading}
            />
            <Form.Field>
              <label>Employee</label>
              <Search
                value={this.state.searchTerm}
                onResultSelect={(e, d) => this.setEmployee(e, d)}
                onSearchChange={this.onEmployeeSearchChange}
                label="Employee"
                placeholder="Search for an employee..."
                results={this.state.employees}
                loading={this.state.loading}
                fluid
              />
            </Form.Field>
            <div className="form-actions" style={{ marginTop: '20px' }}>
              <BasicButton
                onClick={this.props.onClose}
                text="Cancel"
              />
              <SubmitButton
                disabled={!this.state.surveyId || !this.state.employeeId}
                onClick={this.onSubmit}
                loading={this.state.loading}
                text="Create"
              />
            </div>
          </form>
        </div>
      </Fade>
    );
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(AddForm);
