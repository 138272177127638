import React from 'react';
import logo from 'assets/images/logo.png';
import {
  FaCogs,
  FaTrophy,
  FaBalanceScale,
  FaPuzzlePiece
} from 'react-icons/fa';

import { Slider } from './';

const iconProps = {
  size: '60',
  color: '#ff7f00'
};

export default function ClearModel({ showSample, selectReport }) {
  return (
    <div className="report-clear-section">
      <img src={logo} alt="CLEAR Screen" />
      <h2 className="report-center">
        The <span className="report-blue">CLEAR</span> Model
      </h2>
      <div className="report-body-text">
        <p>
          Research has identified an array of notable elements to consider when
          evaluating leaders, and "CLEAR" is an acronym for the most important
          of these:
        </p>

        <div className="report-icon-list report-icon-list-intro">
          <div className="report-icon-list-item">
            <div className="icon">
              <FaCogs {...iconProps} />
              <h3>
                <b>C</b>ognition
              </h3>
            </div>
            <div className="text">
              <p>
                Cognition, or Problem-Solving Skills, is the MOST IMPORTANT
                factor to consider in relation to leadership efficacy and is
                always positively related to performance.
              </p>
            </div>
          </div>
          <div className="report-icon-list-item">
            <div className="icon">
              <FaBalanceScale {...iconProps} />
              <h3>
                <b>L</b>eadership
                <br />
                <b>E</b>xpression
              </h3>
            </div>
            <div className="text">
              <p>
                Leadership Expression the intersection of People Orientation and
                Process Discipline. Key components of People Orientation include
                social boldness, openness to change, dominance, stability, and
                liveliness. Those with high scores in this area are engaging,
                open-minded, assertive, steady, and enthusiastic. Process
                discipline includes groundedness, urgency, rule-consciousness,
                and perfectionism. Higher scores in this area indicate
                practicality, urgency, caution, conscientiousness, and a
                perfectionism.
              </p>
            </div>
          </div>
          <div className="report-icon-list-item-grouped">
            <div className="items">
              <div className="report-icon-list-item">
                <div className="icon">
                  <FaTrophy {...iconProps} />
                  <h3>
                    <b>A</b>ssurance
                  </h3>
                </div>
                <div className="text">
                  <p>
                    Assurance is the combination of emotional stability,
                    self-confidence, and calmness. It involves holding steady
                    under adversity, remaining confident despite setbacks and
                    maintaining composure under pressure. It is a staple
                    characteristic of the best leaders.
                  </p>
                </div>
              </div>
              <div className="report-icon-list-item">
                <div className="icon">
                  <FaPuzzlePiece {...iconProps} />
                  <h3>
                    <b>R</b>eceptivity
                  </h3>
                </div>
                <div className="text">
                  <p>
                    Receptivity is the combination of sensitivity to nuance,
                    conceptual perspective, and open-mindedness. It includes
                    attending to subtleties, thinking beyond the "here and now,"
                    and welcoming input from various sources.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          Those who demonstrate capabilities related to each of these elements
          are much more likely to perform effectively as leaders in the
          workplace.
        </p>
        <p>
          Key <b>Watchouts</b> (if any) for this individual are then presented,
          followed by <b>Work Motives</b>
          {!selectReport ? (
            <React.Fragment>
              {' '}
              and <b>Competency Factor Estimates</b>
            </React.Fragment>
          ) : (
            '.'
          )}
          .
        </p>
        {showSample && (
          <p>
            A sample graph similar to many found in this report is presented
            below. This individual's score is represented by the orange diamond
            plotted along a horizontal bar, with opposite meanings represented
            at different poles of the scale. The "optimal" range for a given
            Functional Position Profile is represented by the horizontal gray
            band along the scale. Where scores fall to the left of the optimal
            range, the individual is likely to under-express the Right-Pole
            traits and associated behaviors. Where scores fall to the right of
            the optimal range, the individual is likely to overexpress the
            Right-Pole traits and associated behaviors. Other types of graphs in
            this report are described as they are presented.
          </p>
        )}
      </div>
      {showSample && (
        <div style={{ marginTop: '40px' }}>
          <Slider
            leftPoleText="Left Pole Meaning"
            rightPoleText="Right Pole Meaning"
            value={5}
            optimalHigh={6}
            optimalLow={2}
          />
        </div>
      )}
    </div>
  );
}

ClearModel.defaultProps = {
  selectReport: false,
  showSample: true
};
