import React from 'react';
import { ReportLayout } from 'components/Layouts';

import EngagementReport from 'components/Reports/EngagementReport';

export default function EngagementDetail({ match, location }) {
  const { id } = match.params;

  return (
    <div>
      <ReportLayout wide>
        <EngagementReport reportId={id} isAdmin pathname={location.pathname} />
      </ReportLayout>
    </div>
  );
}
