import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { connect } from 'react-redux';

import Fade from 'react-reveal/Fade';

import BasicForm from 'components/shared/Forms/BasicForm';
import surveyVariety from '@util/data/surveyVariety';
import { BasicButton } from 'components/shared/Buttons';

import { SET_TOAST } from 'duck/toast';

function AddForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      name: 'name',
      label: 'Version Name',
      initialValue: '',
      required: false,
      schema: () => null
    }
  ];

  function onSubmit(postData) {
    setLoading(true);
    axios
      .post(`/surveys/${props.id}/versions/`, postData)
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Survey Version Created!'
          }
        });
      })
      .then(() => props.onSubmit())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <Fade>
      <BasicForm
        buttonText="Create"
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
      >
        <BasicButton onClick={props.onClose} text="Cancel" />
      </BasicForm>
    </Fade>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AddForm);
