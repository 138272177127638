import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduceReducers from 'reduce-reducers';
import initialState from './initialState';

import adminReducer from './admin';
import loadingReducer from './loading';
import managerReducer from './manager';
import toastReducer from './toast';

const rootReducer = reduceReducers(
  initialState,
  adminReducer,
  loadingReducer,
  managerReducer,
  toastReducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers()
  // composeEnhancers(
  //   applyMiddleware(
  //     createSentryMiddleware(Sentry),
  //     thunk
  //   ),
  // )
);
