import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { ActionButton } from 'components/shared/Buttons';

import { SET_TOAST } from 'duck/toast';

function CompanyEngagementInvite({ companyId, onSuccess, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [surveysLoading, setSurveysLoading] = useState(false);

  useEffect(() => {
    fetchOptions();
  }, [companyId]);

  function fetchOptions() {
    if (!companyId) return;

    axios
      .post(`/companies/${companyId}/retrieve-surveys/`, {
        company_id: companyId,
        status: 0
      })
      .then(({ data }) => {
        setSurveysLoading(false);
        setSurveyOptions(
          data.surveys
            .filter((f) => f.variety === 2)
            .map((s) => ({
              key: s.id,
              text: s.name,
              value: s.id
            }))
        );
      })
      .catch((err) => {
        setSurveysLoading(false);
        setSurveysLoading([]);
      });
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      company_id: companyId,
      survey_id: surveyId
    };

    axios
      .post(`/companies/${companyId}/engagement-invite/`, req)
      .then(({ data }) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Invite Sent. Go to Requests to view.',
            positive: true
          }
        });
        setSurveyId(null);
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: { message: err.response.data.ui_message, negative: true }
        });
      });
  }

  return (
    <div>
      <p>
        The following will create a request for the selected Engagement Survey
        for this company
      </p>
      <Form style={{ marginBottom: '1em' }}>
        <Form.Select
          label="Engagement Survey"
          placeholder="Select one..."
          options={surveyOptions}
          loading={surveysLoading}
          onChange={(e, d) => setSurveyId(d.value)}
          value={surveyId}
        />
      </Form>
      <ActionButton
        text="Create Invite"
        loading={loading}
        onClick={onSubmit}
        disabled={!surveyId}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyEngagementInvite);
