import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const margin = { top: 10, right: 50, bottom: 10, left: 50 };
const width = 100 - margin.left - margin.right;
const height = 500 - margin.top - margin.bottom;

export default function CompareCognition({ value, adjustedValue, color }) {
  const svgDiv = useRef(null);
  useEffect(() => {
    if (!svgDiv.current) return;

    const sliderDiv = d3.select(svgDiv.current);

    const svg = sliderDiv
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    const scale = d3
      .scaleLinear()
      .domain([1, 10])
      .range([height - 25, 25]);

    const xAxis = d3.axisLeft().tickFormat('').tickSize(20).scale(scale);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(xAxis);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', scale(5.5))
      .attr('width', 100)
      .attr('height', 4)
      .attr('fill', '#000');

    svg
      .append('text')
      .attr('x', 0)
      .attr('y', () => scale(value - 0.2))
      .attr('class', 'graph-icon')
      .attr('font-size', '40px')
      .attr('fill', color)
      .attr('transform', `translate(20, ${margin.top})`)
      .text(`\u2666`);

    // if (adjustedValue > 0 && adjustedValue !== value) {
    //   svg
    //     .append('text')
    //     .attr('x', scale(adjustedValue - 0.11))
    //     .attr('y', 22)
    //     .attr('class', 'graph-icon')
    //     .attr('font-size', '32px')
    //     .attr('fill', '#000')
    //     .attr('transform', `translate(${margin.left}, ${margin.top})`)
    //     .text(`\u2666`);
    // } else {
    //   svg
    //     .append('text')
    //     .attr('x', 0)
    //     .attr('y', () => scale(value - 0.2))
    //     .attr('class', 'graph-icon')
    //     .attr('font-size', '40px')
    //     .attr('fill', color)
    //     .attr('transform', `translate(20, ${margin.top})`)
    //     .text(`\u2666`);
    // }
  });

  return <div className="compare-slider" ref={svgDiv}></div>;
}

CompareCognition.defaultProps = {
  value: 0,
  adjustedValue: 0,
  color: '#000'
};

CompareCognition.propTypes = {
  value: PropTypes.number,
  adjustedValue: PropTypes.number
};
