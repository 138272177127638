import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { CompanyMetaBlock } from 'components/Company';
import { Wrapped } from 'components/Layouts';
import { SET_TOAST } from 'duck/toast';
import { Subnav } from 'components/shared';

import {
  ArchiveNotes,
  EmployeeForm,
  Evaluations,
  LegacyResult,
  Notes,
  SurveyInvites,
  Documents
} from 'components/Employee';

import { ErrorView } from 'components/shared/Errors';

import Management360 from 'components/360Management';

class EmployeeDetail extends Component {
  state = {
    error: null,
    loading: true,
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    positionId: null,
    division: '',
    status: '',
    companyId: '',
    companyName: '',
    companyLogoFilename: '',
    note: '',
    legacyAvailable: false
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchData();
  };

  fetchData = () => {
    axios
      .get(`/employees/${this.props.match.params.id}/`)
      .then(({ data }) => {
        this.setState({
          error: null,
          loading: false,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          position: data.position,
          positionId: data.company_position_id,
          division: data.division,
          status: data.status,
          companyId: data.company_id,
          companyName: data.company_name,
          note: data.note ? data.note : '',
          companyLogoFilename: data.company_logo,
          legacyAvailable: data.legacy_available
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message
            ? err.response.data.ui_message
            : 'Could not find the employee you were looking for',
          loading: false
        });
      });
  };

  onUpdate = (formData) => {
    const employeeId = this.props.match.params.id;
    this.setState({ loading: true });

    const postData = { ...formData };

    axios
      .put(`/employees/${employeeId}/`, postData)
      .then(({ data }) => {
        this.setState({ loading: false, name: data.name });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Employee Updated!'
          }
        });
      })
      .then(() => this.fetchData())
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  };

  render() {
    const { match } = this.props;
    const employeeId = this.props.match.params.id;
    const { error } = this.state;

    if (error) {
      return <ErrorView text={error} />;
    }

    return (
      <div>
        <Wrapped style={{ marginBottom: '0', paddingBottom: '0' }}>
          <CompanyMetaBlock
            companyName={this.state.companyName}
            employeeName={`${this.state.firstName} ${this.state.lastName}`}
            logoFilename={this.state.companyLogoFilename}
          />
          <Subnav
            links={[
              { to: match.url, text: 'Detail' },
              { to: `${match.url}/survey-invites`, text: 'Survey Invites' },
              { to: `${match.url}/360`, text: '360' },
              { to: `${match.url}/notes`, text: 'Notes' },
              { to: `${match.url}/documents`, text: 'Documents' }
            ].map((m) => ({
              ...m,
              active: `/admin${m.to}` === window.location.pathname
            }))}
          />
        </Wrapped>
        <Switch>
          <Route path={match.path} exact>
            <React.Fragment>
              <Wrapped style={{ marginTop: '0' }}>
                <EmployeeForm
                  isAdmin
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  email={this.state.email}
                  position={this.state.position}
                  positionId={this.state.positionId}
                  division={this.state.division}
                  status={this.state.status}
                  companyId={this.state.companyId}
                  showCompany={false}
                  buttonText="Update"
                  loading={this.state.loading}
                  onUpdate={this.onUpdate}
                />
              </Wrapped>
            </React.Fragment>
          </Route>
          <Route path={`${match.path}/survey-invites`}>
            <React.Fragment>
              <SurveyInvites
                companyId={this.state.companyId}
                companyName={this.state.companyName}
                employeeId={employeeId}
                employeeName={`${this.state.firstName} ${this.state.lastName}`}
                reportsEnabled={this.state.reportsEnabled}
                admin
              />
              <LegacyResult
                employeeId={employeeId}
                employeeName={`${this.state.firstName} ${this.state.lastName}`}
                available={this.state.legacyAvailable}
                fetchData={this.fetchData}
                companyId={this.state.companyId}
              />
            </React.Fragment>
          </Route>
          <Route path={`${match.path}/360`}>
            <Management360
              isAdmin
              employeeId={employeeId}
              companyId={this.state.companyId}
              employeeName={`${this.state.firstName} ${this.state.lastName}`}
            />
          </Route>
          <Route path={`${match.path}/notes`}>
            <React.Fragment>
              <Notes employeeId={this.props.match.params.id} admin />
              <ArchiveNotes note={this.state.note} />
            </React.Fragment>
          </Route>
          <Route path={`${match.path}/documents`}>
            <Documents employeeId={this.props.match.params.id} admin />
          </Route>
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(EmployeeDetail));
