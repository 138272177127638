import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Icon, Popup } from 'semantic-ui-react';

import { ActionButton, BasicButton } from 'components/shared/Buttons';

export default function EmployeeManagerLinks({
  companyId,
  employeeId,
  links,
  onUpdate
}) {
  const [isOpen, setOpen] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchManagers();
  }, [isOpen]);

  function fetchManagers() {
    if (!isOpen) return;

    axios
      .get(`/companies/${companyId}/managers/`)
      .then(({ data }) => {
        setManagerOptions(
          data.company_managers.sort((a, b) => (a.status > b.status ? 1 : -1))
        );
        setLoaded(true);
      })
      .catch((err) => {
        setManagerOptions([]);
      });
  }

  function onClose() {
    setOpen(false);
    onUpdate();
  }

  function onAddClick(managerId) {
    const req = {
      company_manager_id: managerId,
      company_employee_id: employeeId
    };

    setError(null);

    axios
      .post(`/company-manager-employee-links/`, req)
      .then(() => onUpdate())
      .catch((err) => {
        setError(err.response.data.ui_message);
      });
  }

  function onDeleteClick(managerId) {
    const req = {
      company_manager_id: managerId,
      company_employee_id: employeeId
    };

    setError(null);

    axios
      .post(`/remove-company-manager-employee-link/`, req)
      .then(() => onUpdate())
      .catch((err) => {
        setError(err.response.data.ui_message);
      });
  }

  const managerText = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
  }).format(links.map((d) => d.company_manager.full_name));

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          circular
          icon="pencil"
          circular
          basic
          size="tiny"
          onClick={() => setOpen(true)}
        />
        {links.length > 0 && (
          <Popup
            content={
              <div>
                <p>{managerText}</p>
              </div>
            }
            trigger={
              <div>
                <Icon
                  style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    marginRight: '5px'
                  }}
                  name={links.length === 1 ? 'user' : 'users'}
                />
                <span>({links.length})</span>
              </div>
            }
          />
        )}
      </div>
      <Modal onClose={onClose} open={isOpen} size="small">
        <Modal.Content>
          {loaded && managerOptions.length === 0 && (
            <p style={{ fontWeight: 'bold' }}>
              This company has no managers. Add some.
            </p>
          )}
          {managerOptions.map((mgr) => (
            <ManagerItem
              key={mgr.id}
              managerId={mgr.id}
              managerName={`${mgr.first_name} ${mgr.last_name}`}
              activeLink={
                links.find((f) => f.company_manager.id === mgr.id)
                  ? true
                  : false
              }
              managerStatus={mgr.status}
              onAdd={() => onAddClick(mgr.id)}
              onDelete={() => onDeleteClick(mgr.id)}
            />
          ))}
          {error && (
            <p style={{ color: 'red', marginTop: '1em', fontWeight: 'bold' }}>
              {error}
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            basic
            size="small"
            onClick={() => onClose()}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

EmployeeManagerLinks.defaultProps = {
  links: []
};

const ManagerItem = ({
  managerName,
  managerStatus,
  activeLink,
  onAdd,
  onDelete
}) => (
  <div
    className={`emp-mgr-link${managerStatus === 1 ? ' inactive' : ' active'}`}
  >
    <div className="content">
      <h4>{managerName}</h4>
      <p>{managerStatus === 0 ? 'Active' : 'Inactive'} Manager</p>
    </div>
    <div className="actions">
      {activeLink && <BasicButton text="Remove" onClick={onDelete} />}
      {!activeLink && managerStatus === 0 && (
        <ActionButton text="Add" onClick={onAdd} />
      )}
    </div>
  </div>
);

ManagerItem.defaultProps = {
  activeLink: false,
  managerStatus: 0,
  managerName: ''
};
