import React from 'react';

export default function SectionTitle({ title, subtitle, large, icon }) {
  return (
    <div className={`section-title${large ? ' large' : ''}`}>
      <h1>
        {icon && <React.Fragment>{icon}</React.Fragment>}
        {title}
      </h1>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
}

SectionTitle.defaultProps = {
  icon: null,
  large: false,
  title: '',
  subtitle: ''
};
