import {
  business,
  collaboration,
  communicate,
  customer,
  drive,
  financial,
  influence,
  manage,
  navigate,
  strategic
} from './groups';

export default function generateRows(output) {
  if (!output) return [];

  return [
    ...business(output),
    ...collaboration(output),
    ...communicate(output),
    ...customer(output),
    ...drive(output),
    ...financial(output),
    ...influence(output),
    ...manage(output),
    ...navigate(output),
    ...strategic(output)
  ].map((r, i) => ({
    ...r,
    key: i
  }));
}
