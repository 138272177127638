import React from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import getLogoUrl from '@util/formatters/getLogoUrl';

import logo from 'assets/images/logo.png';

export default function Meta({ title, date, companyLogo }) {
  return (
    <div className="perf-meta">
      <img src={companyLogo ? getLogoUrl(companyLogo) : logo} alt="Logo" />
      <h1>{title}</h1>
      {date && (
        <div className="date">
          <p>
            <FaRegCalendarAlt /> {date}
          </p>
        </div>
      )}
    </div>
  );
}

Meta.defaultProps = {
  companyLogo: logo,
  date: '',
  title: ''
};
