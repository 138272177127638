import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import BasicForm from 'components/shared/Forms/BasicForm';

import { SET_TOAST } from 'duck/toast';

const tfOptions = [
  { text: 'Enabled', value: true },
  { text: 'Disabled', value: false }
];

function ReportSettings({
  companyId,
  dispatch,
  coach,
  select,
  medical,
  clinical
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: 'coach_report',
          label: 'Coach Report',
          select: true,
          options: tfOptions,
          initialValue: coach,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'select_report',
          label: 'Select Report',
          select: true,
          options: tfOptions,
          initialValue: select,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      grouped: true,
      fields: [
        {
          name: 'medical_report',
          label: 'Medical Report',
          select: true,
          options: tfOptions,
          initialValue: medical,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'clinical_report',
          label: 'Clinical Report',
          select: true,
          options: tfOptions,
          initialValue: clinical,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    }
  ];

  function onSubmit(postData) {
    setLoading(true);

    axios
      .put(`/companies/${companyId}/`, postData)
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Report Settings Updated!'
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <div className="segment">
      <BasicForm
        buttonText="Save"
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

ReportSettings.defaultProps = {
  companyId: null,
  coach: false,
  select: false,
  medical: false,
  clinical: false
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ReportSettings);
