import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import Fade from 'react-reveal/Fade';
import { Form } from 'semantic-ui-react';

import './index.scss';

import { SET_TOAST } from 'duck/toast';

import { FaCheckCircle } from 'react-icons/fa';

import {
  DESCRIPTOR_COGNITION,
  DESCRIPTOR_PERSONALITY,
  questionDescriptorOptions
} from '@util/data/questionDescriptor';

import {
  MULTIPLE_CHOICE_VALUE,
  TRUE_FALSE_VALUE,
  YES_NO_VALUE,
  OPEN_RESPONSE_VALUE,
  questionTypeOptions
} from '@util/data/questionType';

import surveyVariety, {
  ENGAGEMENT_VALUE,
  CLEARSCREEN_VALUE,
  COMPETENCY_ASSESSMENT_VALUE,
  PERFORMANCE_REVIEW
} from '@util/data/surveyVariety';

import { BasicButton, SubmitButton } from 'components/shared/Buttons';

const HAS_CORRECT_ANSWER_NO = false;
const HAS_CORRECT_ANSWER_YES = true;

class QuestionForm extends Component {
  state = {
    loading: false,
    questionType: null,
    data: {
      a: 'Strongly Disagree',
      b: 'Disagree',
      c: 'Neutral',
      d: 'Agree',
      e: 'Strongly Agree',
      f: '',
      g: '',
      h: '',
      i: '',
      j: '',
      k: '',
      categoryString: '',
      hasComment: false,
      hasCorrectAnswer: HAS_CORRECT_ANSWER_NO,
      correctAnswer: null,
      descriptor: DESCRIPTOR_PERSONALITY,
      variety: CLEARSCREEN_VALUE,
      text: '',
      dimension: null,
      factor: null
    },
    multiMode: 'clearscreen'
  };

  componentDidMount = () => {
    this.populateStateFromProps();
  };

  setMultipleChoiceAnswers = () => {
    if (this.state.multiMode === 'clearscreen') {
      this.setState({
        data: {
          ...this.state.data,
          a: 'Strongly Disagree',
          b: 'Disagree',
          c: 'Neutral',
          d: 'Agree',
          e: 'Strongly Agree'
        }
      });
    } else if (this.state.multiMode === '360') {
      this.setState({
        data: {
          ...this.state.data,
          a: '1',
          b: '2',
          c: '3',
          d: '4',
          e: '5'
        }
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          a: 'Unacceptable',
          b: 'Below Expectations',
          c: 'Meets Expectations',
          d: 'Exceeds Expectations',
          e: 'Exemplary'
        }
      });
    }
  };

  onMultiModeChange = (multiMode) => {
    this.setState({ multiMode }, () => this.setMultipleChoiceAnswers());
  };

  populateStateFromProps = () => {
    const { props } = this;

    this.setState({
      questionType: props.type,
      data: {
        a: props.a,
        b: props.b,
        c: props.c,
        d: props.d,
        e: props.e,
        f: props.f,
        g: props.g,
        h: props.h,
        i: props.i,
        j: props.j,
        k: props.k,
        l: props.l,
        correctAnswer: props.correctAnswer,
        categoryString: props.categoryString,
        descriptor: props.descriptor,
        hasComment: props.hasComment,
        hasCorrectAnswer: props.hasCorrectAnswer,
        variety: props.variety,
        text: props.text
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.populateStateFromProps();
    }
  };

  handleChange = (e) => {
    const data = { ...this.state.data, [e.target.name]: e.target.value };
    this.setState({ data });
  };

  handleDescriptorChange = (descriptor) => {
    this.setState({
      data: {
        ...this.state.data,
        descriptor,
        a: descriptor === DESCRIPTOR_PERSONALITY ? 'Strongly Disagree' : '',
        b: descriptor === DESCRIPTOR_PERSONALITY ? 'Disagree' : '',
        c: descriptor === DESCRIPTOR_PERSONALITY ? 'Neutral' : '',
        d: descriptor === DESCRIPTOR_PERSONALITY ? 'Agree' : '',
        e: descriptor === DESCRIPTOR_PERSONALITY ? 'Strongly Agree' : ''
      }
    });
  };

  formatRequest = () => {
    const { data, questionType } = this.state;
    const { descriptor, text, variety } = this.state.data;
    let postData = {
      descriptor,
      variety,
      text
    };

    let clearMultipleChoiceObj = {
      a: '',
      b: '',
      c: '',
      d: '',
      e: '',
      f: '',
      g: '',
      h: '',
      i: '',
      j: '',
      k: '',
      l: '',
      correct_a: false,
      correct_b: false,
      correct_c: false,
      correct_d: false,
      correct_e: false,
      correct_f: false,
      correct_g: false,
      correct_h: false
    };

    switch (questionType) {
      case MULTIPLE_CHOICE_VALUE:
        postData = {
          ...postData,
          true_false: false,
          yes_no: false,
          multiple_choice: true,
          a: data.a,
          b: data.b,
          c: data.c,
          d: data.d,
          e: data.e,
          f: data.f,
          g: data.g,
          h: data.h,
          i: data.i,
          j: data.j,
          k: data.k,
          l: data.l,
          category_string: data.categoryString
        };
        break;
      case TRUE_FALSE_VALUE:
        postData = {
          ...postData,
          ...clearMultipleChoiceObj,
          multiple_choice: false,
          yes_no: false,
          true_false: true
        };
        break;
      case YES_NO_VALUE:
        postData = {
          ...postData,
          ...clearMultipleChoiceObj,
          multiple_choice: false,
          true_false: false,
          yes_no: true
        };
        break;
      case OPEN_RESPONSE_VALUE:
        postData = {
          ...postData,
          open_response: true,
          descriptor: null,
          variety: PERFORMANCE_REVIEW,
          category_string: data.categoryString
        };
    }

    if (data.hasCorrectAnswer) {
      // postData[`correct_${data.correctAnswer}`] = true;
      postData = {
        ...postData,
        has_correct_answer: true,
        correct_a: false,
        correct_b: false,
        correct_c: false,
        correct_d: false,
        correct_e: false,
        correct_f: false,
        correct_g: false,
        correct_h: false,
        correct_i: false,
        correct_j: false,
        correct_k: false,
        correct_l: false,
        correct_yes: false,
        correct_no: false,
        correct_true_false: null,
        [`correct_${data.correctAnswer}`]: true
      };
    } else {
      postData.has_correct_answer = false;
    }

    if (data.hasComment) {
      postData.has_respondant_comment = true;
    } else {
      postData.has_respondant_comment = false;
    }

    if (this.props.isEngagement) {
      postData.descriptor = null;
      postData.dimension = data.dimension;
      // this is a custom question
      postData.factor = 6;
    }

    return postData;
  };

  setQuestionType = (questionType) => {
    this.setState({
      questionType,
      data: {
        ...this.state.data,
        correctAnswer: null,
        hasCorrectAnswer: HAS_CORRECT_ANSWER_NO
      }
    });
  };

  onCorrectAnswerClick = (e, correctAnswer) => {
    e.preventDefault();

    this.setState({
      data: {
        ...this.state.data,
        correctAnswer
      }
    });
  };

  onVarietyChange = (e, d) => {
    this.setState(
      {
        data: { ...this.state.data, variety: d.value },
        multiMode:
          d.value === CLEARSCREEN_VALUE ? 'clearscreen' : 'performance_review'
      },
      () => this.setMultipleChoiceAnswers()
    );
  };

  onSubmit = (e) => {
    e.preventDefault();

    const postData = this.formatRequest();

    if (this.props.updating) {
      return this.props.onSubmit(postData);
    }

    this.setState({ loading: true });

    axios
      .post(`/questions/`, postData)
      .then(({ data }) => {
        if (this.props.isEngagement) {
          return this.props.onSuccess(data);
        }

        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Question Created!'
          }
        });
      })
      .then(() => this.props.onClose())
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  };

  render() {
    const { props } = this;
    const { isEngagement } = props;
    const { data, questionType } = this.state;
    const {
      correctAnswer,
      descriptor,
      hasCorrectAnswer,
      variety
    } = this.state.data;

    const submitDisabled = () => {
      if (
        !data.text ||
        !questionType
        // (questionType === MULTIPLE_CHOICE_VALUE && !data.a) ||
        // (questionType === MULTIPLE_CHOICE_VALUE && !data.b) ||
        // (questionType === MULTIPLE_CHOICE_VALUE && !data.c) ||
        // (questionType === MULTIPLE_CHOICE_VALUE && !data.d) ||
        // (questionType === MULTIPLE_CHOICE_VALUE && !data.e)
      ) {
        return true;
      }

      if (isEngagement) {
        if (data.dimension === null || data.factor === null) return true;
      }

      return false;
    };

    return (
      <Fade>
        <div className={!isEngagement ? 'segment' : ''}>
          <form className="ui form question-form">
            <div className="question-meta">
              <Form.Select
                value={questionType}
                onChange={(e, d) => this.setQuestionType(d.value)}
                options={questionTypeOptions}
                label="What type of question?"
                placeholder="Select a type..."
              />
              {!isEngagement &&
                questionType &&
                questionType !== OPEN_RESPONSE_VALUE && (
                  <Form.Select
                    name="variety"
                    value={data.variety}
                    onChange={(e, d) =>
                      this.handleChange({
                        target: { name: d.name, value: d.value }
                      })
                    }
                    onChange={this.onVarietyChange}
                    options={surveyVariety}
                    label="What variety of question?"
                    placeholder="Select a variety..."
                  />
                )}
              {questionType &&
                variety === CLEARSCREEN_VALUE &&
                questionType !== OPEN_RESPONSE_VALUE && (
                  <Form.Select
                    name="descriptor"
                    value={data.descriptor}
                    onChange={(e, d) => this.handleDescriptorChange(d.value)}
                    options={questionDescriptorOptions}
                    label="What is the question's descriptor?"
                    placeholder="Select a descriptor..."
                  />
                )}
              {
                // questionType && descriptor === DESCRIPTOR_COGNITION &&
                questionType &&
                  variety === CLEARSCREEN_VALUE &&
                  questionType !== OPEN_RESPONSE_VALUE && (
                    <Form.Select
                      name="hasCorrectAnswer"
                      value={data.hasCorrectAnswer}
                      onChange={(e, d) =>
                        this.handleChange({
                          target: { name: d.name, value: d.value }
                        })
                      }
                      options={[
                        { text: 'No', value: HAS_CORRECT_ANSWER_NO },
                        { text: 'Yes', value: HAS_CORRECT_ANSWER_YES }
                      ]}
                      label="Does this quesiton have a correct answer?"
                      placeholder="Select the correct answer..."
                    />
                  )
              }
              {questionType && variety === PERFORMANCE_REVIEW && (
                <Form.Input
                  label="Category (optional)"
                  onChange={(e) =>
                    this.setState({
                      data: {
                        ...this.state.data,
                        categoryString: e.target.value
                      }
                    })
                  }
                  value={data.categoryString}
                />
              )}
              {questionType && isEngagement && (
                <React.Fragment>
                  <Form.Select
                    label="Dimension"
                    value={data.dimension}
                    placeholder="Select one..."
                    options={dimensionOptions}
                    onChange={(e, d) =>
                      this.setState({
                        data: {
                          ...this.state.data,
                          dimension: d.value
                        }
                      })
                    }
                  />
                  <p>
                    Factor: <b>Custom</b>
                  </p>
                  {/* <Form.Select
                    label="Factor"
                    value={data.factor}
                    placeholder="Select one..."
                    options={factorOptions}
                    onChange={(e, d) =>
                      this.setState({
                        data: {
                          ...this.state.data,
                          factor: d.value
                        }
                      })
                    }
                  /> */}
                </React.Fragment>
              )}
              {questionType && (
                <Form.TextArea
                  name="text"
                  value={data.text}
                  label="Question Text"
                  onChange={this.handleChange}
                />
              )}
              {questionType && questionType === MULTIPLE_CHOICE_VALUE && (
                <Form.Checkbox
                  label="Include comment box"
                  checked={data.hasComment}
                  onChange={(e, d) =>
                    this.handleChange({
                      target: { name: 'hasComment', value: d.checked }
                    })
                  }
                />
              )}
            </div>
            {questionType === MULTIPLE_CHOICE_VALUE && (
              <div className="question-details">
                <Form.Select
                  label="Prefill Choices With"
                  onChange={(e, d) => this.onMultiModeChange(d.value)}
                  value={this.state.multiMode}
                  options={[
                    {
                      key: 1,
                      text: 'ClearScreen Choices',
                      value: 'clearscreen'
                    },
                    {
                      key: 2,
                      text: 'Performance Review Choices',
                      value: 'performance_review'
                    },
                    {
                      key: 3,
                      text: '360 Choices',
                      value: '360'
                    }
                  ]}
                />
                {[
                  { name: 'a', label: 'Choice A' },
                  { name: 'b', label: 'Choice B' },
                  { name: 'c', label: 'Choice C' },
                  { name: 'd', label: 'Choice D' },
                  { name: 'e', label: 'Choice E' },
                  { name: 'f', label: 'Choice F' },
                  { name: 'g', label: 'Choice G' },
                  { name: 'h', label: 'Choice H' },
                  { name: 'i', label: 'Choice I' },
                  { name: 'j', label: 'Choice J' },
                  { name: 'k', label: 'Choice K' },
                  { name: 'l', label: 'Choice L' }
                ]
                  .filter((f) =>
                    variety === COMPETENCY_ASSESSMENT_VALUE
                      ? true
                      : !['f', 'g', 'h', 'i', 'j', 'k', 'l'].includes(f.name)
                  )
                  .map((m, i) => (
                    <Form.Input
                      key={i}
                      name={m.name}
                      value={data[m.name]}
                      onChange={this.handleChange}
                      label={
                        <ChoiceLabel
                          text={m.label}
                          isCorrectAnswer={correctAnswer === m.name}
                          showButton={hasCorrectAnswer}
                          onClick={(e) => this.onCorrectAnswerClick(e, m.name)}
                        />
                      }
                    />
                  ))}
              </div>
            )}
            {questionType === TRUE_FALSE_VALUE && (
              <div className="question-details expanded">
                {hasCorrectAnswer && (
                  <Form.Select
                    label="Correct Answer"
                    options={[
                      { value: true, text: 'True' },
                      { value: false, text: 'False' }
                    ]}
                    placeholder="Select the correct answer..."
                    onChange={(e, d) =>
                      this.setState({
                        data: { ...this.state.data, correctAnswer: d.value }
                      })
                    }
                    value={data.correctAnswer}
                  />
                )}
              </div>
            )}
            {questionType === YES_NO_VALUE && (
              <div className="question-details expanded">
                {hasCorrectAnswer && (
                  <Form.Select
                    label="Correct Answer"
                    options={[
                      { value: 'yes', text: 'Yes' },
                      { value: 'no', text: 'No' }
                    ]}
                    placeholder="Select the correct answer..."
                    onChange={(e, d) =>
                      this.setState({
                        data: { ...this.state.data, correctAnswer: d.value }
                      })
                    }
                    value={data.correctAnswer}
                  />
                )}
              </div>
            )}
            <div className="form-actions">
              {props.showCancel && (
                <BasicButton onClick={this.props.onClose} text="Cancel" />
              )}
              <SubmitButton
                disabled={submitDisabled()}
                onClick={this.onSubmit}
                loading={this.state.loading || props.loading}
                text={props.buttonText}
              />
            </div>
          </form>
        </div>
      </Fade>
    );
  }
}

QuestionForm.defaultProps = {
  // data stuff
  a: 'Strongly Disagree',
  b: 'Disagree',
  c: 'Neutral',
  d: 'Agree',
  e: 'Strongly Agree',
  f: '',
  g: '',
  h: '',
  i: '',
  j: '',
  k: '',
  l: '',
  correctAnswer: null,
  descriptor: DESCRIPTOR_PERSONALITY,
  hasComment: false,
  hasCorrectAnswer: false,
  isEngagement: false,
  categoryString: '',
  text: '',
  type: null,
  variety: 0,
  // form stuff
  buttonText: 'Create',
  showCancel: false,
  // action stuff
  loading: false,
  updating: false
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(QuestionForm);

const dimensionOptions = [
  { key: 0, text: 'Organization', value: 0 },
  { key: 2, text: 'Self', value: 2 },
  { key: 1, text: 'Team', value: 1 }
];

const factorOptions = [
  { key: 0, text: 'Cohesion', value: 0 },
  { key: 3, text: 'Communication', value: 3 },
  { key: 6, text: 'Custom', value: 6 },
  { key: 2, text: 'Development', value: 2 },
  { key: 4, text: 'Resources', value: 4 },
  { key: 1, text: 'Supervision', value: 1 },
  { key: 5, text: 'Workload', value: 5 }
];

const ChoiceLabel = (props) => {
  return (
    <label className="choice-label">
      {props.text}
      {props.showButton && (
        <button
          className={props.isCorrectAnswer ? 'selected' : ''}
          onClick={props.onClick}
          title="Mark correct answer"
        >
          <FaCheckCircle />
        </button>
      )}
    </label>
  );
};
