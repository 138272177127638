import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Form as SemanticForm } from 'semantic-ui-react';

import { SubmitButton } from '../Buttons';
import CustomInputComponent from './CustomInputComponent';

export default function BasicForm(props) {
  let initialValues = {};
  let schemas = {};

  if (!props.fields.length) {
    return <p>No fields.</p>;
  }

  props.fields.forEach((f) => {
    if (f.grouped) {
      f.fields.forEach((field) => {
        initialValues[field.name] = field.initialValue;
        schemas[field.name] = field.schema();
      });
    } else {
      initialValues[f.name] = f.initialValue;
      schemas[f.name] = f.schema();
    }
  });

  const schema = Yup.object().shape(schemas);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          props.onSubmit(values);
          // resetForm();
        }}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form
            // className={`ui form ${loading ? 'loading' : ''}`}
            className="ui form"
          >
            {props.fields.map((f, i) => {
              if (f.grouped) {
                return (
                  <SemanticForm.Group widths="equal" key={i}>
                    {f.fields.map((fi, ii) => (
                      <Field
                        component={CustomInputComponent}
                        checkbox={fi.checkbox}
                        companyPositionSelect={fi.companyPositionSelect}
                        datepicker={fi.datepicker}
                        key={ii}
                        label={fi.label}
                        maxDate={fi.maxDate}
                        minDate={fi.minDate}
                        name={fi.name}
                        options={fi.options}
                        radio={fi.radio}
                        required={fi.required}
                        select={fi.select}
                        search={fi.search}
                        textarea={fi.textarea}
                      />
                    ))}
                  </SemanticForm.Group>
                );
              }

              return (
                <Field
                  component={CustomInputComponent}
                  checkbox={f.checkbox}
                  companyPositionSelect={f.companyPositionSelect}
                  datepicker={f.datepicker}
                  key={i}
                  label={f.label}
                  maxDate={f.maxDate}
                  minDate={f.minDate}
                  name={f.name}
                  options={f.options}
                  radio={f.radio}
                  required={f.required}
                  select={f.select}
                  search={f.search}
                  textarea={f.textarea}
                />
              );
            })}
            {props.error && <p className="form-error">{props.error}</p>}
            <div className="form-actions">
              {props.children}
              <SubmitButton
                style={props.buttonStyle}
                loading={props.loading}
                text={props.buttonText}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

BasicForm.defaultProps = {
  buttonStyle: {},
  buttonText: 'Save',
  fields: []
};
