import React, { useState } from 'react';
import { BarChart, Grid, SectionTitle } from './';
import { BasicButton, FilterButtons } from 'components/shared/Buttons';

import { formatTraitGroupResponses } from '../helpers';

export default function TraitGroup({ title, subtitle, questions, id, pGroup }) {
  const [activeId, setActiveId] = useState(null);

  const activeItem = questions.find((f) => f.id === activeId);

  const compositeResponses = formatTraitGroupResponses(
    questions.map((q) => q.responses).reduce((acc, cur) => acc.concat(cur), [])
  );

  const activeItemData = activeItem
    ? formatTraitGroupResponses(activeItem.responses)
    : null;

  return (
    <div className="trait-group" id={id}>
      <div className="left">
        <div className="tg-meta">
          <div className="group-meta">
            <SectionTitle
              title={`${title}${activeItem ? `: ${activeItem.title}` : ''}`}
              subtitle={
                activeItem ? activeItem.subtitle : 'viewing composite scores'
              }
            />
          </div>
          {/* <div className="meta">
            <h2>{activeItem ? activeItem.title : 'Composite Scores'}</h2>
            <p>
              {activeItem
                ? activeItem.subtitle
                : 'View individual questions by clicking below'}
            </p>
          </div> */}
        </div>
        <div className="viz">
          <BarChart
            width={1000}
            height={400}
            pGroup={pGroup}
            skills={
              activeItem
                ? activeItemData.map((m, i) => ({
                    x: i + 1,
                    y: m.skill_total,
                    color: m.rater_color
                  }))
                : compositeResponses.map((m, i) => ({
                    x: i + 1,
                    y: m.skill_total,
                    color: m.rater_color
                  }))
            }
            importance={
              activeItem
                ? activeItemData.map((m, i) => ({
                    x: i + 1,
                    y: m.importance_total,
                    color: m.rater_color
                  }))
                : compositeResponses.map((m, i) => ({
                    x: i + 1,
                    y: m.importance_total,
                    color: m.rater_color
                  }))
            }
            tickFormat={
              activeItem
                ? activeItemData.map((m) => m.rater_title)
                : compositeResponses.map((m) => m.rater_title)
            }
          />
          <BasicButton
            text="View Composite"
            onClick={() => setActiveId(null)}
            disabled={!activeId}
          />
        </div>
      </div>
      <div className="right">
        <div className="trait-questions">
          <Grid
            pGroup={pGroup}
            items={questions.map((m) => ({
              ...m,
              onClick: () =>
                activeId !== m.id ? setActiveId(m.id) : setActiveId(null),
              active: activeId === m.id
            }))}
          />
        </div>
      </div>
    </div>
  );
}

TraitGroup.defaultProps = {
  title: '',
  subtitle: '',
  // questions for the group
  questions: []
};
