import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.communicates_effectively_factors) return [];

  const best = output.communicates_effectively_factors.best
    ? Object.keys(output.communicates_effectively_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.communicate,
        value: output.communicates_effectively_factors.best[b]
      }))
    : [];
  const worst = output.communicates_effectively_factors.worst
    ? Object.keys(output.communicates_effectively_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.communicate,
        value: output.communicates_effectively_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Communicates Effectively',
      value: output.communicates_effectively,
      behavior_value: output.pan_result.communicates_effectively_score,
      cls: groupClasses.communicate,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Connection',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Presentation',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Impact',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Modality',
    //   cls: groupClasses.communicate,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Communicates-Effectively-Courses.aspx'
  }));
}
