import React from 'react';

export default function SectionTitle({ title, icon, subtitle, text }) {
  return (
    <div className="report-section-title">
      <div className="report-section-title-header">
        {icon && icon}
        {title && <h2>{title}</h2>}
      </div>
      {subtitle && <h3>{subtitle}</h3>}
      {text && <div className="text">{text}</div>}
    </div>
  );
}

SectionTitle.defaultProps = {
  title: '',
  icon: null,
  subtitle: '',
  text: null
};
