export default function personaGraphDataFormatter(data) {
  let lclLabel = data.calculation_container.lcl_likeness;
  lclLabel = lclLabel.charAt(0).toUpperCase() + lclLabel.slice(1);

  return {
    op: {
      name: data.profile_name,
      x: data.x_axis,
      y: data.y_axis,
      z: data.z_axios
    },
    candidate: {
      name: data.employee ? data.employee.full_name : 'Candidate',
      x: data.calculation_container.x_axis,
      y: data.calculation_container.y_axis,
      z: data.calculation_container.z_axis
    },
    lcl: {
      likeness: lclLabel,
      x: data.calculation_container.lcl_likeness_x,
      y: data.calculation_container.lcl_likeness_y,
      z: data.calculation_container.lcl_likeness_z
    }
  };
}
