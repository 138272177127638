import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaList, FaRegClock } from 'react-icons/fa';

import './index.scss';
import { BasicButton, ToggleButton } from 'components/shared/Buttons';
import { Wrapped } from 'components/Layouts';

import Meta from './Meta';
import QuestionItem from './QuestionItem';
import Timeline from './Timeline';

const QUESTION_VIEW = 'QUESTION_VIEW';
const TIMELINE_VIEW = 'TIMELINE_VIEW';

export default function QuestionPresentation({ surveyId }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(populateMeta({}));
  const [questions, setQuestions] = useState([]);
  const [activeView, setActiveView] = useState(QUESTION_VIEW);
  const [isOpen, setOpen] = useState(false);

  if (true) return null;

  if (!surveyId) return null;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/surveys/${surveyId}/response-evaluation/`)
      .then(({ data }) => {
        setQuestions(data.questions);
        setMeta(data);
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  }

  const presentations = questions
    .map((q) =>
      q.question_presentations.map((p) => ({
        ...p,
        question_id: q.id,
        question_text: q.text
      }))
    )
    .flat()
    .sort((a, b) => (a.id > b.id ? 1 : -1));
  // .sort((a, b) => (new Date(a.started_at) > new Date(b.started_at) ? 1 : -1));

  const viewBtns = [
    {
      key: 1,
      onClick: () => setActiveView(QUESTION_VIEW),
      title: 'Question View',
      icon: <FaList />,
      active: activeView === QUESTION_VIEW
    },
    {
      key: 2,
      onClick: () => setActiveView(TIMELINE_VIEW),
      title: 'Timeline View',
      icon: <FaRegClock />,
      active: activeView === TIMELINE_VIEW
    }
  ];

  if ((!loading && !questions.length) || error) {
    return null;
  }

  return (
    <Wrapped
      title="Question Presentation"
      button={
        <ToggleButton
          closedText="Add Calculation Container"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen && (
        <div>
          {meta && !loading && (
            <Meta
              averagePresentationTime={meta.average_presentation_time_formatted}
              averageResponseLatency={meta.average_response_latency_formatted}
              totalAnswerChanges={meta.total_answer_changes}
              totalPresentationTime={meta.total_presentation_time_formatted}
            />
          )}
          <div className="pres-view-btns">
            {viewBtns.map((btn) => (
              <BasicButton
                key={btn.key}
                onClick={btn.onClick}
                title={btn.title}
                icon={btn.icon}
                cls={btn.active ? 'active' : ''}
              />
            ))}
          </div>
          {activeView === TIMELINE_VIEW && <Timeline items={presentations} />}
          {activeView === QUESTION_VIEW && (
            <div>
              {questions.map((q) => (
                <QuestionItem
                  key={q.id}
                  text={q.text}
                  averagePresentationTime={
                    q.average_presentation_time_in_seconds
                  }
                  presentations={q.question_presentations}
                  questionLatency={q.response_latency_formatted}
                  totalAnswerChanges={q.total_answer_changes}
                  totalPresentations={q.total_presentations}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </Wrapped>
  );
}

QuestionPresentation.defaultProps = {
  surveyId: null
};

function populateMeta(d) {
  const data = { ...d };

  return {
    average_presentation_time_formatted:
      data.average_presentation_time_formatted || '',
    average_response_latency_formatted:
      data.average_response_latency_formatted || '',
    total_presentation_time_formatted:
      data.total_presentation_time_formatted || '',
    total_answer_changes: data.total_answer_changes || ''
  };
}
