import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import ProfileItem from './ProfileItem';

import { SET_TOAST } from 'duck/toast';

import './index.scss';

function ContainerItem({
  defaultOpen,
  name,
  employeeId,
  companyId,
  createdAt,
  id,
  remoteCode,
  surveyId,
  surveyVariety,
  dispatch,
  fetchContainers
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [editing, setEditing] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [reportsEnabled, setReportsEnabled] = useState({
    clinical: false,
    coach: false,
    medical: false,
    select: false
  });

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  useEffect(() => {
    if (!isOpen) return;

    fetchData();
    fetchCompanyReportSettings();
  }, [isOpen]);

  useEffect(() => {
    if (!defaultOpen) return;

    setOpen(true);
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/calculation-containers/${id}/`)
      .then(({ data }) => {
        setProfiles(data.occupational_profile_calculation_containers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function fetchCompanyReportSettings() {
    if (!companyId) return;

    axios
      .get(`/companies/${companyId}/`)
      .then(({ data }) => {
        setReportsEnabled({
          clinical: data.clinical_report,
          coach: data.coach_report,
          medical: data.medical_report,
          select: data.select_report
        });
      })
      .catch((err) => {});
  }

  function onNameChange() {
    const postData = {
      name: nameValue,
      calc_container_id: id
    };

    axios
      .put(`/calculation-containers/${id}/`, postData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Container updated!'
          }
        });
      })
      .then(() => fetchContainers())
      .then(() => setEditing(false))
      .catch((err) => {
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  function onCompleteClick() {
    setCompleteLoading(true);

    const req = {
      survey_id: surveyId
    };

    axios
      .post(`/hol-mark-report-ready/`, req)
      .then(() => {
        setCompleteLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: { message: 'Report Link Emailed!', positive: true }
        });
        fetchContainers();
      })
      .catch((err) => {
        setCompleteLoading(false);
      });
  }

  const companyReportsEnabled = Object.keys(reportsEnabled)
    .map((r, k) => ({
      key: r,
      value: reportsEnabled[r],
      text: r
    }))
    .filter((f) => f.value);

  return (
    <div className="container-item segment">
      <div className="meta">
        {editing ? (
          <Form>
            <Form.Input
              value={nameValue}
              onBlur={onNameChange}
              onChange={(e) => setNameValue(e.target.value)}
            />
          </Form>
        ) : (
          <h2>{name}</h2>
        )}
        <Button
          size="mini"
          circular
          icon={editing ? 'remove' : 'pencil'}
          onClick={() => setEditing(!editing)}
          basic
          style={{ margin: '0 10px 0 5px' }}
        />
        {!editing && <p>{createdAt}</p>}
        <div className="actions">
          <Button
            size="tiny"
            basic
            icon={isOpen ? 'caret up' : 'caret down'}
            onClick={() => setOpen(!isOpen)}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          {surveyVariety === 3 && (
            <div style={{ marginBottom: '1.5em' }}>
              <p style={{ fontWeight: 'bold' }}>
                When you're satisfied with the results below, clicking the
                button below will email a report link to the survey taker.
              </p>
              <div>
                <Button
                  content="Release Results"
                  primary
                  loading={completeLoading}
                  onClick={onCompleteClick}
                  disabled={completeLoading}
                />
                <Button
                  as="a"
                  href={`/code/${remoteCode}`}
                  content="View Report"
                  target="_blank"
                />
              </div>
            </div>
          )}
          {!loading && profiles.length === 0 && <p>No profiles!</p>}
          {profiles
            .sort((a, b) => (a.profile_name > b.profile_name ? 1 : -1))
            .map((profile) => (
              <ProfileItem
                key={profile.id}
                id={profile.id}
                fetchData={fetchData}
                calculationContainerId={id}
                name={profile.profile_name}
                completed={profile.completed}
                evaluated={profile.evaluated}
                cosTotal={profile.cos_total}
                cogScore={profile.cog_score}
                steqScore={profile.steq_score_formatted}
                similarity={profile.similarity}
                viewable={profile.viewable}
                employeeId={employeeId}
                reportsEnabled={companyReportsEnabled.map((r) => r.text)}
                surveyVariety={surveyVariety}
              />
            ))}
        </div>
      )}
    </div>
  );
}

ContainerItem.defaultProps = {
  defaultOpen: false
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ContainerItem);
