import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import './index.scss';

const iconStyle = {
  size: 20,
};

export default function Message(props) {
  return (
    <div className={`msg${props.negative ? ' negative' : ''}`}>
      <div className="icon">
        {
          props.negative &&
            <FaExclamationCircle {...iconStyle} />
        }
      </div>
      <p>{props.text}</p>
    </div>
  );
}
