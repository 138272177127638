import React from 'react';
import { PartnerResultInfo } from './';

export function NotStarted({ children }) {
  return <div>{children}</div>;
}

export function Incomplete({ children }) {
  return <div>{children}</div>;
}

export function Complete({ employee }) {
  return (
    <div>
      <PartnerResultInfo
        name={employee.first_name}
        derailers={employee.derailers}
        cog={employee.cog}
        cls={employee.cls}
        fpf={employee.fpf}
        persona={employee.persona}
        coachability={employee.coachability}
        workMotives={employee.work_motives}
      />
    </div>
  );
}
