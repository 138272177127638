import React, { Component } from 'react';
import axios from 'axios';
import { Wrapped } from 'components/Layouts';
import {
  CompanyManagerForm,
  CompanyManagement
} from 'components/CompanyManager';

export default class ManagerDetail extends Component {
  state = {
    data: {
      firstName: '',
      lastName: '',
      email: '',
      hrAdmin: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      status: null,
      country: ''
    },
    managedCompanies: [],
    loading: true
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.setState({ loading: true });
    axios
      .get(`/company-managers/${this.props.match.params.id}/`)
      .then(({ data }) => {
        this.setState({
          data: {
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            address1: data.address_line_1,
            address2: data.address_line_2,
            city: data.city,
            hrAdmin: data.hr_admin,
            state: data.state_provence,
            zip: data.zip,
            phone: data.phone,
            status: data.status,
            country: data.country
          },
          managedCompanies: data.companies,
          loading: false
        });
      })
      .catch((err) => {});
  };

  render() {
    const { id } = this.props.match.params;
    const { data } = this.state;

    return (
      <div>
        <Wrapped title={`${data.firstName} ${data.lastName} - Company Manager`}>
          <CompanyManagerForm
            companyManagerId={id}
            firstName={data.firstName}
            lastName={data.lastName}
            email={data.email}
            address1={data.address1}
            address2={data.address2}
            city={data.city}
            hrAdmin={data.hrAdmin}
            phone={data.phone}
            state={data.state}
            zip={data.zip}
            country={data.country}
            status={data.status}
            onUpdate={this.fetchData}
          />
        </Wrapped>
        <CompanyManagement
          companyManagerId={id}
          fetchData={this.fetchData}
          managedCompanies={this.state.managedCompanies}
        />
      </div>
    );
  }
}
