import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const BasicGauge = ({ description, total }) => {
  const svgDiv = useRef(null);

  useEffect(() => {
    if (svgDiv.current) {
      const gaugeDiv = d3.select(svgDiv.current);

      const data = [
        {
          value: total,
          label: `${total}%`,
          description
        },
        { min: 0, max: 20, color: '#e5383b', label: '0%', description: 'Poor' },
        {
          min: 20,
          max: 40,
          color: '#fdc500',
          label: '20%',
          description: 'Fair'
        },
        {
          min: 40,
          max: 60,
          color: '#ffff3f',
          label: '40%',
          description: 'Good'
        },
        {
          min: 60,
          max: 80,
          color: '#80b918',
          label: '60%',
          description: 'Great'
        },
        {
          min: 80,
          max: 100,
          color: '#007f5f',
          label: '80%',
          description: 'Excellent'
        }
      ];

      const height = 400;
      const radius = 500 / 2.85;
      const ringInset = 20;
      const ringWidth = 40;
      const degrees = 180;
      const minAngle = -90;
      const maxAngle = 90;
      const range = maxAngle - minAngle;
      const labelInset = -5;
      const pointerLineHeight = -radius * 0.9;
      const pointerLineData = [
        [1.5, 0],
        [1.5, pointerLineHeight],
        [0, pointerLineHeight - 5],
        [-1.5, pointerLineHeight],
        [-1.5, 0]
      ];

      const value = data.find((item) => item.hasOwnProperty('value')) || {
        value: 0
      };
      const ranges = data.reduce((filtered, item) => {
        if (item.hasOwnProperty('min') && item.hasOwnProperty('max')) {
          filtered.push(item);
        }
        return filtered;
      }, []);

      const deg2rad = (deg) => (deg * Math.PI) / 180;
      const centerTx = () => `translate(${radius + 10}, ${radius + 10})`;

      const scale = d3
        .scaleLinear()
        .range([0, 1])
        .domain([
          Math.min(...ranges.map((range) => range.min)),
          Math.max(...ranges.map((range) => range.max))
        ]);

      const ticks = scale.ticks(ranges.length);
      const tickData = d3.range(ranges.length).map(() => 1 / ranges.length);

      const arc = d3
        .arc()
        .innerRadius(radius - ringWidth - ringInset)
        .outerRadius(radius - ringInset)
        .startAngle((d, i) => deg2rad(minAngle + d * i * degrees))
        .endAngle((d, i) => deg2rad(minAngle + d * (i + 1) * degrees));

      let svg = gaugeDiv
        // .select(classSelector)
        .append('svg')
        .attr('class', 'gauge')
        .attr('width', height + 15)
        .attr('height', height / 2 + 100);

      const arcs = svg
        .append('g')
        .attr('class', 'arc')
        .attr('transform', centerTx);

      arcs
        .selectAll('path')
        .data(tickData)
        .enter()
        .append('path')
        .attr('fill', (_, i) => ranges[i].color)
        // .attr('fill', (_, i) => {
        //   return d3.hsl(i * 5, 1, .5).toString();
        // })
        .attr('d', arc)
        .attr('id', (_, i) => `arc-${i}`);

      arcs
        .selectAll('text.description')
        .data(ticks)
        .enter()
        .append('text')
        .attr('class', 'description')
        .attr('dy', labelInset - 15)
        .append('textPath')
        .attr('font-family', 'Open Sans')
        .attr('font-weight', 500)
        .style('letter-spacing', 1)
        .attr('font-size', '16px')
        .attr('href', (_, i) => `#arc-${i}`)
        .text((d, i) => {
          const item = data.find(
            (item) => d === item.min && item.hasOwnProperty('description')
          );
          if (item) {
            return item.description;
          }
        });

      const pointerGroup = svg
        .append('g')
        .data([pointerLineData])
        .attr('class', 'pointer')
        .attr('transform', centerTx);

      const pointerLine = d3.line();

      pointerGroup
        .append('path')
        .attr('d', pointerLine)
        .attr('transform', `rotate(${minAngle})`)
        // .transition()
        // .duration(3000)
        // .ease('elastic')
        .attr('transform', `rotate(${minAngle + scale(value.value) * range})`);

      pointerGroup.append('circle').attr('r', 7);

      const mainLegendGroup = svg
        .append('g')
        .attr('class', 'main-label')
        .attr('transform', `translate(10, ${radius + 10})`);

      mainLegendGroup
        .append('text')
        .attr('class', 'label')
        .attr('y', 35)
        .attr('x', radius)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 500)
        .text(value.label || value.value);

      mainLegendGroup
        .append('text')
        .attr('class', 'description')
        .attr('y', 55)
        .attr('x', radius)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .text(value.description);
    }
  });

  return <div ref={svgDiv}></div>;
};

export default function Gauges({ clsValue, fpfValue }) {
  return (
    <div className="gauges">
      <BasicGauge
        className="report-gauge-cls"
        description="Chance of Leadership Success"
        total={clsValue}
      />
      <BasicGauge
        className="report-gauge-fpf"
        description="Function Position Fit"
        total={fpfValue}
      />
    </div>
  );
}

Gauges.defaultProps = {
  clsValue: 0,
  fpfValue: 0
};
