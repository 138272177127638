import React from 'react';
import Slide from 'react-reveal/Slide';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';

import { SET_LOADING } from '../../../duck/toast';

import './index.scss';

class Loading extends React.Component {
  state = {}

  componentDidUpdate = (prevProps) => {
    if (prevProps.duration !== this.props.duration) {
      this.setTimer(this.props.duration);
    }
  }

  setTimer = (duration) => {
    setTimeout(() => {
      this.setVisible(false);
    }, duration)
  }

  setVisible = (visible) => {
    this.props.dispatch({ type: SET_LOADING, payload: null })
  }

  render() {
    const { props } = this;
    const { visible } = props;

    return (
      <div className={`loading-wrapper ${visible ? 'visible' : ''}`}>
        <Slide top when={visible}>
          <div className={`loading-content ${visible ? 'visible' : ''}`}>
            <Loader active inline />
            <p>{props.message}</p>
          </div>
        </Slide>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    visible: state.loading,
    message: state.loading && state.loading.message ? state.loading.message : 'Loading...',
  }
}

export default connect(mapStateToProps)(Loading);
