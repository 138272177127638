import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.business_industry_acumen_factors) return [];

  const best = output.business_industry_acumen_factors.best
    ? Object.keys(output.business_industry_acumen_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.business,
        value: output.business_industry_acumen_factors.best[b]
      }))
    : [];
  const worst = output.business_industry_acumen_factors.worst
    ? Object.keys(output.business_industry_acumen_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.business,
        value: output.business_industry_acumen_factors.worst[b]
      }))
    : [];
  return [
    {
      title: 'Business & Industry Acumen',
      value: output.business_industry_acumen,
      behavior_value: output.pan_result.business_industry_acumen_score,
      cls: groupClasses.business,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Teamwork',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'LP Structure',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'LP Strategy',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Reconnaissance',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Salesforce',
    //   cls: groupClasses.business,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Business-and-Industry-Acumen-Courses.aspx'
  }));
}
