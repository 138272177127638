import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import { Message } from '../';

import Fade from 'react-reveal/Fade';

const loaderConfig = {
  type: 'Bars',
  height: 20,
  width: 20,
  color: '#fff'
};

class Login extends Component {
  state = {
    email: '',
    error: null,
    loading: false,
    password: '',
    remember: false
  };

  email = React.createRef();
  pw = React.createRef();

  componentDidMount = () => {
    const email = localStorage.getItem('email') || '';

    if (email) {
      this.setState({ email, remember: true });
      setTimeout(() => {
        this.pw.current.focus();
      }, 100);
    } else {
      this.setState({ email: '', remember: false });
      setTimeout(() => {
        this.email.current.focus();
      }, 100);
    }
  };

  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onRememberCheck = (e) => {
    if (e.target.checked) {
      localStorage.setItem('email', this.state.email);
      this.setState({ remember: true });
    } else {
      localStorage.removeItem('email');
      this.setState({ remember: false });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const postData = {
      email: this.state.email,
      entity: this.props.entity,
      password: this.state.password,
      representer: this.props.representer
    };

    this.setState({ loading: true });

    let postUrl = `/admin-login/`;

    if (this.props.entity === 'CompanyManager') {
      postUrl = `/company-manager-login/`;
    }

    axios
      .post(postUrl, postData)
      .then(({ data }) => {
        // this.props.dispatch({ type: SET_ADMIN, payload: data })
        if (this.props.entity === 'CompanyManager') {
          return (window.location = `/employees`);
        }

        return (window.location = `/admin/surveys`);
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message || 'Try again later',
          loading: false
        });
      });
  };

  render() {
    const { error } = this.state;

    let title = 'Login';

    if (localStorage.getItem('user')) {
      title = `Welcome back, ${JSON.parse(localStorage.getItem('user')).name}!`;
    }

    return (
      <div>
        <h1>{this.props.title}</h1>
        <form className="auth-form">
          <input
            ref={this.email}
            placeholder="Email"
            type="text"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <input
            ref={this.pw}
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          {error && (
            <Fade bottom>
              <Message negative text={error} />
            </Fade>
          )}
          <button
            disabled={!this.state.email || !this.state.password ? true : false}
            onClick={this.onSubmit}
            id="submit"
          >
            {this.state.loading ? <Loader {...loaderConfig} /> : 'Login'}
          </button>
        </form>
        <div className="auth-other">
          <div className="form-group">
            <input
              type="checkbox"
              onChange={this.onRememberCheck}
              checked={this.state.remember}
            />
            <label>Remember Me</label>
          </div>
          {this.props.entity === 'CompanyManager' && (
            <Link to="/forgot-password">Forgot Password?</Link>
          )}
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  entity: 'Admin',
  representer: 'AdminRepresenter',
  title: 'Login'
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Login);
