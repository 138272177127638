import React from 'react';
import { Radio, Icon } from 'semantic-ui-react';

import { BasicTable } from 'components/shared/Tables';

export default function NotImported({ data }) {
  const employeeColumns = [
    { Header: 'Row', accessor: 'row', width: 50 },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Email', accessor: 'company_email' }
  ];

  return (
    <BasicTable
      fetch={false}
      data={data}
      columns={employeeColumns}
      entityName="Employees"
    />
  );
}

NotImported.defaultProps = {
  data: []
};
