import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Wrapped } from 'components/Layouts';

import { ToggleButton } from 'components/shared/Buttons';
import { AddForm, Table } from 'components/SurveyRequest';

export default function SurveyRequestIndex(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Survey Requests"
      // button={
      //   <ToggleButton
      //     closedText="Add Request"
      //     onClick={() => setOpen(!isOpen)}
      //     open={isOpen}
      //   />
      // }
    >
      <Table />
      {/*
        isOpen
          ? (
            <AddForm
              onClose={() => setOpen(false)}
              showCancel
              onSuccess={(id) => props.history.push(`/requests/${id}`)}
            />
          )
          : <Table />
          */}
    </Wrapped>
  );
}
