import React from 'react';
import { Form as SemanticForm, Label } from 'semantic-ui-react';
import { CompanyPositionSelect } from 'components/Company';

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  if (props.radio) {
    return (
      <SemanticForm.Field>
        <label style={{ marginBottom: '10px' }}>{props.label}</label>
        <SemanticForm.Radio
          label={field.value ? 'Yes' : 'No'}
          name={field.name}
          toggle
          checked={field.value}
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.checked
              }
            })
          }
        />
      </SemanticForm.Field>
    );
  }
  if (props.checkbox) {
    return (
      <SemanticForm.Field>
        <SemanticForm.Checkbox
          label={props.label}
          checked={field.value}
          name={field.name}
          onChange={(e, d) =>
            field.onChange({ target: { name: d.name, value: d.checked } })
          }
        />
      </SemanticForm.Field>
    );
  }
  if (props.textarea) {
    return (
      <SemanticForm.Field>
        <SemanticForm.TextArea
          label={props.label}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        />
        {touched[field.name] && errors[field.name] && (
          <Label
            basic
            // color="red"
            style={{ marginTop: '0.25em' }}
            pointing
          >
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }
  if (props.companyPositionSelect) {
    return (
      <SemanticForm.Field>
        <CompanyPositionSelect
          error={errors[field.name] && touched[field.name]}
          label={props.label}
          companyId={props.companyPositionSelect.companyId}
          onChange={(companyId) =>
            field.onChange({
              target: {
                name: field.name,
                value: companyId
              }
            })
          }
          onBlur={(companyId) =>
            field.onBlur({
              target: {
                name: field.name,
                value: companyId
              }
            })
          }
          value={field.value}
        />
      </SemanticForm.Field>
    );
  }
  if (props.select) {
    return (
      <SemanticForm.Field>
        <SemanticForm.Select
          error={errors[field.name] && touched[field.name]}
          value={field.value}
          label={props.label}
          onBlur={(e, d) =>
            field.onBlur({
              target: {
                name: d.name,
                value: d.value
              }
            })
          }
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.value
              }
            })
          }
          options={props.options}
          placeholder="Select one..."
          name={field.name}
          search={props.search ? true : false}
        />
        {touched[field.name] && errors[field.name] && (
          <Label
            basic
            // color="red"
            style={{ marginTop: '0.25em' }}
            pointing
          >
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }
  return (
    <SemanticForm.Field required>
      <SemanticForm.Input
        name={field.name}
        type="text"
        onBlur={field.onBlur}
        onChange={field.onChange}
        label={`${props.label}${!props.required ? ' (optional)' : ''}`}
        value={field.value}
        error={errors[field.name] && touched[field.name]}
      />
      {touched[field.name] && errors[field.name] && (
        <Label
          basic
          // color="red"
          style={{ marginTop: '0.25em' }}
          pointing
        >
          {errors[field.name]}
        </Label>
      )}
    </SemanticForm.Field>
  );
};

export default CustomInputComponent;
