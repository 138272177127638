import React from 'react';

import { FaPencilAlt } from 'react-icons/fa';

export default function SurveyMeta({ name, submitted }) {
  return (
    <div className="segment">
      <div className="survey-meta-block">
        <FaPencilAlt size="50" />
        <div>
          <h2>{name}</h2>
          {submitted && <p>Submitted on {submitted}</p>}
          {!submitted && <p>Not submitted yet...</p>}
        </div>
      </div>
    </div>
  );
}

SurveyMeta.defaultProps = {
  name: '',
  submitted: ''
};
