import React from 'react';

import ListItem from './ListItem';

const List = ({ items }) => (
  <div className="list">
    {items.map((item) => (
      <ListItem
        key={item.id}
        text={item.text}
        comments={item.comments ? item.comments.filter((f) => f !== null) : []}
        employeeCount={item.employee_count}
        employeeResponseCount={item.employee_response_count}
        rawResponses={item.responses}
        responseTotal={item.response_total}
        responseTotalMax={item.response_total_max}
      />
    ))}
  </div>
);

List.defaultProps = {
  items: []
};

export default List;
