import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Icon, Label } from 'semantic-ui-react';

export default function EmailCheck({ surveyInviteId, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const [emailInfo, setEmailInfo] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [sendError, setSendError] = useState(null);

  const emailIsValid = value.length ? emailValid(value) : true;

  useEffect(() => {
    setError(null);
    setEmailInfo(null);
    if (emailValid(value)) {
      fetchEmail();
    }
  }, [value]);

  function fetchEmail() {
    const req = {
      survey_invite_id: surveyInviteId,
      email: value
    };

    setLoading(true);

    axios
      .post(`/engagement-email-status/`, req)
      .then(({ data }) => {
        setLoading(false);
        setEmailInfo(data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onSingleSendClick() {
    if (!emailInfo.survey_id) return;

    setSendError(null);
    setSendLoading(true);

    const req = {
      survey_invite_id: surveyInviteId,
      survey_id: emailInfo.survey_id
    };

    axios
      .post(`/send-engagement-surveys/`, req)
      .then(({ data }) => {
        setSendLoading(false);
        onUpdate();

        if (data && data.length && data[0].email_info) {
          setEmailInfo({
            message_id: data[0].email_info.message_id,
            status: 'Processing, check back later for more info',
            message_events: [
              {
                Type: 'Processing',
                ReceivedAt: data[0].email_info.submitted_at
              }
            ]
          });
        }
      })
      .catch((err) => {
        setSendError(err.response.data.ui_message);
        setSendLoading(false);
      });
  }

  return (
    <div>
      <Form>
        <Form.Input
          label="Check the email status for an employee in this survey"
          loading={loading}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          error={!emailIsValid}
        />
        {error && <ErrorText text={error} />}
      </Form>
      {emailInfo && (
        <div className="engagement-email-info">
          {emailInfo.message_id && (
            <React.Fragment>
              <p>
                Status: <b>{emailInfo.status}</b>
              </p>
              <ul>
                {emailInfo.message_events.map((event, i) => (
                  <li key={i}>
                    {event.Type}{' '}
                    <Label
                      content={new Date(event.ReceivedAt).toLocaleString()}
                      size="small"
                      style={{ marginLeft: '5px' }}
                    />
                  </li>
                ))}
              </ul>
              {emailInfo.submitted && (
                <p style={{ fontWeight: '500' }}>
                  This survey has been completed. Resending an invite is not
                  possible now.
                </p>
              )}
              {emailInfo.survey_id && !emailInfo.submitted && (
                <Button
                  style={{ marginTop: '1em' }}
                  content="Resend"
                  primary
                  onClick={onSingleSendClick}
                  loading={sendLoading}
                  size="small"
                />
              )}
            </React.Fragment>
          )}
          {!emailInfo.message_id && (
            <React.Fragment>
              <p>
                Status:{' '}
                <b>
                  {emailInfo.reason || 'Unknown'}
                  {!emailInfo.sent && (
                    <React.Fragment>, Never Sent</React.Fragment>
                  )}
                </b>
              </p>
              {sendError && <ErrorText text={sendError} />}
              {emailInfo.survey_id && (
                <Button
                  style={{ marginTop: '1em' }}
                  content="Send Now"
                  primary
                  onClick={onSingleSendClick}
                  loading={sendLoading}
                  size="small"
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

function emailValid(num) {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(num);
}

function ErrorText({ text }) {
  return (
    <p style={{ fontWeight: '500', minHeight: '25px' }}>
      <Icon name="exclamation circle" color="red" /> {text}
    </p>
  );
}
