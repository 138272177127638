import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import { Wrapped } from 'components/Layouts';

import './index.scss';

export default function ErrorView(props) {
  return (
    <Wrapped>
      <div className="error-view">
        <FaExclamationTriangle size="80" />
        <h1>Yikes!</h1>
        <p>{props.text}</p>
      </div>
    </Wrapped>
  );
}