import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaUsers, FaBalanceScale, FaCogs, FaGlasses } from 'react-icons/fa';
import { RiTestTubeLine } from 'react-icons/ri';
import { colors } from './colors';

export const ratersObj = {
  0: 'Superior',
  1: 'Colleague',
  2: 'Direct Report',
  3: 'Indirect Report',
  4: 'Other',
  5: 'Self'
};

export const ALL_RATER = 99999;

const skillsByP = {
  principles: {
    principles: [0, 1, 2, 3]
  },
  processes: {
    businessAcumen: [4, 5, 6, 7, 8, 9, 51],
    resultsOrientation: [14, 15, 16, 17, 18, 19],
    problemSolving: [10, 11, 12, 13]
  },
  people: {
    constituentFocus: [20, 21, 22, 23],
    interpersonalEffectiveness: [24, 25, 26, 27, 28, 29, 59, 60, 61, 62],
    influence: [30, 31, 32, 33, 34, 35, 52, 53, 55, 57, 58, 67, 68]
  },
  perspectives: {
    strategicVision: [36, 37, 38, 39, 74],
    thoughtLeadership: [40, 41, 71, 73]
  },
  potency: {
    potency: [42, 43, 44]
  }
};

const pSkills = Object.keys(skillsByP).reduce((acc, cur) => {
  const arrs = Object.keys(skillsByP[cur]).map((m) => skillsByP[cur][m]);
  acc[cur] = [].concat.apply([], arrs);
  return acc;
}, {});

function getP(val) {
  let grp = null;
  Object.keys(pSkills).forEach((f) => {
    if (pSkills[f].includes(val)) {
      grp = f;
    }
  });

  return grp;
}

export const skills = [
  { value: 0, text: 'Values & Behavior Alignment' },
  { value: 1, text: 'Self-Accountability' },
  { value: 2, text: 'Organizational Commitment' },
  { value: 3, text: 'Integrity' },
  { value: 4, text: 'Organizational Knowledge' },
  { value: 5, text: 'Organizational Savvy' },
  { value: 6, text: 'Industry Regulations' },
  { value: 7, text: 'Industry/Markets' },
  { value: 8, text: 'Challenge-Seeking' },
  { value: 9, text: 'Feedback-Seeking' },
  { value: 10, text: 'Execution' },
  { value: 11, text: 'Urgency' },
  { value: 12, text: 'Looking Beyond the Obvious' },
  { value: 13, text: 'Inclusion' },
  { value: 14, text: 'Tenacity' },
  { value: 15, text: 'Focus' },
  { value: 16, text: 'Verification' },
  { value: 17, text: 'Information-Sharing' },
  { value: 18, text: 'Deadline Enforcement' },
  { value: 19, text: 'Performance Standards' },
  { value: 20, text: 'Responsiveness to Customers' },
  { value: 21, text: 'Customer Relationships' },
  { value: 22, text: 'Responsiveness to Co-Workers' },
  { value: 23, text: 'Co-Worker Relationships' },
  { value: 24, text: 'Consistency' },
  { value: 25, text: 'Positivity' },
  { value: 26, text: 'Listening' },
  { value: 27, text: 'Caring' },
  { value: 28, text: 'Collaboration' },
  { value: 29, text: 'Input-Seeking' },
  { value: 30, text: 'Conflict Resolution' },
  { value: 31, text: 'Empowerment/Delegation' },
  { value: 32, text: 'Reciprocity' },
  { value: 33, text: 'Disciplinary Action' },
  { value: 34, text: 'Presence' },
  { value: 35, text: 'Influence' },
  { value: 36, text: 'Future Orientation' },
  { value: 37, text: 'Interdependence' },
  { value: 38, text: 'Linking Work to Strategy' },
  { value: 39, text: 'Visionary Communication' },
  { value: 40, text: 'Creativity' },
  { value: 41, text: 'Continuous Improvement' },
  { value: 42, text: 'Team Cohesiveness' },
  { value: 43, text: 'Team Performance' },
  { value: 44, text: 'Leadership Capabilities' }
].map((m) => ({
  ...m,
  id: uuidv4(),
  p_group: getP(m.value)
}));

const skillsObj = Object.keys(skills).reduce((acc, cur) => {
  const skill = skills.find((s) => s.value === parseInt(cur));
  if (skill) {
    acc[cur] = skill.text;
  }
  return acc;
}, {});

export function formatSkillsImportance(responses, raterType) {
  const filteredResponses = [...responses].filter((f) =>
    raterType === ALL_RATER ? true : f.rater_type === raterType
  );

  return skills
    .map((s) => ({
      ...s,
      title: s.text,
      skill_total:
        filteredResponses
          .filter((f) => f.skill === s.value)
          .map((r) => r.response)
          .reduce((acc, cur) => (acc += cur), 0) /
        filteredResponses.filter((f) => f.skill === s.value).length,
      importance_total:
        filteredResponses
          .filter((f) => f.skill === s.value)
          .map((r) => r.importance)
          .reduce((acc, cur) => (acc += cur), 0) /
        filteredResponses.filter((f) => f.skill === s.value).length
      // responses: responses.filter((f) => f.skill === s.id)
    }))
    .map((m) => ({
      ...m,
      skill_total: Math.round(m.skill_total * 100) / 100,
      importance_total: Math.round(m.importance_total * 100) / 100,
      gap: Math.round(Math.abs(m.importance_total - m.skill_total) * 100) / 100
    }));
}

export function formatComposites(questions, responses) {
  function formatQuestions(newQuestions) {
    return newQuestions.map((q) => ({
      ...q,
      title: skillsObj[q.skill],
      subtitle: q.text,
      responses: responses.filter((f) => f.parent_id === q.id)
    }));
    // .sort((a, b) => a.position - b.position);
  }

  return [
    {
      title: 'Principles',
      subtitle:
        'The PRINCIPLES competency area includes Citizenship and Integrity items.  Principle involves having the integrity required to be a part of the organization. This level sets the foundation for growth and development and must be present in order to perform at any higher levels.',
      icon: <FaBalanceScale />,
      sections: [
        {
          title: 'Values',
          question_skills: skillsByP.principles.principles
        }
      ].map((m) => ({ ...m, id: uuidv4() })),
      // filter these questions by descriptor 4
      questions: formatQuestions(questions.filter((f) => f.descriptor === 4))
    },
    {
      title: 'Processes',
      subtitle:
        'The PROCESSES competency area includes Business Acumen, Problem-Solving, and Results Orientation.  Those who demonstrate skill in Processes are likely good operators and producers.  Processes focuses on the discipline and tactical skills required to be an effective contributor; talent in this arena is antecedent to good management.',
      icon: <FaCogs />,
      sections: [
        {
          title: 'Business Acumen',
          question_skills: skillsByP.processes.businessAcumen
        },
        {
          title: 'Results Orientation',
          question_skills: skillsByP.processes.resultsOrientation
        },
        {
          title: 'Problem Solving',
          question_skills: skillsByP.processes.problemSolving
        }
      ].map((m) => ({ ...m, id: uuidv4() })),
      // filter these questions by descriptor 5
      questions: formatQuestions(questions.filter((f) => f.descriptor === 5))
    },
    {
      title: 'People',
      subtitle:
        'The PEOPLE competency area includes Influence, Interpersonal Effectiveness, and Constituent Focus.  Talent in the People competency area is required for those who supervise/manage others and is concerned with interpersonal presence and influence.',
      icon: <FaUsers />,
      sections: [
        {
          title: 'Constituent Focus',
          question_skills: skillsByP.people.constituentFocus
        },
        {
          title: 'Interpersonal Effectiveness',
          question_skills: skillsByP.people.interpersonalEffectiveness
        },
        {
          title: 'Influence',
          question_skills: skillsByP.people.influence
        }
      ].map((m) => ({ ...m, id: uuidv4() })),
      // filter these questions by descriptor 6
      questions: formatQuestions(questions.filter((f) => f.descriptor === 6))
    },
    {
      title: 'Perspectives',
      subtitle:
        'The PERSPECTIVES competency area includes Thought Leadership and Strategic Thinking.  These are transcendent elements describing the vision required of top leadership and the executive function.',
      icon: <FaGlasses />,
      sections: [
        {
          title: 'Strategic Vision',
          question_skills: skillsByP.perspectives.strategicVision
        },
        {
          title: 'Thought Leadership',
          question_skills: skillsByP.perspectives.thoughtLeadership
        }
      ].map((m) => ({ ...m, id: uuidv4() })),
      // filter these questions by descriptor 7
      questions: formatQuestions(questions.filter((f) => f.descriptor === 7))
    },
    {
      title: 'Potency',
      subtitle: '',
      icon: <RiTestTubeLine />,
      sections: [
        {
          title: 'Impact',
          question_skills: skillsByP.potency.potency
        }
      ].map((m) => ({ ...m, id: uuidv4() })),
      // filter these questions by descriptor 4
      questions: formatQuestions(questions.filter((f) => f.descriptor === 8))
    }
  ].map((m) => ({
    ...m,
    id: uuidv4(),
    question_skills: m.questions.map((q) => q.skill),
    section_skills: m.sections.reduce(
      (acc, cur) => acc.concat(cur.question_skills),
      []
    )
  }));
}

export function formatTraitGroupResponses(responses) {
  return Object.keys(ratersObj)
    .map((m) => ({
      rater_type: parseInt(m),
      rater_color: colors[parseInt(m)],
      rater_responses: responses.filter((f) => f.rater_type === parseInt(m))
    }))
    .filter((f) => f.rater_responses.length)
    .map((m) => ({
      rater_title: ratersObj[parseInt(m.rater_type)],
      rater_color: m.rater_color,
      skill_total:
        m.rater_responses.reduce((acc, cur) => (acc += cur.response), 0) /
        m.rater_responses.length,
      importance_total:
        m.rater_responses.reduce((acc, cur) => (acc += cur.importance), 0) /
        m.rater_responses.length
    }));
}
