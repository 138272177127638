import React from 'react';
import { Link } from 'react-router-dom';

import {
  FaCommentDots,
  FaEye,
  FaMinusCircle,
  FaPlusCircle,
  FaBrain,
  FaTheaterMasks
} from 'react-icons/fa';

const iconProps = {
  size: '20px'
};

export default function QuestionItem(props) {
  const { actionsDisabled } = props;

  return (
    <div
      className="question-item"
      ref={props.innerRef}
      {...props.draggableProps}
      {...props.dragHandleProps}
    >
      {props.categoryString && (
        <p className="meta-category">{props.categoryString}</p>
      )}
      <div className="meta">
        <p>
          {/* {props.descriptor === 0 && <FaTheaterMasks />}
          {props.descriptor === 1 && <FaBrain />} */}
          {props.hasComment && <FaCommentDots title="Has Respondant Comment" />}
          {props.position && <b>{props.position}.</b>}
          {props.text}
        </p>
        {(props.dimension || props.factor) && (
          <p className="dimension">
            {props.dimension && <span>{props.dimension}, </span>}
            {props.factor && (
              <span style={{ marginLeft: '2px' }}> {props.factor}</span>
            )}
          </p>
        )}
      </div>
      <div className="actions">
        {!actionsDisabled && (
          <React.Fragment>
            {props.showAdd && (
              <button onClick={props.onAdd} disabled={props.loading}>
                <FaPlusCircle color="#009912" {...iconProps} />
              </button>
            )}
            {props.showRemove && (
              <button onClick={props.onRemove} disabled={props.loading}>
                <FaMinusCircle color="#F92C5F" {...iconProps} />
              </button>
            )}
          </React.Fragment>
        )}
        <Link to={`/questions/${props.id}`} target="_blank">
          <FaEye size={20} />
        </Link>
      </div>
    </div>
  );
}

QuestionItem.defaultProps = {
  actionsDisabled: false,
  categoryString: '',
  dimension: '',
  factor: '',
  hasComment: false,
  position: null,
  showAdd: false,
  showRemove: false
};
