import React, { useState } from 'react';
import axios from 'axios';
import { ActionButton } from 'components/shared/Buttons';

export function Init({ name, reqData, onSuccess }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      ...reqData
    };

    axios
      .post(`/initiate-clearscreen/`, req)
      .then(() => {
        // setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.message);
      });
  }

  return (
    <ActionButton
      text={`Start ClearScreen`}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    />
  );
}

Init.defaultProps = {
  name: 'Name'
};

export function Resend({ name, surveyId, reqData, onSuccess }) {
  const [loading, setLoading] = useState(false);

  if (!surveyId) return null;

  function onClick() {
    setLoading(true);

    axios
      .post(`/surveys/${surveyId}/resend-invite/`)
      .then(() => {
        setLoading(false);
        alert('Resent!');
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.debug_message);
      });
  }

  return (
    <ActionButton
      text={`Resend ClearScreen Link`}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    />
  );
}

Resend.defaultProps = {
  name: 'Name'
};
