import React, { useState } from 'react';
import { Wrapped } from 'components/Layouts';
import CompanyManagerForm from './CompanyManagerForm';
import Table from './Table';

import { ToggleButton } from 'components/shared/Buttons';

export default function CompanyManagerManagement({ companyId }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Company Managers"
      button={
        <ToggleButton
          closedText="Add Manager"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <CompanyManagerForm
          onSubmit={() => setOpen(false)}
          onClose={() => setOpen(false)}
          companyId={companyId}
          create
        />
      ) : (
        <Table companyId={companyId} />
      )}
    </Wrapped>
  );
}

CompanyManagerManagement.defaultProps = {
  companyId: null
};
