import React from 'react';
import { TraitGroup } from './';

export default function TraitSection({
  icon,
  sections,
  title,
  subtitle,
  questions
}) {
  return (
    <div className={`trait-section ${title.toLowerCase()}`}>
      <div className={`trait-section-meta`}>
        <div className="wrapper">
          {icon}
          <h1>{title}</h1>
          <div className="section-tags">
            {sections.map((s) => (
              <button
                key={`tag${s.id}`}
                onClick={() =>
                  document.getElementById(s.id).scrollIntoView({
                    behavior: 'smooth'
                  })
                }
              >
                {s.title}
              </button>
            ))}
          </div>
        </div>
        <p className="description">{subtitle}</p>
      </div>
      <div className="sections">
        {sections.map((s) => (
          <div id={s.id} key={s.id} className="trait-group-wrapper">
            <TraitGroup
              key={s.id}
              title={s.title}
              pGroup={title.toLowerCase()}
              questions={questions.filter((f) =>
                s.question_skills.includes(f.skill)
              )}
              subtitle={s.subtitle}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

TraitSection.defaultProps = {
  questions: [],
  sections: [],
  subtitle: '',
  title: ''
};
