import React from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'semantic-ui-react';

export default function ResponseFilters({ respondants, onResponderClick }) {
  if (!respondants.length) return null;

  return (
    <div className="perf-response-filters">
      {respondants.map((r) => (
        <div key={uuidv4()}>
          <Person
            name={`${r.first_name} ${r.last_name}`}
            cls={r.is_employee ? 'employee' : 'manager'}
            title={r.is_employee ? 'Employee' : 'Manager'}
          />
          <Button
            title="Show Responses"
            icon="plus"
            basic
            size="tiny"
            circular
            onClick={() => onResponderClick(r.uuid)}
          />
        </div>
      ))}
    </div>
  );
}

ResponseFilters.defaultProps = {
  respondants: []
};

const Person = ({ name, title, cls }) => (
  <div className={`person ${cls}`}>
    <FaUserAlt />
    <p>{name}</p>
    <p>{title}</p>
  </div>
);
