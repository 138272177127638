import React from 'react';
import StarRatings from 'react-star-ratings';
import { FaLink } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import './index.scss';

import teamImg from 'assets/images/team.gif';
import evangelizeImg from 'assets/images/affiliate-marketing.gif';
import heartImg from 'assets/images/heart.gif';
import decisiveImg from 'assets/images/rocket.gif';
import horizonImg from 'assets/images/analysis.gif';

export default function HolOutcomes({
  admin,
  executiveValue,
  managerValue,
  engineerValue,
  panResult
}) {
  if (!panResult) return null;

  const rows = [
    {
      title: 'Team First',
      a: panResult.team_first_trait_avg,
      b: panResult.team_first_avg,
      admin: true,
      header: true,
      cls: 'team',
      img: teamImg,
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/framework-team-first/'
    },
    {
      title: 'Elevate',
      a: panResult.trait_leadership_elevate,
      b: panResult.action_leadership_elevate_teamwork,
      cls: 'team',
      link:
        'https://www.engineering-leadership.com/engineering-leadership/elevate/'
    },
    {
      title: 'Teach/Learn',
      a: panResult.trait_leadership_teach_learn,
      b: panResult.action_leadership_teach_learn_teaching,
      cls: 'team',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-teachandlearn/'
    },
    {
      title: 'Inspire',
      a: panResult.trait_leadership_inspire,
      b: panResult.action_leadership_inspire_relationships,
      cls: 'team',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-inspire/'
    },
    //
    {
      title: 'Heart of Leadership',
      a: panResult.hol_trait_avg,
      b: panResult.hol_avg,
      admin: true,
      header: true,
      cls: 'hol',
      img: heartImg,
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/framework-heart/'
    },
    {
      title: 'Humility/Love',
      a: panResult.trait_leadership_humility_love,
      b: panResult.action_leadership_humility_love_caring,
      cls: 'hol',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-love/'
    },
    {
      title: 'Courage',
      a: panResult.trait_leadership_courage,
      b: panResult.action_leadership_courage_group_commitment,
      cls: 'hol',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-courage/'
    },
    {
      title: 'Optimism/Trust',
      a: panResult.trait_leadership_optimism_trust,
      b: panResult.action_leadership_optimism_trust_service,
      cls: 'hol',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-trust/'
    },
    //
    {
      title: 'See Over Horizon',
      a: panResult.horizon_trait_avg,
      b: panResult.horizon_avg,
      admin: true,
      header: true,
      cls: 'horizon',
      img: horizonImg,
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/framework-over-the-horizon/'
    },
    {
      title: 'Challenge',
      a: panResult.trait_leadership_challenge,
      b: panResult.action_leadership_challenge_influence,
      cls: 'horizon',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-challenge/'
    },
    {
      title: 'Anticipate',
      a: panResult.trait_leadership_anticipate,
      b: panResult.action_leadership_anticipate_initiative,
      cls: 'horizon',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-anticipate/'
    },
    {
      title: 'Engage',
      a: panResult.trait_leadership_enagage,
      b: panResult.action_leadership_enagage_information_seeking,
      cls: 'horizon',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/engage-2/'
    },
    //
    {
      title: 'Evangelize',
      a: panResult.evangelize_trait_avg,
      b: panResult.evangelize_avg,
      admin: true,
      header: true,
      cls: 'evangelize',
      img: evangelizeImg,
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/framework-evangelize/'
    },
    {
      title: 'Mission',
      a: panResult.trait_leadership_mission,
      b: panResult.action_leadership_mission_achievement,
      cls: 'evangelize',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-mission/'
    },
    {
      title: 'Values',
      a: panResult.trait_leadership_values,
      b: panResult.action_leadership_values_self_control,
      cls: 'evangelize',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-values/'
    },
    {
      title: 'Goals',
      a: panResult.trait_leadership_goals,
      b: panResult.action_leadership_goals_orderliness,
      cls: 'evangelize',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-goals/'
    },
    //
    {
      title: 'Decisive Execution',
      a: panResult.decisive_trait_avg,
      b: panResult.decisive_avg,
      admin: true,
      header: true,
      cls: 'decisive',
      img: decisiveImg,
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/framework-decisive-execution/'
    },
    {
      title: 'Drive Results',
      a: panResult.trait_leadership_drive_results,
      b: panResult.action_leadership_drive_results_directiveness,
      cls: 'decisive',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/drive-results-2/'
    },
    {
      title: 'Resolute',
      a: panResult.trait_leadership_resolute,
      b: panResult.action_leadership_resolute_self_confidence,
      cls: 'decisive',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-resolute/'
    },
    {
      title: 'Agile',
      a: panResult.trait_leadership_agile,
      b: panResult.action_leadership_agile_flexibility,
      cls: 'decisive',
      link:
        'https://www.engineering-leadership.com/17constants-engineering-leadership/constant-agile/'
    },
    //
    {
      title: 'Think',
      a: panResult.think_trait,
      b: panResult.think_avg,
      admin: true,
      header: true,
      cls: 'think'
    }
  ]
    .map((r, i) => ({ ...r, key: i }))
    .filter((f) => (admin ? f.admin : true));

  const groups = [
    { name: 'hol', rows: rows.filter((f) => f.cls === 'hol') },
    { name: 'team', rows: rows.filter((f) => f.cls === 'team') },
    { name: 'horizon', rows: rows.filter((f) => f.cls === 'horizon') },
    { name: 'evangelize', rows: rows.filter((f) => f.cls === 'evangelize') },
    { name: 'decisive', rows: rows.filter((f) => f.cls === 'decisive') },
    { name: 'think', rows: rows.filter((f) => f.cls === 'think') }
  ];

  const profiles = [
    { title: 'Engineering Executive', a: executiveValue },
    { title: 'Technical Manager', a: managerValue },
    { title: 'Multi-Industry Executive', a: engineerValue }
  ].map((r, i) => ({ ...r, key: i }));

  const sortedRows = rows
    .filter((f) => !f.header)
    .reduce((acc, cur) => {
      acc.push({
        id: uuidv4(),
        value: cur.a,
        title: cur.title,
        link: cur.link,
        description: 'traits/preferences'
      });
      acc.push({
        id: uuidv4(),
        value: cur.b,
        title: cur.title,
        link: cur.link,
        description: 'actions/behaviors'
      });
      return acc;
    }, [])
    .sort((a, b) => b.value - a.value);

  const top3 = [...sortedRows].slice(0, 3);
  const bottom3 = [...sortedRows].slice(-3);

  return (
    <React.Fragment>
      <div className="hol-profiles">
        {profiles.map((r, i) => (
          <div key={r.key} className={`hol-profile pro-${i + 1}`}>
            <div className="title">{r.title}</div>
            <div className="traits">
              <StarDisplay value={r.a} starSize={36} />
            </div>
            <p>traits/preferences</p>
          </div>
        ))}
      </div>
      {!admin && (
        <div className="hol-top-bottom">
          <TopBottom title="Top 3" items={top3} />
          <TopBottom title="Bottom 3" items={bottom3} bottom />
        </div>
      )}
      <div className="hol-outcomes">
        {groups.map((g) => (
          <div className="hol-group" key={g.name}>
            {g.rows.map((r) => (
              <div
                key={r.key}
                className={`hol-outcome${r.cls ? ` ${r.cls}` : ''}${
                  r.header ? ' header' : ''
                }`}
                style={g.rows.length === 1 ? { width: '100%' } : {}}
              >
                {r.img && (
                  <img
                    className="section-img"
                    src={r.img}
                    alt={`${r.title} Image`}
                  />
                )}
                <div className="title">
                  {r.link && (
                    <a href={r.link} target="_blank">
                      {r.title} <FaLink />
                    </a>
                  )}
                  {!r.link && <React.Fragment>{r.title}</React.Fragment>}
                </div>
                <div className="values">
                  <div className="traits">
                    <StarDisplay value={r.a} />
                    <p>traits/preferences</p>
                  </div>
                  <div className="actions">
                    <StarDisplay value={r.b} />
                    <p>actions/behaviors</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

HolOutcomes.defaultProps = {
  admin: false,
  executiveValue: 0,
  managerValue: 0,
  engineerValue: 0,
  panResult: null
};

const StarDisplay = ({ value, starSize }) => (
  <div className="star-display">
    <StarRatings
      rating={value}
      starDimension={`${starSize}px`}
      starRatedColor="#F56600"
      starSpacing="1px"
    />
    <span>{value} / 5 stars</span>
  </div>
);

StarDisplay.defaultProps = {
  starSize: 24
};

const TopBottom = ({ title, items, bottom }) => {
  return (
    <div className="hol-top-bottom-block">
      <h2>{title}</h2>
      <p className="description">
        {!bottom && (
          <React.Fragment>Here's where you performed best.</React.Fragment>
        )}
        {bottom && (
          <React.Fragment>
            Here are some opportunities for improvement.
          </React.Fragment>
        )}
      </p>
      {items.map((item) => (
        <div key={item.id} className="value-block">
          <div className="meta">
            <h3>
              {item.title} <span>{item.description}</span>
            </h3>
            <StarDisplay value={item.value} />
          </div>
          {item.link && bottom && (
            <a href={item.link} target="_blank">
              <FaLink />
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

TopBottom.defaultProps = {
  title: '',
  items: [],
  bottom: false
};
