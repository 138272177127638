import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

function formatClsVal(clsValue) {
  const cls = clsValue - 1;
  const bottomVal = cls.toString();
  return parseFloat(bottomVal) * 38.8888 - 15.5;
}

function formatFpfVal(fpfValue) {
  const fpf = fpfValue - 1;
  const leftVal = fpf.toString();
  return parseFloat(leftVal) * 77.7777 - 11.5;
}

export default function EstimateGraph({ companyName, employees, showNames }) {
  const markerStyle = {
    position: 'absolute',
    fontSize: '26px'
  };

  return (
    <div>
      <div className="estimate-graph-container">
        <div className="estimate-box tl">
          CS
          <br />
          Ascendancy
        </div>
        <div className="estimate-box br">
          {companyName}
          <br />
          Potential
        </div>
        <p className="axis-x">
          FUNCTION POSITION FIT <FaLongArrowAltRight />
        </p>
        <p className="axis-x2">
          JOB PERFORMANCE <FaLongArrowAltRight />
        </p>
        <p className="axis-y">
          CHANCE OF LEADERSHIP SUCCESS
          <FaLongArrowAltRight />
        </p>
        <p className="axis-y2">
          LEADERSHIP TALENT
          <FaLongArrowAltRight />
        </p>
        <div className="estimate-graph">
          <div className="estimate-graph-table">
            {employees.map((employee, i) => (
              <React.Fragment key={i}>
                <span
                  className="ar-marker"
                  style={{
                    ...markerStyle,
                    left: `${formatFpfVal(employee.fpf)}px`,
                    bottom: `${formatClsVal(employee.cls)}px`
                  }}
                >
                  &#x2666;
                </span>
                {showNames && (
                  <span
                    className="estimate-label"
                    style={{
                      ...markerStyle,
                      left: `${formatFpfVal(employee.fpf) + 30}px`,
                      bottom: `${formatClsVal(employee.cls) + 2}px`
                    }}
                  >
                    {employee.name}
                  </span>
                )}
              </React.Fragment>
            ))}
            <div className="estimate-graph-row">
              <div>
                <h3>
                  GENERALIST
                  <br />
                  'Inexperienced High Potential'
                </h3>
              </div>
              <div>
                <h3>
                  POTENT
                  <br />
                  'Developing High Potential'
                </h3>
              </div>
              <div>
                <h3>
                  PROTOTYPICAL
                  <br />
                  'High Potential'
                </h3>
              </div>
            </div>
            <div className="estimate-graph-row">
              <div>
                <h3>
                  ATYPICAL
                  <br />
                  'Inexperienced Talent'
                </h3>
              </div>
              <div>
                <h3>
                  MODERATE
                  <br />
                  'Transitional'
                </h3>
              </div>
              <div>
                <h3>
                  TYPICAL
                  <br />
                  'Informal Leader'
                </h3>
              </div>
            </div>
            <div className="estimate-graph-row">
              <div>
                <h3>
                  UNLIKELY
                  <br />
                  'Does Not Meet Standards'
                </h3>
              </div>
              <div>
                <h3>
                  LIMITED
                  <br />
                  'Meets Standards'
                </h3>
              </div>
              <div>
                <h3>
                  SPECIALIST
                  <br />
                  'Functional Master'
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EstimateGraph.defaultProps = {
  companyName: '',
  employees: [],
  showNames: false
};
