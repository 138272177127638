import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { FaAngleDoubleDown } from 'react-icons/fa';

const margin = {
  top: 20,
  right: 10,
  bottom: 20,
  left: 10
};

const width = 500 - margin.left - margin.right;
const height = 70 - margin.top - margin.bottom;
const transformStyle = `translate(${50}, ${25})`;

export default function Slider({
  leftPoleText,
  rightPoleText,
  optimalHigh,
  optimalLow,
  value,
  adjustedValue,
  implications
}) {
  const svgDiv = useRef(null);

  useEffect(() => {
    if (svgDiv.current) {
      const sliderDiv = d3.select(svgDiv.current);

      const svg = sliderDiv
        .append('svg')
        .attr('class', 'chart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom);

      const scale = d3
        .scaleLinear()
        .domain([1, 10])
        .range([0, width - 100]);

      const xAxis = d3.axisBottom().tickFormat('').tickSize(20).scale(scale);

      const optimalRange = svg
        .append('rect')
        .attr('class', 'optimal-range')
        .attr('x', () => scale(optimalLow))
        .attr('y', () => 3)
        .attr('width', () => scale(optimalHigh - 0.2) - scale(optimalLow - 0.2))
        .attr('height', 12)
        .attr('transform', transformStyle)
        .attr('fill', '#ccc');

      svg.append('g').attr('transform', transformStyle).call(xAxis);

      const halfTick = svg
        .append('rect')
        .attr('x', scale(5.4))
        .attr('y', -8)
        .attr('width', 4)
        .attr('height', 40)
        .attr('transform', transformStyle)
        .attr('fill', '#000');

      const halfTick2 = svg
        .append('rect')
        .attr('x', scale(5.6))
        .attr('y', -8)
        .attr('width', 4)
        .attr('height', 40)
        .attr('transform', transformStyle)
        .attr('fill', '#000');

      const resultIcon = svg
        .append('text')
        .attr('x', () => scale(value - 0.11))
        .attr('class', 'slider-icon')
        .attr('y', 22)
        .attr('font-size', () => '32px')
        .attr('fill', '#000')
        .attr('transform', transformStyle)
        .text(() => `\u2666`);

      if (value > 0 && adjustedValue > 0 && adjustedValue !== value) {
        const resultIcon2 = svg
          .append('text')
          .attr('x', () => scale(adjustedValue - 0.11))
          .attr('class', 'graph-icon')
          .attr('y', 22)
          .attr('font-size', '32px')
          .attr('fill', '#000')
          .attr('transform', transformStyle)
          .text(`\u2666`);
      }
    }
  }, []);

  return (
    <div className="report-slider-section">
      <div className="report-slider-wrapper">
        <h3 className="left-pole">{leftPoleText}</h3>
        <div className="report-slider" ref={svgDiv}></div>
        <h3 className="right-pole">{rightPoleText}</h3>
      </div>
      <Implications text={implications} />
    </div>
  );
}

Slider.defaultProps = {
  leftPoleText: 'Left Pole Text',
  rightPoleText: 'Right Pole Text',
  optimalHigh: 0,
  optimalLow: 0,
  value: 0,
  adjustedValue: 0,
  implications: ''
};

Slider.propTypes = {
  leftPoleText: PropTypes.string,
  rightPoleText: PropTypes.string,
  optimalHigh: PropTypes.number,
  optimalLow: PropTypes.number,
  value: PropTypes.number,
  adjustedValue: PropTypes.number,
  implications: PropTypes.string
};

const Implications = ({ text }) => {
  const [isOpen, setOpen] = useState(false);
  if (!text) return null;

  return (
    <div className="implications">
      <div className="btn-wrapper">
        <button onClick={() => setOpen(!isOpen)}>
          Implications <FaAngleDoubleDown size={10} />
        </button>
      </div>
      {isOpen && (
        <div className="explanation">
          <p>
            *In general, {text[0].toLowerCase()}
            {text.slice(1, text.length)}
          </p>
        </div>
      )}
    </div>
  );
};
