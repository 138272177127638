import React, { useState } from 'react';
import Slide from 'react-reveal/Slide';

export default function UnansweredQuestions(props) {
  const [isOpen, setOpen] = useState(false);

  function onQuestionClick(questionId) {
    setOpen(false);
    props.onQuestionClick(questionId)
  }

  if (!props.questions.length) return null;

  return (
    <div className="unanswered-questions">
      <button
        className="toggle"
        onClick={() => setOpen(!isOpen)}
      >{isOpen ? 'Hide' : `Show Unanswered Questions (${props.questions.length})`}</button>
      {
        isOpen &&
          <Slide up>
            <div className="question-list">
              {
                props.questions.map((q) => (
                  <button
                    className="question-button"
                    onClick={() => onQuestionClick(q.id)}
                    key={q.id}>{q.text}</button>
                ))
              }
            </div>
          </Slide>
      }
    </div>
  );
}
