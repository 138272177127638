import React, { useState } from 'react';
import axios from 'axios';
import './index.scss';

import { ActionButton } from 'components/shared/Buttons';

const newAxios = axios.create({
  baseURL: process.env.EXPRESS_URL
});

const PrintButton = ({ filename, pathname, cls, landscape, margin, hol }) => {
  const [isLoading, setLoading] = useState(false);

  if (!pathname) return null;

  function getPDF() {
    const req = { pathname, landscape };

    if (margin) {
      req.margin = margin;
    }

    if (hol) {
      req.remote = true;
    }

    return newAxios({
      method: 'post',
      url: `/generate-pdf`,
      data: req,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    });
  }

  function onClick() {
    setLoading(true);
    return getPDF()
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.pdf`;
        link.click();
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <ActionButton
      cls={`report-dl-btn ${cls}`}
      loading={isLoading}
      text="Download"
      disabled={isLoading}
      onClick={onClick}
    />
  );
};

PrintButton.defaultProps = {
  cls: '',
  pathname: '',
  landscape: false,
  hol: false
};

export default PrintButton;
