import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  FaNewspaper,
  FaHandshake,
  FaStar,
  FaStreetView,
  FaGamepad,
  FaPhone,
  FaFire,
  FaTrain
} from 'react-icons/fa';

export const boxData = [
  {
    id: uuidv4(),
    title: 'Contributor',
    traits: `Introverted, Tough-minded, Reserved, Intelligent, Serious, Practical, Self-reliant, Compromising`,
    hoverText: `Player/Coach, Servant Leadership, does not desire personal glory, first one in/last one out`,
    icon: <FaNewspaper />
  },
  {
    id: uuidv4(),
    title: 'Conductor',
    traits: `Anxious, Intelligent, Sensitive, Vigilant, Practical, Apprehensive, Tense, Influential, Compromising`,
    hoverText: `Highly rule conscious, think school principal, "by the books" in approach`,
    icon: <FaTrain />
  },
  {
    id: uuidv4(),
    title: 'Collaborator',
    traits: `Extraverted, Low Anxiety, Controlled, Warm, Intelligent, Bold, Utilitarian, Trusting, Practical, Open, People-Oriented`,
    hoverText: `Rule conscious with some social "pop", relatively less intuitive`,
    icon: <FaHandshake />
  },
  {
    id: uuidv4(),
    title: 'Competitor',
    traits: `Extraverted, Low Anxiety, Stable, Dominant, Bold, Trusting, Practical, Confident, Open, Group-oriented`,
    hoverText: `Wants to be MVP/star, good for sales hunters or quota-based performers`,
    icon: <FaStar />
  },
  {
    id: uuidv4(),
    title: 'Creator',
    traits: `Receptive, Intelligent, Expedient, Trusting, Open, Accommodating`,
    hoverText: `Seeks out situations wherein he/she will be successful, "creates" environments to shine. Potential for less risk-taking, stagnation`,
    icon: <FaStreetView />
  },
  {
    id: uuidv4(),
    title: 'Controller',
    traits: `Introverted, Low Anxiety, Reserved, Intelligent, Stable, Serious, Utilitarian, Trusting, Private, Confident, Relaxed`,
    hoverText: `"Controls" environment to be successful`,
    icon: <FaGamepad />
  },
  {
    id: uuidv4(),
    title: 'Consultant',
    traits: `Introverted, Low Anxiety, Reserved, Intelligent, Stable, Serious, Utilitarian, Trusting, Private, Confident, Relaxed`,
    hoverText: `Moderately influential, less follow-up/follow-through`,
    icon: <FaPhone />
  },
  {
    id: uuidv4(),
    title: 'Catalyst',
    traits: `Extraverted, Low Anxiety, Receptive, Independent, Warm, Bold, Trusting, Forthright, Confident, Group-oriented`,
    hoverText: `Change agent, lacking in follow-up, follow-through, can suffer from "shiny object syndrome"`,
    icon: <FaFire />
  }
];

export default function PersonaBoxes({ activePersona }) {
  return (
    <div className="persona-boxes">
      {boxData.map(({ id, icon, title, traits, hoverText }) => (
        <div
          key={id}
          className={`persona-box${
            activePersona === title.toLocaleLowerCase() ? ' active' : ''
          }`}
        >
          {icon && <React.Fragment>{icon}</React.Fragment>}
          <h2>{title}</h2>
          <h4>Key Traits</h4>
          <p>{traits}</p>
          <p className="hover-text">{hoverText}</p>
        </div>
      ))}
    </div>
  );
}

PersonaBoxes.defaultProps = {
  activePersona: null
};
