import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCircle } from 'react-icons/fa';

import { TableLinkButton } from 'components/shared/Buttons';
import { BasicTable } from 'components/shared/Tables';
import { IconFilterButtons } from 'components/shared/Buttons';

export default function Table() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [hol, setHol] = useState(false);

  useEffect(() => {
    fetchData();
  }, [hol]);

  function fetchData() {
    setLoading(true);
    axios
      .get(`/active-occupational-profiles/`)
      .then(({ data }) => {
        setItems(data.occupational_profiles);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  const filteredItems = hol
    ? items.filter((f) => f.hol)
    : items.filter((f) => true);

  return (
    <div className="segment">
      <IconFilterButtons
        active={hol}
        buttons={holButtons}
        onClick={(e) => setHol(e)}
        text="Profile Type"
      />
      <BasicTable
        columns={columns}
        entityName="Profiles"
        // filterButtons={filterButtons}
        // filterOptions={filterOptions}
        // keyName="occupational_profiles"
        // url="/occupational-profiles/"
        fetch={false}
        data={filteredItems}
        loading={loading}
      />
    </div>
  );
}

const columns = [
  {
    Header: '',
    id: 'view',
    width: 40,
    accessor: (d) => <TableLinkButton url={`/profiles/${d.id}`} />
  },
  {
    Header: 'Name',
    accessor: (d) => (d.original_name ? d.original_name : d.name),
    id: 'name',
    filterable: true
  }
];

const filterOptions = [{ id: 'name', object: 'self' }];

const holButtons = [
  { text: 'CLEAR Screen', value: false, icon: <FaCircle /> },
  { text: 'Heart of Leadership', value: true, icon: <FaCircle /> }
];
