import React from 'react';
import { BasicTable } from 'components/shared/Tables';

export default function CheckboxTable({ columns, data, onChange, entityName }) {
  return (
    <BasicTable
      columns={columns}
      fetch={false}
      entityName={entityName}
      data={data}
    />
  );
}

CheckboxTable.defaultProps = {
  data: [],
  entityName: 'Rows'
};
