import React, { useState, useContext } from 'react';
// import Highcharts from 'highcharts/highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import dumbbell from 'highcharts/modules/dumbbell';
// import CustomEvents from 'highcharts-custom-events';
import { FaClipboardList } from 'react-icons/fa';
// CustomEvents(Highcharts);
// dumbbell(Highcharts);

import { formatSkillsImportance, ratersObj, ALL_RATER } from '../helpers';
import { Context360 } from '../';
import { BasicButton, FilterButtons } from 'components/shared/Buttons';
import { SectionTitle, SkillSection } from './';

const gridLineColor = 'rgba(241,245,248,0.13)';
const textColor = '#f1f5f8';

const SKILL_SORT = 'Skill';
const IMPORTANCE_SORT = 'Importance';
const GAP_SORT = 'Gap';

const ALL_P = null;
const PRINCPLE_P = 'Principles';
const PROCESS_P = 'Processes';
const PEOPLE_P = 'People';
const PERSPECTIVE_P = 'Perspectives';
const POTENCY_P = 'Potency';

const pButtons = [
  ALL_P,
  PRINCPLE_P,
  PROCESS_P,
  PEOPLE_P,
  PERSPECTIVE_P,
  POTENCY_P
].map((m) => ({
  value: m,
  text: !m ? 'All' : m,
  cls: m ? m.toLowerCase() : ''
}));

export default function SkillImportance({ title }) {
  const [sortOption, setSortOption] = useState(GAP_SORT);
  const [raterType, setRaterType] = useState(ALL_RATER);
  const [pFilter, setPFilter] = useState(ALL_P);

  const reportData = useContext(Context360);
  const { raterTypes, responses } = reportData;

  const skillsImportanceData = formatSkillsImportance(responses, raterType);

  let sortedData = [...skillsImportanceData];

  if (sortOption === SKILL_SORT) {
    sortedData = skillsImportanceData.sort(
      (a, b) => b.skill_total - a.skill_total
    );
  }

  if (sortOption === IMPORTANCE_SORT) {
    sortedData = skillsImportanceData.sort(
      (a, b) => b.importance_total - a.importance_total
    );
  }

  if (sortOption === GAP_SORT) {
    sortedData = skillsImportanceData.sort((a, b) => b.gap - a.gap);
  }

  if (pFilter !== ALL_P) {
    sortedData = sortedData.filter((f) => f.p_group === pFilter.toLowerCase());
  }

  const sortButtons = [GAP_SORT, SKILL_SORT, IMPORTANCE_SORT].map((m) => ({
    text: (
      <React.Fragment>
        <span
          style={{ marginRight: '5px', fontSize: '1.5em' }}
          // className={
          //   m === SKILL_SORT
          //     ? 'skill-color'
          //     : m === IMPORTANCE_SORT
          //     ? 'importance-color'
          //     : 'gap-color'
          // }
        >
          {m === SKILL_SORT && <React.Fragment>{`\u2666`}</React.Fragment>}
          {m === GAP_SORT && <React.Fragment>{`\u27FA`}</React.Fragment>}
          {m === IMPORTANCE_SORT && <React.Fragment>{`\u2605`}</React.Fragment>}
          {/* {m === GAP_SORT ? (
            <React.Fragment>{`\u27FA`}</React.Fragment>
          ) : (
            <React.Fragment>{`\u2666`}</React.Fragment>
          )} */}
        </span>
        {m}
      </React.Fragment>
    ),
    active: sortOption === m,
    onClick: () => setSortOption(m),
    value: m
  }));

  const raterButtons = [ALL_RATER, ...Object.keys(ratersObj)]
    .map((m) => ({
      text: m === ALL_RATER ? 'All' : ratersObj[m],
      active: raterType === m,
      onClick: () => setRaterType(m),
      value: parseInt(m)
    }))
    .filter((f) => raterTypes.includes(f.value) || f.value === ALL_RATER);

  const ClearButton = ({ top }) => (
    <div
      className="skill-importance-clear"
      style={{
        marginBottom: top ? '30px' : '0',
        marginTop: top ? '30px' : '0'
      }}
    >
      <BasicButton
        disabled={
          raterType === ALL_RATER &&
          sortOption === GAP_SORT &&
          pFilter === ALL_P
        }
        text="Clear Filters"
        onClick={() => {
          setRaterType(ALL_RATER);
          setSortOption(GAP_SORT);
          setPFilter(ALL_P);
        }}
      />
    </div>
  );

  return (
    <div className="skill-importance">
      <SectionTitle icon={<FaClipboardList />} title={title} large />
      <div className="filter-btns">
        <FilterButtons
          text="Rater"
          buttons={raterButtons}
          active={raterType}
          onClick={(e) => setRaterType(e)}
        />
        <FilterButtons
          text="Sorted by"
          buttons={sortButtons}
          active={sortOption}
          onClick={(e) => setSortOption(e)}
        />
        <FilterButtons
          text="Competency"
          buttons={pButtons}
          active={pFilter}
          onClick={(e) => setPFilter(e)}
        />
      </div>
      <ClearButton top />
      <SkillSection sample />
      {sortedData
        // .filter((f) => !isNaN(f.gap))
        .map((d) => (
          <SkillSection
            key={d.id}
            id={d.id}
            title={d.title}
            skillInt={d.value}
            skillTotal={d.skill_total}
            importanceTotal={d.importance_total}
            gap={d.gap}
            pGroup={d.p_group}
          />
        ))}
      <SkillSection sample />
      <ClearButton bottom />
      {/* <Viz
        data={sortedData.map((d) => ({
          name: d.title,
          low: d.skill_total,
          high: d.importance_total,
          gap: d.gap,
          category_color: d.category_color
        }))}
      /> */}
    </div>
  );
}

SkillImportance.defaultProps = {
  title: '',
  data: []
};

class Viz extends React.Component {
  render() {
    const { data } = this.props;

    const options = {
      chart: {
        type: 'dumbbell',
        inverted: true,
        backgroundColor: 'transparent',
        height: 900,
        style: {
          fontFamily: 'inherit',
          fontWeight: 600
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      colorAxis: {
        min: 1,
        max: 5
      },
      title: {
        text: ''
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return `<div>
              <b><u>${this.point.options.name}</u></b><br />
              <b>Skill</b>: ${this.point.options.low}
              <br />
              <b>Importance</b>: ${this.point.options.high}
              <br />
              <b>Gap</b>: ${Math.abs(this.point.options.gap).toFixed(2)}
            </div>`;
        }
      },
      xAxis: {
        type: 'category',
        gridLineColor: gridLineColor,
        labels: {
          events: {
            click: function () {
              document
                .getElementById('1')
                .scrollIntoView({ behavior: 'smooth' });
            }
          },
          style: {
            color: textColor,
            fontSize: '14px'
          },
          formatter: function () {
            const item = data.find((d) => d.name === this.value);

            if (!item) {
              return this.value;
            }

            // return `<span style="color:${item.category_color};">${item.name}</span>`;
            return `<span>${item.name}</span>`;
          }
        }
      },
      yAxis: {
        gridLineColor: gridLineColor,
        title: {
          text: ''
        },
        tickInterval: 0.1,
        min: 1,
        max: 5,
        labels: {
          style: {
            color: textColor
          }
        }
      },
      plotOptions: {
        dumbbell: {}
      },
      series: [
        {
          marker: {
            fillColor: '#5effb7',
            radius: 6
          },
          name: '',
          lowColor: '#00becc',
          connectorColor: 'rgba(241,245,248,0.8)',
          connectorWidth: 3,
          data: this.props.data
        }
      ]
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}
