import React from 'react';

export default function Terms({
  name,
  instructions,
  employee,
  variety,
  children
}) {
  return (
    <div className="terms segment">
      <h1>Welcome, {name}!</h1>
      {employee && (
        <p>
          You are evaluating: <b>{employee}</b>
        </p>
      )}
      {variety !== 5 && <p>{instructions}</p>}
      {variety === 5 && (
        <React.Fragment>
          <p>
            The first portion of the following assessment relates to how your
            temperamental preferences align with LP’s articulated sales
            competencies. These items will ask you to indicate your level of
            agreement which each statement – best practice is to go with the
            first response that comes to mind! You will also be presented with
            ten cognitive questions.
          </p>
          <p>
            The second part of the assessment includes a variety of knowledge,
            skills, and behaviors aligned with the sales competencies. For each
            item, you may select as many or as few that apply.
          </p>
          <p>
            You do not have to complete this assessment in one session and may
            return to it using your email link at any time. Once you submit your
            responses, they will be considered final.
          </p>
          <p>
            Your results will be emailed to you within 24 hours after
            completion. When you are ready to begin, use the button below!
          </p>
        </React.Fragment>
      )}
      {variety === 0 && (
        <div>
          <p>
            Thank you for taking the CLEAR Screen! This session should last
            approximately 40 minutes. You do not have to complete the CLEAR
            Screen in one session, and you may use your email invite to
            re-access the assessment. Should you have any questions, please
            contact testing@bckpsych.com or call 404.588.2420.
          </p>
          <p>
            By clicking "Accept" below, you acknowledge and agree to the
            following:
          </p>
          <ul>
            <li>You are the person to whom the link was sent.</li>
            <li>
              You will address this evaluation without assistance of any kind.
            </li>
            <li>
              You will respect the integrity of these tools by neither sharing
              the contents with others, copying, nor otherwise inappropriately
              sharing beyond the boundaries of this evaluation process.
            </li>
            <li>
              You understand this information is used to estimate the degree of
              match to the specific demands of the position you seek. It may not
              be used for other positional evaluations without your consent.
            </li>
            <li>
              Cowart Consulting Group/CCG retains the right to use the data
              obtained in ongoing research on leadership, management, and
              organizational development. In this case, no personal information
              of any kind is appended to results. Your data privacy is securely
              maintained by the licensed Psychologists of CCG.
            </li>
            <li>
              Results of this employment evaluation, specific to the position in
              question, remain the property of the referring organization. It is
              the choice of the referring organization whether candidates not
              selected will be afforded feedback on the positional evaluation
              results.
            </li>
            <li>
              You understand this is a voluntary assessment that is a standard
              expectation of the referring organization. By your agreement, you
              are agreeing to hold harmless both the referring organization and
              the Psychologists of CCG for any recommendations or outcomes
              resulting from your voluntary participation in this process.
            </li>
          </ul>
        </div>
      )}
      {children}
    </div>
  );
}

Terms.defaultProps = {
  employee: '',
  instructions: '',
  name: '',
  variety: 0
};
