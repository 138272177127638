import React, { useState } from 'react';
import { Wrapped } from 'components/Layouts';

import { ToggleButton } from 'components/shared/Buttons';

import { SurveyForm, Table } from 'components/Survey';

export default function SurveyIndex(props) {
  const [isOpen, setOpen] = useState(false);

  function onCreate(surveyId) {
    setOpen(false);
    props.history.push(`/surveys/${surveyId}`);
  }

  return (
    <Wrapped
      title="Surveys"
      button={
        <ToggleButton
          closedText="Add Survey"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <div className="segment">
          <SurveyForm onClose={() => setOpen(false)} onSuccess={onCreate} />
        </div>
      ) : (
        <Table />
      )}
    </Wrapped>
  );
}
