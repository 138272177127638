import React from 'react';
import { FaStopwatch, FaCheckSquare } from 'react-icons/fa';

export default function PresentationItem({
  answered,
  cls,
  duration,
  ended,
  questionText,
  response,
  started
}) {
  return (
    <div className={`pres-item ${cls}`}>
      {started && (
        <span className="tag">
          {new Date(started).toLocaleString()} -{' '}
          {new Date(ended).toLocaleString()}
        </span>
      )}
      {/* {ended && <span className="tag">{new Date(ended).toLocaleString()}</span>} */}
      <span className="tag">
        <FaStopwatch title="Duration" /> {duration} seconds
      </span>
      {questionText && <span className="tag">{questionText}</span>}
      {response && (
        <span className="tag">
          {answered && <FaCheckSquare title="Answered" className="green" />}
          <b>{response}</b>
        </span>
      )}
    </div>
  );
}

PresentationItem.defaultProps = {
  answered: false,
  cls: '',
  duration: 0,
  ended: '',
  questionText: '',
  response: '',
  started: ''
};
