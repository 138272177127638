import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserAlt, FaExternalLinkAlt } from 'react-icons/fa';

export default function EmployeeMeta({
  name,
  email,
  id,
  employeeId,
  companyName,
  companyId,
  position
}) {
  return (
    <div className="segment">
      <div className="survey-meta-block">
        <FaUserAlt size="50" />
        <div>
          <h2>
            {name}{' '}
            <Link to={`/employees/${employeeId}`}>
              <FaExternalLinkAlt />
            </Link>
          </h2>
          <p>{email}</p>
          <p>
            <Link to={`/companies/${companyId}`} style={{ fontWeight: 'bold' }}>
              {companyName}
            </Link>
          </p>
          {position && <p>{position}</p>}
        </div>
      </div>
    </div>
  );
}

EmployeeMeta.defaultProps = {
  name: '',
  email: '',
  id: null,
  companyName: '',
  companyId: '',
  position: ''
};
