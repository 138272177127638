import React, { useState, useEffect } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Resolver from './Resolver';

import { ActionButton } from 'components/shared/Buttons';

import CheckboxTable from './CheckboxTable';
import EngagementInvite from './EngagementInvite';
import ExistingEmployees from './ExistingEmployees';
import NotImported from './NotImported';

import './index.scss';

const MISSING_STEP = 'missing';
const EMPLOYEE_STEP = 'employee';
const EVALUATE_STEP = 'evaluate';
const UPLOAD_STEP = 'upload';
const SUMMARY_STEP = 'summary';

export default function EmployeeUpload({ companyId, companyName, onSuccess }) {
  const [createLoading, setCreateLoading] = useState(false);
  // options
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);

  const [error, setError] = useState(null);
  const [existingEmployees, setExistingEmployees] = useState([]);
  const [file, setFile] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [missingData, setMissingData] = useState([]);
  const [nonCompanyEmployees, setNonCompanyEmployees] = useState([]);
  const [step, setStep] = useState(UPLOAD_STEP);
  const [notImported, setNotImported] = useState([]);
  const [employeesToUpdateCount, setEmployeesToUpdateCount] = useState(0);
  const [surveyId, setSurveyId] = useState(null);
  const [sendSurvey, setSendSurvey] = useState(false);

  if (!companyId) return <p>No company provided.</p>;

  useEffect(() => {
    fetchOptions();
  }, []);

  function fetchOptions() {
    axios
      .post(`/retrieve-employee-importer-options/`, { company_id: companyId })
      .then(({ data }) => {
        setDepartmentOptions(
          data.departments
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name
            }))
        );
        setEthnicityOptions(
          Object.keys(data.ethnicities)
            .map((g, i) => ({
              key: i,
              text: data.ethnicities[i],
              value: i
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
        setGenderOptions(
          Object.keys(data.genders)
            .map((g, i) => ({
              key: i,
              text: data.genders[i],
              value: i
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
        setLocationOptions(
          data.locations
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name
            }))
        );
        setPositionOptions(
          data.positions
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name
            }))
        );
        setRaceOptions(
          Object.keys(data.races)
            .map((g, i) => ({
              key: i,
              text: data.races[i],
              value: i
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
        setCreateLoading(false);
      })
      .catch((err) => {
        setDepartmentOptions([]);
        setEthnicityOptions([]);
        setGenderOptions([]);
        setLocationOptions([]);
        setPositionOptions([]);
        setRaceOptions([]);
      });
  }

  function onUpload(e) {
    if (!e.target.files || !e.target.files.length) return;

    const inputFile = e.target.files[0];

    setFile(inputFile);

    setLoading(true);
    setError([]);

    const req = new FormData();
    req.append('file', inputFile);
    req.append('company_id', companyId);
    req.append('task', 'missing');

    axios
      .post(`/company-employee-importer/`, req)
      .then(({ data }) => {
        setLoading(false);

        if (!data.missing_data.length && !data.bad_format.length) {
          onMissingSubmit(inputFile);
        } else {
          setStep(MISSING_STEP);

          const missing = data.missing_data.map((m) => ({
            row: m.row,
            missing: m.types,
            bad_format: []
          }));
          const bad = data.bad_format.map((m) => ({
            row: m.row,
            missing: [],
            bad_format: m.types
          }));

          const combined = [...missing, ...bad].reduce((acc, cur) => {
            const item = acc.find((r) => r.row === cur.row);

            if (!item) {
              acc.push(cur);
            } else {
              if (item.bad_format.length) {
                item.missing = cur.missing;
              } else {
                item.bad_format = cur.bad_format;
              }
            }

            return acc;
          }, []);

          setMissingData(
            combined
              .sort((a, b) => (a.row > b.row ? 1 : -1))
              .map((c) => ({
                ...c,
                id: uuidv4()
              }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  }

  function onResolverChange(id, value) {
    const newItems = [...items];
    const item = newItems.find((d) => d.id === id);

    if (!item) return;

    item.value = value;

    setItems(newItems);
  }

  function onDepartmentCreate(name, itemId) {
    const req = {
      name
    };

    setCreateLoading(true);

    axios.post(`/departments/`, req).then(({ data }) => {
      fetchOptions();
      if (itemId) {
        onResolverChange(itemId, { value: data.id, text: data.name });
      }
    });
  }

  function onPositionCreate(name, itemId) {
    const req = {
      name,
      company_id: companyId
    };

    setCreateLoading(true);

    axios.post(`/companies/${companyId}/positions/`, req).then(({ data }) => {
      fetchOptions();
      if (itemId) {
        onResolverChange(itemId, { value: data.id, text: data.name });
      }
    });
  }

  function onLocationCreate(name, itemId) {
    const req = {
      name,
      company_id: companyId
    };

    setCreateLoading(true);

    axios.post(`/companies/${companyId}/locations/`, req).then(({ data }) => {
      fetchOptions();
      if (itemId) {
        onResolverChange(itemId, { value: data.id, text: data.name });
      }
    });
  }

  function onMissingChange(id, checked) {
    const newMissing = [...missingData];
    const item = newMissing.find((d) => d.id === id);
    if (!item) return;

    item.checked = checked;

    setMissingData(newMissing);
  }

  function onMissingSubmit(inputFile = null) {
    setLoading(true);
    setError(null);

    const req = new FormData();
    if (inputFile) {
      req.append('file', inputFile);
    } else {
      req.append('file', file);
    }
    req.append('company_id', companyId);
    req.append('task', 'employee');

    axios
      .post(`/company-employee-importer/`, req)
      .then(({ data }) => {
        // nothing to do here...
        if (
          !data.possible_existing_employees.length &&
          !data.existing_employees_not_this_company.length
        ) {
          return onEmployeeSubmit(inputFile);
        }

        setExistingEmployees(
          data.possible_existing_employees.map((d) => ({
            ...d,
            uuid: uuidv4(),
            value: null
          }))
        );

        setNonCompanyEmployees(
          data.existing_employees_not_this_company.map((d) => ({
            ...d,
            uuid: uuidv4(),
            value: null
          }))
        );
        setEmployeesToUpdateCount(data.employees_to_update_count);
        setLoading(false);

        setStep(EMPLOYEE_STEP);
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  }

  function onEvaluateSubmit() {
    setLoading(true);
    setError(null);

    const departments = items
      .filter((f) => f.department && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const ethnicities = items
      .filter((f) => f.ethnicity && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const genders = items
      .filter((f) => f.gender && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const locations = items
      .filter((f) => f.location && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const positions = items
      .filter((f) => f.position && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const races = items
      .filter((f) => f.race && f.value)
      .map((d) => ({
        [d.originalText]: d.value.value
      }));

    const emails = existingEmployees
      .filter((f) => f.value)
      .map((employee) => ({
        [employee.email]: employee.value.id
      }));

    const req = new FormData();
    req.append('company_id', companyId);
    req.append('departments', JSON.stringify(departments));
    req.append('ethnicities', JSON.stringify(ethnicities));
    req.append('emails', JSON.stringify(emails));
    req.append('file', file);
    req.append('genders', JSON.stringify(genders));
    req.append('locations', JSON.stringify(locations));
    req.append('positions', JSON.stringify(positions));
    req.append('races', JSON.stringify(races));
    req.append('task', 'create');

    if (sendSurvey && surveyId) {
      req.append('invite_id', surveyId);
    }

    axios
      .post(`/company-employee-importer/`, req)
      .then(({ data }) => {
        setLoading(false);
        setStep(SUMMARY_STEP);
        setNotImported(data.rows);
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  }

  function onEmployeeSubmit(inputFile = null) {
    setError(null);
    setLoading(true);

    const req = new FormData();
    req.append('company_id', companyId);
    req.append('task', 'evaluate');

    if (inputFile) {
      req.append('file', inputFile);
    } else {
      req.append('file', file);
    }

    axios
      .post(`/company-employee-importer/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems([
          ...items,
          ...data.department
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              department: true,
              key: i + 1,
              id: uuidv4()
            })),
          ...data.ethnicities
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              ethnicity: true,
              key: i + 1,
              id: uuidv4()
            })),
          ...data.genders
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              gender: true,
              key: i + 1,
              id: uuidv4()
            })),
          ...data.locations
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              location: true,
              key: i + 1,
              id: uuidv4()
            })),
          ...data.positions
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              position: true,
              key: i + 1,
              id: uuidv4()
            })),
          ...data.races
            .sort((a, b) => (a > b ? 1 : -1))
            .map((d, i) => ({
              originalText: d,
              race: true,
              key: i + 1,
              id: uuidv4()
            }))
        ]);
        setStep(EVALUATE_STEP);
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  }

  function onEmployeeChange(employeeUuid, selected) {
    const newEmployees = [...existingEmployees];
    const employee = newEmployees.find((d) => d.uuid === employeeUuid);

    employee.value = selected;

    setExistingEmployees(newEmployees);
  }

  function onReset() {
    setFile(null);
    setStep(UPLOAD_STEP);
  }

  const itemsToResolve = [
    {
      key: 1,
      items: items.filter((f) => f.department),
      options: departmentOptions,
      title: 'Department Issues',
      itemTitle: 'Department',
      itemLabel: 'Name',
      addable: true,
      onCreate: (formData, itemId) => onDepartmentCreate(formData, itemId)
    },
    {
      key: 2,
      items: items.filter((f) => f.ethnicity),
      options: ethnicityOptions,
      title: 'Ethnicity Issues',
      itemTitle: 'Ethnicity',
      itemLabel: 'Name'
    },
    {
      key: 3,
      items: items.filter((f) => f.gender),
      options: genderOptions,
      title: 'Gender Issues',
      itemTitle: 'Gender',
      itemLabel: 'Name'
    },
    {
      key: 4,
      items: items.filter((f) => f.position),
      options: positionOptions,
      title: 'Position Issues',
      itemTitle: 'Position',
      addable: true,
      itemLabel: 'Name',
      onCreate: (formData, itemId) => onPositionCreate(formData, itemId)
    },
    {
      key: 5,
      items: items.filter((f) => f.race),
      options: raceOptions,
      title: 'Race Issues',
      itemTitle: 'Race',
      itemLabel: 'Name'
    },
    {
      key: 6,
      items: items.filter((f) => f.location),
      options: locationOptions,
      title: 'Location Issues',
      itemTitle: 'Location',
      addable: true,
      itemLabel: 'Name',
      onCreate: (formData, itemId) => onLocationCreate(formData, itemId)
    }
  ].filter((f) => f.items.length);

  const missingColumns = [
    {
      Header: '',
      width: 50,
      id: 'check',
      accessor: (d) => (
        <input
          type="checkbox"
          checked={d.checked}
          onChange={() => onMissingChange(d.id, !d.checked)}
        />
      )
    },
    { Header: 'Row', width: 80, accessor: 'row' },
    {
      Header: 'Missing',
      id: 'missing',
      accessor: (d) => formatter.format(d.missing)
    },
    {
      Header: 'Bad Format',
      id: 'badf',
      accessor: (d) => formatter.format(d.bad_format)
    }
  ];

  return (
    <div>
      {!file && (
        <Form>
          <Form.Input
            type="file"
            label="Employee Roster"
            onChange={onUpload}
            loading={loading}
            accept=".csv"
          />
        </Form>
      )}
      {file && (
        <div>
          {step !== SUMMARY_STEP && (
            <p>
              Working file: <b>{file.name}</b>
            </p>
          )}
          {step === MISSING_STEP && (
            <React.Fragment>
              {missingData.length > 0 && (
                <ResolverSection
                  defaultOpen
                  title={`Missing or Poorly Formatted (${missingData.length} Rows)`}
                  unresolvedCount={-1}
                  instructions="The following rows have
              missing or poorly formatted data. You may use the checkboxes below to track your corrections. After you're finished, you will re-upload your corrected spreadsheet."
                >
                  <CheckboxTable
                    data={missingData}
                    onChange={onMissingChange}
                    columns={missingColumns}
                  />
                </ResolverSection>
              )}
            </React.Fragment>
          )}
          {step === EVALUATE_STEP && (
            <React.Fragment>
              {itemsToResolve.map((section) => (
                <ResolverSection
                  key={section.key}
                  title={`${section.title} (${section.items.length})`}
                  unresolvedCount={section.items.filter((f) => !f.value).length}
                >
                  <div className="items">
                    {section.items.map((item) => (
                      <Resolver
                        key={item.id}
                        addable={section.addable}
                        originalText={item.originalText}
                        options={section.options}
                        onChange={(e) => onResolverChange(item.id, e)}
                        onCreate={(formData) =>
                          section.onCreate(formData, item.id)
                        }
                        value={item.value ? item.value.value : null}
                        title={section.itemTitle}
                        label={section.itemLabel}
                        loading={createLoading}
                      />
                    ))}
                  </div>
                </ResolverSection>
              ))}
              <EngagementInvite
                companyId={companyId}
                sendSurvey={sendSurvey}
                surveyId={surveyId}
                onSendSurveyChange={(newSendSurvey) => {
                  if (newSendSurvey) {
                    setSendSurvey(newSendSurvey);
                  } else {
                    setSendSurvey(newSendSurvey);
                    setSurveyId(null);
                  }
                }}
                onSurveyChange={(e) => setSurveyId(e)}
              />
            </React.Fragment>
          )}
          {step === EMPLOYEE_STEP && (
            <React.Fragment>
              {employeesToUpdateCount > 0 && (
                <p className="resolver-instructions">
                  <Icon name="lightbulb outline" /> There are{' '}
                  {employeesToUpdateCount} employees that will be updated.
                </p>
              )}
              {nonCompanyEmployees.length > 0 && (
                <ResolverSection
                  title="Unprocessable"
                  unresolvedCount={-1}
                  defaultOpen={existingEmployees.length === 0}
                  instructions="The following employees are unprocessable."
                >
                  <div style={{ marginTop: '1em' }}>
                    <ExistingEmployees
                      data={nonCompanyEmployees}
                      actionable={false}
                    />
                  </div>
                </ResolverSection>
              )}
              {existingEmployees.length > 0 && (
                <ResolverSection
                  title="Potenital Employee Matches"
                  unresolvedCount={-1}
                  instructions="Here are some potential matches for employees in your spreadsheet and employees in the database."
                  defaultOpen
                >
                  <div style={{ marginTop: '1em' }}>
                    <ExistingEmployees
                      data={existingEmployees}
                      onChange={onEmployeeChange}
                    />
                  </div>
                </ResolverSection>
              )}
            </React.Fragment>
          )}
          {step === SUMMARY_STEP && (
            <div className="import-summary">
              <div className="meta">
                <Icon size="huge" color="green" name="check circle" />
                <p>Nice job! You're done here.</p>
                {employeesToUpdateCount > 0 && (
                  <p>
                    <b>{employeesToUpdateCount} employees were updated</b>
                  </p>
                )}
                <ActionButton onClick={onSuccess} text="Close" />
              </div>
              {notImported.length > 0 && (
                <div>
                  <ResolverSection
                    title="Non-imported People"
                    unresolvedCount={-1}
                    instructions="The following people were not imported."
                  >
                    <div style={{ marginTop: '1em' }}>
                      <NotImported data={notImported} />
                    </div>
                  </ResolverSection>
                </div>
              )}
            </div>
          )}
          <div style={{ marginTop: '15px' }}>
            {step === MISSING_STEP && (
              <ActionButton
                loading={loading}
                // onClick={() => onMissingSubmit()}
                onClick={onReset}
                text="Re-upload"
              />
            )}
            {step === EMPLOYEE_STEP && (
              <ActionButton
                loading={loading}
                onClick={() => onEmployeeSubmit()}
                text="I'm Finished With Employees"
              />
            )}
            {step === EVALUATE_STEP && (
              <ActionButton
                loading={loading}
                onClick={onEvaluateSubmit}
                text="Finish"
                disabled={
                  items.find((f) => !f.value) || (sendSurvey && !surveyId)
                    ? true
                    : false
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

EmployeeUpload.defaultProps = {
  companyId: null,
  companyName: ''
};

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction'
});

const ResolverSection = ({
  children,
  defaultOpen,
  instructions,
  title,
  unresolvedCount
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(defaultOpen);
  }, []);

  return (
    <div className="resolver-section">
      <button
        className="resolver-section-title"
        onClick={() => setOpen(!isOpen)}
        style={{ marginBottom: isOpen ? '10px' : '0' }}
      >
        {unresolvedCount === 0 && (
          <Icon
            style={{ marginRight: '10px' }}
            name="check"
            color="green"
            title="Resolved"
          />
        )}
        {title}
        {unresolvedCount > 0 && <span>{unresolvedCount} unresolved</span>}
        <Icon name={isOpen ? 'caret down' : 'caret right'} />{' '}
      </button>
      {isOpen && (
        <React.Fragment>
          {instructions && (
            <p className="instructions">
              <Icon name="lightbulb outline" /> {instructions}
            </p>
          )}
          {children}
        </React.Fragment>
      )}
    </div>
  );
};

ResolverSection.defaultProps = {
  defaultOpen: false,
  instructions: '',
  unresolvedCount: 0
};
