import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import ListItem from './ListItem';

const SORT_PERCENTAGE_DESC = 'percent_desc';
const SORT_PERCENTAGE_ASC = 'percent_asc';

export default function QuestionList({ questions }) {
  const [sortValue, setSortValue] = useState(SORT_PERCENTAGE_DESC);

  const formattedQuestions = questions.map((q) => ({
    ...q,
    percentage: (q.response_total / q.response_total_max) * 100
  }));

  const sortedQuestions =
    sortValue === SORT_PERCENTAGE_DESC
      ? formattedQuestions.sort((a, b) =>
          a.percentage < b.percentage ? 1 : -1
        )
      : formattedQuestions.sort((a, b) =>
          a.percentage > b.percentage ? 1 : -1
        );

  function onSortChange() {
    if (sortValue === SORT_PERCENTAGE_DESC) {
      setSortValue(SORT_PERCENTAGE_ASC);
    } else {
      setSortValue(SORT_PERCENTAGE_DESC);
    }
  }

  return (
    <div className="engagement-question-list">
      <Button
        content="Sort Questions By Rating"
        circular
        size="mini"
        basic
        icon={sortValue === SORT_PERCENTAGE_DESC ? 'arrow down' : 'arrow up'}
        onClick={onSortChange}
        style={{ marginBottom: '1em' }}
        labelPosition="right"
      />
      {sortedQuestions.map((item) => (
        <ListItem
          key={item.id}
          text={item.text}
          rawResponses={item.responses}
          employeeCount={item.employee_count}
          employeeResponseCount={item.employee_response_count}
          responseTotal={item.response_total}
          responseTotalMax={item.response_total_max}
        />
      ))}
    </div>
  );
}

QuestionList.defaultProps = {
  questions: []
};
