import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

export default function Subnav({ links }) {
  return (
    <div className="subnav">
      {links.map((l, i) => (
        <Link to={l.to} className={l.active ? 'active' : ''} key={i + 1}>
          {l.text}
        </Link>
      ))}
    </div>
  );
}
