import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FaCalendarAlt,
  FaCaretSquareRight,
  FaCaretSquareDown,
  FaQuestionCircle,
  FaStar,
  FaRegStar
} from 'react-icons/fa';

import { CustomQuestionForm, Assigner } from 'components/Questions';

import { ENGAGEMENT_VALUE } from '@util/data/surveyVariety';

class VersionItem extends Component {
  state = {
    open: false,
    refresh: false
  };

  toggleOpen = () => this.setState({ open: !this.state.open });

  render() {
    const { props } = this;
    const { open } = this.state;

    const { assignerDisabled, surveyVariety } = this.props;

    return (
      <div className="version-item">
        <div className="version-meta">
          <button className="version-toggle-button" onClick={this.toggleOpen}>
            {open ? <FaCaretSquareDown /> : <FaCaretSquareRight />}
          </button>
          <div>
            <h2>
              Version {props.number} {props.name ? `(${props.name})` : ''}
            </h2>
            <div className="tags">
              <span>
                <FaCalendarAlt /> {props.createdAt}
              </span>
              <span>
                <FaQuestionCircle /> {props.questionCount}
              </span>
            </div>
          </div>
          <button
            className={`version-default-button${props.active ? ' active' : ''}`}
            title="Set as active version"
            disabled={props.active}
            onClick={() => (!props.active ? props.onActiveClick() : null)}
          >
            {props.active && <FaStar />}
            {!props.active && <FaRegStar />}
          </button>
        </div>
        {open && (
          <div className="version-details">
            {surveyVariety === ENGAGEMENT_VALUE && (
              <div style={{ marginBottom: '15px' }}>
                <CustomQuestionForm
                  position={props.questionCount}
                  versionId={this.props.id}
                  onUpdate={() =>
                    this.setState({ refresh: !this.state.refresh })
                  }
                />
              </div>
            )}
            <Assigner
              refresh={this.state.refresh}
              disabled={assignerDisabled}
              versionId={this.props.id}
            />
          </div>
        )}
      </div>
    );
  }
}

export default VersionItem;

VersionItem.defaultProps = {
  addDisabled: false,
  assignerDisabled: false,
  orderingDisabled: false,
  removeDisabled: false,
  surveyVariety: ''
};

VersionItem.propTypes = {
  addDisabled: PropTypes.bool,
  assignerDisabled: PropTypes.bool,
  orderingDisabled: PropTypes.bool,
  removeDisabled: PropTypes.bool
};
