import React from 'react';
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
highchartsMore(Highcharts);

export default function FunctionalFitGraph({ data, fillColor }) {
  if (!data.length) return <p>No data!</p>;

  const categories = data.map((c) => c.profile_name);
  const seriesData = data.map((c) => c.steq_score * 10);

  const options = {
    chart: {
      polar: true,
      height: '60%',
      width: 800,
      type: 'area',
      style: {
        fontFamily: 'Open Sans'
      }
    },
    title: '',
    pane: {
      size: '85%'
    },
    plotOptions: {
      area: {
        fillColor,
        marker: {
          enabled: false
        }
      },
      series: {
        states: {
          hover: {}
        }
      }
    },
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      gridLineColor: '#ccc',
      labels: {
        useHTML: true,
        formatter: ({ value }) => {
          if (typeof value !== 'string') return null;

          return `<div><h3 class="spiderweb-title">${value}</h3></div>`;
        }
      }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: Math.min(...seriesData) - 10,
      max: Math.max(...seriesData) + 5,
      gridLineColor: 'transparent',
      labels: {
        enabled: false
      }
    },
    // tooltip: {
    //   formatter: (d) => {
    //     return `Hello!`;
    //   },
    //   shared: true
    // },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'FPF',
        data: seriesData,
        pointPlacement: 'on'
      }
    ]
  };

  return (
    <div className="functional-fit-graph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

FunctionalFitGraph.defaultProps = {
  data: [],
  fillColor: `rgba(255, 127, 0, 0.64)`
};
