import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Wrapped } from 'components/Layouts';

import { ToggleButton } from 'components/shared/Buttons';
import { EmptyList } from 'components/shared/List';

import AddForm from './AddForm';
import VersionItem from './VersionItem';

import './index.scss';

class List extends Component {
  state = {
    addOpen: false,
    versions: []
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    axios
      .get(`/surveys/${this.props.surveyId}/versions/`)
      .then(({ data }) => {
        this.setState({
          addOpen: false,
          loading: false,
          versions: data.versions
        });
      })
      .catch((err) => {});
  };

  onActiveClick = (versionId) => {
    this.setState({ loading: true });

    axios
      .post(`/versions/${versionId}/activate/`)
      .then(() => this.fetchData())
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  toggleAddOpen = () => this.setState({ addOpen: !this.state.addOpen });

  render() {
    const { addOpen, versions } = this.state;
    const { surveyVariety, surveyId } = this.props;

    return (
      <Wrapped
        title="Versions"
        button={
          surveyVariety !== 2 ? (
            <ToggleButton onClick={this.toggleAddOpen} open={addOpen} />
          ) : null
        }
      >
        <div>
          {addOpen && (
            <div className="segment">
              <AddForm
                onClose={this.toggleAddOpen}
                onSubmit={this.fetchData}
                id={surveyId}
              />
            </div>
          )}
          {!addOpen && (
            <div>
              {versions.map((v) => (
                <VersionItem
                  key={v.id}
                  id={v.id}
                  active={v.active}
                  name={v.name}
                  questionCount={v.question_count}
                  createdAt={new Date(v.created_at).toLocaleString()}
                  number={v.number}
                  onActiveClick={() => this.onActiveClick(v.id)}
                  assignerDisabled={[0, 3, 5].includes(
                    this.props.surveyVariety
                  )}
                  surveyVariety={this.props.surveyVariety}
                  onUpdate={this.fetchData}
                />
              ))}
              {versions.length === 0 && (
                <EmptyList
                  header="Nothing here"
                  text="No survey versions. Create one."
                />
              )}
            </div>
          )}
        </div>
      </Wrapped>
    );
  }
}

export default List;

List.defaultProps = {
  surveyId: null,
  surveyVariety: ''
};

List.propTypes = {
  surveyId: PropTypes.string.isRequired
  // surveyVariety: PropTypes.string
};
