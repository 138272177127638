import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  FaUserAlt,
  FaUsers,
  FaBuilding,
  FaExternalLinkAlt
} from 'react-icons/fa';
import ResendButton from 'components/Employee/SurveyInvites/ResendButton';

const iconStyles = {
  size: 50
};

export default function Summary({
  showResend,
  surveys,
  surveyName,
  companyName,
  companyId
}) {
  return (
    <div className="request-summary">
      <div className="summary-block">
        <FaBuilding {...iconStyles} />
        <div>
          <h2>
            {companyName}{' '}
            {companyId && (
              <Link to={`/companies/${companyId}`}>
                <FaExternalLinkAlt size={16} />
              </Link>
            )}
          </h2>
          <p>Company</p>
        </div>
      </div>
      {surveyName && (
        <div className="summary-block">
          <FaUsers {...iconStyles} />
          <div>
            <h2>{surveyName}</h2>
            <p>Engagement Survey</p>
          </div>
        </div>
      )}
      {surveys.map((s) => (
        <div
          key={uuidv4()}
          className={`summary-block${
            s.rater_type !== undefined
              ? ` user activity-status-${s.activity_status}`
              : ''
          }`}
        >
          <FaUserAlt {...iconStyles} className="user-icon" />
          <div>
            <h2>
              {s.company_manager_name && `${s.company_manager_name}`}
              {s.company_employee_name && `${s.company_employee_name}`}
              {s.company_manager &&
                `${s.company_manager.first_name} ${s.company_manager.last_name} (Manager)`}
              {s.company_employee &&
                `${s.company_employee.first_name} ${s.company_employee.last_name} (Employee)`}
              {s.target && <span className="rater-tag">target</span>}
              {!s.target && s.rater_type && (
                <span className="rater-tag">
                  {s.rater_type}
                  {s.other_rater_context && `: ${s.other_rater_context}`}
                </span>
              )}
              {showResend && (
                <span style={{ marginLeft: '10px' }}>
                  <ResendButton id={s.id} />
                </span>
              )}
            </h2>
            <p>{s.name}</p>
            {s.company_manager && <p>{s.company_manager.email}</p>}
            {s.company_employee && <p>{s.company_employee.email}</p>}
            {s.email && <p>{s.email}</p>}
          </div>
        </div>
      ))}
    </div>
  );
}

Summary.defaultProps = {
  companyId: null,
  companyName: '',
  showResend: false,
  surveys: [],
  surveyName: ''
};

Summary.propTypes = {
  companyName: PropTypes.string,
  surveys: PropTypes.array
};
