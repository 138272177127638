import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import BasicForm from 'components/shared/Forms/BasicForm';

import { SET_TOAST } from 'duck/toast';

function ProfileForm({
  anValue,
  area,
  aValue,
  bValue,
  ciss,
  cValue,
  cumulativeSd,
  eValue,
  exValue,
  fValue,
  gValue,
  hol,
  holland,
  hValue,
  id,
  inValue,
  iValue,
  lValue,
  mbti,
  mValue,
  name,
  nValue,
  oValue,
  q1Value,
  q2Value,
  q3Value,
  q4Value,
  scValue,
  sourceYear,
  tmValue,
  dispatch,
  onSuccess
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    if (id) {
      return onUpdate(formData);
    }

    return onCreate(formData);
  }

  function onCreate(formData) {
    const postData = { ...formData };

    axios
      .post(`/occupational-profiles/`, postData)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onUpdate(formData) {
    const postData = {
      ...formData
    };

    axios
      .put(`/occupational-profiles/${id}/`, postData)
      .then(() => {
        setLoading(false);
      })
      .then(() =>
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Profile Updated!'
          }
        })
      )
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  const fields = [
    {
      name: 'original_name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'holland',
      label: 'Holland',
      initialValue: holland,
      required: true,
      textarea: true,
      schema: () => Yup.string().required('Required')
    },
    {
      grouped: true,
      fields: [
        {
          name: 'source_year',
          label: 'Source Year',
          initialValue: sourceYear,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'cumulative_sd',
          label: 'Cumulative SD',
          initialValue: cumulativeSd,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'ciss',
          label: 'CISS',
          initialValue: ciss,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'mbti',
          label: 'MBTI',
          initialValue: mbti,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      name: 'area',
      label: 'Area',
      initialValue: area,
      required: true,
      textarea: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'an',
      label: 'AN',
      initialValue: anValue,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'ex',
      label: 'EX',
      initialValue: exValue,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'in',
      label: 'IN',
      initialValue: inValue,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'sc',
      label: 'SC',
      initialValue: scValue,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'tm',
      label: 'TM',
      initialValue: tmValue,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      grouped: true,
      fields: [
        {
          name: 'a',
          label: 'A',
          initialValue: aValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'b',
          label: 'B',
          initialValue: bValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'c',
          label: 'C',
          initialValue: cValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'e',
          label: 'E',
          initialValue: eValue,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      grouped: true,
      fields: [
        {
          name: 'f',
          label: 'F',
          initialValue: fValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'g',
          label: 'G',
          initialValue: gValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'h',
          label: 'H',
          initialValue: hValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'i',
          label: 'I',
          initialValue: iValue,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      grouped: true,
      fields: [
        {
          name: 'l',
          label: 'L',
          initialValue: lValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'm',
          label: 'M',
          initialValue: mValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'n',
          label: 'N',
          initialValue: nValue,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'o',
          label: 'O',
          initialValue: oValue,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      grouped: true,
      fields: [
        {
          name: 'q1',
          label: 'Q1',
          initialValue: q1Value,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'q2',
          label: 'Q2',
          initialValue: q2Value,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'q3',
          label: 'Q3',
          initialValue: q3Value,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'q4',
          label: 'Q4',
          initialValue: q4Value,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      name: 'hol',
      label: 'Heart of Leadership Profile',
      required: false,
      radio: true,
      initialValue: hol,
      schema: () => null
    }
  ].filter((f) => (id ? f.name !== 'hol' : true));

  return (
    <div className="segment">
      <BasicForm
        buttonText="Save"
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

ProfileForm.defaultProps = {
  anValue: '',
  area: '',
  aValue: '',
  bValue: '',
  ciss: '',
  cValue: '',
  cumulativeSd: '',
  eValue: '',
  exValue: '',
  fValue: '',
  gValue: '',
  hol: false,
  holland: '',
  hValue: '',
  id: null,
  inValue: '',
  iValue: '',
  lValue: '',
  mbti: '',
  mValue: '',
  name: '',
  nValue: '',
  oValue: '',
  q1Value: '',
  q2Value: '',
  q3Value: '',
  q4Value: '',
  scValue: '',
  sourceYear: '',
  tmValue: ''
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ProfileForm);
