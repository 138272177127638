import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Wrapped } from 'components/Layouts';
import { ActionButton } from 'components/shared/Buttons';
import { SET_TOAST } from 'duck/toast';

function LegacyResult({
  employeeId,
  employeeName,
  available,
  fetchData,
  managerId,
  adminId,
  companyId,
  dispatch
}) {
  const [loading, setLoading] = useState(false);

  if (!employeeId || !available || !companyId) return null;

  function onRequest() {
    setLoading(true);
    axios
      .post(`/request-employee-legacy-result/`, {
        company_employee_id: employeeId,
        company_manager_id: managerId,
        company_id: companyId
      })
      .then(() => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Request Sent!',
            positive: true
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            message: err.response.data.ui_message,
            negative: true
          }
        });
      });
  }

  function onApprove() {
    setLoading(true);
    axios
      .post(`/evaluate-employee-legacy-result/`, {
        company_employee_id: employeeId
      })
      .then(() => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Sent To Evaluation Queue!',
            positive: true
          }
        });
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            message: err.response.data.ui_message,
            negative: true
          }
        });
      });
  }

  return (
    <Wrapped title="Legacy Report">
      <div
        className="segment"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {managerId && (
          <React.Fragment>
            <p style={{ marginRight: '10px', marginBottom: '0' }}>
              <b>{employeeName}</b> has a legacy report. You may request it now.
            </p>
            <ActionButton
              loading={loading}
              onClick={onRequest}
              text="Request Now"
            />
          </React.Fragment>
        )}
        {adminId && (
          <React.Fragment>
            <p style={{ marginRight: '10px', marginBottom: '0' }}>
              <b>{employeeName}</b> has a legacy report. You may evaluate it
              now.
            </p>
            <ActionButton
              loading={loading}
              onClick={onApprove}
              text="Send To Evaluation Queue"
            />
          </React.Fragment>
        )}
      </div>
    </Wrapped>
  );
}

LegacyResult.defaultProps = {
  available: false,
  employeeName: '',
  employeeId: null
};

function mapStateToProps(state) {
  return {
    managerId: state.manager ? state.manager.id : null,
    adminId: state.admin ? state.admin.id : null
  };
}

export default connect(mapStateToProps)(LegacyResult);
