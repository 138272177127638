import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Modal } from 'semantic-ui-react';
import { ActionButton } from 'components/shared/Buttons';
import { PerformanceEvaluationLayout } from 'components/Layouts';
import {
  Meta,
  QuestionList,
  ResponseFilters
} from 'components/PerformanceEvaluation';
import { ErrorView } from 'components/shared/Errors';

function PerformanceEvaluationDetail({ match, managerId }) {
  const [surveyDate, setSurveyDate] = useState('');
  const [surveyName, setSurveyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [questions, setQuestions] = useState([]);
  const [respondants, setRespondants] = useState([]);
  const [error, setError] = useState(null);
  const [activeResponderIds, setActiveResponderIds] = useState([]);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const { surveyContainerId } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/performance-surveys/${surveyContainerId}/`)
      .then(({ data }) => {
        if (!data.respondants.length) {
          return setError('There were no respondants for this review');
        }

        if (!data.survey_questions.length) {
          return setError('There were no questions for this review');
        }

        const editableSurvey = data.surveys
          .filter((f) => f.activity_status !== 2)
          .find((s) => s.company_manager_id === managerId);

        const responders = data.respondants.map((r) => ({
          ...r,
          uuid: `${r.id}${r.is_employee ? 'Employee' : 'Manager'}`,
          currentUser: !editableSurvey
            ? false
            : managerId === r.id && !r.is_employee
        }));

        const formattedQuestions = mapRespondantsToQuestions(
          data.survey_questions,
          responders
        );

        setQuestions(
          formattedQuestions.map((q) => ({
            ...q,
            id: q.id,
            category: q.category_string,
            text: q.text
          }))
        );
        setActiveSurvey(editableSurvey);
        setActiveResponderIds(responders.map((r) => r.uuid));
        setRespondants(responders);
        setCompanyLogo(data.company_logo);
        setSurveyName(data.name || 'Performance Review');
        setSurveyDate(data.created_at_formatted);
      })
      .catch((err) => {
        setError(
          err.response.data.ui_message ||
            'There was a problem loading this evaluation'
        );
      });
  }

  function setResponderInactive(responderId) {
    setActiveResponderIds(
      [...activeResponderIds].filter((f) => f !== responderId)
    );
  }

  function setResponderActive(responderId) {
    setActiveResponderIds([...activeResponderIds, responderId]);
  }

  function onFinalizeClick() {}

  if (error) {
    return <ErrorView text={error} />;
  }

  const inactiveResponders = [...respondants].filter(
    (f) => !activeResponderIds.includes(f.uuid)
  );

  const activeResponders = [...respondants].filter((f) =>
    activeResponderIds.includes(f.uuid)
  );

  const filteredQuestions = [...questions].map((q) => {
    // get filtered responses
    const filteredResponses = q.responses.filter((f) =>
      activeResponderIds.includes(f.responder_id)
    );

    return { ...q, responses: filteredResponses };
  });

  return (
    <div>
      <PerformanceEvaluationLayout>
        <Meta companyLogo={companyLogo} title={surveyName} date={surveyDate} />
        <ResponseFilters
          respondants={inactiveResponders}
          onResponderClick={setResponderActive}
        />
        <QuestionList
          questions={filteredQuestions}
          respondants={activeResponders}
          onResponderClick={setResponderInactive}
          onUpdate={fetchData}
        />
        {activeSurvey && (
          <FinalizeModal surveyId={activeSurvey.id} onUpdate={fetchData} />
        )}
      </PerformanceEvaluationLayout>
    </div>
  );
}

function mapRespondantsToQuestions(questions, respondants) {
  const respondantQuestions = respondants.map((r) => ({
    responder_id: r.uuid,
    questions: r.questions,
    editable: r.currentUser
  }));

  let newQuestions = [...questions].map((q) => ({ ...q, responses: [] }));
  newQuestions.forEach((q) => {
    respondantQuestions.forEach((r) => {
      let foundQuest = r.questions.find((r) => r.text === q.text);
      if (!foundQuest) return;

      // multichoice
      ['a', 'b', 'c', 'd', 'e'].forEach((a) => {
        if (foundQuest[`response_${a}`]) {
          foundQuest.answer = foundQuest[a];
        }
      });

      if (foundQuest.respondant_comment) {
        foundQuest.comment = foundQuest.respondant_comment;
      }

      // open response
      if (foundQuest.open_response_reply) {
        foundQuest.answer = foundQuest.open_response_reply;
      }

      if (foundQuest.true_false) {
        foundQuest.answer =
          foundQuest.response_true_false !== undefined
            ? foundQuest.response_true_false
              ? 'True'
              : 'False'
            : null;
      }

      q.responses.push({
        ...foundQuest,
        responder_id: r.responder_id,
        editable: r.editable
      });
    });
  });

  return newQuestions;
}

function mapStateToProps(state) {
  return {
    managerId: state.manager.id
  };
}

export default connect(mapStateToProps)(PerformanceEvaluationDetail);

const FinalizeModal = ({ surveyId, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setError(null);
  }

  function onError() {
    setLoading(false);
    setError(
      `There are some questions that need answering. Please answer them and finalize again.`
    );
  }

  function onConfirm() {
    setLoading(true);
    setError(null);

    axios
      .post(`/employee-surveys/${surveyId}/submit/`)
      .then(({ data }) => {
        if (!data.message_id) {
          return onError();
        }

        setLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <React.Fragment>
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}
      >
        <ActionButton text="Finalize" onClick={() => setOpen(true)} />
      </div>
      <Modal open={isOpen} size="tiny" onClose={onClose}>
        <Modal.Header content="Heads Up!" />
        <Modal.Content>
          <p style={{ fontSize: '1.15em' }}>
            You are about to finalize this survey. After finalizing, you will no
            longer have the ability to edit your responses.{' '}
            <b>Are you sure you want to do this?</b>
          </p>
          {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic size="small" onClick={onClose} />
          <Button
            content="Confirm"
            primary
            size="small"
            onClick={onConfirm}
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
