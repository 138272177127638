import React, { useState, useEffect } from 'react';
import { FaRegTrashAlt, FaDownload } from 'react-icons/fa';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Wrapped } from 'components/Layouts';
import {
  BasicButton,
  SubmitButton,
  ToggleButton
} from 'components/shared/Buttons';
import { SET_TOAST } from 'duck/toast';

const AddForm = ({ error, onSubmit, loading, children, admin }) => {
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(true);

  function onSubmitClick(e) {
    e.preventDefault();
    const docInput = document.getElementById('doc');
    if (!docInput.files.length) return;

    const file = docInput.files[0];

    onSubmit(title, file, visible);
  }

  return (
    <div className="segment">
      <Form>
        <Form.Input
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <Form.Checkbox
          label="Visible"
          toggle
          checked={visible}
          onChange={(e, d) => setVisible(d.checked)}
        />
        <Form.Input label="Document" type="file" id="doc" />
        {error && <p>{error}</p>}
        <div style={{ display: 'flex' }}>
          {children}
          <SubmitButton
            disabled={!title}
            text="Save"
            loading={loading}
            onClick={onSubmitClick}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </Form>
    </div>
  );
};

function Documents({ employeeId, admin, hideIfEmpty, dispatch }) {
  const [documents, setDocuments] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [companyId, setCompanyId] = useState(null);

  if (!employeeId) return null;

  useEffect(() => {
    fetchDocuments();
  }, []);

  function fetchDocuments() {
    setLoading(true);

    axios
      .get(`/employees/${employeeId}/`)
      .then(({ data }) => {
        setCompanyId(data.company_id);

        if (admin) {
          setDocuments(data.documents);
        } else {
          setDocuments(data.documents.filter((d) => d.visible));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setDocuments([]);
        setError(err.response.data.ui_message);
      });
  }

  function createDocument(title, file, visible) {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('document', file);
    formData.append('visible', visible);
    formData.append('company_employee_id', employeeId);
    formData.append('company_id', companyId);

    setLoading(true);
    setError(null);

    axios
      .post(`/companies/${companyId}/upload-document/`, formData)
      .then(() => fetchDocuments())
      .then(() => setOpen(false))
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onDeleteClick(filename) {
    if (!filename) return;

    setLoading(true);
    setError(null);

    axios
      .delete(`/documents/${filename}/`)
      .then(() => fetchDocuments())
      .then(() => setOpen(false))
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Document Deleted'
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  if ((!loading && !admin && documents.length === 0) || (loading && !admin)) {
    return null;
  }

  return (
    <Wrapped
      title="Documents"
      button={
        admin ? (
          <ToggleButton
            closedText="Add Document"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
    >
      {isOpen ? (
        <AddForm
          loading={loading}
          error={error}
          onSubmit={createDocument}
          admin={admin}
        >
          <BasicButton
            onClick={() => setOpen(false)}
            text="Cancel"
            error={error}
            loading={loading}
          />
        </AddForm>
      ) : (
        <div>
          {documents.map(({ id, title, visible, filename }) => (
            <div key={id} className="segment employee-document">
              <h2>{title}</h2>
              {admin && !visible && <div className="tag">Admin Only</div>}
              <div className="actions">
                {admin && (
                  <button
                    disabled={loading}
                    onClick={() => onDeleteClick(filename)}
                  >
                    <FaRegTrashAlt />
                  </button>
                )}
                <a href={`/api/document/${filename}/`} download target="blank">
                  <FaDownload />
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </Wrapped>
  );
}

Documents.defaultProps = {
  admin: false,
  employeeId: ``,
  hideIfEmpty: false
};

Documents.propTypes = {
  admin: PropTypes.bool,
  employeeId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Documents);
