import React from 'react';
import { Wrapped } from 'components/Layouts';
import { Table } from 'components/Evaluation';

export default function EvaluationIndex() {
  return (
    <div>
      <Wrapped title="Evaluations">
        <Table />
      </Wrapped>
    </div>
  );
}
