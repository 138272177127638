import React from 'react';
import { CompareTable } from './';

export default function CompareLeadershipSuccess({ employees }) {
  return (
    <CompareTable
      headers={['Candidate', 'CLS']}
      rows={employees.map((emp, i) => (
        <tr key={i}>
          <td>{emp.name}</td>
          <td>{emp.value ? Math.round(emp.value) : emp.value}%</td>
        </tr>
      ))}
    />
  );
}
