import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'semantic-ui-react';

import { QuestionForm } from './';

import { ENGAGEMENT_VALUE } from '@util/data/surveyVariety';

export default function CustomQuestionForm({ versionId, position, onUpdate }) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function onCreate(question) {
    const req = {
      version_id: versionId,
      question_id: question.id,
      position
    };
    axios.post(`/versions/${versionId}/questions/`, req).then(() => onUpdate());
  }

  return (
    <div>
      <Button
        content="Add Custom Question"
        basic
        onClick={() => setOpen(true)}
        size="small"
      />
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Content>
          <QuestionForm
            isEngagement
            variety={ENGAGEMENT_VALUE}
            onSuccess={onCreate}
            onClose={() => setOpen(false)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={onClose} basic />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

CustomQuestionForm.defaultProps = {
  position: 0
};
