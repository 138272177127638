import React from 'react';

export default function Derailer({ name, triggered, icon, text }) {
  return (
    <div className="report-icon-list-item">
      <div className={`icon${triggered ? ' triggered' : ''}`}>
        {icon}
        <h3>{name}</h3>
      </div>
      <div className="text">
        <p>{text}</p>
      </div>
    </div>
  );
}

Derailer.defaultProps = {
  name: 'Derailer',
  text: 'Derailer Text',
  triggered: false,
  icon: null
};
