import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export default function ProceedModal(props) {
  return (
    <Modal open={props.open} size="tiny">
      <Modal.Header content={props.header} />
      {
        props.children &&
          <Modal.Content>
            {props.children}
          </Modal.Content>
      }
      <Modal.Actions>
        <Button
          content="No, I'll come back later"
          basic
          onClick={props.onNoClick}
        />
        <Button
          content="Yes! Let's Go!"
          positive
          onClick={props.onYesClick}
        />
      </Modal.Actions>
    </Modal>
  );
}

ProceedModal.defaultProps = {
  header: 'Are you ready?',
  open: false,
};
