import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Wrapped } from 'components/Layouts';
import { ErrorView } from 'components/shared/Errors';
import { ProfileForm, TagManagement } from 'components/Profiles';

export default function ProfileDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);

  const profileId = match.params.id;

  useEffect(() => {
    fetchProfile();
  }, []);

  function fetchProfile() {
    setLoading(true);

    axios
      .get(`/occupational-profiles/${profileId}/`)
      .then(({ data }) => {
        setLoading(false);
        setProfile(data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!profile) return null;

  return (
    <div>
      <Wrapped title={profile.original_name}>
        <ProfileForm
          id={profileId}
          anValue={profile.an}
          area={profile.area}
          aValue={profile.a}
          bValue={profile.b}
          ciss={profile.ciss}
          cValue={profile.c}
          cumulativeSd={profile.cumulative_sd}
          eValue={profile.e}
          exValue={profile.ex}
          fValue={profile.f}
          gValue={profile.g}
          hol={profile.hol}
          holland={profile.holland}
          hValue={profile.h}
          inValue={profile.in}
          iValue={profile.i}
          lValue={profile.l}
          mbti={profile.mbti}
          mValue={profile.m}
          name={profile.original_name}
          nValue={profile.n}
          oValue={profile.o}
          q1Value={profile.q1}
          q2Value={profile.q2}
          q3Value={profile.q3}
          q4Value={profile.q4}
          scValue={profile.sc}
          sourceYear={profile.source_year}
          tmValue={profile.tm}
          onSuccess={() => fetchProfile()}
        />
      </Wrapped>
      <TagManagement profileId={profileId} />
    </div>
  );
}
