export const CLEARSCREEN_VALUE = 0;
export const PERFORMANCE_REVIEW = 1;
export const ENGAGEMENT_VALUE = 2;
export const HOL_VALUE = 3;
export const VALUE_360 = 4;
export const COMPETENCY_ASSESSMENT_VALUE = 5;

const surveyVariety = [
  { text: 'Clear Screen', value: CLEARSCREEN_VALUE },
  { text: 'Engagement', value: ENGAGEMENT_VALUE },
  { text: 'Performance Review', value: PERFORMANCE_REVIEW },
  { text: 'Heart of Leadership', value: HOL_VALUE },
  { text: 'Competency Assessment', value: COMPETENCY_ASSESSMENT_VALUE },
  { text: '360', value: VALUE_360 }
];

export default surveyVariety;
