import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Loader } from 'semantic-ui-react';
import { Warning } from 'components/shared/Messages';

export default function ProfileDisplay({
  containerIds,
  profileId,
  onProfileChange
}) {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  if (!containerIds.length) return null;

  useEffect(() => {
    fetchProfiles();
  }, [containerIds]);

  function fetchProfiles() {
    setLoading(true);
    const req = {
      calculation_container_ids: [...containerIds]
    };

    axios
      .post(`/compare-profile-similarity/`, req)
      .then(({ data }) => {
        setProfiles(
          data.occupational_profiles
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((p) => ({
              key: p.id,
              text: p.name,
              value: p.id
            }))
        );
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="compare-profiles">
      <h4>
        Available Profiles To Compare{' '}
        <Loader
          inline
          active={loading}
          size="small"
          style={{ marginLeft: '5px' }}
        />
      </h4>
      {!loading && profiles.length === 0 && (
        <Warning text="These employees have no profiles in common." />
      )}
      {profiles.length > 0 && (
        <Form>
          <Form.Select
            placeholder="Select one..."
            options={profiles}
            value={profileId}
            onChange={(e, d) => onProfileChange(d.value)}
          />
        </Form>
      )}
    </div>
  );
}

ProfileDisplay.defaultProps = {
  containerIds: [],
  profileId: null
};
