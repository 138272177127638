import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import { FaCheckCircle } from 'react-icons/fa';

import { ActionButton } from 'components/shared/Buttons';

const pStyle = {
  fontWeight: 400,
  fontSize: '1.25em'
};

export default function CompanyBullhorn({
  companyId,
  isBullhornCompany,
  fetchData
}) {
  const [isBullhorn, setBullhorn] = useState(false);

  useEffect(() => {
    setBullhorn(isBullhornCompany);
  }, [isBullhornCompany]);

  function onChange() {
    setBullhorn(!isBullhorn);

    const req = {
      company_id: companyId
    };

    axios
      .post(`/set-bullhorn-partner/`, req)
      .then(() => {
        console.log('done');
      })
      .catch((err) => {
        setBullhorn(!isBullhorn);
      });
  }

  return (
    <div className="segment">
      <Form>
        <Form.Radio
          toggle
          label="This company is a Bullhorn Company"
          onChange={onChange}
          checked={isBullhorn}
        />
      </Form>
      {isBullhorn && (
        <div style={{ marginTop: '2em' }}>
          <ApiKey companyId={companyId} />
        </div>
      )}
    </div>
  );
}

CompanyBullhorn.defaultProps = {
  isBullhornCompany: false
};

function ApiKey({ companyId }) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    fetchStatus();
  }, []);

  function fetchStatus() {
    axios
      .post(`/bullhorn-api-key-status/`, { company_id: companyId })
      .then(({ data }) => {
        setStatus(data.status);
      })
      .catch((err) => {
        setStatus(null);
      });
  }

  if (!status) return <p>Loading...</p>;

  return (
    <div>
      {status === 1 && (
        <React.Fragment>
          {/* <p style={{ ...pStyle }}>no key</p> */}
          <ApiKeyInit companyId={companyId} onSuccess={fetchStatus} />
        </React.Fragment>
      )}
      {status === 2 && (
        <React.Fragment>
          <p style={{ ...pStyle }}>API Key needs to be authorized</p>
          <AuthorizeButton onSuccess={fetchStatus} companyId={companyId} />
        </React.Fragment>
      )}
      {status === 3 && (
        <React.Fragment>
          <p style={{ ...pStyle }}>
            <FaCheckCircle color="green" style={{ marginRight: '5px' }} /> API
            Key is good to go
          </p>
        </React.Fragment>
      )}
    </div>
  );
}

function AuthorizeButton({ companyId, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onClick() {
    setError(null);
    setLoading(true);

    axios
      .post(`/authorize-bullhorn-api-key/`, { company_id: companyId })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);

        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  return (
    <React.Fragment>
      {error && (
        <p style={{ color: 'red' }}>
          <b>{error}</b>
        </p>
      )}
      <ActionButton
        text="Authorize API Key"
        loading={loading}
        disabled={loading}
        onClick={onClick}
      />
    </React.Fragment>
  );
}

function ApiKeyInit({ companyId, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    key_data: '',
    client_name: '',
    client_phrase: '',
    data: ''
  });

  function onFormChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  function onSubmitClick() {
    setLoading(true);
    setError(null);

    const req = {
      company_id: companyId,
      ...formData
    };

    axios
      .post(`/set-bullhorn-api-key/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);

        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  const fields = [
    { name: 'client_name', label: 'API Username' },
    { name: 'client_phrase', label: 'API Password' },
    { name: 'key_data', label: 'Client ID' },
    { name: 'data', label: 'Client Secret' }
  ].map((m, i) => ({ ...m, key: i + 1 }));

  return (
    <div>
      <Form style={{ marginBottom: '1em' }}>
        {fields.map((f) => (
          <Form.Input
            key={f.key}
            label={f.label}
            value={formData[f.name]}
            onChange={onFormChange}
            name={f.name}
          />
        ))}
      </Form>
      {error && (
        <p style={{ color: 'red' }}>
          <b>{error}</b>
        </p>
      )}
      <ActionButton
        text="Submit"
        onClick={onSubmitClick}
        disabled={
          loading ||
          !formData.client_name ||
          !formData.client_phrase ||
          !formData.data ||
          !formData.key_data
        }
        loading={loading}
      />
    </div>
  );
}
