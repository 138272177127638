import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ProfileSearch from './ProfileSearch';
import ConfirmProfiles from './ConfirmProfiles';

import { SET_TOAST } from 'duck/toast';

const PROFILE_STEP = 'profiles';
const CONFIRM_STEP = 'confirm';

function AddForm({ panResultId, fetchData, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [activeStep, setActiveStep] = useState(PROFILE_STEP);

  function onCreate() {
    if (!panResultId) return;

    setLoading(true);

    const postData = {
      pan_result_id: panResultId,
      op_ids: profiles.map((p) => p.id)
    };

    axios
      .post(`/calculation-containers/`, postData)
      .then(() =>
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Calculation Container Created!'
          }
        })
      )
      .then(() => setLoading(false))
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  return (
    <div className="segment">
      {activeStep === PROFILE_STEP && (
        <ProfileSearch
          profiles={profiles}
          onSubmit={(pros) => {
            setActiveStep(CONFIRM_STEP);
            setProfiles(pros);
          }}
        />
      )}
      {activeStep === CONFIRM_STEP && (
        <ConfirmProfiles
          profiles={profiles}
          onBackClick={() => setActiveStep(PROFILE_STEP)}
          onSubmit={onCreate}
          loading={loading}
        />
      )}
    </div>
  );
}

AddForm.defaultProps = {
  panResultId: null
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AddForm);
