import React, { useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { BasicTable } from 'components/shared/Tables';
import { TableLinkButton } from 'components/shared/Buttons';

import { EngagementEmail } from './';

export default function EngagementList({ companyId }) {
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      Header: '',
      id: 'view',
      width: 40,
      sortable: false,
      accessor: (d) => <TableLinkButton url={`/engagement/${d.id}`} />
    },
    {
      Header: '',
      id: 'send',
      sortable: false,
      accessor: (d) =>
        d.survey_invite ? (
          <EngagementEmail
            lastReminderSent={d.last_reminder_sent}
            surveyName={d.engagement_survey_name}
            surveyInviteId={d.survey_invite.id}
            surveyContainerId={d.id}
            onUpdate={() => setRefresh(!refresh)}
          />
        ) : null,
      width: 40
    },
    {
      Header: 'Company',
      accessor: 'company_name',
      filterable: true,
      show: !companyId
    },
    { Header: 'Survey', accessor: 'engagement_survey_name', filterable: true },
    {
      Header: 'Created',
      accessor: 'created_at_formatted',
      width: 100,
      show: companyId ? true : false
    },
    {
      Header: <FaUsers title="Respondants" />,
      width: 80,
      id: 'completion',
      accessor: (d) =>
        `${d.completion_progress.complete}/${
          d.completion_progress.complete + d.completion_progress.incomplete
        }`
    }
  ];

  return (
    <div>
      <p>
        The following are Engagement Surveys that have been <b>approved</b> by
        BCK
      </p>
      <BasicTable
        columns={columns}
        refresh={refresh}
        entityName="Engagement Surveys"
        defaultPageSize={20}
        url={
          companyId
            ? `/companies/${companyId}/engagement-reports/`
            : `/engagement-reports/`
        }
      />
    </div>
  );
}

EngagementList.defaultProps = {
  companyId: null
};
