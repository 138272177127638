import React from 'react';

export default function ReportLayout({ children, id, wide, hol, competency }) {
  return (
    <div
      className={`report-layout${hol ? ' hol' : ''}${
        competency ? ' competency' : ''
      }`}
      id={id}
    >
      <div className={`wrapper${wide ? ' wide' : ''}`}>{children}</div>
    </div>
  );
}

ReportLayout.defaultProps = {
  competency: false,
  hol: false,
  id: '',
  wide: false
};
