import React, { Component } from 'react';
import { Wrapped } from 'components/Layouts';

export default class Dashboard extends Component {
  render() {
    return (
      <div>
        <Wrapped title="Dashboard Coming Soon..."></Wrapped>
      </div>
    );
  }
}
