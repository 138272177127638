import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import axios from 'axios';
import { connect } from 'react-redux';

import { ActionButton } from 'components/shared/Buttons';
import { CompanySelectList } from 'components/CompanyManager';

import { SET_TOAST } from 'duck/toast';

function EngagementRequest({ dispatch, onSuccess }) {
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = {
      company_id: companyId
    };

    axios
      .post(`/request-engagement-survey/`, req)
      .then(() => {
        setLoading(false);

        dispatch({
          type: SET_TOAST,
          payload: {
            message:
              'Request Sent! BCK will let you know when your Engagement Survey is ready.',
            positive: true,
            duration: 5000
          }
        });

        setTimeout(() => {
          onSuccess();
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);

        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Sorry. This request could not be made at this time.',
            negative: true
          }
        });
      });
  }

  return (
    <div>
      <p style={{ fontSize: '1.15em' }}>
        <Icon name="lightbulb outline" /> Make a request for an Engagement
        Survey below.
      </p>
      <CompanySelectList
        multiple={false}
        label="Company"
        value={companyId}
        onChange={(e) => setCompanyId(e)}
      />
      <ActionButton
        loading={loading}
        onClick={onSubmit}
        disabled={!companyId}
        text="Send Request"
        style={{ marginTop: '1em' }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(EngagementRequest);
