import React from 'react';
import Item from './Item';

import { ActionButton, BasicButton } from 'components/shared/Buttons';

export default function ConfirmProfiles({
  profiles,
  onBackClick,
  onSubmit,
  loading
}) {
  return (
    <div>
      <h2>Confirm the following profiles</h2>
      <div className="profile-items">
        {profiles
          .sort((a, b) => (a.original_name > b.original_name ? 1 : -1))
          .map((profile) => (
            <Item
              key={profile.id}
              name={profile.original_name}
              profile={profile}
              tags={profile.tags}
            />
          ))}
      </div>
      <div className="profile-confirm-actions">
        <BasicButton text="Back" onClick={onBackClick} disabled={loading} />
        <ActionButton text="Confirm" onClick={onSubmit} loading={loading} />
      </div>
    </div>
  );
}

ConfirmProfiles.defaultProps = {
  profiles: [],
  loading: false
};
