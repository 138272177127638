import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';
import CurrentTags from './CurrentTags';
import TagForm from './TagForm';
import TagSearch from './TagSearch';

import './index.scss';

export default function TagManagement({ profileId }) {
  const [isOpen, setOpen] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);

  useEffect(() => {
    fetchTags();
  }, []);

  function fetchTags() {
    setOpen(false);
    axios
      .get(`/occupational-profiles/${profileId}/tags/`)
      .then(({ data }) => {
        setCurrentTags(data);
      })
      .catch((err) => {});
  }

  if (!profileId) return null;

  return (
    <Wrapped
      title="Tags"
      button={
        <ToggleButton
          closedText="Add Tag"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <TagForm profileId={profileId} onSuccess={() => fetchTags()} />
      ) : (
        <div className="segment">
          <TagSearch
            fetchData={fetchTags}
            profileId={profileId}
            bannedIds={currentTags.map((t) => t.id)}
          />
          <CurrentTags
            tags={currentTags}
            profileId={profileId}
            fetchData={fetchTags}
          />
        </div>
      )}
    </Wrapped>
  );
}

TagManagement.defaultProps = {
  profileId: null
};
