import React, { useState } from 'react';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { FaComment } from 'react-icons/fa';

import ProgressLine from './ProgressLine';
import StarDisplay from './StarDisplay';

const ListItem = ({
  comments,
  employeeCount,
  employeeResponseCount,
  rawResponses,
  responseTotal,
  responseTotalMax,
  text
}) => {
  const [isOpen, setOpen] = useState(false);

  const percentage = (responseTotal / responseTotalMax) * 100;

  function onClose() {
    setOpen(false);
  }

  const breakdown = [
    { text: 'Strongly Agree', value: 5 },
    { text: 'Agree', value: 4 },
    { text: 'Neutral', value: 3 },
    { text: 'Disagree', value: 2 },
    { text: 'Strongly Disagree', value: 1 }
  ].map((v) => ({
    key: v.value,
    star_count: v.value,
    text: v.text,
    count: rawResponses.filter((f) => f === v.value).length,
    percentage:
      (rawResponses.filter((f) => f === v.value).length / rawResponses.length) *
      100
  }));

  return (
    <div className="list-item">
      {comments.length === 0 && <p>{text}</p>}
      {comments.length > 0 && (
        <button className="title" onClick={() => setOpen(true)}>
          <FaComment title="Comments Available" /> {text}
        </button>
      )}
      <Popup
        content={
          <div>
            <ResponseBreakdown items={breakdown} />
          </div>
        }
        trigger={
          <div>
            <StarDisplay value={percentage} />
          </div>
        }
      />
      {/* {percentage > 0 && <ProgressLine total={percentage} />} */}
      <Modal
        open={isOpen}
        onClose={onClose}
        size="small"
        className="engagement-question-modal"
      >
        <Modal.Header content={text} />
        <Modal.Content>
          <div className="comments">
            {comments.map((c, i) => (
              <p key={i}>{c}</p>
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic onClick={onClose} />
        </Modal.Actions>
      </Modal>
      {/* <br />
      {responseTotal}/{responseTotalMax} response total
      <br />
      {employeeResponseCount}/{employeeCount} employees answered */}
    </div>
  );
};

ListItem.defaultProps = {
  comments: [],
  employeeCount: 0,
  employeeResponseCount: 0,
  rawResponses: [],
  responseTotal: 0,
  responseTotalMax: 0,
  text: ''
};

export default ListItem;

function ResponseBreakdown({ items }) {
  return (
    <div className="response-breakdown">
      <ul>
        {items.map(({ key, star_count, text, count, percentage }) => (
          <li key={key}>
            <span className="answer-title">{text}</span>
            <span className="viz">
              <ProgressLine total={percentage} />
            </span>
            {/* <span className="stars">
              {[...Array(star_count)].map((e, i) => (
                <FaStar key={i} />
              ))}
            </span> */}
            <span className="count">{Math.round(percentage * 100) / 100}%</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

ResponseBreakdown.defaultProps = {
  stars: []
};
