import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBinoculars, FaCogs, FaTheaterMasks } from 'react-icons/fa';

import { ReportLayout } from 'components/Layouts';
import {
  Derailer,
  Footer,
  Intro,
  PersonaBoxes,
  SectionTitle
} from './components';
import { CompetencyOutcomes } from 'components/Reports/components';
import derailerDataFormatter from './helpers/derailerDataFormatter';

export default function CompetencyAssessment({ calcContainerId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
    document.body.className += 'competency-body';
  }, []);

  function fetchData() {
    axios
      .get(`/comp-output/${calcContainerId}/`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {});
  }

  if (!data) return null;

  const candidateName = data.employee ? data.employee.full_name : 'Candidate';
  const activePersona = data.comp_assess.lcl_likeness;
  const derailerData = derailerDataFormatter({
    calculation_container: {
      derailers: data.comp_assess.derailers
    }
  });

  return (
    <ReportLayout competency>
      <div id="competencyReport">
        <Intro
          companyName={data.company.name}
          fullName={data.employee.full_name}
          competency
        />
        {data.comp_assess && data.comp_assess.pan_result && (
          <CompetencyOutcomes
            output={{
              ...data.comp_assess,
              business_industry_acumen:
                data.comp_assess.pan_result.business_industry_acumen,
              collaboration: data.comp_assess.pan_result.collaboration,
              communicates_effectively:
                data.comp_assess.pan_result.communicates_effectively,
              customer_focused: data.comp_assess.pan_result.customer_focused,
              drives_results_consistently:
                data.comp_assess.pan_result.drives_results_consistently,
              financial_analytical_insight:
                data.comp_assess.pan_result.financial_analytical_insight,
              influences_others: data.comp_assess.pan_result.influences_others,
              manages_conflict_resolution:
                data.comp_assess.pan_result.manages_conflict_resolution,
              navigates_complexity:
                data.comp_assess.pan_result.navigates_complexity,
              strategic_mindset: data.comp_assess.pan_result.strategic_mindset
            }}
          />
        )}
        <div className="page"></div>
        <SectionTitle
          title="Supplementary Info"
          icon={<FaCogs />}
          text={
            <React.Fragment>
              <p>
                Supplemental information in the form of Key Watchouts (if any)
                and Work Motives follow.
              </p>
            </React.Fragment>
          }
        />
        <SectionTitle
          title="Persona"
          icon={<FaTheaterMasks />}
          text={
            <React.Fragment>
              <p>
                Plotting <b>People Orientation</b> and <b>Process Discipline</b>{' '}
                reveals this individual's 'Leader Persona'. There are eight (8)
                possible Leader Personas, based upon the degrees of the two
                component factors.
              </p>
            </React.Fragment>
          }
        />
        <div style={{ marginBottom: '3em' }}>
          <PersonaBoxes activePersona={activePersona} />
        </div>
        <SectionTitle
          title="Watchouts"
          icon={<FaBinoculars />}
          text={
            <React.Fragment>
              <p>
                <b>Watchouts</b> are sets of characteristics that have been
                shown to hinder the positional level, career longevity, work
                performance, and co-worker regard of leaders in the workplace.
              </p>
              <p>
                {candidateName}'s potential Risk levels associated with these
                watchouts are presented below.{' '}
                <b>
                  Icons presented in <span className="green">green</span>{' '}
                  indicate low risk for associated Watchout. Icons presented in{' '}
                  <span className="red">red</span> indicate elevated risk.
                </b>
              </p>
            </React.Fragment>
          }
        />
        {derailerData.map(({ id, name, icon, hol, triggered }) => (
          <Derailer
            key={id}
            name={name}
            icon={icon}
            text={hol}
            triggered={triggered}
          />
        ))}
        <Footer />
      </div>
    </ReportLayout>
  );
}

const fakeData = {
  comp_assess: {
    derailers: [
      {
        id: 25169,
        name: 'dramatic',
        steq: 6,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25170,
        name: 'callous',
        steq: 6,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25171,
        name: 'detached',
        steq: 6.5,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25172,
        name: 'deflated',
        steq: 6.4,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25173,
        name: 'guarded',
        steq: 6.5,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25174,
        name: 'hesitant',
        steq: 7.5,
        triggered: true,
        calculation_container_id: 2293
      },
      {
        id: 25175,
        name: 'hostile',
        steq: 5.4,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25176,
        name: 'respondent',
        steq: 6.7,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25177,
        name: 'eccentric',
        steq: 4.6,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25178,
        name: 'arrogant',
        steq: 3,
        triggered: false,
        calculation_container_id: 2293
      },
      {
        id: 25179,
        name: 'obsessive',
        steq: 6,
        triggered: false,
        calculation_container_id: 2293
      }
    ]
  },
  company: {
    name: 'LPSU'
  },
  employee: {
    full_name: 'John Doe'
  },
  pan_result: {}
};
