import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Modal } from 'semantic-ui-react';

import { ToggleButton } from 'components/shared/Buttons';

export default function CompanyPositionSelect({
  companyId,
  error,
  label,
  value,
  onChange,
  onBlur
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [createError, setCreateError] = useState(null);

  useEffect(() => {
    fetchPositions();
  }, [companyId]);

  function fetchPositions() {
    if (!companyId) return;

    setLoading(true);

    axios
      .get(`/companies/${companyId}/positions/`)
      .then(({ data }) => {
        setLoading(false);
        setOptions(
          data.company_positions
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((p) => ({
              key: p.id,
              text: p.name,
              value: p.id
            }))
        );
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  function onClose() {
    setOpen(false);
    setLoading(false);
    setCreateError(null);
  }

  function onPositionCreate() {
    setCreateError(null);
    setLoading(true);

    const req = {
      company_id: companyId,
      name: nameValue
    };

    axios
      .post(`/companies/${companyId}/positions/`, req)
      .then(({ data }) => {
        onChange(data.id);
        fetchPositions();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        setCreateError(null);
      });
  }

  return (
    <Form.Select
      label={label}
      search
      error={error}
      value={value}
      onChange={(e, d) => onChange(d.value)}
      onBlur={(e, d) => onBlur(d.value)}
      options={options}
      loading={loading}
      placeholder="Select one..."
    />
  );

  // return (
  //   <React.Fragment>
  //     <Form.Field>
  //       <div
  //         style={{ display: 'flex', alignItems: 'center', marginBottom: '7px' }}
  //       >
  //         <label style={{ marginBottom: '0' }}>{label} </label>
  //         <ToggleButton
  //           style={{ marginBottom: '0', marginLeft: '4px' }}
  //           onClick={() => setOpen(!isOpen)}
  //           open={isOpen}
  //         />
  //       </div>
  //       <Form.Select
  //         search
  //         error={error}
  //         value={value}
  //         onChange={(e, d) => onChange(d.value)}
  //         onBlur={(e, d) => onBlur(d.value)}
  //         options={options}
  //         loading={loading}
  //         placeholder="Select one..."
  //       />
  //     </Form.Field>
  //     <Modal open={isOpen} onClose={onClose} size="tiny">
  //       <Modal.Header content="Create new position" />
  //       <Modal.Content>
  //         <Form>
  //           <Form.Input
  //             label="Position"
  //             value={nameValue}
  //             onChange={(e) => setNameValue(e.target.value)}
  //           />
  //         </Form>
  //         {createError && (
  //           <p style={{ color: 'red', fontWeight: '600', marginTop: '10px' }}>
  //             {createError}
  //           </p>
  //         )}
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <Button content="Close" basic size="small" onClick={onClose} />
  //         <Button
  //           content="Save"
  //           primary
  //           loading={loading}
  //           size="small"
  //           onClick={onPositionCreate}
  //           disabled={!nameValue}
  //         />
  //       </Modal.Actions>
  //     </Modal>
  //   </React.Fragment>
  // );
}

CompanyPositionSelect.defaultProps = {
  companyId: null,
  error: false,
  label: 'Position',
  value: null
};
