import React from 'react';

import logo from 'assets/images/logo.png';

export default function AuthWrapper(props) {
  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="wrapper">{props.children}</div>
      </div>
      <div className="auth-right">
        <img src={logo} alt="BCK" />
      </div>
    </div>
  );
}
