import React from 'react';

import { Slider } from 'components/Reports/components';

export default function Results({
  exValue,
  anValue,
  tmValue,
  inValue,
  scValue,
  aValue,
  bValue,
  cValue,
  eValue,
  fValue,
  gValue,
  hValue,
  iValue,
  lValue,
  mValue,
  nValue,
  oValue,
  q1Value,
  q2Value,
  q3Value,
  q4Value
}) {
  const data = [
    {
      left: 'Reserved',
      right: 'Warm',
      value: aValue,
      text: 'Warmth',
      key: 'A'
    },
    {
      left: 'Concrete',
      right: 'Abstract',
      value: bValue,
      text: 'Reasoning',
      key: 'B'
    },
    {
      left: 'Reactive',
      right: 'Emotionally Stable',
      value: cValue,
      text: 'Emotional Stability',
      key: 'C'
    },
    {
      left: 'Deferential',
      right: 'Dominant',
      value: eValue,
      text: 'Dominance',
      key: 'E'
    },
    {
      left: 'Serious',
      right: 'Lively',
      value: fValue,
      text: 'Liveliness',
      key: 'F'
    },
    {
      left: 'Expedient',
      right: 'Rule-Conscious',
      value: gValue,
      text: 'Rule-Consciousness',
      key: 'G'
    },
    {
      left: 'Shy',
      right: 'Socially Bold',
      value: hValue,
      text: 'Social Boldness',
      key: 'H'
    },
    {
      left: 'Utilitarian',
      right: 'Sensitive',
      value: iValue,
      text: 'Sensitivity',
      key: 'I'
    },
    {
      left: 'Trusting',
      right: 'Vigilant',
      value: lValue,
      text: 'Vigilance',
      key: 'L'
    },
    {
      left: 'Grounded',
      right: 'Abstracted',
      value: mValue,
      text: 'Abstractedness',
      key: 'M'
    },
    {
      left: 'Forthright',
      right: 'Private',
      value: nValue,
      text: 'Privateness',
      key: 'N'
    },
    {
      left: 'Self-Assured',
      right: 'Apprehensive',
      value: oValue,
      text: 'Apprehension',
      key: '0'
    },
    {
      left: 'Traditional',
      right: 'Open to Change',
      value: q1Value,
      text: 'Openess to Change',
      key: 'Q1'
    },
    {
      left: 'Group-Oriented',
      right: 'Self-Reliant',
      value: q2Value,
      text: 'Self-Reliance',
      key: 'Q2'
    },
    {
      left: 'Tolerates Disorder',
      right: 'Perfectionistic',
      value: q3Value,
      text: 'Perfectionism',
      key: 'Q3'
    },
    {
      left: 'Relaxed',
      right: 'Tense',
      value: q4Value,
      text: 'Tension',
      key: 'Q4'
    }
  ];

  return (
    <div className="profile-results">
      {data.map((slider) => (
        <div key={slider.key} className="profile-result">
          <div className="result-line-item">
            <span className="name">{slider.text}</span>
            <span className="key">{slider.key}</span>
            <span className="value">{slider.value}</span>
          </div>
          <Slider
            leftPoleText={slider.left}
            rightPoleText={slider.right}
            value={slider.value}
          />
        </div>
      ))}
      {/* <div className="results-list">
        <p>EX: {exValue}</p>
        <p>AN: {anValue}</p>
        <p>TM: {tmValue}</p>
        <p>IN: {inValue}</p>
        <p>SC: {scValue}</p>
        <p>a: {aValue}</p>
        <p>b: {bValue}</p>
        <p>c: {cValue}</p>
        <p>e: {eValue}</p>
        <p>f: {fValue}</p>
        <p>g: {gValue}</p>
        <p>h: {hValue}</p>
        <p>i: {iValue}</p>
        <p>l: {lValue}</p>
        <p>m: {mValue}</p>
        <p>n: {nValue}</p>
        <p>o: {oValue}</p>
        <p>q1: {q1Value}</p>
        <p>q2: {q2Value}</p>
        <p>q3: {q3Value}</p>
        <p>q4: {q4Value}</p>
      </div> */}
    </div>
  );
}

Results.defaultProps = {
  exValue: '',
  anValue: '',
  tmValue: '',
  inValue: '',
  scValue: '',
  aValue: '',
  bValue: '',
  cValue: '',
  eValue: '',
  fValue: '',
  gValue: '',
  hValue: '',
  iValue: '',
  lValue: '',
  mValue: '',
  nValue: '',
  oValue: '',
  q1Value: '',
  q2Value: '',
  q3Value: '',
  q4Value: ''
};
