const industries = [
  { value: 0, text: 'Banking & Financial Services' },
  { value: 1, text: 'Engineering & Construction' },
  { value: 2, text: 'Education' },
  { value: 3, text: 'Government & Public Safety' },
  { value: 4, text: 'Forestry Products' },
  { value: 5, text: 'Consumer Packaged Goods' },
  { value: 6, text: 'Keller Enterprises' },
  { value: 7, text: 'Manufacturing & Fabrication' },
  { value: 8, text: 'Hospitality & Property Mgt' },
  { value: 9, text: 'Retail & Consumer Services' },
  { value: 10, text: 'Science & Technology' },
  { value: 11, text: 'Sales & Marketing' },
  { value: 12, text: 'Social Services' },
  { value: 13, text: 'Trades' },
  { value: 14, text: 'Logistics & Distribution' },
  { value: 15, text: 'Property Mgt'}
];

export default industries;
