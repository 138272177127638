import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Wrapped } from 'components/Layouts';

import { SET_TOAST } from 'duck/toast';

import { SurveyForm } from 'components/Survey';
import { Assigner } from 'components/Questions';

import { List } from 'components/Survey/Versions';

import { ErrorView } from 'components/shared/Errors';

class SurveyDetail extends Component {
  state = {
    allowMultipleTargets: false,
    allowMultipleEvaluators: false,
    createdAt: new Date(),
    error: null,
    instructionText: '',
    loading: true,
    name: '',
    variety: null,
    varietyFormatted: ''
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchData();
  };

  fetchData = () => {
    axios
      .get(`/surveys/${this.props.match.params.id}/`)
      .then(({ data }) => {
        this.setState({
          allowMultipleTargets: data.allow_multiple_targets,
          allowMultipleEvaluators: data.allow_multiple_evaluators,
          createdAt: new Date(data.created_at).toLocaleString(),
          instructionText: data.instruction_text,
          loading: false,
          name: data.name,
          variety: data.variety,
          varietyFormatted: data.variety_formatted
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message
            ? err.response.data.ui_message
            : 'Could not find the survey you were looking for',
          loading: false
        });
      });
  };

  onUpdate = (formData) => {
    const surveyId = this.props.match.params.id;
    this.setState({ loading: true });

    axios
      .put(`/surveys/${surveyId}/`, {
        name: formData.name,
        instructionText: formData.instruction_text,
        variety: formData.variety
      })
      .then(({ data }) => {
        this.setState({ loading: false, name: data.name });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Survey Updated!'
          }
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  };

  render() {
    const surveyId = this.props.match.params.id;
    const { error } = this.state;

    if (error) {
      return <ErrorView text={error} />;
    }

    return (
      <div>
        <Wrapped
          title={`${this.state.name} - created on ${this.state.createdAt}`}
        >
          <div className="segment">
            <h2>
              <span style={{ textTransform: 'capitalize' }}>
                {this.state.varietyFormatted}
              </span>{' '}
              Survey
            </h2>
            <SurveyForm
              allowMultipleEvaluators={this.state.allowMultipleEvaluators}
              allowMultipleTargets={this.state.allowMultipleTargets}
              instructionText={this.state.instructionText}
              loading={this.state.loading}
              name={this.state.name}
              id={surveyId}
              loading={this.state.loading}
              onSuccess={this.fetchData}
              variety={this.state.variety}
            />
          </div>
        </Wrapped>
        <List surveyId={surveyId} surveyVariety={this.state.variety} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SurveyDetail);
