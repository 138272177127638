import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { Button, Form } from 'semantic-ui-react';

import { EngagementContext } from '../../';

import RangeSlider from './RangeSlider';

export default function Filters({
  completionPercentage,
  employeeCount,
  filteredCompletionPercentage,
  minsAndMaxs,
  totalEmployeeCount,
  totalResponsePercentage,
  values,
  onChange,
  onResetClick
}) {
  const reportData = React.useContext(EngagementContext);

  const filtersApplied =
    values.ageMin ||
    values.ageMax ||
    values.department.length ||
    values.position.length ||
    values.race.length ||
    values.ethnicity.length ||
    values.gender.length ||
    values.salaryMin ||
    values.salaryMax ||
    values.tenureMin ||
    values.tenureMax
      ? true
      : false;

  const departmentOptions = reportData.departments.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );
  const genderOptions = reportData.genders.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );
  const ethnicityOptions = reportData.ethnicities.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );
  const raceOptions = reportData.races.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );
  const positionOptions = reportData.positions.sort((a, b) =>
    a.text > b.text ? 1 : -1
  );

  function onInputChange(name, value) {
    let newValues = { ...values };
    if (values[name] === undefined) return;

    return onChange({ ...newValues, [name]: value });
  }

  function onHourlyChange() {
    return onChange({
      ...values,
      payType: 2,
      salaryMin: '',
      salaryMax: ''
    });
  }

  return (
    <div className="filters">
      <Form size="small" style={{ marginBottom: '15px' }}>
        <Form.Select
          label="Department"
          placeholder="Select some..."
          options={departmentOptions}
          name="department"
          multiple
          search
          onChange={(e, d) => onInputChange('department', d.value)}
          value={values.department}
        />
        <Form.Select
          label="Position"
          placeholder="Select some..."
          options={positionOptions}
          name="position"
          multiple
          search
          onChange={(e, d) => onInputChange('position', d.value)}
          value={values.position}
        />
        <Form.Select
          label="Ethnicity"
          placeholder="Select some..."
          options={ethnicityOptions}
          name="ethnicity"
          multiple
          search
          onChange={(e, d) => onInputChange('ethnicity', d.value)}
          value={values.ethnicity}
        />
        <Form.Select
          label="Race"
          placeholder="Select some..."
          options={raceOptions}
          name="race"
          multiple
          search
          onChange={(e, d) => onInputChange('race', d.value)}
          value={values.race}
        />
        <Form.Select
          label="Gender"
          placeholder="Select some..."
          options={genderOptions}
          name="gender"
          multiple
          search
          onChange={(e, d) => onInputChange('gender', d.value)}
          value={values.gender}
        />
        <Form.Field>
          <label>Tenure</label>
          <Form.Group>
            <Form.Input
              placeholder="Min Tenure"
              min="0"
              type="number"
              onChange={(e) => onInputChange('tenureMin', e.target.value)}
              value={values.tenureMin}
            />
            <Form.Input
              placeholder="Max Tenure"
              min="0"
              type="number"
              onChange={(e) => onInputChange('tenureMax', e.target.value)}
              value={values.tenureMax}
            />
          </Form.Group>
        </Form.Field>
        <Form.Field>
          <label>Age</label>
          <Form.Group>
            <Form.Input
              placeholder="Min Age"
              min="0"
              type="number"
              onChange={(e) => onInputChange('ageMin', e.target.value)}
              value={values.ageMin}
            />
            <Form.Input
              placeholder="Max Age"
              min="0"
              type="number"
              onChange={(e) => onInputChange('ageMax', e.target.value)}
              value={values.ageMax}
            />
          </Form.Group>
        </Form.Field>
        {/* <Form.Field>
          <label>Pay Type</label>
          <Form.Group>
            <Form.Radio
              label="Salary"
              checked={values.payType === 1}
              onChange={() => onInputChange('payType', 1)}
            />
            <Form.Radio
              label="Hourly"
              checked={values.payType === 2}
              onChange={onHourlyChange}
            />
          </Form.Group>
        </Form.Field> */}
        {values.payType === 1 && (
          <Form.Field>
            <label>Salary</label>
            <Form.Group>
              <Form.Input
                placeholder="Min Salary"
                min="0"
                type="number"
                onChange={(e) => onInputChange('salaryMin', e.target.value)}
                value={values.salaryMin}
              />
              <Form.Input
                placeholder="Max Salary"
                min="0"
                type="number"
                onChange={(e) => onInputChange('salaryMax', e.target.value)}
                value={values.salaryMax}
              />
            </Form.Group>
          </Form.Field>
        )}
      </Form>
      {/* <RangeSlider
        label="Age (years)"
        min={minsAndMaxs.ageMin}
        max={minsAndMaxs.ageMax}
        step={1}
        onMinChange={(val) => onInputChange('ageMin', val)}
        onMaxChange={(val) => onInputChange('ageMax', val)}
      />
      <RangeSlider
        label="Tenure (years)"
        min={minsAndMaxs.tenureMin}
        max={minsAndMaxs.tenureMax}
        step={1}
        onMinChange={(val) => onInputChange('tenureMin', val)}
        onMaxChange={(val) => onInputChange('tenureMax', val)}
      />
      <RangeSlider
        label="Salary ($)"
        min={minsAndMaxs.salaryMin}
        max={minsAndMaxs.salaryMax}
        step={1000}
        onMinChange={(val) => onInputChange('salaryMin', val)}
        onMaxChange={(val) => onInputChange('salaryMax', val)}
      /> */}
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}
      >
        <Donut
          value={
            filtersApplied ? filteredCompletionPercentage : completionPercentage
          }
          title={`Finished`}
          // title={`${employeeCount}/${totalEmployeeCount} Employee${
          //   employeeCount === 1 ? '' : 's'
          // }${filtersApplied ? ' matching these filters' : ''}`}
        />
        <Donut
          value={(employeeCount / totalEmployeeCount) * 100}
          title={
            !filtersApplied
              ? `${totalEmployeeCount} Employees`
              : `${employeeCount}/${totalEmployeeCount} of Employees Filtered`
          }
          // title={`${employeeCount}/${totalEmployeeCount} Employee${
          //   employeeCount === 1 ? '' : 's'
          // }${filtersApplied ? ' matching these filters' : ''}`}
        />
      </div>
      {filtersApplied && (
        <Button
          onClick={onResetClick}
          content="Clear Filters"
          basic
          size="mini"
        />
      )}
    </div>
  );
}

Filters.defaultProps = {
  employeeCount: 0,
  filteredCompletionPercentage: 0,
  totalEmployeeCount: 0,
  totalResponsePercentage: 0,
  values: {
    ageMax: '',
    ageMin: '',
    department: [],
    ethnicity: [],
    gender: [],
    race: [],
    salaryMax: '',
    salaryMin: '',
    tenureMax: '',
    tenureMin: ''
  }
};

function Donut({ value, title }) {
  const data = [
    { x: 1, y: value },
    { x: 2, y: 100 - value }
  ];

  return (
    <div className="donut">
      <svg viewBox="0 0 200 160" width="125px" height="80px">
        <VictoryPie
          standalone={false}
          width={200}
          height={200}
          data={data}
          innerRadius={60}
          // animate={{
          //   duration: 2
          // }}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                const color = datum.y > 68 ? '#37bcc8' : '#ff6666';
                return datum.x === 1 ? color : 'transparent';
              }
            }
          }}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          x={100}
          y={100}
          text={`${Math.round(value * 100) / 100}%`}
          style={{ fontSize: 20, fontFamily: 'Open Sans', fontWeight: 700 }}
        />
      </svg>
      <p>{title}</p>
    </div>
  );
}

Donut.defaultProps = {
  value: 0,
  title: 'Donut'
};
