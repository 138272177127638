import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

export default function Reports({ employeeId, reports, reportsEnabled }) {
  const [opValue, setOpValue] = useState(null);
  const [calcId, setCalcId] = useState(null);

  useEffect(() => {
    if (!reports.length) return;

    setOpValue(reports[0].id);
    setCalcId(reports[0].calculation_container_id);
  }, [reports]);

  function onChange(e, d) {
    const report = reports.find((r) => r.id === d.value);
    if (!report) return;

    setOpValue(report.id);
    setCalcId(report.calculation_container_id);
  }

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select an OP..."
          options={reports.map((r) => ({
            key: r.id,
            text: r.profile_name,
            value: r.id,
            calcId: r.calculation_container_id
          }))}
          label="Occupational Profile"
          value={opValue}
          onChange={onChange}
        />
      </Form>
      {opValue && (
        <div className="report-link-buttons">
          {reportsEnabled.map((report) => (
            <Link
              key={report}
              to={`/report/${calcId}/${opValue}/${report}`}
              className="report-link-button"
            >
              View {report} Report
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

Reports.defaultProps = {
  employeeId: null,
  calcId: null,
  reports: [],
  reportsEnabled: []
};
