import React from 'react';
import logo from 'assets/images/logo.png';
import selectMdLogo from 'assets/images/selectmd-logo.png';

export default function Footer({ medical }) {
  return (
    <div className="report-footer">
      <img src={medical ? selectMdLogo : logo} alt="CLEAR Screen" />
      <p>
        For more information, please contact{' '}
        {medical ? 'SelectMD' : 'CLEARScreen'} at +14045882420
      </p>
      <small>
        <b>{medical ? 'SelectMD' : 'CLEAR Screen'}</b> &copy; 2011-
        {new Date().getFullYear()}, Cowart Consulting Group, LLC, Roswell,
        Georgia, USA. Unauthorized use and/or reproduction without approval of
        copyright holders is prohibited. This report contains CONFIDENTIAL
        assessment results intended for development and promotion purposes by
        the designated organization. Development and promotion decisions should
        be based upon more than test results alone, such as work history,
        educational background, performance, and motivation.
      </small>
    </div>
  );
}

Footer.defaultProps = {
  medical: false
};
