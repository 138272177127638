import React from 'react';
import { FaSadTear } from 'react-icons/fa';
import './index.scss';

export default function EmptyList(props) {
  return (
    <div className="empty-list">
      <FaSadTear size={60} />
      <div>
        <h1>{props.header}</h1>
        <p>{props.text}</p>
      </div>
    </div>
  );
}
