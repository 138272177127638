import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti/dist/react-confetti';

const REGULAR_CONFETTI = ['#60AECA', '#C7F0FF', '#7ADCFF', '#637880', '62B0CC'];
const HOL_CONFETTI = ['#F56600', '#FF9C8D', '#522D80', '#B1A8B9'];

export default function SubmitSuccess(props) {
  const { width, height } = useWindowSize();

  if (!props.scores) {
    return (
      <div className="candidate-survey-success">
        <h1>Nice Work, {props.name}!</h1>
        {props.submittedAt ? (
          <p>
            Your{' '}
            {[0, 3].includes(props.variety)
              ? 'test'
              : props.variety === 5
              ? 'assessment'
              : 'review'}{' '}
            was successfully submitted at{' '}
            {new Date(props.submittedAt).toLocaleTimeString()} on{' '}
            {new Date(props.submittedAt).toLocaleDateString()}.
            {props.variety === 5 && (
              <React.Fragment>
                {' '}
                Results will be emailed to you within 24 hours.{' '}
              </React.Fragment>
            )}
            You may now close this window.
          </p>
        ) : (
          <p>
            Your test was successfully submitted. You may now close this window.
          </p>
        )}
        <Confetti
          width={width}
          height={height}
          colors={props.variety === 3 ? HOL_CONFETTI : REGULAR_CONFETTI}
        />
      </div>
    );
  }

  let scores = [];

  Object.keys(props.scores).forEach((k) => {
    if (!props.scores[k]) return;
    scores.push({ key: k.toUpperCase(), value: props.scores[k] });
  });

  return (
    <div className="candidate-survey-success">
      <h1>Nice Work, {props.name}!</h1>
      {props.submittedAt ? (
        <p>
          Your {props.variety === 0 ? 'test' : 'review'} was successfully
          submitted at {new Date(props.submittedAt).toLocaleTimeString()} on{' '}
          {new Date(props.submittedAt).toLocaleDateString()}. You may now close
          this window.
        </p>
      ) : (
        <p>
          Your test was successfully submitted. You may now close this window.
        </p>
      )}
      {scores && process.env.ENV === 'qa' && (
        <table>
          <tbody>
            {scores.map((s, i) => (
              <tr key={i}>
                <td>{s.key}</td>
                <td>{s.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Confetti
        width={width}
        height={height}
        colors={props.variety === 3 ? HOL_CONFETTI : REGULAR_CONFETTI}
      />
    </div>
  );
}
