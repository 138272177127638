import React from 'react';
import Slide from 'react-reveal/Slide';
import { connect } from 'react-redux';

import { SET_TOAST } from '../../../duck/toast';

import './index.scss';

class Toast extends React.Component {
  state = {};

  componentDidUpdate = (prevProps) => {
    if (prevProps.duration !== this.props.duration) {
      this.setTimer(this.props.duration);
    }
  };

  setTimer = (duration) => {
    setTimeout(() => {
      this.setVisible(false);
    }, duration);
  };

  setVisible = (visible) => {
    this.props.dispatch({ type: SET_TOAST, payload: null });
  };

  render() {
    const { props } = this;
    const { visible } = props;

    return (
      <div className={`toast-wrapper ${visible ? 'visible' : ''}`}>
        <Slide right when={visible}>
          <div className={`toast ${visible ? 'visible' : ''} ${props.color}`}>
            <p>
              {/*
                    <Icon
                      name={props.icon}
                    />
                  */}
              {props.message}
            </p>
          </div>
        </Slide>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { toast } = state;

  if (toast) {
    const config = {
      color: toast.color,
      duration: toast.duration ? toast.duration : 3000,
      icon: toast.icon,
      message: toast.message
    };

    if (toast.positive) {
      config.color = 'green';
      config.icon = 'circle check';
      config.message = toast.message ? toast.message : 'Success!';
    }

    if (toast.negative) {
      config.color = 'red';
      config.icon = 'circle exclamation';
      config.message = toast.message
        ? toast.message
        : `Something went wrong. We're investigating the issue.`;
    }

    return {
      color: config.color,
      duration: config.duration,
      icon: config.icon,
      message: config.message,
      visible: true
    };
  }

  return {
    color: '',
    duration: 0,
    icon: '',
    message: '',
    visible: false
  };
}

export default connect(mapStateToProps)(Toast);
