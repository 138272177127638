import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { Reports } from 'components/Employee';

export default function SurveyModal({
  employeeId,
  surveyId,
  surveyName,
  reports,
  reportsEnabled
}) {
  const [isOpen, setOpen] = useState(false);

  if (!surveyId || reports.length === 0) return '';

  return (
    <div>
      <Button icon="eye" basic size="tiny" onClick={() => setOpen(true)} />
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <Modal.Header content={`Reports Available for ${surveyName}`} />
        <Modal.Content>
          <Reports
            employeeId={employeeId}
            reports={reports.sort((a, b) =>
              a.profile_name > b.profile_name ? 1 : -1
            )}
            reportsEnabled={reportsEnabled}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

SurveyModal.defaultProps = {
  employeeId: null,
  reports: [],
  reportsEnabled: [],
  surveyName: '',
  surveyId: null
};
