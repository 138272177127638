import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from 'components';
import { Loading, Toast } from 'components/shared';
import Dashboard from '../pages/manager/Dashboard';
import CompareIndex from '../pages/manager/CompareIndex';
import EmployeeIndex from '../pages/manager/EmployeeIndex';
import EmployeeDetail from '../pages/manager/EmployeeDetail';
import EngagementDetail from '../pages/manager/EngagementDetail';
import EngagementIndex from '../pages/manager/EngagementIndex';
import PerformanceEvaluationDetail from '../pages/manager/PerformanceEvaluationDetail';
import ReportDetail from '../pages/manager/ReportDetail';
import Report360 from '../pages/manager/Report360';

class Manager extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Router basename="/">
          <div style={{ height: '100%' }}>
            <Toast />
            <Loading />
            <Header manager />
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/compare" component={CompareIndex} />
              <Route exact path="/employees" component={EmployeeIndex} />
              <Route path="/employees/:id" component={EmployeeDetail} />
              <Route exact path="/360/:id" component={Report360} />
              <Route exact path="/engagement" component={EngagementIndex} />
              <Route
                exact
                path="/engagement/:id"
                component={EngagementDetail}
              />
              <Route
                exact
                path="/performance/:surveyContainerId"
                component={PerformanceEvaluationDetail}
              />
              <Route
                exact
                path="/report/:calcId/:opId/:reportType"
                component={ReportDetail}
              />
              <Route path="/360" exact component={Report360} />
              <Route path="*">
                <h2 style={{ textAlign: 'center' }}>
                  The page you were looking for cannot be found.
                </h2>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default Manager;
