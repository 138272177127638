import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Wrapped } from 'components/Layouts';

import { SET_TOAST } from 'duck/toast';

import { QuestionForm } from 'components/Questions';

import { ErrorView } from 'components/shared/Errors';

import { DESCRIPTOR_PERSONALITY } from '@util/data/questionDescriptor';

import {
  MULTIPLE_CHOICE_VALUE,
  TRUE_FALSE_VALUE,
  YES_NO_VALUE,
  OPEN_RESPONSE_VALUE
} from '@util/data/questionType';

class QuestionDetail extends Component {
  state = {
    createdAt: new Date().toLocaleString(),
    data: {
      a: '',
      b: '',
      c: '',
      d: '',
      e: '',
      f: '',
      g: '',
      h: '',
      i: '',
      j: '',
      k: '',
      l: '',
      correctAnswer: null,
      descriptor: DESCRIPTOR_PERSONALITY,
      hasCorrectAnswer: false,
      hasComment: false,
      text: '',
      categoryString: '',
      type: null,
      variety: 0
    },
    error: null,
    loading: true
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchData();
  };

  fetchData = () => {
    this.setState({ loading: true });
    axios
      .get(`/questions/${this.props.match.params.id}/`)
      .then(({ data }) => {
        let type = null;

        if (data.multiple_choice) {
          type = MULTIPLE_CHOICE_VALUE;
        }

        if (data.true_false) {
          type = TRUE_FALSE_VALUE;
        }

        if (data.yes_no) {
          type = YES_NO_VALUE;
        }

        if (data.open_response) {
          type = OPEN_RESPONSE_VALUE;
        }

        const correctAnswer = () => {
          let possibleCorrects = [
            { key: 'correct_a', value: 'a' },
            { key: 'correct_b', value: 'b' },
            { key: 'correct_c', value: 'c' },
            { key: 'correct_d', value: 'd' },
            { key: 'correct_e', value: 'e' },
            { key: 'correct_f', value: 'f' },
            { key: 'correct_g', value: 'g' },
            { key: 'correct_h', value: 'h' },
            { key: 'correct_i', value: 'i' },
            { key: 'correct_j', value: 'j' },
            { key: 'correct_k', value: 'k' },
            { key: 'correct_l', value: 'l' },
            { key: 'correct_yes', value: 'yes' },
            { key: 'correct_no', value: 'no' }
            // {key: 'correct_true_false', value: true},
          ];

          let answer = null;

          possibleCorrects.forEach((p) => {
            if (data[p.key]) {
              answer = p.value;
            }
          });

          if (data.correct_true_false) {
            answer = data.correct_true_false;
          }

          return answer;
        };

        this.setState({
          createdAt: new Date(data.created_at).toLocaleString(),
          data: {
            a: data.a || '',
            b: data.b || '',
            c: data.c || '',
            d: data.d || '',
            e: data.e || '',
            f: data.f || '',
            g: data.g || '',
            h: data.h || '',
            i: data.i || '',
            j: data.j || '',
            k: data.k || '',
            l: data.l || '',
            descriptor: data.descriptor || DESCRIPTOR_PERSONALITY,
            hasCorrectAnswer: data.has_correct_answer || false,
            hasComment: data.has_respondant_comment || false,
            categoryString: data.category_string || '',
            correctAnswer: correctAnswer(),
            text: data.text,
            type,
            variety: data.variety || 0
          },
          loading: false
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message
            ? err.response.data.ui_message
            : 'Could not find the survey you were looking for',
          loading: false
        });
      });
  };

  onUpdate = (formData) => {
    const questionId = this.props.match.params.id;
    this.setState({ loading: true });
    axios
      .put(`/questions/${questionId}/`, formData)
      .then(({ data }) => {
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Question Updated!'
          }
        });
      })
      .then(() => this.fetchData())
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  };

  render() {
    const questionId = this.props.match.params.id;
    const { data, error, loading } = this.state;

    if (error) {
      return <ErrorView text={error} />;
    }

    return (
      <div>
        <Wrapped title={`${data.text} - created on ${this.state.createdAt}`}>
          <QuestionForm
            // important stuff
            // data stuff
            a={data.a}
            b={data.b}
            c={data.c}
            d={data.d}
            e={data.e}
            f={data.f}
            g={data.g}
            h={data.h}
            i={data.i}
            j={data.j}
            k={data.k}
            l={data.l}
            categoryString={data.categoryString}
            correctAnswer={data.correctAnswer}
            descriptor={data.descriptor}
            hasCorrectAnswer={data.hasCorrectAnswer}
            hasComment={data.hasComment}
            text={data.text}
            type={data.type}
            variety={data.variety}
            // form stuff
            buttonText="Update"
            loading={loading}
            onSubmit={this.onUpdate}
            updating
          />
        </Wrapped>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(QuestionDetail);
