import React from 'react';

export default function CompareTable({ headers, rows }) {
  return (
    <table className="compare-report-table">
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
