import React, { useState } from 'react';
import axios from 'axios';
import { ActionButton, BasicButton } from 'components/shared/Buttons';
import { Form, Modal } from 'semantic-ui-react';

export default function SubmitText(props) {
  const [isOpen, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [opportunity, setOpportunity] = useState('');
  const [showComment, setShowComment] = useState(false);

  const { surveyId, variety } = props;

  function onFinishClick() {
    if (variety === 4) {
      return setShowComment(true);
    }

    setOpen(false);
    props.onSubmitConfirm();
  }

  function onCommentSubmit() {
    if (!comment && !opportunity) {
      setOpen(false);
      props.onSubmitConfirm();
    } else {
      axios
        .post(`/surveys/${surveyId}/comments/`, {
          survey_id: surveyId,
          comment,
          comment_2: opportunity
        })
        .then(() => {
          setOpen(false);
          props.onSubmitConfirm();
        });
    }
  }

  return (
    <div>
      <ActionButton
        text={variety === 0 ? 'Finish Test' : 'Complete'}
        style={{ fontWeight: 'bold' }}
        onClick={() => setOpen(true)}
      />
      <Modal open={isOpen} size="tiny">
        <Modal.Header
          content={!showComment ? 'Are You Sure?' : 'Any Comments?'}
        />
        <Modal.Content>
          {showComment && (
            <div>
              <Form>
                <Form.TextArea
                  label={`Strengths (optional)`}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <Form.TextArea
                  label={`Opportunities for improvement (optional)`}
                  value={opportunity}
                  onChange={(e) => setOpportunity(e.target.value)}
                />
              </Form>
            </div>
          )}
          {!showComment && (
            <React.Fragment>
              {variety === 4 && props.targetName && (
                <p>
                  The questions you answered in this survey evaluated{' '}
                  <b>{props.targetName}</b>.
                </p>
              )}
              {variety === 0 && (
                <p>
                  You are about to complete this Clear Screen test. Once a test
                  has been submitted, you will no longer have the ability to
                  change your answers.
                </p>
              )}
              {variety === 1 && (
                <p>
                  You are about to complete this evaluation. Once an evaluation
                  has been submitted, you will no longer have the ability to
                  change your answers.
                </p>
              )}
              {variety === 2 && (
                <p>
                  You are about to complete this survey. Once your survey has
                  been submitted, you will no longer have the ability to change
                  your answers.
                </p>
              )}
              {[3, 4, 5].includes(variety) && (
                <p>
                  You are about to complete this survey. Once your survey has
                  been submitted, you will no longer have the ability to change
                  your answers.
                </p>
              )}
              <p>
                <b>Are you sure you want to do this?</b>
              </p>
            </React.Fragment>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!showComment && (
            <React.Fragment>
              <BasicButton text="No" onClick={() => setOpen(false)} />
              <ActionButton
                onClick={onFinishClick}
                text="Yes, I'm Sure"
                style={{ fontWeight: 'bold' }}
                cls={variety === 3 ? 'hol-submit' : ''}
              />
            </React.Fragment>
          )}
          {showComment && (
            <ActionButton
              onClick={onCommentSubmit}
              text="Finish"
              style={{ fontWeight: 'bold' }}
            />
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
}

SubmitText.defaultProps = {
  variety: 0
};
