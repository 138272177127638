import React, { useState } from 'react';
import { FaBan } from 'react-icons/fa';

import { EngagementContext } from '../../';
import { FilterButtons } from 'components/shared/Buttons';

import QuestionItem from './QuestionItem';

export default function QuestionView() {
  const reportData = React.useContext(EngagementContext);
  const [factor, setFactor] = useState(null);
  const [dimension, setDimension] = useState(null);

  const formattedData = formatData(reportData);

  // console.log(formattedData);

  const factorBtns = [
    { key: 9999, text: 'All', value: null },
    ...factors.map((f, i) => ({
      key: i,
      text: f,
      value: i
    }))
  ];

  const dimensionBtns = [
    { key: 9999, text: 'All', value: null },
    ...dimensions.map((f, i) => ({
      key: i,
      text: f,
      value: i
    }))
  ];

  const displayData = formattedData
    .filter((f) => {
      if (factor === null) {
        return true;
      }

      return f.factor === factor;
    })
    .filter((f) => {
      if (dimension === null) {
        return true;
      }

      return f.dimension === dimension;
    });

  return (
    <div>
      <div className="question-filters">
        <FilterButtons
          text="Dimension"
          active={dimension}
          buttons={dimensionBtns.map((f) => ({
            ...f
          }))}
          onClick={(e) => setDimension(e)}
        />
        <FilterButtons
          text="Factor"
          active={factor}
          buttons={factorBtns.map((f) => ({
            ...f
          }))}
          onClick={(e) => setFactor(e)}
        />
      </div>
      {displayData.length === 0 && (
        <div className="empty">
          <FaBan />
          <p>No data available</p>
        </div>
      )}
      <div className="question-dept-list">
        {displayData.map((q) => (
          <QuestionItem
            key={q.id}
            title={q.text}
            factor={q.factor_formatted}
            dimension={q.dimension_formatted}
            departments={q.departments}
          />
        ))}
      </div>
    </div>
  );
}

function formatData(data) {
  const { employees, questions, departments } = data;

  return questions.map((question) => {
    return {
      ...question,
      factor_formatted: factors[question.factor],
      dimension_formatted: dimensions[question.dimension],
      departments: departments
        .map((dept) => {
          const employeesInDepartment = employees.filter(
            (f) => f.department_id === dept.value
          );

          const employeeAnswersForQuestion = employeesInDepartment
            .map((m) => m.answers.filter((f) => f.question_id === question.id))
            .flat();

          const responseTotal = employeeAnswersForQuestion.reduce(
            (acc, cur) => acc + cur.response,
            0
          );

          const responseMax = employeeAnswersForQuestion.length * 5;

          return {
            ...dept,
            answers: employeeAnswersForQuestion,
            response_total: responseTotal,
            response_max: responseMax,
            response_rating: (responseTotal / responseMax) * 100,
            employee_count: employeesInDepartment.length
          };
        })
        .filter((f) => f.employee_count > 5)
    };
  });
}

const dimensions = ['Organization', 'Team', 'Self'];
const factors = [
  'Cohesion',
  'Supervision',
  'Development',
  'Communication',
  'Resources',
  'Workload',
  'Custom'
];
