import React from 'react';
import selectMdLogo from 'assets/images/selectmd-logo.png';
import holLogo from 'assets/images/hol-3.png';
import competnecyLogo from 'assets/images/lpsu-logo.png';

export default function Intro({
  fullName,
  companyName,
  competency,
  hol,
  medical,
  reportType,
  reportSubtitle
}) {
  return (
    <div className="report-intro">
      {reportType && (
        <div className="report-meta">
          <h1>{reportType}</h1>
          <h2>{reportSubtitle}</h2>
        </div>
      )}
      {medical && (
        <div className="report-image-header">
          <img src={selectMdLogo} alt="SelectMD" />
        </div>
      )}
      {(hol || competency) && (
        <div
          className={`report-image-header centered${
            competency ? ' competency' : ''
          }`}
        >
          {hol && <img src={holLogo} alt="Heart of Leadership Logo" />}
          {competency && (
            <img src={competnecyLogo} alt="Competency Assessment Logo" />
          )}
        </div>
      )}
      <h1>{fullName}</h1>
      {!hol && !competency && <h2>{companyName}</h2>}
      {!hol && !competency && (
        <small>
          <b>{medical ? 'SelectMD' : 'CLEAR Screen'}</b> &copy; 2011-
          {new Date().getFullYear()}, Cowart Consulting Group, LLC, Roswell,
          Georgia, USA. Unauthorized use and/or reproduction without approval of
          copyright holders is prohibited. This report contains CONFIDENTIAL
          assessment results intended for development and promotion purposes by
          the designated organization. Development and promotion decisions
          should be based upon more than test results alone, such as work
          history, educational background, performance, and motivation.
        </small>
      )}
      {hol && (
        <div className="hol-intro">
          <h3>Heart of Leadership Outcome</h3>
        </div>
      )}
      {competency && (
        <div className="hol-intro">
          <h3>Competency Assessment Output</h3>
        </div>
      )}
    </div>
  );
}

Intro.defaultProps = {
  companyName: 'Default Company',
  competency: false,
  fullName: 'Default Candidate',
  hol: false,
  medical: false,
  reportType: '',
  reportSubtitle: ''
};
