import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';

import BasicForm from 'components/shared/Forms/BasicForm';
import { BasicButton } from 'components/shared/Buttons';
import statusOptions from './helpers/statusOptions';
import { SET_TOAST } from 'duck/toast';

function EmployeeForm({
  isAdmin,
  buttonText,
  managerOptions,
  firstName,
  lastName,
  email,
  position,
  positionId,
  division,
  status,
  companyId,
  showCompany,
  dispatch,
  onSubmit,
  onUpdate,
  companies,
  onClose
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let fields = [
    {
      grouped: true,
      fields: [
        {
          name: 'first_name',
          label: 'First Name',
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'last_name',
          label: 'Last Name',
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      name: 'email',
      label: 'Email',
      initialValue: email,
      required: true,
      schema: () =>
        Yup.string().email('Not a valid email...').required('Required')
    },
    {
      name: 'division',
      label: 'Division',
      initialValue: division,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'position',
      label: 'Position',
      initialValue: position,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'status',
      label: 'Status',
      initialValue: status,
      required: true,
      select: true,
      options: statusOptions,
      schema: () => Yup.string().required('Required')
    }
  ];

  if (showCompany) {
    fields.push({
      name: 'company_id',
      label: 'Company',
      initialValue: companyId,
      required: true,
      search: true,
      select: true,
      options: companies.map((company) => ({
        text: company.name,
        key: company.id,
        value: company.id
      })),
      schema: () => Yup.string().required('Required')
    });
  }

  if (managerOptions.length > 0) {
    fields.push({
      name: 'company_manager_id',
      label: 'Company Manager',
      initialValue: null,
      required: true,
      search: true,
      select: true,
      options: managerOptions,
      schema: () => Yup.string().nullable('Required').required('Required')
    });
  }

  function onFormSubmit(postData) {
    if (onUpdate) return onUpdate(postData);

    setLoading(true);

    const req = {
      ...postData
    };

    let url = `/employees/`;

    if (isAdmin) {
      url = `/company-employees/`;
      req.company_id = companyId;
    }

    axios
      .post(url, req)
      .then(({ data }) => {
        setLoading(false);
        return data.id;
      })
      .then((employeeId) => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Employee Created!'
          }
        });
        return employeeId;
      })
      .then((id) => onSubmit(id))
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <Fade>
      <div className="segment">
        <BasicForm
          buttonText={buttonText}
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onFormSubmit}
        >
          {onClose && <BasicButton onClick={onClose} text="Cancel" />}
        </BasicForm>
      </div>
    </Fade>
  );
}

function mapStateToProps(state) {
  return {
    companies: state.manager ? state.manager.active_companies : []
  };
}

export default connect(mapStateToProps)(EmployeeForm);

EmployeeForm.defaultProps = {
  buttonText: 'Create',
  firstName: '',
  isAdmin: false,
  lastName: '',
  email: '',
  managerOptions: [],
  position: '',
  positionId: null,
  division: '',
  status: ``,
  companyId: ``,
  showCompany: true
};
