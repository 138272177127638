import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'semantic-ui-react';
import { FaMinusCircle } from 'react-icons/fa';
import { CompanySelectList } from 'components/CompanyManager';
import { ActionButton } from 'components/shared/Buttons';
import { Warning } from 'components/shared/Messages';
import EmployeeModal from './EmployeeModal';
import ProfileDisplay from './ProfileDisplay';

import './index.scss';

export default function CompareForm({ admin, onSubmit, clearData }) {
  const [companyId, setCompanyId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [opOptions, setOpOptions] = useState([]);
  const [opId, setOpId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);

  function onConfirmClick(e) {
    e.preventDefault();

    // if (!employeeIds.length || !opId || !companyId) return;

    // let calculationContainerIds = employees
    //   .filter((emp) => employeeIds.includes(emp.value))
    //   .map((emp) => emp.calc_id);

    // if (!calculationContainerIds.length) return;
    const calculationContainerIds = selectedEmployees.map(
      (e) => e.container_id
    );

    const submitData = {
      opId,
      calculationContainerIds
      // calculationContainerIds
    };

    onSubmit(submitData);
  }

  function setCompany(id) {
    setCompanyId(id);
    setEmployees([]);
    setEmployeeIds([]);
    setOpOptions([]);
    setOpId(null);
    fetchEmployees(id);
    setSelectedEmployees([]);
    clearData();
  }

  function onEmployeeSelect(employeeId) {
    const emp = employees.find((f) => f.value === employeeId);

    if (!emp) return;

    setEmployeeData(emp);
    clearData();
  }

  function fetchEmployees(companyId) {
    setLoading(true);

    axios
      .post(`/employees-to-compare/`, { company_id: companyId, admin: admin })
      .then(({ data }) => {
        setEmployees(
          data.company_employees.map((emp) => ({
            calc_id: emp.active_pan_result_calc_id,
            key: emp.id,
            text: emp.full_name,
            value: emp.id
          }))
        );
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setEmployeeIds([]);
        setLoading(false);
      });
  }

  function onEmployeeReportSelect(container) {
    const emp = { ...employeeData };

    if (!emp) return;

    setSelectedEmployees([
      ...selectedEmployees,
      {
        ...emp,
        container_name: container.name,
        container_id: container.id,
        uuid: uuidv4()
      }
    ]);
    setEmployeeData(null);
  }

  function onEmployeeRemove(uuid) {
    setSelectedEmployees([...selectedEmployees].filter((f) => f.uuid !== uuid));
  }

  return (
    <div>
      <CompanySelectList
        label="Company"
        multiple={false}
        value={companyId}
        onChange={(id) => setCompany(id)}
        admin={admin}
      />
      {companyId && (
        <Form style={{ marginTop: '15px' }}>
          <Form.Select
            label={`Employees to Compare (${employees.length})`}
            // multiple
            // onChange={(e, d) => setEmployeeIds(d.value)}
            onChange={(e, d) => onEmployeeSelect(d.value)}
            // value={employeeIds}
            search
            options={employees}
            placeholder="Select some employees..."
          />
          {!employees.length && !isLoading && (
            <Warning text="The selected company has no employees to compare at the moment" />
          )}
        </Form>
      )}
      {companyId && employeeData && (
        <EmployeeModal
          id={employeeData.value}
          name={employeeData.text}
          onClose={() => setEmployeeData(null)}
          onSelect={onEmployeeReportSelect}
          admin={admin}
        />
      )}
      {selectedEmployees.length > 0 && (
        <div className="compare-selected-emps">
          <h4>Selected Employees</h4>
          {selectedEmployees.map((s) => (
            <EmployeeItem
              key={uuidv4()}
              name={s.text}
              id={s.id}
              containerName={s.container_name}
              containerId={s.container_id}
              onRemove={() => onEmployeeRemove(s.uuid)}
            />
          ))}
        </div>
      )}
      {selectedEmployees.length > 1 && (
        <ProfileDisplay
          containerIds={selectedEmployees.map((e) => e.container_id)}
          onProfileChange={(p) => {
            clearData();
            setOpId(p);
          }}
          profileId={opId}
        />
      )}
      {/* {companyId && (
        <Form style={{ marginTop: '15px' }}>
          <Form.Select
            label={`Occupational Profile (${opOptions.length})`}
            onChange={(e, d) => setOpId(d.value)}
            value={opId}
            options={opOptions}
            placeholder="Select an occupational profile..."
          />
          {!opOptions.length && !isLoading && (
            <Warning text="The selected company has no occupational profiles at the moment" />
          )}
        </Form>
      )} */}
      {opId && (
        <ActionButton
          style={{ marginTop: '15px' }}
          onClick={onConfirmClick}
          text="Compare"
          disabled={!opId}
          disabled={selectedEmployees.length <= 1}
        />
      )}
    </div>
  );
}

function EmployeeItem({ name, id, containerName, containerId, onRemove }) {
  return (
    <p className="compare-emp">
      {name} {containerName}
      <button onClick={onRemove}>
        <FaMinusCircle />
      </button>
    </p>
  );
}

EmployeeItem.defaultProps = {
  admin: false,
  name: '',
  id: null
};
