import React from 'react';

export default function Wrapped(props) {
  return (
    <div className="wrapped" style={{ ...props.style }}>
      {props.title && (
        <h1 className="page-title">
          {props.title} {props.button ? props.button : null}
        </h1>
      )}
      {props.children}
    </div>
  );
}

Wrapped.defaultProps = {
  title: '',
  style: {}
};
