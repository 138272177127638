import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

const markerStyle = {
  position: 'absolute',
  fontSize: '30px'
};

function formatAssuranceVal(assuranceValue) {
  const assurance = assuranceValue - 1;

  let leftVal = assurance.toString();
  return parseFloat(leftVal) * 66.66 - 11.5;
}

function formatReceptivityVal(receptivityValue) {
  const receptivity = receptivityValue - 1;

  let bottomVal = receptivity.toString();
  return parseFloat(bottomVal) * 33.33 - 15.5;
}

export default function AssuranceAndReceptivityGraph({ employees, showNames }) {
  return (
    <div className="assurance-graph-container">
      <p className="axis-x">
        Assurance Score <FaLongArrowAltRight />
      </p>
      <p className="axis-y">
        Receptivity <span style={{ marginLeft: '5px' }}>Score</span>{' '}
        <FaLongArrowAltRight />
      </p>
      <div className="assurance-graph">
        <div className="assurance-graph-table">
          {employees.map((employee, i) => (
            <React.Fragment key={i}>
              <span
                className="ar-marker"
                style={{
                  ...markerStyle,
                  bottom: `${formatReceptivityVal(employee.receptivity)}px`,
                  left: `${formatAssuranceVal(employee.assurance)}px`
                }}
              >
                &#x2666;
              </span>
              {showNames && (
                <span
                  className="estimate-label"
                  style={{
                    ...markerStyle,
                    bottom: `${
                      formatReceptivityVal(employee.receptivity) + 2
                    }px`,
                    left: `${formatAssuranceVal(employee.assurance) + 30}px`
                  }}
                >
                  {employee.name}
                </span>
              )}
            </React.Fragment>
          ))}
          <div className="assurance-graph-row">
            <div>
              <h3>
                REACTIVE WHIMSY
                <br />
                'Gullible'
              </h3>
            </div>
            <div>
              <h3>
                CONFIDENT WHIMSY
                <br />
                'Unbridled'
              </h3>
            </div>
          </div>
          <div className="assurance-graph-row">
            <div>
              <h3>
                REACTIVE RECEPTIVITY
                <br />
                'Naïve'
              </h3>
            </div>
            <div className="assurance-selected">
              <h3>
                ANCHORED RECEPTIVITY
                <br />
                'Optimal'
              </h3>
            </div>
          </div>
          <div className="assurance-graph-row">
            <div>
              <h3>
                REACTIVE RESOLVE
                <br />
                'Reactionary'
              </h3>
            </div>
            <div>
              <h3>
                CONFIDENT RESOLVE
                <br />
                'Resistant'
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AssuranceAndReceptivityGraph.defaultProps = {
  showNames: false
};
