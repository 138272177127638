import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import 'react-table/react-table.css';
import { Provider } from 'react-redux';
import { store } from './duck';

import './style/main.scss';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import BckApp from './BckApp';

axios.defaults.baseURL = '/api';

class App extends React.Component {
  state = {};

  render() {
    return (
      <Provider store={store}>
        <BckApp style={{ height: '100%' }} />
      </Provider>
    );
  }
}

ReactDOM.render(
  <App style={{ height: '100%' }} />,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept();
}
