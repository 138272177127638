import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from 'semantic-ui-react';

import './index.scss';

export default function PrintReport({ questions, employeeName, surveyName }) {
  const componentRef = useRef();

  if (!questions.length) return null;

  return (
    <React.Fragment>
      <ReactToPrint
        trigger={() => (
          <div style={{ textAlign: 'center' }}>
            <Button
              content="Print My Responses"
              icon="print"
              labelPosition="right"
              primary
            />
          </div>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }}>
        <div className="print-questions" ref={componentRef}>
          <h1>{surveyName}</h1>
          <p className="meta">Submitted by: {employeeName}</p>
          {questions.map((q) => (
            <QuestionItem
              key={q.id}
              text={q.text}
              answer={getAnswer(q)}
              comment={q.respondant_comment}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

PrintReport.defaultProps = {
  questions: []
};

const QuestionItem = ({ text, answer, comment }) => (
  <div className="print-question-item">
    <h4>{text}</h4>
    <p>{answer}</p>
    {comment && <p>{comment}</p>}
  </div>
);

QuestionItem.defaultProps = {
  answer: '',
  comment: '',
  surveyName: '',
  text: ''
};

function getAnswer(question) {
  let answer = '';

  if (question.multiple_choice) {
    ['a', 'b', 'c', 'd', 'e'].forEach((r) => {
      if (question[`response_${r}`]) {
        answer = question[r];
      }

      if (question.selected) {
        answer = question[question.selected];
      }
    });
  }

  if (question.true_false) {
    if (question.response_true_false) {
      answer = 'True';
    } else {
      answer = 'False';
    }
  }

  if (question.open_response) {
    answer = question.open_response_reply;
  }

  if (question.yes_no) {
    if (question.response_yes) {
      answer = 'Yes';
    }

    if (question.response_no) {
      answer = 'No';
    }
  }

  return answer;
}
