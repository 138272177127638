import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { SET_TOAST } from 'duck/toast';
import getLogoUrl from '@util/formatters/getLogoUrl';

function CompanyMetaBlock({
  allowUpload,
  city,
  companyId,
  companyName,
  logoFilename,
  employeeName,
  state,
  managerCount,
  employeeCount,
  loading,
  dispatch,
  fetchData
}) {
  function onChange(e) {
    if (!e.target.files.length || !companyId) return;

    const formData = new FormData();
    const file = e.target.files[0];

    formData.append('company_photo', file);
    formData.append('company_id', companyId);

    axios
      .post(`/companies/${companyId}/upload-logo/`, formData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            message: 'Logo Uploaded!',
            positive: true
          }
        });
      })
      .then(() => fetchData())
      .catch((err) => {
        dispatch({
          type: SET_TOAST,
          payload: {
            message: err.response.data.ui_message,
            negative: true
          }
        });
      });
  }

  return (
    <div className="segment company-meta-block">
      <div>
        {!loading && logoFilename && <img src={getLogoUrl(logoFilename)} />}
        {allowUpload && (
          <p className="upload-text">(Click to upload new logo)</p>
        )}
      </div>
      {allowUpload && (
        <input
          type="file"
          name="file"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => onChange(e)}
        />
      )}
      <div>
        {employeeName ? (
          <React.Fragment>
            <h1>{employeeName}</h1>
            <p>Employee for {companyName}</p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1>{companyName}</h1>
            <p>
              {city}, {state}{' '}
              {managerCount > 0 && (
                <span> &middot; {managerCount} Managers</span>
              )}
              {employeeCount > 0 && (
                <span> &middot; {employeeCount} Employees</span>
              )}
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyMetaBlock);

CompanyMetaBlock.defaultProps = {
  allowUpload: false,
  city: '',
  companyId: null,
  companyName: '',
  loading: false,
  logoFilename: '',
  employeeName: '',
  state: '',
  managerCount: '',
  employeeCount: ''
};
