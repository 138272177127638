import React from 'react';
import { Popup } from 'semantic-ui-react';

export default function ProgressLine({ total }) {
  return (
    <div className="progress-line">
      <div className="line-wrapper">
        {total > 0 && (
          <span
            className="line"
            style={{
              width: `${total}%`,
              background: `hsl(185, 62%, ${total / 1.15}%)`
            }}
          ></span>
        )}
      </div>
      {/* <span className="text">{total}%</span> */}
    </div>
  );

  return (
    <Popup
      content={`${Math.round(total * 100) / 100}% of employees agree`}
      trigger={
        <div className="progress-line">
          <div className="line-wrapper">
            {total > 0 && (
              <span
                className="line"
                style={{
                  width: `${total}%`,
                  background: `hsl(185, 62%, ${total / 1.15}%)`
                }}
              ></span>
            )}
          </div>
          {/* <span className="text">{total}%</span> */}
        </div>
      }
    />
  );
}
