import React, { useEffect } from 'react';
import axios from 'axios';

export default function HeadlessSession() {
  useEffect(() => {
    getSession();
  }, []);

  function getSession() {
    axios.get(`/headless-session/`).then(({ data }) => {
      window.location = '/';
    });
  }

  return <div></div>;
}
