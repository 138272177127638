import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { Wrapped } from 'components/Layouts';
import { ErrorView } from 'components/shared/Errors';
import {
  CalculationContainers,
  EmployeeMeta,
  QuestionPresentation,
  Results,
  SurveyMeta
} from 'components/Evaluation';

import { CompetencyOutcomes, HolOutcomes } from 'components/Reports/components';

import { SET_TOAST } from 'duck/toast';

function EvaluationDetail({ match, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [surveyName, setSurveyName] = useState('');
  const [surveyVariety, setSurveyVariety] = useState(null);
  const [submittedAt, setSubmittedAt] = useState(null);
  const [panResult, setPanResult] = useState(null);
  const [calculationContainers, setCalculationContainers] = useState([]);
  const [remoteCode, setRemoteCode] = useState(null);
  const [completeLoading, setCompleteLoading] = useState(false);

  const evaluationId = match.params.id;

  useEffect(() => {
    fetchEvaluation();
  }, []);

  function fetchEvaluation() {
    setLoading(true);

    axios
      .get(`/survey-evaluations/${evaluationId}/`)
      .then(({ data }) => {
        setLoading(false);
        setEvaluation(data);
        setEmployee(data.employee);
        setPanResult(data.pan_result);
        setSurveyName(data.name);
        setSurveyVariety(data.variety);
        setSubmittedAt(new Date(data.submitted_at).toLocaleString());
        if (data.submitted_at) {
          setSubmittedAt(new Date(data.submitted_at).toLocaleString());
        } else {
          setSubmittedAt('');
        }
        setCalculationContainers(data.calculation_containers);
        setRemoteCode(data.remote_code ? data.remote_code.code : null);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onCompleteClick() {
    setCompleteLoading(true);

    const req = {
      survey_id: evaluationId
    };

    axios
      .post(`/hol-mark-report-ready/`, req)
      .then(() => {
        setCompleteLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: { message: 'Report Link Emailed!', positive: true }
        });
      })
      .catch((err) => {
        setCompleteLoading(false);
      });
  }

  function onCompReadyClick() {
    setCompleteLoading(true);

    const req = {
      survey_id: evaluationId,
      final: true
    };

    axios
      .post(`/comp-mark-report-ready/`, req)
      .then(() => {
        setCompleteLoading(false);
        fetchEvaluation();
        dispatch({
          type: SET_TOAST,
          payload: { message: 'Report Link Emailed!', positive: true }
        });
      })
      .catch((err) => {
        setCompleteLoading(false);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!evaluation) return null;

  return (
    <div>
      {employee && (
        <Wrapped title="Employee">
          <EmployeeMeta
            name={employee.full_name}
            email={employee.email}
            employeeId={employee.id}
            id={employee.id}
            position={employee.position}
            companyName={employee.company.name}
            companyId={employee.company.id}
          />
        </Wrapped>
      )}
      <Wrapped title="Survey">
        <SurveyMeta name={surveyName} submitted={submittedAt} />
      </Wrapped>
      <QuestionPresentation surveyId={evaluationId} />
      {employee && ![3, 5].includes(surveyVariety) && (
        <CalculationContainers
          employeeId={employee.id}
          companyId={employee.company.id}
          containers={calculationContainers}
          panResultId={panResult ? panResult.id : null}
          fetchData={fetchEvaluation}
          addable={surveyVariety !== 3}
          surveyVariety={surveyVariety}
          surveyId={evaluationId}
          remoteCode={remoteCode}
        />
      )}
      {panResult && ![3, 5].includes(surveyVariety) && (
        <Wrapped title="STEN Scores">
          <div className="segment">
            <Results
              exValue={panResult.ex}
              anValue={panResult.an}
              tmValue={panResult.tm}
              inValue={panResult.in}
              scValue={panResult.sc}
              aValue={panResult.a}
              bValue={panResult.b}
              cValue={panResult.c}
              eValue={panResult.e}
              fValue={panResult.f}
              gValue={panResult.g}
              hValue={panResult.h}
              iValue={panResult.i}
              lValue={panResult.l}
              mValue={panResult.m}
              nValue={panResult.n}
              oValue={panResult.o}
              q1Value={panResult.q1}
              q2Value={panResult.q2}
              q3Value={panResult.q3}
              q4Value={panResult.q4}
            />
          </div>
        </Wrapped>
      )}
      {surveyVariety === 3 && (
        <Wrapped title="Heart of Leadership Outcomes">
          <div className="segment">
            {calculationContainers.length > 0 && (
              <HolOutcomes
                admin
                panResult={panResult}
                executiveValue={calculationContainers[0].hol_exec}
                managerValue={calculationContainers[0].hol_man}
                engineerValue={calculationContainers[0].hol_staff}
              />
            )}
            {remoteCode && <p style={{ marginTop: '1.5em' }}>{remoteCode}</p>}
            <Button
              content="Release Results"
              loading={completeLoading}
              onClick={onCompleteClick}
              disabled={completeLoading}
            />
          </div>
        </Wrapped>
      )}
      {surveyVariety === 5 && (
        <Wrapped title="Competency Assessment Outcomes">
          <div className="segment">
            {/* {panResult && <CompetencyOutcomes admin panResult={panResult} />} */}
            <div>
              <Button
                content="Release Results"
                loading={completeLoading}
                onClick={onCompReadyClick}
                disabled={completeLoading}
              />
              {calculationContainers.length > 0 && (
                <Button
                  as={Link}
                  to={`/competency-assessment/${calculationContainers[0].id}`}
                  content="View Full Results"
                  basic
                  target="_blank"
                />
              )}
            </div>
          </div>
        </Wrapped>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(EvaluationDetail));
