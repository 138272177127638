import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react';

import { ActionButton } from 'components/shared/Buttons';

import Item from '../Item';

export default function ProfileSearch({ onSubmit, profiles }) {
  const [tagIds, setTagIds] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchProfiles();
    fetchTags();
  }, []);

  useEffect(() => {
    setSelectedProfiles(profiles);
  }, [profiles]);

  function fetchProfiles() {
    axios
      .get(`/occupational-profiles/`)
      .then(({ data }) => {
        setAllProfiles(
          data.occupational_profiles.sort((a, b) =>
            a.original_name > b.original_name ? 1 : -1
          )
        );
      })
      .catch((err) => {});
  }

  function fetchTags() {
    axios
      .get(`/op-tags/`)
      .then(({ data }) => {
        setTags(
          data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((t) => ({
              key: t.id,
              text: t.name,
              value: t.id
            }))
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onTagChange(value) {
    setTagIds(value);
  }

  function onProfileActionClick(profile) {
    const { id } = profile;

    if (selectedProfiles.find((p) => p.id === id)) {
      return setSelectedProfiles(selectedProfiles.filter((f) => f.id !== id));
    }

    if (selectedProfiles.length === 10) {
      return alert(`You've added the maximum amount of profiles!`);
    }

    setSelectedProfiles([...selectedProfiles, profile]);
  }

  const filteredProfiles = allProfiles.filter((f) => {
    if (f.tags.some((t) => tagIds.includes(t.id))) {
      return f;
    }

    if (!tagIds.length) {
      return f;
    }
  });

  const selectedProfileIds = selectedProfiles.map((p) => p.id);

  return (
    <div>
      <h2>Search Profiles</h2>
      <Form size="large">
        <Form.Select
          placeholder="Select some tags..."
          search
          multiple
          options={tags}
          value={tagIds}
          onChange={(e, d) => onTagChange(d.value)}
        />
      </Form>
      <div className="profile-items">
        {filteredProfiles.map((profile) => (
          <Item
            key={profile.id}
            name={profile.original_name}
            profile={profile}
            tags={profile.tags}
          >
            <Button
              basic
              size="tiny"
              icon={selectedProfileIds.includes(profile.id) ? 'trash' : 'plus'}
              onClick={() => onProfileActionClick(profile)}
            />
          </Item>
        ))}
      </div>
      {selectedProfiles.length > 0 && (
        <div className="profile-counter">
          <ActionButton
            onClick={() => onSubmit(selectedProfiles)}
            disabled={selectedProfileIds.length !== 10}
            text={
              selectedProfileIds.length === 10
                ? 'Next'
                : `${selectedProfileIds.length}/10 Profiles Select`
            }
          />
        </div>
      )}
    </div>
  );
}
