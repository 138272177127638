import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';

export default function BackButton(props) {
  return (
    <Link
      className="button-back"
      to={props.url}><IoIosArrowRoundBack /> {props.text}</Link>
  );
}

BackButton.defaultProps = {
  text: 'Back',
  url: '/',
}
