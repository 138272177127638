import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Dashboard, Header } from 'components';
import { Loading, Toast } from 'components/shared';

import {
  CompanyDetail,
  CompanyIndex,
  CompareIndex,
  EmployeeIndex,
  EmployeeDetail,
  EngagementDetail,
  EvaluationDetail,
  EvaluationIndex,
  ManagerDetail,
  ManagerIndex,
  ProfileDetail,
  ProfileIndex,
  Report360,
  ReportCompetencyAssessment,
  ReportDetail,
  QuestionDetail,
  QuestionIndex,
  SurveyDetail,
  SurveyIndex,
  SurveyRequestDetail,
  SurveyRequestIndex
} from '../pages';

class Admin extends Component {
  render() {
    return (
      <div>
        <Router basename="/admin">
          <div>
            <Toast />
            <Loading />
            <Header />
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/requests" component={SurveyRequestIndex} />
              <Route
                exact
                path="/requests/:id"
                component={SurveyRequestDetail}
              />
              <Route exact path="/companies" component={CompanyIndex} />
              <Route path="/companies/:id" component={CompanyDetail} />
              <Route exact path="/compare" component={CompareIndex} />
              <Route exact path="/employees" component={EmployeeIndex} />
              <Route path="/employees/:id" component={EmployeeDetail} />
              <Route
                exact
                path="/report/:calcId/:opId/:reportType"
                component={ReportDetail}
              />
              <Route
                exact
                path="/engagement/:id"
                component={EngagementDetail}
              />
              <Route exact path="/evaluations" component={EvaluationIndex} />
              <Route
                exact
                path="/evaluations/:id"
                component={EvaluationDetail}
              />
              <Route exact path="/managers" component={ManagerIndex} />
              <Route exact path="/managers/:id" component={ManagerDetail} />
              <Route exact path="/profiles" component={ProfileIndex} />
              <Route exact path="/profiles/:id" component={ProfileDetail} />
              <Route exact path="/surveys" component={SurveyIndex} />
              <Route exact path="/surveys/:id" component={SurveyDetail} />
              <Route exact path="/questions" component={QuestionIndex} />
              <Route exact path="/questions/:id" component={QuestionDetail} />
              <Route exact path="/360/:id" component={Report360} />
              <Route
                exact
                path="/competency-assessment/:id"
                component={ReportCompetencyAssessment}
              />
              <Route path="*">
                <h2>No Match!</h2>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default Admin;
