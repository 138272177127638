import React from 'react';

import Loader from 'react-loader-spinner';

const loaderConfig = {
  type: 'ThreeDots',
  height: 15,
  width: 15,
  color: '#fff'
};

export default function IconFilterButtons(props) {
  return (
    <div className="icon-filter-buttons-wrapper">
      <p>{props.text}</p>
      <div className="icon-filter-buttons">
        {props.buttons.map((button, i) => (
          <button
            className={`icon-filter-button${
              props.active === button.value ? ' active' : ''
            }`}
            key={i}
            onClick={() => props.onClick(button.value)}
            title={button.text}
          >
            {/* {button.icon} */}
            <small>{button.text}</small>
          </button>
        ))}
        {props.children}
      </div>
    </div>
  );
}

IconFilterButtons.defaultProps = {
  loading: false
};
