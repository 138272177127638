export const DIMENSION_ORG = 0;
export const DIMENSION_TEAM = 1;
export const DIMENSION_SELF = 2;

export default function populateRows(questions = []) {
  const cohesionQuestions = questions.filter((f) => f.factor === 0);
  const supervisionQuestions = questions.filter((f) => f.factor === 1);
  const developmentQuestions = questions.filter((f) => f.factor === 2);
  const resourcesQuestions = questions.filter((f) => f.factor === 4);
  const communicationQuestions = questions.filter((f) => f.factor === 3);
  const workloadQuestions = questions.filter((f) => f.factor === 5);
  const customQuestions = questions.filter((f) => f.factor === 6);

  return [
    // cohesion
    {
      key: 1,
      count: cohesionQuestions.length,
      columns: [
        {
          title: 'Cohesion',
          response_total: cohesionQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: cohesionQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: cohesionQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: cohesionQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle:
            'Affinity for working with others as well as being receptive to and committing to coworkers.'
        },
        {
          questions: cohesionQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          ),
          group_title: 'Cohesion & Organization'
        },
        {
          questions: cohesionQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          ),
          group_title: 'Cohesion & Team'
        },
        {
          questions: cohesionQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          ),
          group_title: 'Cohesion & Self'
        }
      ]
    },
    // supervision
    {
      key: 2,
      count: supervisionQuestions.length,
      columns: [
        {
          title: 'Supervision',
          response_total: supervisionQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: supervisionQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: supervisionQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: supervisionQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle:
            'Clarity of expectations along with levels of support and empowerment.'
        },
        {
          group_title: 'Supervision & Organization',
          questions: supervisionQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          )
        },
        {
          group_title: 'Supervision & Team',
          questions: supervisionQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          )
        },
        {
          group_title: 'Supervision & Self',
          questions: supervisionQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    },
    // development
    {
      key: 3,
      count: developmentQuestions.length,
      columns: [
        {
          title: 'Development',
          response_total: developmentQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: developmentQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: developmentQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: developmentQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle:
            'Degrees of recognition, extent of feedback, and extensiveness of career development.'
        },
        {
          questions: developmentQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          ),
          group_title: 'Development & Organization'
        },
        {
          questions: developmentQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          ),
          group_title: 'Development & Team'
        },
        {
          group_title: 'Development & Self',
          questions: developmentQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    },
    // resources
    {
      key: 4,
      count: resourcesQuestions.length,
      columns: [
        {
          title: 'Resources',
          response_total: resourcesQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: resourcesQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: resourcesQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: resourcesQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle:
            'Level of training, adequacy of materials, and effectiveness of processes.'
        },
        {
          group_title: 'Resources & Organization',
          questions: resourcesQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          )
        },
        {
          group_title: 'Resources & Team',
          questions: resourcesQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          )
        },
        {
          group_title: 'Resources & Self',
          questions: resourcesQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    },
    // communication
    {
      key: 5,
      count: communicationQuestions.length,
      columns: [
        {
          title: 'Communication',
          response_total: communicationQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: communicationQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: communicationQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: communicationQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle: 'Ease of communication and availability of information.'
        },
        {
          group_title: 'Communication & Organization',
          questions: communicationQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          )
        },
        {
          group_title: 'Communication & Team',
          questions: communicationQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          )
        },
        {
          group_title: 'Communication & Self',
          questions: communicationQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    },
    // workload
    {
      key: 6,
      count: workloadQuestions.length,
      columns: [
        {
          title: 'Workload',
          response_total: workloadQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: workloadQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: workloadQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: workloadQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle: 'Staffing levels and work pressure.'
        },
        {
          group_title: 'Workload & Organization',
          questions: workloadQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          )
        },
        {
          group_title: 'Workload & Team',
          questions: workloadQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          )
        },
        {
          group_title: 'Workload & Self',
          questions: workloadQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    },
    // custom
    {
      key: 7,
      count: customQuestions.length,
      columns: [
        {
          title: 'Custom',
          response_total: customQuestions.reduce(
            (acc, cur) => acc + cur.response_total,
            0
          ),
          response_total_max: customQuestions.reduce(
            (acc, cur) => acc + cur.response_total_max,
            0
          ),
          employee_count: customQuestions.reduce(
            (acc, cur) => acc + cur.employee_count,
            0
          ),
          employee_response_count: customQuestions.reduce(
            (acc, cur) => acc + cur.employee_response_count,
            0
          ),
          subtitle:
            'Net Promoter items and items requested by the organization.'
        },
        {
          group_title: 'Custom & Organization',
          questions: customQuestions.filter(
            (f) => f.dimension === DIMENSION_ORG
          )
        },
        {
          group_title: 'Custom & Team',
          questions: customQuestions.filter(
            (f) => f.dimension === DIMENSION_TEAM
          )
        },
        {
          group_title: 'Custom & Self',
          questions: customQuestions.filter(
            (f) => f.dimension === DIMENSION_SELF
          )
        }
      ]
    }
  ].filter((f) => f.count > 0);
}
