import { v4 as uuidv4 } from 'uuid';

export default function sliderDataFormatter(data, category = 'leadership') {
  const getImplications = (key) => {
    if (data.text && data.text.implications) {
      return data.text.implications[`implications_${key}`] || null;
    }

    return null;
  };

  if (category === 'processDiscipline') {
    return [
      {
        leftPoleText: `Conceptual, Imaginative`,
        rightPoleText: `Grounded, Practical`,
        id: uuidv4(),
        value: data.grounded_result,
        optimalLow: data.grounded_optimal_low,
        optimalHigh: data.grounded_optimal_high,
        adjustedValue: data.grounded_adjusted_result,
        implications: getImplications('m')
      },
      {
        leftPoleText: `Relaxed, Satisfied`,
        rightPoleText: `Urgent, Impatient`,
        id: uuidv4(),
        value: data.urgent_result,
        optimalLow: data.urgent_optimal_low,
        optimalHigh: data.urgent_optimal_high,
        adjustedValue: data.urgent_adjusted_result,
        implications: getImplications('q4')
      },
      {
        leftPoleText: `Skeptical, Pessimistic`,
        rightPoleText: `Trusting, Optimistic`,
        id: uuidv4(),
        value: data.trusting_result,
        optimalLow: data.trusting_optimal_low,
        optimalHigh: data.trusting_optimal_high,
        adjustedValue: data.trusting_adjusted_result,
        implications: getImplications('l')
      },
      {
        leftPoleText: `Expedient, Pragmatic`,
        rightPoleText: `Rule-oriented, Compliant`,
        id: uuidv4(),
        value: data.compliant_result,
        optimalLow: data.compliant_optimal_low,
        optimalHigh: data.compliant_optimal_high,
        adjustedValue: data.compliant_adjusted_result,
        implications: getImplications('g')
      },
      {
        leftPoleText: `Flexible, Disorderly`,
        rightPoleText: `Detailed, Meticulous`,
        id: uuidv4(),
        value: data.detailed_result,
        optimalLow: data.detailed_optimal_low,
        optimalHigh: data.detailed_optimal_high,
        adjustedValue: data.detailed_adjusted_result,
        implications: getImplications('q3')
      }
    ];
  }

  return [
    {
      leftPoleText: `Shy, Aloof`,
      rightPoleText: `Bold, Engaging`,
      id: uuidv4(),
      value: data.boldness_result,
      optimalLow: data.boldness_optimal_low,
      optimalHigh: data.boldness_optimal_high,
      adjustedValue: data.boldness_adjusted_result,
      implications: getImplications('h')
    },
    {
      leftPoleText: `Traditional, Conventional`,
      rightPoleText: `Open-minded, Intuitive`,
      id: uuidv4(),
      value: data.intuitive_result,
      optimalLow: data.intuitive_optimal_low,
      optimalHigh: data.intuitive_optimal_high,
      adjustedValue: data.intuitive_adjusted_result,
      implications: getImplications('q1')
    },
    {
      leftPoleText: `Deferential, Accommodating`,
      rightPoleText: `Dominant, Assertive`,
      id: uuidv4(),
      value: data.dominant_result,
      optimalLow: data.dominant_optimal_low,
      optimalHigh: data.dominant_optimal_high,
      adjustedValue: data.dominant_adjusted_result,
      implications: getImplications('e')
    },
    {
      leftPoleText: `Reactive, Erratic`,
      rightPoleText: `Stable, Steady`,
      id: uuidv4(),
      value: data.stable_result,
      optimalLow: data.stable_optimal_low,
      optimalHigh: data.stable_optimal_high,
      adjustedValue: data.stable_adjusted_result,
      implications: getImplications('c')
    },
    {
      leftPoleText: `Serious, Stoic`,
      rightPoleText: `Lively, Enthusiastic`,
      id: uuidv4(),
      value: data.serious_result,
      optimalLow: data.serious_optimal_low,
      optimalHigh: data.serious_optimal_high,
      adjustedValue: data.serious_adjusted_result,
      implications: getImplications('f')
    }
  ];
}
