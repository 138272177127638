import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';
import RequestSurveyInvite from './RequestSurveyInvite';
import Table from './Table';

import { SET_TOAST } from 'duck/toast';

import './index.scss';

function SurveyInvites({
  admin,
  companyId,
  companyName,
  dispatch,
  employeeId,
  employeeName,
  reportsEnabled
}) {
  const [isOpen, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [surveys, setSurveys] = useState([]);

  function onInviteSuccess() {
    setRefresh(!refresh);
    setOpen(false);

    dispatch({
      type: SET_TOAST,
      payload: {
        positive: true,
        message: 'Invite sent for approval!'
      }
    });
  }

  function onInviteFail() {
    // reload surveys here...
    setRefresh(!refresh);
  }

  return (
    <Wrapped
      title={!admin ? 'Surveys' : 'Evaluations'}
      button={
        !admin ? (
          <ToggleButton
            closedText="Request Survey"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
    >
      <RequestSurveyInvite
        companyId={companyId}
        companyName={companyName}
        employeeName={employeeName}
        employeeId={employeeId}
        open={isOpen}
        onClose={() => setOpen(false)}
        onInviteFail={onInviteFail}
        onInviteSuccess={onInviteSuccess}
        remoteCodes={surveys.filter((f) => f.approval_status < 2)}
      />
      <Table
        refresh={refresh}
        employeeId={employeeId}
        onLoad={(d) => setSurveys(d)}
        reportsEnabled={reportsEnabled}
        admin={admin}
      />
    </Wrapped>
  );
}

SurveyInvites.defaultProps = {
  admin: false,
  companyId: null,
  companyName: '',
  employeeId: null,
  employeeName: '',
  reportsEnabled: []
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SurveyInvites);
