import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaMinusCircle, FaPlusCircle, FaPaperPlane } from 'react-icons/fa';
import { Wrapped } from 'components/Layouts';
import { SET_TOAST } from 'duck/toast';

import getLogoUrl from '@util/formatters/getLogoUrl';

const iconProps = {
  size: '20px'
};

function CompanyManagement({
  companyManagerId,
  managedCompanies,
  fetchData,
  dispatch
}) {
  const [isLoading, setLoading] = useState(true);
  const [activeCompanies, setActiveCompanies] = useState([
    ...managedCompanies.sort((a, b) => (a.name > b.name ? 1 : -1))
  ]);
  const [availableCompanies, setAvailableCompanies] = useState([]);

  useEffect(() => {
    fetchAvailableCompanies();
  }, []);

  useEffect(() => {
    setActiveCompanies(managedCompanies);
  });

  if (!companyManagerId) return null;

  function fetchAvailableCompanies() {
    setLoading(true);

    axios
      .get(`/company-list/`)
      .then(({ data }) => {
        setAvailableCompanies(
          data.companies
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter((c) => !managedCompanies.find((a) => a.id === c.id))
        );
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setAvailableCompanies([]);
      });
  }

  function onCompanyAdd(companyId) {
    if (!companyId) return;

    const postData = {
      company_manager_id: companyManagerId,
      company_ids: [companyId]
    };

    setLoading(true);

    axios
      .post(`/add-manager-to-company/`, postData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Manager Added!'
          }
        });
      })
      .then(() => fetchData())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  function onCompanyRemove(companyId) {
    if (!companyId) return;

    const postData = {
      company_manager_id: companyManagerId,
      company_id: companyId
    };

    setLoading(true);

    axios
      .post(`/delete-manager-from-company/`, postData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Manager Removed!'
          }
        });
      })
      .then(() => fetchData())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  function onResendClick(companyId) {
    if (!companyId) return;

    const postData = {
      company_manager_id: companyManagerId,
      company_id: companyId
    };

    setLoading(true);

    axios
      .post(`/resend-manager-invite/`, postData)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Invite Sent!'
          }
        });
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  const availableCompanyOptions = availableCompanies.filter(
    (c) => !managedCompanies.find((a) => a.id === c.id)
  );

  return (
    <Wrapped title="Companies Managed">
      <div className="manager-company-management">
        <div className="wrapper">
          <div className="active-companies">
            <h2>Active Companies ({activeCompanies.length})</h2>
            <div className="companies">
              {activeCompanies.map((company) => (
                <div className="company-item" key={company.id}>
                  {company.logo_url && (
                    <img
                      src={getLogoUrl(company.logo_url)}
                      alt={company.name}
                    />
                  )}
                  <div className="meta">
                    <p>
                      <b>{company.name}</b>
                    </p>
                  </div>
                  <div className="actions">
                    <button
                      title="Resend Invite"
                      disabled={isLoading}
                      onClick={() => onResendClick(company.id)}
                    >
                      <FaPaperPlane {...iconProps} color="#041847" />
                    </button>
                    <button
                      onClick={() => onCompanyRemove(company.id)}
                      disabled={isLoading}
                    >
                      <FaMinusCircle color="#F92C5F" {...iconProps} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="available-companies">
            <h2>Available Companies ({availableCompanyOptions.length})</h2>
            <div className="companies">
              {availableCompanyOptions.map((company) => (
                <div className="company-item" key={company.id}>
                  {company.logo_url && (
                    <img
                      src={getLogoUrl(company.logo_url)}
                      alt={company.name}
                    />
                  )}
                  <div className="meta">
                    <p>
                      <b>{company.name}</b>
                    </p>
                  </div>
                  <div className="actions">
                    <button
                      onClick={() => onCompanyAdd(company.id)}
                      disabled={isLoading}
                    >
                      <FaPlusCircle color="#009912" {...iconProps} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapped>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyManagement);

CompanyManagement.defaultProps = {
  companyManagerId: null,
  managedCompanies: []
};
