import React from 'react';
import axios from 'axios';

export default function CurrentTags({ tags, profileId, fetchData }) {
  function onRemove(id) {
    axios
      .post(`/untag-op/`, { tag_id: id, occupational_profile_id: profileId })
      .then(() => fetchData())
      .catch((err) => {});
  }

  return (
    <div className="tag-buttons">
      {tags
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ name, id }) => (
          <button key={id} className="tag-button" onClick={() => onRemove(id)}>
            {name}
          </button>
        ))}
      {tags.length === 0 && (
        <p style={{ marginTop: '10px' }}>
          <b>No tags yet! Add some above.</b>
        </p>
      )}
    </div>
  );
}

CurrentTags.defaultProps = {
  profileId: null,
  tags: []
};
