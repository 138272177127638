import Code from './Code';
import ForgotPassword from './ForgotPassword';
import HeadlessSession from './HeadlessSession';
import Login from './Login';
import NotFound from './NotFound';
import AuthWrapper from './AuthWrapper';
import PartnerIndex from './PartnerIndex';

import './index.scss';

export {
  Code,
  ForgotPassword,
  HeadlessSession,
  Login,
  NotFound,
  AuthWrapper,
  PartnerIndex
};
