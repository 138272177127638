import React from 'react';
import { ReportLayout } from 'components/Layouts';
import CompareReport from 'components/Reports/CompareReport';

export default function CompareIndex(props) {
  return (
    <ReportLayout>
      <CompareReport admin />
    </ReportLayout>
  );
}
