import React, { useState } from 'react';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';
import { EmployeeForm, Table } from 'components/Employee';

export default function EmployeeIndex(props) {
  const [isOpen, setOpen] = useState(false);

  function onCreate(employeeId) {
    setOpen(false);
    props.history.push(`/employees/${employeeId}`);
  }

  return (
    <Wrapped
      title="Employees"
      button={
        <ToggleButton
          closedText="Add Employee"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <EmployeeForm onClose={() => setOpen(false)} onSubmit={onCreate} />
      ) : (
        <Table isCompanyManager />
      )}
    </Wrapped>
  );
}
