import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Wrapped } from 'components/Layouts';

import { FaCircle } from 'react-icons/fa';

import { IconFilterButtons } from 'components/shared/Buttons';
import AddedSurveys from './AddedSurveys';
import AvailableSurveys from './AvailableSurveys';

const addedFilterButtons = [
  { text: 'Added', value: true, icon: <FaCircle /> },
  { text: 'Available', value: false, icon: <FaCircle /> }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

export default function SurveyManagement({ companyId }) {
  const [addedView, setAddedView] = useState(true);
  const [status, setStatus] = useState(0);
  const [companySurveys, setCompanySurveys] = useState([]);

  useEffect(() => {
    fetchCompanySurveys();
  }, []);

  useEffect(() => {
    fetchCompanySurveys();
  }, [status]);

  function fetchCompanySurveys() {
    if (!companyId) return;

    axios
      .post(`/companies/${companyId}/retrieve-surveys/`, {
        company_id: companyId,
        status
      })
      .then(({ data }) => {
        setCompanySurveys(data.surveys);
      })
      .catch((err) => {});
  }

  function onAddedClick(added) {
    setAddedView(added);
  }

  function onAddClick(surveyId) {
    if (!surveyId) return;

    axios
      .post(`/companies/${companyId}/surveys/`, {
        company_id: companyId,
        survey_id: surveyId
      })
      .then(() => {
        fetchCompanySurveys();
      })
      .catch((err) => {});
  }

  function onRemoveClick(surveyId) {
    if (!surveyId) return;

    axios
      .post(`/companies/${companyId}/remove-survey/`, {
        company_id: companyId,
        survey_id: surveyId
      })
      .then(() => {
        fetchCompanySurveys();
      })
      .catch((err) => {});
  }

  function onStatusClick(status) {
    setStatus(status);
  }

  if (!companyId) return null;
  return (
    <Wrapped title="Survey Management">
      <div className="segment">
        <div className="filter-button-header">
          <IconFilterButtons
            active={addedView}
            buttons={addedFilterButtons}
            // loading={this.state.loading}
            onClick={onAddedClick}
            text="Availability"
          />
          {addedView && (
            <IconFilterButtons
              active={status}
              buttons={statusFilterButtons}
              // loading={this.state.loading}
              onClick={onStatusClick}
              text="Status"
            />
          )}
        </div>
        {addedView && (
          <AddedSurveys
            surveys={companySurveys}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            adding={status === 1}
          />
        )}
        {!addedView && (
          <AvailableSurveys
            onAddClick={onAddClick}
            addedSurveyIds={companySurveys.map((s) => s.id)}
          />
        )}
      </div>
    </Wrapped>
  );
}
