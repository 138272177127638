import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.strategic_mindset_factors) return [];

  const best = output.strategic_mindset_factors.best
    ? Object.keys(output.strategic_mindset_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.strategic,
        value: output.strategic_mindset_factors.best[b]
      }))
    : [];
  const worst = output.strategic_mindset_factors.worst
    ? Object.keys(output.strategic_mindset_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.strategic,
        value: output.strategic_mindset_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Strategic Mindset',
      value: output.strategic_mindset,
      behavior_value: output.pan_result.strategic_mindset_score,
      cls: groupClasses.strategic,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.strategic,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.strategic,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.strategic,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Roles',
    //   cls: groupClasses.strategic,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Duties',
    //   cls: groupClasses.strategic,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Strategic-Mindset-Courses.aspx'
  }));
}
