import React from 'react';
import {
  FaBalanceScale,
  FaBinoculars,
  FaCogs,
  FaPuzzlePiece,
  FaTrophy
} from 'react-icons/fa';
import {
  AssetsAndLimitations,
  AssuranceAndReceptivity,
  ClearModel,
  Derailer,
  Footer,
  FunctionalFitGraph,
  Intro,
  PersonaBoxes,
  PersonaGraph,
  SectionTitle,
  Slider,
  WorkMotivesGraph
} from './components';
import derailerDataFormatter from './helpers/derailerDataFormatter';
import functionalFitDataFormatter from './helpers/functionalFitDataFormatter';
import personaGraphDataFormatter from './helpers/personaGraphDataFormatter';
import sliderDataFormatter from './helpers/sliderDataFormatter';
import workMotivesDataFormatter from './helpers/workMotivesDataFormatter';

import PrintButton from 'components/Reports/components/PdfDownload/PrintButton';

export default function CoachReport({ data, children, pathname }) {
  const { company } = data;
  const derailerData = derailerDataFormatter(data);
  const functionalFitData = functionalFitDataFormatter(data);
  const personaGraphData = personaGraphDataFormatter(data);
  const peopleOrientationData = sliderDataFormatter(data);
  const processDisciplineData = sliderDataFormatter(data, 'processDiscipline');
  const workMotivesData = workMotivesDataFormatter(data);
  const candidateName = data.employee ? data.employee.full_name : 'Candidate';
  const activePersona = data.calculation_container.lcl_likeness;

  return (
    <div>
      <PrintButton
        label="Download"
        id="coachReport"
        filename={`${candidateName} Coach Report`}
        pathname={pathname}
      />
      <div id="coachReport">
        <div className="page">
          <Intro
            fullName={candidateName}
            companyName={company.name}
            reportType="Coach"
            reportSubtitle="For onboarding and development"
          />
        </div>
        <ClearModel />
        <SectionTitle
          title="Summary"
          text={
            <React.Fragment>
              <p>
                Assessment results suggest that, relative to the{' '}
                <b>'{data.profile_name}'</b>, {candidateName}'s assets and
                limitations include:
              </p>
            </React.Fragment>
          }
        />
        <AssetsAndLimitations
          assets={data.text.summary_assessment_results}
          limitations={data.text.summary_developmental_limitations}
        />
        <SectionTitle
          title="Cognition"
          icon={<FaCogs />}
          text={
            <React.Fragment>
              <p>
                Among all the factors studied in relation to leadership
                efficacy, <b>Cognition</b> (e.g., intelligence, logic,
                problem-solving, abstract reasoning) is the most important.
              </p>
              <p>
                Below is a graph of {candidateName}'s results on the Cognitive
                task compared with those of the '{data.profile_name}'.
                Implications of assessment outcomes are also noted.
              </p>
            </React.Fragment>
          }
        />
        <Slider
          leftPoleText="Lower"
          rightPoleText="Higher"
          value={data.calculation_container.cog_score}
          optimalHigh={0}
          optimalLow={0}
        />
        <div className="page"></div>
        <SectionTitle
          title="Leadership Expression"
          icon={<FaTrophy />}
          subtitle="People Orientation"
          text={
            <React.Fragment>
              <p>
                <b>People Orientation</b> is the primary factor influencing
                Leadership Expression. Its key components are social boldness,
                openness to change, dominance, emotional stability, and
                liveliness. As a consequence, those with higher scores in this
                area tend to be engaging, openminded, assertive, steady, and
                enthusiastic.
              </p>
              <p>
                The following graphs compare {candidateName}'s scores in these
                key components with the "{data.profile_name}."
              </p>
            </React.Fragment>
          }
        />
        {peopleOrientationData.map(
          ({
            id,
            leftPoleText,
            rightPoleText,
            value,
            optimalHigh,
            optimalLow,
            adjustedValue,
            implications
          }) => (
            <Slider
              key={id}
              leftPoleText={leftPoleText}
              rightPoleText={rightPoleText}
              value={value}
              optimalHigh={optimalHigh}
              optimalLow={optimalLow}
              adjustedValue={adjustedValue}
              implications={implications}
            />
          )
        )}
        <SectionTitle
          subtitle="Process Discipline"
          text={
            <React.Fragment>
              <p>
                <b>Process Discipline</b> is another major factor in Leadership
                Expression. Its key components include groundedness, tension,
                relatively less trust, rule-consciousness, and perfectionism. As
                a result, those with higher scores in this area are typically
                practical, urgent, cautious, conscientious, and meticulous.
              </p>
              <p>
                <b>
                  The following graphs compare {candidateName}'s scores in these
                  key components with the '{data.profile_name}'.
                </b>
              </p>
            </React.Fragment>
          }
        />
        {processDisciplineData.map(
          ({
            id,
            leftPoleText,
            rightPoleText,
            value,
            optimalHigh,
            optimalLow,
            adjustedValue,
            implications
          }) => (
            <Slider
              key={id}
              leftPoleText={leftPoleText}
              rightPoleText={rightPoleText}
              value={value}
              optimalHigh={optimalHigh}
              optimalLow={optimalLow}
              adjustedValue={adjustedValue}
              implications={implications}
            />
          )
        )}
        <div className="page"></div>
        <SectionTitle
          subtitle="Persona"
          text={
            <React.Fragment>
              <p>
                Plotting <b>People Orientation</b> and <b>Process Discipline</b>{' '}
                reveals this individual's 'Leader Persona'. There are eight (8)
                possible Leader Personas, based upon the degrees of the two
                component factors. A 'High Performance Leadership' range is
                represented by the cube in the plot below. This range captures
                about two-thirds of the best leaders. Any Leader Persona can be
                a high performer, but 'Competitors', for example, tend to have
                more high perfomers because they are the most prevalent Leader
                Persona.
              </p>
            </React.Fragment>
          }
        />
        <PersonaGraph
          candidateName={candidateName}
          candidate={personaGraphData.candidate}
          op={personaGraphData.op}
          lcl={personaGraphData.lcl}
        />
        <PersonaBoxes activePersona={activePersona} />
        <div className="page"></div>
        <SectionTitle
          title="Assurance & Receptivity"
          icon={<FaBalanceScale />}
          text={
            <React.Fragment>
              <p>
                <b>Assurance</b> is the combination of emotional stability,
                self-confidence, and calmness. It involves holding steady under
                adversity, remaining confident despite setbacks, and maintaining
                composure under pressure. Much like the Cognition factor
                presented earlier, Assurance is a staple characteristic of the
                vast majority of leaders, particularly the best ones.
              </p>
              <p>
                <b>Receptivity</b> is the combination of sensitivity to nuance,
                conceptual perspective, and open-mindedness. It includes
                attending to subtleties, thinking beyond the 'here and now', and
                welcoming input from a variety of sources. Business realities
                require leaders to be attuned to the future needs of the
                enterprise, necessitating intuitive and receptive perspectives.
              </p>
            </React.Fragment>
          }
        />
        <AssuranceAndReceptivity
          candidateName={candidateName}
          arType={data.calculation_container.assurance_receptivity_type}
          assuranceValue={data.calculation_container.assurance}
          receptivityValue={data.calculation_container.receptivity}
        />
        <SectionTitle
          title="Supplementary Info"
          icon={<FaCogs />}
          text={
            <React.Fragment>
              <p>
                Supplemental information in the form of Key Watchouts (if any)
                and Work Motives follow.
              </p>
            </React.Fragment>
          }
        />
        <div className="page"></div>
        <SectionTitle title="Functional Fit" icon={<FaPuzzlePiece />} />
        <FunctionalFitGraph data={functionalFitData} />
        <SectionTitle
          title="Work Motives"
          icon={<FaCogs />}
          text={
            <React.Fragment>
              <p>
                Those similar to the {data.profile_name} exhibit {data.holland}.
              </p>
            </React.Fragment>
          }
        />
        <WorkMotivesGraph data={workMotivesData} />
        <div className="page"></div>
        <SectionTitle
          title="Watchouts"
          icon={<FaBinoculars />}
          text={
            <React.Fragment>
              <p>
                <b>Watchouts</b> are sets of characteristics that block, hinder,
                hamper, restrict, thwart, or otherwise derail performance, rank,
                persistence, and behavior in the workplace. Such watchouts may
                be thought of as representing the 'dark side' of humanity and
                leadership, where confidence turns to arrogance, and skepticism
                becomes suspicion. Developmentally, understanding these
                watchouts is critical in order to navigate successfully both
                intrapersonal and interpersonal domains of leadership.
              </p>
              <p>
                {candidateName}'s potential Risk levels associated with these
                watchouts are presented below.{' '}
                <b>
                  Icons presented in <span className="green">green</span>{' '}
                  indicate low risk for associated Watchout. Icons presented in{' '}
                  <span className="red">red</span> indicate elevated risk.
                </b>
              </p>
            </React.Fragment>
          }
        />
        {derailerData.map(({ id, name, icon, text, triggered }) => (
          <Derailer
            key={id}
            name={name}
            icon={icon}
            text={text}
            triggered={triggered}
          />
        ))}
        <Footer />
      </div>
    </div>
  );
}
