import React from 'react';
import * as Yup from 'yup';

import BasicForm from 'components/shared/Forms/BasicForm';

export default function CandidateForm(props) {
  const fields = [
    {
      name: 'first_name',
      label: 'First Name',
      initialValue: props.firstName,
      required: true,
      schema: () => (
        Yup.string()
          .required('Required')
      )
    },
    {
      name: 'middle_name',
      label: 'Middle Name',
      initialValue: props.middleName,
      required: false,
      schema: () => null,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      initialValue: props.lastName,
      required: true,
      schema: () => (
        Yup.string()
          .required('Required')
      )
    },
  ];

  return (
    <div className="segment">
      <h2>Complete the following to take your test</h2>
      <BasicForm
        buttonStyle={{ width: '100%' }}
        buttonText="Next"
        fields={fields}
        loading={props.loading}
        onSubmit={props.onSubmit}
      />
    </div>
  );
}