import React from 'react';
import { SectionTitle } from './';
import { FaLongArrowAltRight } from 'react-icons/fa';

export default function AssuranceAndReceptivity({
  arType,
  candidateName,
  assuranceValue,
  receptivityValue
}) {
  const assurance = assuranceValue - 1;
  const receptivity = receptivityValue - 1;

  let leftVal = assurance.toString();
  leftVal = parseFloat(leftVal) * 66.66 - 11.5;

  let bottomVal = receptivity.toString();
  bottomVal = parseFloat(bottomVal) * 33.33 - 15.5;

  const markerStyle = {
    position: 'absolute',
    bottom: `${bottomVal}px`,
    left: `${leftVal}px`,
    fontSize: '30px'
  };

  return (
    <div>
      <div className="assurance-graph-container">
        <p className="axis-x">
          Assurance Score <FaLongArrowAltRight />
        </p>
        <p className="axis-y">
          Receptivity <span style={{ marginLeft: '5px' }}>Score</span>{' '}
          <FaLongArrowAltRight />
        </p>
        <div className="assurance-graph">
          <div className="assurance-graph-table">
            <span className="ar-marker" style={markerStyle}>
              &#x2666;
            </span>
            <div className="assurance-graph-row">
              <div>
                <h3>
                  REACTIVE WHIMSY
                  <br />
                  'Gullible'
                </h3>
              </div>
              <div>
                <h3>
                  CONFIDENT WHIMSY
                  <br />
                  'Unbridled'
                </h3>
              </div>
            </div>
            <div className="assurance-graph-row">
              <div>
                <h3>
                  REACTIVE RECEPTIVITY
                  <br />
                  'Naïve'
                </h3>
              </div>
              <div className="assurance-selected">
                <h3>
                  ANCHORED RECEPTIVITY
                  <br />
                  'Optimal'
                </h3>
              </div>
            </div>
            <div className="assurance-graph-row">
              <div>
                <h3>
                  REACTIVE RESOLVE
                  <br />
                  'Reactionary'
                </h3>
              </div>
              <div>
                <h3>
                  CONFIDENT RESOLVE
                  <br />
                  'Resistant'
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionTitle
        text={
          <React.Fragment>
            <p>
              Despite the desirability of Receptivity in terms of dynamic
              leadership, if it eclipses Assurance, then it suggests an
              individual who may over-commit and over-promise, or who may have
              grander ideas than are practical. It is not just Receptivity in
              isolation that makes for a great leader, but{' '}
              <b>Anchored Receptivity</b> -- innovative thinking grounded in
              business realities. Moreover, the relationship between Assurance
              and Receptivity can provide insight about an individual's
              coachability.
            </p>
            <p>
              Those with {candidateName}'s Assurance and Receptivity score
              pattern exhibit
              {arType === 'reactive_whimsy' && (
                <span>
                  reactive whimsy. As a result, they may be characterized as
                  gullible. They are not particularly coachable because, despite
                  their receptivity, they never develop confidence in their
                  abilities.
                </span>
              )}
              {arType === 'confident_resolve' && (
                <span>
                  confident resolve. They are both comfortable with and
                  confident in what they know and are, thus, less likely to
                  change. They may be characterized as stubborn at times and
                  could be resistant to coaching.
                </span>
              )}
              {arType === 'confident_resolve' && (
                <span>
                  reactive resolve. They may be characterized as reactionary
                  and, having both low assurance and low receptivity, they are
                  likely to avoid divergent thinking and, thus, be resistant to
                  coaching.
                </span>
              )}
              {arType === 'confident_whimsy' && (
                <span>
                  confident whimsy. Despite their unbridled curiosity, they are
                  likely to be only moderately coachable because of their
                  intense interests in new and different ideas, resulting in
                  potential lack of focus.
                </span>
              )}
              {arType === 'solid_coachability' && (
                <span>
                  both sufficient confidence and open-mindedness to possess
                  solid 'Coachability'. As a result, they are likely to balance
                  their receptive nature with confident practicality and to
                  embrace coaching.
                </span>
              )}
              {arType === 'reactive_receptivity' && (
                <span>
                  reactive receptivity. They may be characterized as naïve at
                  times and are not particularly coachable because, despite
                  their receptivity, they seldom become confident in their
                  capabilities.
                </span>
              )}
            </p>
          </React.Fragment>
        }
      />
    </div>
  );
}

AssuranceAndReceptivity.defaultProps = {
  arType: '',
  assuranceValue: 0,
  receptivityValue: 0,
  candidateName: ''
};
