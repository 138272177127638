import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

import Fade from 'react-reveal/Fade';

import { Message } from '../';

class ForgotPassword extends Component {
  state = {
    email: '',
    error: null,
    success: false
  };

  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ error: null });

    const postData = {
      email: this.state.email
    };

    axios
      .post(`/forgot-password/`, postData)
      .then(({ data }) => {
        this.setState({ success: true });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message || `There was a problem.`
        });
      });
  };

  render() {
    const { error } = this.state;

    if (this.state.success) {
      return (
        <div>
          <FaCheckCircle size={90} color="#05BF7D" />
          <h1>Email Sent!</h1>
          <p>
            Check your {this.state.email} inbox for password reset instructions.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h1>Forgot Password?</h1>
        <p>Enter your email below to reset your password.</p>
        <form className="auth-form">
          <input
            placeholder="Email"
            type="text"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          {error && (
            <Fade bottom>
              <Message text={error} negative />
            </Fade>
          )}
          <button disabled={!this.state.email} onClick={this.onSubmit}>
            Send
          </button>
        </form>
        <div className="auth-other">
          <Link to="/">Remember Password?</Link>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
