import React from 'react';
import { Wrapped } from 'components/Layouts';

import { Table } from 'components/CompanyManager';

export default function ManagerIndex(props) {
  return (
    <Wrapped title="Managers">
      <Table />
    </Wrapped>
  );
}
