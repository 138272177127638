import React, { Component } from 'react';
import { Wrapped } from '../Layouts';

class Dashboard extends Component {
  render() {
    return (
      <div>
        <Wrapped title="Dashboard Coming Soon..." />
      </div>
    );
  }
}

export default Dashboard;
