import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.navigates_complexity_factors) return [];

  const best = output.navigates_complexity_factors.best
    ? Object.keys(output.navigates_complexity_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.navigates,
        value: output.navigates_complexity_factors.best[b]
      }))
    : [];
  const worst = output.navigates_complexity_factors.worst
    ? Object.keys(output.navigates_complexity_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.navigates,
        value: output.navigates_complexity_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Navigates Complexity',
      value: output.navigates_complexity,
      behavior_value: output.pan_result.navigates_complexity_score,
      cls: groupClasses.navigates,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Solution Scope',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Challenges',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Insights',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Information',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Processes',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Resources',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Offerings',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Educating',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Work-Life Balance',
    //   cls: groupClasses.navigates,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Navigates-Complexity-Courses.aspx'
  }));
}
