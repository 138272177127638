export const SET_MANAGER = 'SET_MANAGER';

function managerReducer(state, action) {
  switch (action.type) {
    case SET_MANAGER:
      return {
        ...state,
        manager: action.payload
      };
    default:
      return state;
  }

  return state;
}

export default managerReducer;
