import React from 'react';
import { Wrapped } from 'components/Layouts';
import { Table } from 'components/Employee';

export default function EmployeeIndex(props) {
  return (
    <Wrapped title="Employees">
      <Table allCompanies />
    </Wrapped>
  );
}
