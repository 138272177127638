import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Modal, Button } from 'semantic-ui-react';

export default function EmployeeModal({ admin, id, name, onClose, onSelect }) {
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);

  if (!id) return null;

  useEffect(() => {
    fetchContainers();
  }, []);

  function fetchContainers() {
    axios
      .post(`/calculation-containers-for-compare/`, {
        company_employee_id: id,
        admin: admin
      })
      .then(({ data }) => {
        setOptions(
          data.map((d) => ({
            text: `${d.name ? `${d.name} - ` : ''}${d.created_at_formatted}`,
            value: d.id,
            key: d.id
          }))
        );
      })
      .catch((err) => {});
  }

  function onContainerSelect(e, d) {
    const container = options.find((c) => c.value === d.value);
    if (!container) return;

    setSelected(container);
  }

  return (
    <Modal open onClose={onClose} size="tiny">
      <Modal.Header content={`Choose a report for ${name}`} />
      <Modal.Content>
        <Form>
          <Form.Select
            onChange={onContainerSelect}
            label={`Choose a report for ${name}`}
            options={options}
            placeholder="Select one..."
            value={selected ? selected.value : null}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button size="tiny" content="Close" basic onClick={onClose} />
        <Button
          size="tiny"
          content="Add"
          disabled={!selected}
          primary
          onClick={() => onSelect({ name: selected.text, id: selected.value })}
        />
      </Modal.Actions>
    </Modal>
  );
}

EmployeeModal.defaultProps = {
  admin: false,
  id: null,
  name: ''
};
