import React, { useState } from 'react';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

import { EmptyList } from 'components/shared/List';

import AddForm from './AddForm';
import ContainerItem from './ContainerItem';

import './index.scss';

export default function CalculationContainers({
  surveyVariety,
  surveyId,
  employeeId,
  companyId,
  containers,
  fetchData,
  remoteCode,
  panResultId
}) {
  const [isOpen, setOpen] = useState(false);

  function onCreate() {
    fetchData();
    setOpen(false);
  }

  return (
    <Wrapped
      title="Calculation Containers"
      button={
        surveyVariety !== 3 ? (
          <ToggleButton
            closedText="Add Calculation Container"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
    >
      {isOpen ? (
        <AddForm panResultId={panResultId} fetchData={onCreate} />
      ) : (
        <div>
          {containers.length === 0 && (
            <div className="segment">
              <EmptyList
                header="No Calculation Containers"
                text="Add one above"
              />
            </div>
          )}
          {containers.map((container, i) => (
            <ContainerItem
              key={container.id}
              id={container.id}
              name={container.name || `Container ${i + 1}`}
              fetchContainers={fetchData}
              createdAt={new Date(container.created_at).toLocaleString()}
              employeeId={employeeId}
              companyId={companyId}
              defaultOpen={surveyVariety === 3}
              surveyId={surveyId}
              surveyVariety={surveyVariety}
              remoteCode={remoteCode}
            />
          ))}
        </div>
      )}
    </Wrapped>
  );
}

CalculationContainers.defaultProps = {
  surveyVariety: null,
  containers: [],
  employeeId: null,
  companyId: null,
  remoteCode: null
};
