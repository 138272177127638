import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Questions from './Questions';

import './index.scss';

class Assigner extends Component {
  state = {
    activeQuestions: [],
    availableQuestions: [],
    loading: true
  };

  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.refresh !== this.props.refresh) {
      this.fetchData();
    }
  };

  fetchData = () => {
    this.setState({ loading: true });

    axios
      .get(`/versions/${this.props.versionId}/question-bank/`)
      .then(({ data }) => {
        this.setState({
          availableQuestions: data.questions
        });
      })
      .then(() => this.fetchVersionQuestions())
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  fetchVersionQuestions = () => {
    axios
      .get(`/versions/${this.props.versionId}/questions/`)
      .then(({ data }) => {
        this.setState({
          activeQuestions: data.questions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          ),
          loading: false
        });
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  onQuestionAdd = (questionId) => {
    let position = 0;

    if (this.state.activeQuestions.length) {
      position =
        Math.max(...this.state.activeQuestions.map((q) => q.position)) + 1;
    }

    this.setState({ loading: true });

    axios
      .post(`/versions/${this.props.versionId}/questions/`, {
        version_id: this.props.versionId,
        question_id: questionId,
        position
      })
      .then(({ data }) => {
        this.setState({
          loading: false
        });
      })
      .then(() => this.fetchData())
      .catch((err) => {
        this.setState({
          loading: false
        });
      });
  };

  onQuestionAddAndReposition = (questionId, questionsToPosition) => {
    axios
      .post(`/versions/${this.props.versionId}/questions/`, {
        version_id: this.props.versionId,
        question_id: questionId
      })
      .then(({ data }) => {
        let modifiedQuestionsToPosition = [...questionsToPosition];
        let addedQuestion = modifiedQuestionsToPosition.find(
          (q) => q.recentlyAdded
        );

        if (!addedQuestion) return;
        addedQuestion.id = data.id;
        return modifiedQuestionsToPosition;
      })
      .then((questions) => this.onReorder(questions))
      .catch((err) => {});
  };

  onQuestionRemove = (questionId) => {
    this.setState({ loading: true });

    axios
      .delete(`/questions/${questionId}/`)
      .then(() => {
        let activeQuestions = [...this.state.activeQuestions].filter(
          (q) => q.id !== parseInt(questionId)
        );
        activeQuestions.forEach((q, i) => (q.position = i));

        this.onReorder(
          activeQuestions.map((q) => ({ id: q.id, position: q.position }))
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.fetchData();
      });
  };

  onReorder = (questions) => {
    const postData = { questions, version_id: this.props.versionId };

    this.setState({ loading: true });

    axios
      .post(`/versions/${this.props.versionId}/reposition/`, postData)
      .then(() => this.fetchData())
      .catch((err) => {});
  };

  render() {
    const { activeQuestions, availableQuestions, loading } = this.state;
    const { disabled } = this.props;

    return (
      <div className="assigner">
        <div className="wrapper">
          <Questions
            activeQuestions={this.state.activeQuestions}
            actionsDisabled={disabled}
            availableQuestions={this.state.availableQuestions}
            loading={loading}
            onAdd={this.onQuestionAdd}
            onAddAndReposition={this.onQuestionAddAndReposition}
            onReorder={this.onReorder}
            onRemove={this.onQuestionRemove}
          />
        </div>
      </div>
    );
  }
}

export default Assigner;

Assigner.defaultProps = {
  disabled: false
};

Assigner.propTypes = {
  disabled: PropTypes.bool,
  versionId: PropTypes.number.isRequired
};
