export default function workMotivesDataFormatter(data) {
  return [
    Math.trunc(data.calculation_container.realistic_motive * 100),
    Math.trunc(data.calculation_container.investigative_motive * 100),
    Math.trunc(data.calculation_container.artistic_motive * 100),
    Math.trunc(data.calculation_container.social_motive * 100),
    Math.trunc(data.calculation_container.enterprising_motive * 100),
    Math.trunc(data.calculation_container.conventional_motive * 100)
  ];
}
