import React from 'react';
import { AssuranceAndReceptivityGraph } from './';

export default function CompareCoachability({ employees }) {
  return (
    <div>
      <AssuranceAndReceptivityGraph employees={employees} showNames />
    </div>
  );
}
