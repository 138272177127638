import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import axios from 'axios';

export default function EngagementReminder({
  surveyContainerId,
  lastReminderSent,
  onSuccess
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [lastSent, setLastSent] = useState(null);

  useEffect(() => {
    setLastSent(lastReminderSent);
  }, [lastReminderSent]);

  function onSubmit() {
    setLoading(true);
    setSuccess(false);

    const req = {
      survey_container_id: surveyContainerId
    };

    axios
      .post(`/send-engagement-survey-reminders/`, req)
      .then(() => {
        onSuccess();
        setSuccess(true);
        setLoading(false);
        setLastSent(new Date().toLocaleString());

        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      {lastSent && (
        <p style={{ fontWeight: '600' }}>
          Last reminders sent: {new Date(lastSent).toLocaleDateString()}
        </p>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          content="Send Reminder Emails"
          color="orange"
          icon="mail"
          labelPosition="right"
          onClick={onSubmit}
          loading={loading}
          size="small"
        />
        {success && (
          <p style={{ margin: '0 0 0 10px' }}>
            <Icon name="check circle" color="green" /> Reminder emails sent!
          </p>
        )}
      </div>
    </div>
  );
}

EngagementReminder.defaultProps = {
  lastReminderSent: null,
  surveyContainerId: null
};
