import React, { Component, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Wrapped } from 'components/Layouts';
import { ErrorView } from 'components/shared/Errors';
import { ActionButton } from 'components/shared/Buttons';
import { Summary } from 'components/SurveyRequest';

import { SET_TOAST } from 'duck/toast';

class SurveyRequestDetail extends Component {
  state = {
    approvalStatus: 0,
    closed: false,
    companyName: '',
    companyId: null,
    createdAt: new Date().toLocaleString(),
    employee: null,
    engagementSurveyName: '',
    error: null,
    is360: false,
    loading: true,
    released: false,
    requestedBy: '',
    survey: null,
    surveyContainerId: null,
    surveys: []
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchData();
  };

  fetchData = () => {
    this.setState({ loading: true });

    axios
      .get(`/survey-requests/${this.props.match.params.id}/`)
      .then(({ data }) => {
        this.setState({
          approvalStatus: data.approval_status,
          closed: data.closed,
          companyId: data.requested_for_formatted
            ? data.requested_for_formatted.id
            : null,
          companyName: data.requested_for_formatted
            ? data.requested_for_formatted.name
            : '',
          createdAt: new Date(data.created_at).toLocaleString(),
          employee: data.employee,
          engagementSurveyName: data.engagement_survey_name || '',
          is360: data.is_360,
          loading: false,
          released: data.released,
          requestedBy: data.requested_by
            ? data.requested_by.full_name
            : 'BCK Admin',
          survey: data.survey || null,
          surveys: data.surveys_info,
          surveyContainerId: data.survey_container_id
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message
            ? err.response.data.ui_message
            : 'Could not find the survey you were looking for',
          loading: false
        });
      });
  };

  onApprovalClick = (approve) => {
    const { id } = this.props.match.params;

    const postData = {
      approve,
      survey_invite_id: id
    };

    this.setState({ loading: true });

    axios
      .post(`/survey-requests/${id}/approval/`, postData)
      .then(() =>
        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: `Request ${approve ? 'Approved' : 'Denied'}!`
          }
        })
      )
      .then(() => this.fetchData())
      .catch((err) => {
        this.setState({ loading: false });

        this.props.dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  };

  setToast = (msg) => {
    this.props.dispatch({ type: SET_TOAST, payload: { ...msg } });
  };

  render() {
    const {
      employee,
      error,
      engagementSurveyName,
      survey,
      surveys
    } = this.state;

    const showDecisionButtons = this.state.approvalStatus === 0;
    const showResendButton = this.state.approvalStatus === 2;

    if (error) {
      return <ErrorView text={error} />;
    }

    return (
      <div>
        <Wrapped title={`Survey Request - Requested ${this.state.createdAt}`}>
          {this.state.is360 && (
            <div style={{ margin: '1.5em 0' }}>
              {['qa', 'dev'].includes(process.env.ENV) && (
                <AutoRespond
                  onSuccess={this.fetchData}
                  surveyContainerId={this.state.surveyContainerId}
                />
              )}
              <ActionButton
                text="View 360"
                style={{ marginLeft: '8px' }}
                onClick={() =>
                  (window.location = `/admin/360/${this.state.surveyContainerId}`)
                }
              />
              <Close360Button
                surveyContainerId={this.state.surveyContainerId}
                onSuccess={() => {
                  this.setToast({
                    positive: true,
                    message: `360 ${
                      this.state.closed ? 're-opened' : 'closed'
                    }!`
                  });
                  this.fetchData();
                }}
                closed={this.state.closed}
              />
              {this.state.closed && (
                <EmailButton
                  surveyContainerId={this.state.surveyContainerId}
                  released={this.state.released}
                  onSuccess={() => {
                    this.setToast({
                      positive: true,
                      message: !this.state.released
                        ? `Email sent!`
                        : 'Results rescinded!'
                    });
                    this.fetchData();
                  }}
                />
              )}
            </div>
          )}
          <div className="segment">
            {surveys.length > 0 && !engagementSurveyName && (
              <Summary
                companyName={
                  employee ? employee.company_name || 'No Company' : 'Unknown'
                }
                employeeName={employee ? employee.full_name : 'Unknown'}
                requesterName={this.state.requestedBy}
                companyName={this.state.companyName}
                surveys={surveys}
                showResend={showResendButton}
              />
            )}
            {this.state.engagementSurveyName && (
              <React.Fragment>
                <Summary
                  companyName={this.state.companyName}
                  companyId={this.state.companyId}
                  surveyName={this.state.engagementSurveyName}
                />
                {/* {showDecisionButtons && (
                  <p style={{ fontSize: '1.15em', marginTop: '2em' }}>
                    Clicking <b>Accept</b> will create{' '}
                    <b>{this.state.engagementSurveyName}</b> invites to{' '}
                    <b>all active employees</b> for{' '}
                    <b>{this.state.companyName}</b>.
                  </p>
                )} */}
              </React.Fragment>
            )}
            {showDecisionButtons && (
              <div style={{ display: 'flex', marginTop: '40px' }}>
                <ActionButton
                  text="Deny"
                  loading={this.state.loading}
                  negative
                  onClick={() => this.onApprovalClick(false)}
                  style={{ width: '50%', margin: '0 5px', fontWeight: 'bold' }}
                />
                <ActionButton
                  text="Accept"
                  loading={this.state.loading}
                  onClick={() => this.onApprovalClick(true)}
                  style={{ width: '50%', margin: '0 5px', fontWeight: 'bold' }}
                />
              </div>
            )}
            {!showDecisionButtons && (
              <div style={{ marginTop: '40px' }}>
                <p className="request-decision">
                  This survey request was{' '}
                  {this.state.approvalStatus === 1 ? 'denied' : ' approved'}. No
                  futher action required.
                </p>
              </div>
            )}
          </div>
        </Wrapped>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SurveyRequestDetail);

function AutoRespond({ surveyContainerId, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);

    axios
      .get(`/survey-containers/${surveyContainerId}/auto-respond/`)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  };

  return (
    <ActionButton text="Auto-respond" onClick={onClick} loading={loading} />
  );
}

function Close360Button({ closed, surveyContainerId, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);

    axios
      .get(`/survey-containers/${surveyContainerId}/toggle-close/`)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  };

  return (
    <ActionButton
      style={{ marginLeft: '8px' }}
      text={closed ? `Re-open 360` : 'Close 360'}
      onClick={onClick}
      loading={loading}
    />
  );
}

Close360Button.defaultProps = {
  closed: false
};

function EmailButton({ released, surveyContainerId, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);

    axios
      .post(`/survey-containers/${surveyContainerId}/release-survey/`, {
        survey_container_id: surveyContainerId
      })
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.ui_message);
      });
  };

  return (
    <ActionButton
      style={{ marginLeft: '8px' }}
      text={released ? 'Rescind release' : 'Release results'}
      onClick={onClick}
      loading={loading}
    />
  );
}

EmailButton.defaultProps = {
  released: false
};
