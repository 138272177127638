import React, { useState } from 'react';

import { Table, EmployeeUpload } from './';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

export default function EmployeeManagement({ companyId, companyName }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Employees"
      button={
        <ToggleButton
          closedText="Upload Employees"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <div className="segment">
          <EmployeeUpload
            companyId={companyId}
            companyName={companyName}
            onSuccess={() => setOpen(false)}
          />
        </div>
      ) : (
        <Table
          companyId={companyId}
          companyName={companyName}
          showCompany={false}
          showInvite={false}
        />
      )}
    </Wrapped>
  );
}
