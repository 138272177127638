import React from 'react';

export default function Calculate({ calcId, employeeId }) {
  if (!calcId) return null;

  return <div>Calculate</div>;
}

Calculate.defaultProps = {
  calcId: null
};
