import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';

import industries from '@util/data/industries';
import usStates from '@util/data/usStates';

import BasicForm from 'components/shared/Forms/BasicForm';
import { BasicButton } from 'components/shared/Buttons';

import { SET_TOAST } from 'duck/toast';

function CompanyForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      name: 'name',
      label: ' Name',
      initialValue: props.name,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'shortname',
      label: 'Shortname (for custom url...lowercase letters and numbers only)',
      initialValue: props.shortname,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'address_line_1',
      label: 'Address',
      initialValue: props.address1,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'address_line_2',
      label: 'Address Line 2',
      initialValue: props.address2,
      required: false,
      schema: () => null
    },
    {
      grouped: true,
      fields: [
        {
          name: 'city',
          label: 'City',
          initialValue: props.city,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'state_provence',
          label: 'State',
          initialValue: props.state,
          required: true,
          select: true,
          search: true,
          options: usStates,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'zip',
          label: 'Zip',
          initialValue: props.zip,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      name: 'country',
      label: 'Country',
      initialValue: props.country,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'target_industry',
      label: 'Industry',
      initialValue: props.targetIndustry,
      required: true,
      select: true,
      options: industries,
      schema: () => Yup.string().nullable().required('Required')
    },
    {
      name: 'hide_invites',
      label: 'Hide Invites',
      initialValue: props.hideInvites,
      required: true,
      select: true,
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      schema: () => Yup.string().nullable().required('Required')
    },
    {
      name: 'hol_company',
      label: 'Heart of Leadership Company',
      initialValue: props.holCompany,
      radio: true,
      schema: () => null
    },
    {
      name: 'comp_assess_company',
      label: 'Competency Assessment Company',
      initialValue: props.compCompany,
      radio: true,
      schema: () => null
    }
  ];

  function onSubmit(postData) {
    setLoading(true);

    if (props.create) {
      return onCreate(postData);
    }

    axios
      .put(`/companies/${props.companyId}/`, postData)
      .then(({ data }) => {
        setLoading(false);
      })
      .then(() => {
        props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Company Updated!'
          }
        });
      })
      .then(() => props.onSubmit())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onCreate(postData) {
    axios
      .post(`/companies/`, postData)
      .then(({ data }) => {
        setLoading(false);
      })
      .then(() => {
        props.dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Company Created!'
          }
        });
      })
      .then(() => props.onSubmit())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <Fade>
      <div className="segment">
        <BasicForm
          buttonText="Save"
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onSubmit}
        >
          {props.showCancel && (
            <BasicButton onClick={props.onClose} text="Cancel" />
          )}
        </BasicForm>
      </div>
    </Fade>
  );
}

CompanyForm.defaultProps = {
  address1: '',
  address2: '',
  city: '',
  compCompany: false,
  country: 'USA',
  create: false,
  hideInvites: false,
  holCompany: false,
  name: '',
  shortname: '',
  showCancel: false,
  state: '',
  targetIndustry: null,
  zip: ''
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyForm);
