import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.drives_results_consistently_factors) return [];

  const best = output.drives_results_consistently_factors.best
    ? Object.keys(output.drives_results_consistently_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.drive,
        value: output.drives_results_consistently_factors.best[b]
      }))
    : [];
  const worst = output.drives_results_consistently_factors.worst
    ? Object.keys(output.drives_results_consistently_factors.worst).map(
        (b) => ({
          title: b,
          cls: groupClasses.drive,
          value: output.drives_results_consistently_factors.worst[b]
        })
      )
    : [];

  return [
    {
      title: 'Drive Results Consistently',
      value: output.drives_results_consistently,
      behavior_value: output.pan_result.drives_results_consistently_score,
      cls: groupClasses.drive,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.drive,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.drive,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.drive,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Drives-Results-Consistently.aspx'
  }));
}
