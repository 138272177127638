import React, { useState } from 'react';
import axios from 'axios';
import { ActionButton } from 'components/shared/Buttons';

export default function EmployeeCsv({ companyId, style }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!companyId) return null;

  function onClick() {
    setLoading(true);
    setError(null);

    const req = {
      company_id: companyId
    };

    axios
      .post(`/download-company-employee-csv/`, req)
      .then(({ data }) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <div style={{ ...style }}>
      <ActionButton onClick={onClick} loading={loading} text="Download CSV" />
      {error && (
        <p style={{ color: 'red', marginTop: '.5em', fontWeight: 'bold' }}>
          {error}
        </p>
      )}
    </div>
  );
}

EmployeeCsv.defaultProps = {
  style: {}
};
