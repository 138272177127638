export const MULTIPLE_CHOICE_VALUE = 1;
export const TRUE_FALSE_VALUE = 2;
export const YES_NO_VALUE = 3;
export const OPEN_RESPONSE_VALUE = 4;

export const questionTypeOptions = [
  { text: 'Multiple Choice', value: MULTIPLE_CHOICE_VALUE },
  { text: 'True/False', value: TRUE_FALSE_VALUE },
  { text: 'Yes/No', value: YES_NO_VALUE },
  { text: 'Open Response', value: OPEN_RESPONSE_VALUE }
];
