import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import { SET_TOAST } from 'duck/toast';

function ResendButton({ id, title, dispatch }) {
  const [loading, setLoading] = useState(false);

  if (!id) return null;

  function onClick() {
    setLoading(true);

    axios
      .post(`/surveys/${id}/resend-invite/`)
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: `Email sent!`
          }
        });
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: SET_TOAST,
          payload: {
            negative: true,
            message: err.response.data.ui_message
          }
        });
      });
  }

  return (
    <Button
      icon="send"
      basic
      circular
      content={title || null}
      title="Resend Invite"
      size="tiny"
      onClick={onClick}
      loading={loading}
    />
  );
}

ResendButton.defaultProps = {
  id: null,
  title: ''
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ResendButton);
