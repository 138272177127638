import React from 'react';
import StarRatings from 'react-star-ratings';

export default function StarDisplay({ starSize, value }) {
  const starsToMake = Math.round((value / 20) * 100) / 100;

  if (!value) return null;

  return (
    <div className="star-display">
      <StarRatings
        rating={starsToMake}
        starDimension={`${starSize}px`}
        starRatedColor="#55ccd7"
        starSpacing="1px"
      />
      <span>{starsToMake} / 5 stars</span>
    </div>
  );
}

StarDisplay.defaultProps = {
  starSize: 20,
  value: 0
};
