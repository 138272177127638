import React, { useState, useEffect } from 'react';

import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

import AddForm from './AddForm';
import Employee360s from './Employee360s';

import './index.scss';

export default function Management360({
  companyId,
  employeeId,
  employeeName,
  isAdmin
}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="360"
      button={
        !isAdmin ? (
          <ToggleButton
            closedText="Request 360"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
    >
      <div className="segment mgmt-360">
        {isOpen && (
          <AddForm
            employeeId={employeeId}
            employeeName={employeeName}
            companyId={companyId}
            onSuccess={() => setOpen(false)}
          />
        )}
        {!isOpen && (
          <Employee360s
            isAdmin={isAdmin}
            employeeId={employeeId}
            showClosed={isAdmin}
          />
        )}
      </div>
    </Wrapped>
  );
}

Management360.defaultProps = {
  isAdmin: false
};
