import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Meta({
  averagePresentationTime,
  averageResponseLatency,
  totalAnswerChanges,
  totalPresentationTime
}) {
  const boxes = [
    {
      title: 'Avg. Presentation Time',
      value: averagePresentationTime,
      key: uuidv4()
    },
    {
      title: 'Avg. Response Latency',
      value: averageResponseLatency,
      key: uuidv4()
    },
    { title: 'Answer Changes', value: totalAnswerChanges, key: uuidv4() },
    { title: 'Presentation Time', value: totalPresentationTime, key: uuidv4() }
  ];

  return (
    <div className="pres-meta-boxes">
      {boxes.map(({ title, value, key }) => (
        <MetaBox title={title} value={value} key={key} />
      ))}
    </div>
  );
}

Meta.defaultProps = {
  averagePresentationTime: '',
  averageResponseLatency: '',
  totalAnswerChanges: '',
  totalPresentationTime: ''
};

function MetaBox({ title, value }) {
  return (
    <div className="box">
      <h3>{value}</h3>
      <p>{title}</p>
    </div>
  );
}
