import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaExclamationCircle } from 'react-icons/fa';

import PasswordForm from './PasswordForm';
import AuthWrapper from './AuthWrapper';

import CandidateSurvey from 'components/CandidateSurvey';
import HolReport from 'components/Reports/HolReport';
import Report360 from 'components/360';
import CompReport from 'components/Reports/CompetencyAssessment';

const varietyLib = (variety) => {
  switch (variety) {
    // case 0:
    //   return {
    //     header: 'Create Password',
    //     body: 'Create the password for your account',
    //     path: '/password-create/'
    //   };
    //   break;
    case 1:
      return {
        header: 'New Password',
        body: 'Create a new password for your account',
        path: '/password-reset/'
      };
      break;
    case 2:
      // survey
      break;
    default:
      break;
  }
};

class Code extends Component {
  state = {
    calculation_container_id: null,
    codeData: null,
    company_manager_email: '',
    company_manager_id: null,
    compReady: false,
    error: null,
    holReady: false,
    loading: true,
    remote_code_id: null,
    submitting: false,
    survey: null,
    surveyContainerId: null,
    variety: null,
    verified: false
  };

  componentDidMount = () => {
    this.verifyCode();
  };

  verifyCode = () => {
    const { id } = this.props.match.params;

    axios
      .get(`/remote-code/${id}/`)
      .then(({ data }) => {
        this.setState({
          holReady: data.hol_report_ready,
          compReady: data.comp_assess_report_ready,
          variety: data.variety,
          survey: data.survey_id || null,
          company_manager_email: data.company_manager_email || '',
          company_manager_id: data.company_manager_id || null,
          remote_code_id: data.remote_code_id || null,
          loading: false,
          verified: true,
          calculation_container_id:
            data.hol_calc_container_id || data.comp_assess_calc_container_id,
          surveyContainerId:
            data.release_for_360 && data.survey_container_id
              ? data.survey_container_id
              : null
        });
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message || 'The code is invalid',
          loading: false,
          verified: false
        });
      });
  };

  onPasswordSubmit = ({ password, confirmedPassword }) => {
    const { codeData } = this.state;

    const postData = {
      confirmed_password: confirmedPassword,
      password,
      remote_code_id: this.state.remote_code_id
    };

    this.setState({ submitting: true });

    axios
      .post(`/password-reset/`, postData)
      .then(({ data }) => {
        if (this.state.company_manager_id) {
          window.location = `/employees`;
        }
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message || 'There was a problem',
          submitting: false
        });

        setTimeout(() => {
          this.setState({ error: null });
        }, 5000);
      });
  };

  onPasswordCreate = ({ password, confirmedPassword }) => {
    const postData = {
      confirmed_password: confirmedPassword,
      password,
      remote_code_id: this.state.remote_code_id
    };

    this.setState({ submitting: true });

    axios
      .post(`/password-create/`, postData)
      .then(({ data }) => {
        if (this.state.company_manager_id) {
          window.location = `/employees`;
        }
      })
      .catch((err) => {
        this.setState({
          error: err.response.data.ui_message || 'There was a problem',
          submitting: false
        });

        setTimeout(() => {
          this.setState({ error: null });
        }, 5000);
      });
  };

  render() {
    const {
      codeData,
      compReady,
      holReady,
      error,
      loading,
      survey,
      surveyContainerId,
      variety,
      verified
    } = this.state;

    const text = varietyLib(variety);

    if (loading) {
      return <div></div>;
    }

    if (holReady) {
      return (
        <HolReport calcContainerId={this.state.calculation_container_id} />
      );
    }

    if (compReady) {
      return (
        <CompReport calcContainerId={this.state.calculation_container_id} />
      );
    }

    if (surveyContainerId) {
      return <Report360 surveyContainerId={surveyContainerId} />;
    }

    if (!verified && !loading) {
      return (
        <AuthWrapper>
          <FaExclamationCircle size={90} color="#D93030" />
          <h1>Oops!</h1>
          <p>{error}</p>
          <div className="auth-other">
            <Link to="/">Login</Link>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </AuthWrapper>
      );
    }

    if (survey) {
      return <CandidateSurvey surveyId={survey} />;
    }

    if (variety === 1) {
      return (
        <AuthWrapper>
          <h1>{text.header}</h1>
          <p>
            Reset your password for this account:{' '}
            <b>({this.state.company_manager_email})</b>
          </p>
          <PasswordForm
            disabled={this.state.submitting || this.state.error}
            error={this.state.error}
            onSubmit={this.onPasswordSubmit}
          />
        </AuthWrapper>
      );
    }

    if (variety === 2) {
      return (
        <AuthWrapper>
          <h1>Create Password</h1>
          <p>
            Create a password for this account:{' '}
            <b>({this.state.company_manager_email})</b>
          </p>
          <PasswordForm
            disabled={this.state.submitting || this.state.error}
            error={this.state.error}
            onSubmit={this.onPasswordCreate}
          />
        </AuthWrapper>
      );
    }

    return <div></div>;
  }
}

export default Code;
