import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Question from './Question';

export default function QuestionList({
  questions,
  respondants,
  onResponderClick,
  onUpdate
}) {
  const [showManager, setShowManager] = useState(true);
  const [showEmployee, setShowEmployee] = useState(true);

  return (
    <React.Fragment>
      <div className="perf-question-list-header">
        <div className="box">
          <span>Question</span>
        </div>
        {respondants.map((r) => (
          <div
            key={`${r.first_name}${r.last_name}${r.id}`}
            className={`box ${r.is_employee ? 'employee' : 'manager'}-res`}
          >
            <button
              onClick={() => onResponderClick(r.uuid)}
              title="Click to remove"
            >
              {r.first_name} {r.last_name}{' '}
              <FaTimes size={9} style={{ marginLeft: '5px' }} />
            </button>
          </div>
        ))}
      </div>
      <div className="perf-question-list">
        {questions.map((q) => (
          <Question
            key={q.id}
            category={q.category}
            responses={q.responses}
            text={q.text}
            onUpdate={onUpdate}
          />
        ))}
      </div>
    </React.Fragment>
  );
}

QuestionList.defaultProps = {
  questions: [],
  respondants: []
};
