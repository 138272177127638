import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loader from 'react-loader-spinner';
import { Form } from 'semantic-ui-react';

import QuestionItem from './QuestionItem';

const ACTIVE_QUESTIONS_ID = 'activeQuestions';
const AVAILABLE_QUESTIONS_ID = 'availableQuestions';

const loaderConfig = {
  type: 'Bars',
  height: 20,
  width: 20,
  color: '#000'
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function Questions(props) {
  const [filterValues, setFilterValues] = useState({
    search: ''
  });

  function onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      destination.droppableId === ACTIVE_QUESTIONS_ID
    ) {
      // REORDER ACTIVE QUESTIONS
      const questions = reorder(
        props.activeQuestions,
        result.source.index,
        result.destination.index
      );

      props.onReorder(questions.map((q, i) => ({ id: q.id, position: i })));
    } else {
      // ADD QUESTION
      if (
        source.droppableId === AVAILABLE_QUESTIONS_ID &&
        destination.droppableId === ACTIVE_QUESTIONS_ID
      ) {
        const questions = move(
          props.availableQuestions,
          props.activeQuestions,
          source,
          destination
        );

        props.onAddAndReposition(
          result.draggableId,
          questions.activeQuestions.map((q, i) => ({
            id: q.id,
            position: i,
            recentlyAdded: q.id == result.draggableId
          }))
        );
      }

      // REMOVE QUESTION
      if (
        source.droppableId === ACTIVE_QUESTIONS_ID &&
        destination.droppableId === AVAILABLE_QUESTIONS_ID
      ) {
        props.onRemove(result.draggableId);
      }
    }
  }

  const { availableQuestions, actionsDisabled } = props;
  console.log(availableQuestions);
  const filteredAvailableQuestions = availableQuestions.filter((f) =>
    filterValues.search
      ? f.text.toLowerCase().includes(filterValues.search.toLowerCase())
      : true
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={ACTIVE_QUESTIONS_ID}
        isDropDisabled={actionsDisabled}
      >
        {(provided, snapshot) => (
          <div
            className="active-questions"
            style={{ width: actionsDisabled ? '100%' : 'inherit' }}
          >
            <h2>
              Active Questions ({props.activeQuestions.length}){' '}
              {props.loading && <Loader {...loaderConfig} />}
            </h2>
            {actionsDisabled && (
              <p>
                <b>
                  You may not change the order or add new questions to this
                  survey
                </b>
              </p>
            )}
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="questions"
              style={{
                minHeight: actionsDisabled ? '800px' : 'inherit',
                maxHeight: actionsDisabled ? '800px' : 'inherit'
              }}
            >
              {props.activeQuestions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={`${question.id}`}
                  index={index}
                >
                  {(provided) => (
                    <QuestionItem
                      actionsDisabled={actionsDisabled}
                      innerRef={provided.innerRef}
                      descriptor={question.descriptor}
                      id={question.id}
                      loading={props.loading}
                      text={question.text}
                      factor={question.factor_formatted}
                      dimension={question.dimension_formatted}
                      hasComment={question.has_respondant_comment}
                      categoryString={question.category_string}
                      position={index + 1}
                      // position={
                      //   question.position !== undefined
                      //     ? question.position + 1
                      //     : null
                      // }
                      draggableProps={provided.draggableProps}
                      dragHandleProps={provided.dragHandleProps}
                      showRemove
                      onRemove={() => props.onRemove(question.id)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
      {!actionsDisabled && (
        <Droppable droppableId={AVAILABLE_QUESTIONS_ID}>
          {(provided, snapshot) => (
            <div className="available-questions">
              <h2>
                Available Questions ({filteredAvailableQuestions.length}){' '}
                {props.loading && <Loader {...loaderConfig} />}
              </h2>
              <Form size="mini" style={{ marginBottom: '1em' }}>
                <Form.Input
                  size="small"
                  placeholder="Search question text"
                  value={filterValues.search}
                  onChange={(e) =>
                    setFilterValues({
                      ...filterValues,
                      search: e.target.value
                    })
                  }
                />
              </Form>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="questions"
              >
                {filteredAvailableQuestions.map((question, index) => (
                  <Draggable
                    key={question.id}
                    draggableId={`${question.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <QuestionItem
                        innerRef={provided.innerRef}
                        descriptor={question.descriptor}
                        id={question.id}
                        loading={props.loading}
                        text={question.text}
                        categoryString={question.category_string}
                        draggableProps={provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        onAdd={() => props.onAdd(question.id)}
                        showAdd
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
}

Questions.defaultProps = {
  actionsDisabled: false
};

Questions.propTypes = {
  actionsDisabled: PropTypes.bool
};
