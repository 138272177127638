import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { FaCircle } from 'react-icons/fa';

import { TableLinkButton } from 'components/shared/Buttons';
import { PaginatedTable } from 'components/shared/Tables';

const columns = [
  {
    Header: '',
    id: 'view',
    width: 40,
    sortable: false,
    accessor: (d) => <TableLinkButton url={`/requests/${d.id}`} />
  },
  {
    Header: 'Company',
    id: 'company',
    accessor: 'requested_for_formatted.name',
    sortable: false
  },
  {
    Header: 'Requested By',
    id: 'requester',
    accessor: (d) => (d.requested_by ? d.requested_by.full_name : 'BCK Admin'),
    sortable: false
  },
  {
    Header: 'Requested On',
    id: 'created',
    sortable: false,
    accessor: (d) => new Date(d.created_at).toLocaleString()
  },
  {
    Header: 'Survey Info',
    id: 'survey',
    sortable: false,
    width: 120,
    accessor: (d) => {
      if (d.engagement_survey_name) {
        return (
          <Popup
            content={
              <div>
                <p>
                  <b>{d.engagement_survey_name}</b>
                  <br />
                  Engagement Survey
                  <br />
                  {d.surveys_info[0]}
                </p>
              </div>
            }
            trigger={<Icon name="users" style={{ cursor: 'pointer' }} />}
          />
        );
      }

      const surveys = getSurveyInfo(d.surveys_info);

      if (!surveys.length) return '';

      return (
        <Popup
          content={
            <div>
              <ul style={{ margin: '0', padding: '5px 15px' }}>
                {surveys.map((p, i) => (
                  <li key={i}>{p}</li>
                ))}
              </ul>
            </div>
          }
          trigger={
            <Icon
              name={surveys.length > 1 ? 'users' : 'user'}
              style={{ cursor: 'pointer' }}
            />
          }
        />
      );
    }
  }
];

const filterOptions = [
  { id: 'first_name', object: 'company_employee' },
  { id: 'last_name', object: 'company_employee' },
  { id: 'email', object: 'company_employee' }
];

const approvalFilterButtons = [
  { text: 'Waiting', value: 0, icon: <FaCircle /> },
  { text: 'Unapproved', value: 1, icon: <FaCircle /> },
  { text: 'Approved', value: 2, icon: <FaCircle /> }
];

const filterButtons = [
  {
    name: 'approval_status',
    text: 'Approval',
    default: 0,
    buttons: approvalFilterButtons
  }
];

export default function Table() {
  return (
    <div className="segment">
      <PaginatedTable
        columns={columns}
        entityName="Requests"
        filterButtons={filterButtons}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-survey-requests/"
      />
    </div>
  );
}

function getSurveyInfo(data) {
  let surveys = [];

  data.forEach((p) => {
    if (p.company_manager) {
      surveys.push(
        `${p.company_manager.first_name} ${p.company_manager.last_name} - ${p.name}`
      );
    }

    if (p.company_employee) {
      surveys.push(
        `${p.company_employee.first_name} ${p.company_employee.last_name} - ${p.name}`
      );
    }

    // 360 stuff
    if (p.company_employee_name) {
      surveys.push(`${p.company_employee_name} - ${p.name}`);
    }

    if (p.company_manager_name) {
      surveys.push(`${p.company_manager_name} - ${p.name}`);
    }
  });

  return surveys;
}
