import React, { useState } from 'react';
import { FaBoxes, FaBrain, FaCircle, FaTheaterMasks } from 'react-icons/fa';

import {
  DESCRIPTOR_COGNITION,
  DESCRIPTOR_PERSONALITY
} from '@util/data/questionDescriptor';

import { IconFilterButtons } from 'components/shared/Buttons';
import { TableLinkButton } from 'components/shared/Buttons';
import { PaginatedTable } from 'components/shared/Tables';

export default function Table() {
  const [status, setStatus] = useState(0);
  const [variety, setVariety] = useState(0);
  const [descriptor, setDescriptor] = useState(0);

  return (
    <div className="segment">
      <div className="filter-button-header">
        <IconFilterButtons
          active={status}
          buttons={statusFilterButtons}
          onClick={(e) => setStatus(e)}
          text="Status"
        />
        <IconFilterButtons
          active={variety}
          buttons={varietyFilterButtons}
          onClick={(e) => setVariety(e)}
          text="Variety"
        />
        <IconFilterButtons
          active={descriptor}
          buttons={descriptorFilterButtons}
          onClick={(e) => setDescriptor(e)}
          text="Descriptor"
        />
      </div>
      <PaginatedTable
        columns={columns}
        entityName="Surveys"
        filterOptions={filterOptions}
        keyName="data"
        url={`/retrieve-questions/`}
        additionalPostData={{
          status,
          descriptor: variety === 0 ? descriptor : 'all',
          variety
        }}
      />
    </div>
  );
}

const columns = [
  {
    Header: '',
    sortable: false,
    id: 'view',
    width: 40,
    accessor: (d) => <TableLinkButton url={`/questions/${d.id}`} />
  },
  {
    Header: 'Text',
    accessor: 'text',
    filterable: true,
    sortable: false
  },
  // {
  //   Header: 'Created',
  //   id: 'created',
  //   accessor: d => d.created_at ? new Date(d.created_at).toLocaleString() : '',
  // },
  {
    Header: 'Type',
    id: 'type',
    sortable: false,
    width: 120,
    accessor: (d) => {
      if (d.multiple_choice) {
        return 'Multiple Choice';
      }

      if (d.true_false) {
        return 'True/False';
      }

      if (d.yes_no) {
        return 'Yes/No';
      }

      return '';
    }
  }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

const descriptorFilterButtons = [
  {
    text: 'Personality',
    value: DESCRIPTOR_PERSONALITY,
    icon: <FaTheaterMasks />
  },
  { text: 'Cognition', value: DESCRIPTOR_COGNITION, icon: <FaBrain /> },
  { text: 'All', value: 'all', icon: <FaBoxes /> }
];

const varietyFilterButtons = [
  { text: 'Clear Screen', value: 0 },
  { text: 'Performance', value: 1 },
  { text: 'Engagement', value: 2 },
  // { text: 'Leadership', value: 3 },
  { text: 'Corp Asst.', value: 5 }
].map((m) => ({ ...m }));

const filterOptions = [{ id: 'text', object: 'self' }];
