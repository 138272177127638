import React, { useState, useEffect } from 'react';
import { Button, Icon, Form } from 'semantic-ui-react';
import axios from 'axios';

import logo from 'assets/images/lpsu-logo.png';

const STEP_EMAIL = 'email';
const STEP_BASICS = 'basics';

import './index.scss';

export default function index() {
  const [step, setStep] = useState(STEP_EMAIL);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [success, setSuccess] = useState(false);
  const [resent, setResent] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // setError(null);
  }, [email, firstName, lastName]);

  function onEmailSubmit() {
    setStep(STEP_BASICS);
  }

  function onBasicSubmit() {
    const req = {
      email,
      first_name: firstName,
      last_name: lastName
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/competency-onboarding/`, req)
      .then(({ data }) => {
        if (data.response === 'resent') {
          setResent(true);
        }

        if (data.response === 'you have already submitted') {
          setSubmitted(true);
        }

        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);

        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  const validEmail = /@lpcorp.com\s*$/.test(email);

  if (success || resent || submitted) {
    return (
      <div className="comp-signup-wrapper">
        <img src={logo} alt="LPSU Logo" />
        <div className="success">
          <Icon size="huge" color="green" name="check circle" />
          {!submitted && <h1>Success!</h1>}
          <p className="intro">
            {!resent && !submitted && (
              <React.Fragment>
                You will get an email at <b>{email}</b> to take a Competency
                Assessment after your request is processed.
              </React.Fragment>
            )}
            {submitted && (
              <span style={{ marginTop: '1em', display: 'block' }}>
                You have already submitted your Competency Assessment.
              </span>
            )}
            {resent && (
              <React.Fragment>
                Your invite has been resent to <b>{email}</b>.
              </React.Fragment>
            )}
          </p>
          <p className="intro">You may now close this window.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="comp-signup-wrapper">
      <img src={logo} alt="LPSU Logo" />
      <h1 className="title">Competency Assessment</h1>
      <p className="intro">
        Enter your email into the field below.{' '}
        <b>This must be an lpcorp.com email address.</b>
      </p>
      {step === STEP_EMAIL && (
        <React.Fragment>
          <Form size="large">
            <Form.Input
              type="email"
              value={email}
              placeholder="Your lpcorp.com email"
              error={email.length && !validEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              content="Next"
              fluid
              positive
              onClick={onEmailSubmit}
              size="large"
              loading={loading}
              disabled={loading || !validEmail}
            />
          </Form>
        </React.Fragment>
      )}
      {step === STEP_BASICS && (
        <React.Fragment>
          <Form size="large">
            <Form.Input
              type="email"
              value={email}
              placeholder="Your student email"
              error={email.length && !validEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            <Form.Input
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            {error && (
              <div className="err-text">
                <Icon name="exclamation circle" className="red" />
                <p>{error}</p>
              </div>
            )}
            <Button
              content="Get My Invite"
              fluid
              positive
              onClick={onBasicSubmit}
              size="large"
              loading={loading}
              disabled={loading || !firstName || !lastName || !validEmail}
            />
          </Form>
        </React.Fragment>
      )}
    </div>
  );
}
