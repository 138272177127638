import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Modal } from 'semantic-ui-react';

export default function RequestSurveyInvite({
  companyId,
  companyName,
  employeeId,
  employeeName,
  onInviteFail,
  onInviteSuccess,
  remoteCodes,
  open,
  onClose
}) {
  const [managerOptions, setManagerOptions] = useState([]);
  // this will be used when 360 comes
  const [targetIds, setTargetIds] = useState([]);
  const [managerIds, setManagerIds] = useState([]);
  const [surveyId, setSurveyId] = useState(null);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const pendingAndApprovedRemoteCodeIds = remoteCodes
    .filter((f) => f.approval_status !== 1)
    .map((c) => c.parent_ident);

  const allowMultipleEvaluators = surveyOptions.find((d) => d.id === surveyId)
    ? surveyOptions.find((d) => d.id === surveyId).allow_multiple_evaluators
    : false;

  const allowMultipleTargets = surveyOptions.find((d) => d.id === surveyId)
    ? surveyOptions.find((d) => d.id === surveyId).allow_multiple_targets
    : false;

  useEffect(() => {
    fetchCompanySurveys();
  }, [open]);

  useEffect(() => {
    fetchCompanyManagers();
  }, [companyId]);

  function fetchCompanyManagers() {
    if (!companyId) return;

    axios
      .get(`/companies/${companyId}/managers/`)
      .then(({ data }) => {
        setManagerOptions(
          data.company_managers
            .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
            .map((c) => ({
              key: c.id,
              value: c.id,
              text: `${c.first_name} ${c.last_name}`
            }))
        );
      })
      .catch((err) => {});
  }

  function onInviteClick() {
    if (!surveyId) return;

    if (allowMultipleTargets && managerIds.length === 0) {
      return alert('Please select at least 1 evaluator');
    }

    const postData = {
      company_employees: [{ id: employeeId, target: true }],
      company_id: companyId,
      company_managers: managerIds.map((m) => ({ id: m, evaluator: true })),
      survey_id: surveyId
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/employees/${employeeId}/request-invite/`, postData)
      .then(() => onInviteSuccess())
      .then(() => {
        setLoading(false);
        setSurveyId(null);
        setSurveyOptions([]);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
        setSurveyId(null);

        onInviteFail();

        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  function fetchCompanySurveys() {
    if (!companyId || !open) return null;

    axios
      .post(`/companies/${companyId}/retrieve-surveys/`, {
        company_id: companyId,
        status: 0
      })
      .then(({ data }) => {
        // filter out engagement surveys
        setSurveyOptions(
          data.surveys.filter((f) => ![2, 4].includes(f.variety))
        );
      })
      .then(() => setLoading(false))
      .catch(() => {});
  }

  if (!companyId) return null;

  function onReset() {
    setSurveyOptions([]);
    setManagerIds([]);
    setSurveyId();
    setError(null);
    onClose();
  }

  const formattedSurveyOptions = surveyOptions
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((s) => ({
      key: s.id,
      text: s.name,
      value: s.id,
      description: pendingAndApprovedRemoteCodeIds.includes(s.id)
        ? `Pending`
        : 'Available',
      disabled: pendingAndApprovedRemoteCodeIds.includes(s.id)
    }));

  return (
    <Modal size="tiny" open={open} onClose={onReset}>
      <Modal.Header content={`Request Invite For ${employeeName}`} />
      <Modal.Content>
        {!loading && surveyOptions.length === 0 && (
          <p>No surveys to choose from</p>
        )}
        {surveyOptions.length > 0 && (
          <Form>
            <Form.Select
              placeholder="Select one..."
              options={formattedSurveyOptions}
              label={`${companyName} Surveys`}
              onChange={(e, d) => {
                setSurveyId(d.value);
                setManagerIds([]);
                setError(null);
              }}
              value={surveyId}
            />
            {allowMultipleEvaluators && (
              <Form.Select
                placeholder="Select some..."
                options={managerOptions}
                search
                multiple
                label="Evaluating Manager(s)"
                onChange={(e, d) => {
                  setManagerIds(d.value);
                  setError(null);
                }}
              />
            )}
            {allowMultipleTargets && (
              <Form.Select
                placeholder="Select some..."
                options={[]}
                search
                multiple
                label={`Targets (in addition to ${employeeName}, optional)`}
                onChange={(e, d) => {
                  setManagerIds(d.value);
                  setError(null);
                }}
              />
            )}
          </Form>
        )}
        {error && (
          <p style={{ fontWeight: 'bold', marginTop: '15px' }} className="red">
            {error}
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" basic size="small" onClick={onReset} />
        <Button
          size="small"
          positive
          content="Send"
          onClick={() => onInviteClick()}
          disabled={
            !surveyId || (allowMultipleEvaluators === 1 && !managerIds.length)
          }
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
}

RequestSurveyInvite.defaultProps = {
  companyId: null,
  companyName: '',
  employeeName: '',
  employeeId: null,
  open: false,
  remoteCodes: []
};
