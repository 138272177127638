export const SET_ADMIN = 'SET_ADMIN';

function adminReducer(state, action) {
  switch (action.type) {
    case SET_ADMIN:
      return {
        ...state,
        admin: action.payload,
      }
    default:
      return state;
  }

  return state;
}

export default adminReducer;
