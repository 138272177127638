import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FaUserAlt } from 'react-icons/fa';
import { Wrapped } from 'components/Layouts';
import BasicForm from 'components/shared/Forms/BasicForm';
import { BasicButton, ToggleButton } from 'components/shared/Buttons';

const AddForm = ({ onSubmit, loading, error, admin, children }) => {
  let fields = [
    {
      name: 'subject',
      label: 'Subject',
      initialValue: '',
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'body',
      label: 'Note',
      initialValue: '',
      textarea: true,
      required: true,
      schema: () => Yup.string().required('Required')
    }
  ];

  if (admin) {
    fields.push({
      name: 'admin_only',
      label: 'Admin Only',
      initialValue: false,
      required: false,
      radio: true,
      schema: () => null
    });
  }

  return (
    <div className="segment">
      <BasicForm
        fields={fields}
        onSubmit={onSubmit}
        loading={loading}
        error={error}
      >
        {children}
      </BasicForm>
    </div>
  );
};

export default function Notes({ employeeId, admin }) {
  const [notes, setNotes] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  if (!employeeId) return null;

  useEffect(() => {
    fetchNotes();
  }, []);

  function fetchNotes() {
    setLoading(true);

    axios
      .get(`/employees/${employeeId}/`)
      .then(({ data }) => {
        if (admin) {
          setNotes(
            data.employee_notes.sort((a, b) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
          );
        } else {
          setNotes(
            data.employee_notes
              .filter((n) => !n.admin_only)
              .sort((a, b) =>
                new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
              )
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setNotes([]);
        setError(err.response.data.ui_message);
      });
  }

  function createNote(formData) {
    const postData = {
      ...formData,
      employee_id: employeeId
    };

    setLoading(true);
    setError(null);

    let postUrl = `/employees/${employeeId}/notes/`;

    if (admin) {
      postUrl = `/admin-employee-note/`;
    }

    axios
      .post(postUrl, postData)
      .then(() => fetchNotes())
      .then(() => setOpen(false))
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <Wrapped
      title="Notes"
      button={
        <ToggleButton
          closedText="Add Note"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {isOpen ? (
        <AddForm
          loading={loading}
          error={error}
          onSubmit={createNote}
          admin={admin}
        >
          <BasicButton
            onClick={() => setOpen(false)}
            text="Cancel"
            error={error}
            loading={loading}
          />
        </AddForm>
      ) : (
        <div>
          {notes.map((note) => (
            <div key={note.id} className="segment employee-note">
              <h2>
                {note.subject}{' '}
                <span>
                  <p>{note.created_at_formatted}</p>
                </span>
              </h2>
              {note.body && <p className="note-body">{note.body}</p>}
              <p className="author">
                <FaUserAlt />
                {note.company_manager && (
                  <span>{note.company_manager.full_name}</span>
                )}
                {!note.company_manager && <span>CLEAR Screen Admin</span>}
              </p>
            </div>
          ))}
        </div>
      )}
    </Wrapped>
  );
}

Notes.defaultProps = {
  admin: false,
  employeeId: ``
};

Notes.propTypes = {
  admin: PropTypes.bool,
  employeeId: PropTypes.string.isRequired
};
