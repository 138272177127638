import React, { useState } from 'react';

import { FaCircle } from 'react-icons/fa';

import { IconFilterButtons } from 'components/shared/Buttons';
import { TableLinkButton } from 'components/shared/Buttons';
import { PaginatedTable } from 'components/shared/Tables';

export default function Table() {
  const [status, setStatus] = useState(0);
  return (
    <div className="segment">
      <div className="filter-button-header">
        <IconFilterButtons
          active={status}
          buttons={statusFilterButtons}
          onClick={(e) => setStatus(e)}
          text="Status"
        />
      </div>
      <PaginatedTable
        columns={columns}
        entityName="Surveys"
        filterOptions={filterOptions}
        keyName="data"
        url={`/retrieve-surveys/`}
        additionalPostData={{
          status
        }}
      />
    </div>
  );
}

const filterOptions = [{ id: 'name', object: 'self' }];
const columns = [
  {
    Header: '',
    id: 'view',
    sortable: false,
    width: 40,
    accessor: (d) => <TableLinkButton url={`/surveys/${d.id}`} />
  },
  {
    Header: 'Name',
    accessor: 'name',
    sortable: false,
    filterable: true
  },
  {
    Header: 'Variety',
    accessor: 'variety_formatted',
    width: 200,
    sortable: false
  },
  {
    Header: 'Created',
    id: 'created',
    width: 200,
    sortable: false,
    accessor: (d) =>
      d.created_at ? new Date(d.created_at).toLocaleString() : ''
  },
  {
    Header: 'Versions',
    accessor: 'version_count',
    sortable: false,
    width: 100
  }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];
