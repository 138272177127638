import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCircle } from 'react-icons/fa';

import { TableLinkButton } from 'components/shared/Buttons';
import { BasicTable } from 'components/shared/Tables';
import { IconFilterButtons } from 'components/shared/Buttons';

export default function Table() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState(0);
  const [competency, setCompetency] = useState(false);

  useEffect(() => {
    fetchData();
  }, [value]);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/survey-evaluation-queue/`, {
        variety: value
      })
      .then(({ data }) => {
        setItems(data.surveys);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div className="segment">
      <IconFilterButtons
        active={value}
        buttons={varietyButtons}
        onClick={(e) => setValue(e)}
        text="Survey Type"
      />
      <BasicTable
        columns={columns}
        entityName="Evaluations"
        fetch={false}
        loading={loading}
        data={items}
      />
      {/* <BasicTable
        columns={columns}
        entityName="Evaluations"
        url={`/survey-evaluation-queue/`}
        // loading={loading}
        // data={items}
        keyName="surveys"
      /> */}
    </div>
  );
}

const columns = [
  {
    Header: '',
    id: 'view',
    width: 40,
    accessor: (d) => <TableLinkButton url={`/evaluations/${d.id}`} />
  },
  {
    Header: 'Name',
    accessor: 'employee.full_name',
    filterable: true
  },
  {
    Header: 'Company',
    accessor: 'employee.company.name',
    id: 'company',
    filterable: true
  },
  {
    Header: 'Survey',
    accessor: 'name',
    filterable: true
  },
  {
    Header: 'Submitted',
    accessor: (d) => new Date(d.submitted_at).toLocaleString(),
    id: 'submitted'
  }
];

const varietyButtons = [
  { text: 'CLEAR Screen', value: 0, icon: <FaCircle /> },
  { text: 'Heart of Leadership', value: 3, icon: <FaCircle /> },
  { text: 'Competency Assesesment', value: 5, icon: <FaCircle /> }
];
