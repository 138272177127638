import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import axios from 'axios';
import { Button, Divider, Icon, Modal, Statistic } from 'semantic-ui-react';

import EmailCheck from './EmailCheck';
import EngagementReminder from './EngagementReminder';

import './index.scss';

export default function EngagementEmail({
  lastReminderSent,
  surveyInviteId,
  surveyContainerId,
  surveyName,
  onUpdate
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [failures, setFailures] = useState([]);
  const [summaryInfo, setSummaryInfo] = useState({
    failed: 0,
    sent: 0,
    unsent: 0
  });

  useEffect(() => {
    if (isOpen) {
      fetchEmailSummary();
    }
  }, [isOpen]);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setError(null);
    onUpdate();
  }

  function onSubmit() {
    setError(null);
    setLoading(true);

    axios
      .post(`/send-engagement-surveys/`, { survey_invite_id: surveyInviteId })
      .then(() => {
        setLoading(false);
        fetchEmailSummary();
      })
      .catch((err) => {
        setError(err.response.data.ui_message);
        setLoading(false);
      });
  }

  function fetchEmailSummary() {
    const req = {
      survey_invite_id: surveyInviteId
    };

    axios
      .post(`/engagement-email-status/`, req)
      .then(({ data }) => {
        setSummaryInfo({
          failed: data.failed_to_send,
          sent: data.sent_successfully,
          unsent: data.unsent
        });
        setFailures(
          data.failures.map((d) => ({
            ...d,
            reason: d.reason || 'No reason specified'
          }))
        );
      })
      .catch((err) => {});
  }

  const statBlocks = [
    { key: 2, text: 'Sent', value: summaryInfo.sent, color: 'green' },
    { key: 1, text: 'Unsent', value: summaryInfo.unsent, color: 'grey' },
    { key: 3, text: 'Failed', value: summaryInfo.failed, color: 'red' }
  ];

  return (
    <div>
      <button
        className="table-link-button"
        title="Send Invites"
        style={{
          border: 'none',
          background: 'none',
          padding: '0',
          cursor: 'pointer',
          outline: 'none'
        }}
        onClick={() => setOpen(true)}
      >
        <FaEnvelope />
      </button>
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Header content={surveyName} />
        <Modal.Content>
          <Statistic.Group style={{ marginBottom: '1em' }}>
            {statBlocks.map((s) => (
              <Statistic
                key={s.key}
                value={s.value}
                label={s.text}
                color={s.color}
              />
            ))}
          </Statistic.Group>
          {failures.length > 0 && (
            <div className="engagement-failures-wrapper">
              <h4 style={{ marginBottom: '0' }}>
                Failures ({failures.length})
              </h4>
              <ul className="engagement-failures">
                {failures.map((f, i) => (
                  <FailureItem
                    key={i}
                    reason={f.reason}
                    email={f.email}
                    surveyId={f.survey_id}
                    surveyInviteId={surveyInviteId}
                    onUpdate={fetchEmailSummary}
                  />
                ))}
              </ul>
            </div>
          )}
          <EmailCheck
            surveyInviteId={surveyInviteId}
            onUpdate={fetchEmailSummary}
          />
          <Divider />
          <EngagementReminder
            lastReminderSent={lastReminderSent}
            surveyContainerId={surveyContainerId}
            onSuccess={() => onUpdate()}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic onClick={onClose} />
          {summaryInfo.unsent > 0 && (
            <Button
              content={`Send To All Unsent Now (${summaryInfo.unsent})`}
              primary
              onClick={onSubmit}
              loading={loading}
            />
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
}

EngagementEmail.defaultProps = {
  lastReminderSent: null,
  surveyName: ''
};

const FailureItem = ({ surveyInviteId, email, reason, surveyId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSendClick() {
    setLoading(true);
    setError(null);

    const req = {
      survey_invite_id: surveyInviteId,
      survey_id: surveyId
    };

    axios
      .post(`/send-engagement-surveys/`, req)
      .then(() => {
        setLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <li>
      <span>
        <b>{email}</b>
        {reason && <span> {reason}</span>}
        {error && (
          <span style={{ fontWeight: '500' }}>
            <br />
            <Icon name="exclamation circle" color="red" /> {error}
          </span>
        )}
      </span>
      {surveyId && (
        <Button
          content="Resend"
          basic
          loading={loading}
          onClick={onSendClick}
          size="tiny"
          style={{ marginRight: '5px' }}
        />
      )}
    </li>
  );
};

FailureItem.defaultProps = {
  email: '',
  reason: ''
};
