import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';

import { PartnerButton, PartnerTestState } from 'components/Partner';

const statusBtns = [
  { text: 'Not Started', value: 'not_assigned' },
  { text: 'Incomplete', value: 'incomplete' },
  { text: 'Complete', value: 'complete' }
].map((m, i) => ({ ...m, key: i + 1 }));

export default function PartnerIndex({ match }) {
  const [error, setError] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);

  const queryInfo = queryString.parseUrl(window.location.href).query;

  useEffect(() => {
    fetchData();
  }, []);

  function setData() {
    setLoading(false);

    // never sent
    // return setEmployee({ status: 'not_assigned', first_name: 'Anjali' });

    // sent, but not started
    // return setEmployee({
    //   first_name: 'Anjali',
    //   status: 'incomplete',
    //   activity_status: 'assigned',
    //   date_time: '2022-09-09T14:22:40.000+00:00',
    //   survey_id: 1732
    // });

    // sent and started
    // return setEmployee({
    //   first_name: 'Anjali',
    //   status: 'incomplete',
    //   activity_status: 'started',
    //   date_time: '2022-09-09T14:23:42.000+00:00',
    //   survey_id: 1732
    // });

    // completed
    return setEmployee({
      first_name: 'Didier',
      status: 'complete',
      cls: 26.0,
      persona: 'contributor',
      cog: 4.0,
      fpf: 5.5,
      coachability: 'Confident resolve',
      derailers: ['callous', 'obsessive'],
      work_motives: {
        artistic_motive: 0.1,
        conventional_motive: 0.14,
        enterprising_motive: 0,
        investigative_motive: 0.14,
        realistic_motive: 0.1,
        social_motive: 0.05
      }
    });
  }

  function fetchData() {
    if (process.env.ENV === 'dev') {
      return setData();
    }

    const req = {
      ...queryInfo
    };

    axios
      .post(`/bullhorn-status/`, req)
      .then(({ data }) => {
        setEmployee(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError('Status error');
      });
  }

  if (loading)
    return (
      <div style={{ padding: '2em', fontSize: '1.35em' }}>
        <p>Loading...</p>
      </div>
    );

  if (!employee || error) {
    return (
      <div className="partner-view">
        <p>Error</p>
      </div>
    );
  }

  return (
    <div className="partner-view">
      {employee.status === 'not_assigned' && (
        <PartnerTestState.NotStarted>
          <p className="partner-view-intro">
            {employee.first_name} has not started the ClearScreen.
          </p>
          <PartnerButton.Init
            onSuccess={() => fetchData()}
            reqData={{ ...queryInfo }}
          />
        </PartnerTestState.NotStarted>
      )}
      {employee.status === 'incomplete' && (
        <PartnerTestState.Incomplete>
          <p className="partner-view-intro">
            {employee.activity_status === 'assigned' && (
              <React.Fragment>
                {employee.first_name} was sent an invite to take the ClearScreen
                {employee.date_time ? (
                  <span>
                    {' '}
                    on {new Date(employee.date_time).toLocaleDateString()}
                  </span>
                ) : (
                  ''
                )}
                .
              </React.Fragment>
            )}
            {employee.activity_status === 'started' && (
              <React.Fragment>
                {employee.first_name} started the ClearScreen
                {employee.date_time ? (
                  <span>
                    {' '}
                    on {new Date(employee.date_time).toLocaleDateString()}
                  </span>
                ) : (
                  ''
                )}
                , but has not yet finished.
              </React.Fragment>
            )}
          </p>
          {employee.survey_id && (
            <PartnerButton.Resend
              onSuccess={() => fetchData()}
              reqData={{ ...queryInfo, survey_id: employee.survey_id }}
              surveyId={employee.survey_id}
            />
          )}
        </PartnerTestState.Incomplete>
      )}
      {employee.status === 'complete' && (
        <PartnerTestState.Complete employee={employee} />
      )}
    </div>
  );
}
