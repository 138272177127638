import React from 'react';
import { Wrapped } from 'components/Layouts';

export default function ArchiveNotes({ note }) {
  if (!note) return null;

  return (
    <Wrapped title="Archive Notes">
      <div className="segment">
        <p>{note}</p>
      </div>
    </Wrapped>
  );
}

ArchiveNotes.defaultProps = {
  note: ''
};
