import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { FaStopwatch, FaEraser, FaTv } from 'react-icons/fa';
import PresentationItem from './PresentationItem';

export default function QuestionItem({
  id,
  averagePresentationTime,
  presentations,
  text,
  questionLatency,
  totalAnswerChanges,
  totalPresentations
}) {
  const [isOpen, setOpen] = useState(false);

  const tagItems = [
    {
      title: 'Presentations',
      value: totalPresentations,
      icon: <FaTv title="Presentations" {...tagProps} />,
      id: uuidv4()
    },
    {
      title: 'Answer Changes',
      value: totalAnswerChanges,
      icon: <FaEraser title="Answer Changes" {...tagProps} />,
      id: uuidv4()
    },
    {
      title: 'Question Latency',
      value: questionLatency,
      icon: <FaStopwatch title="Question Latency" {...tagProps} />,
      id: uuidv4()
    }
  ];

  return (
    <div className="response-question-item segment">
      <div className="meta">
        <h3>{text}</h3>
        <div className="tags">
          {tagItems
            .filter((f) => f.value)
            .map((t) => (
              <div className="tag-item" key={t.id}>
                {t.icon} {t.value}
              </div>
            ))}
        </div>
        <div className="actions">
          <Button
            size="tiny"
            basic
            icon={isOpen ? 'caret up' : 'caret down'}
            onClick={() => setOpen(!isOpen)}
            title={isOpen ? 'Close' : 'View Presentations'}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          {presentations.map((p) => (
            <PresentationItem
              key={p.id}
              answered={p.answered}
              duration={p.duration_in_seconds}
              response={p.response_value}
            />
          ))}
        </div>
      )}
    </div>
  );
}

QuestionItem.defaultProps = {
  averagePresentationTime: 0,
  questionLatency: '',
  position: '',
  presentations: [],
  text: '',
  totalAnswerChanges: 0,
  totalPresentations: 0
};

const tagProps = {
  size: 18
};
