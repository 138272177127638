import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PresentationItem from './PresentationItem';

export default function Timeline({ items }) {
  return (
    <div>
      {items.map((item) => (
        <PresentationItem
          key={uuidv4()}
          answered={item.answered}
          cls={`segment pres-timeline-item`}
          ended={item.ended_at}
          duration={item.duration_in_seconds}
          questionText={item.question_text}
          response={item.response_value}
          started={item.started_at}
        />
      ))}
    </div>
  );
}

Timeline.defaultProps = {
  items: []
};
