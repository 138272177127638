import React, { useState } from 'react';
import StarRatings from 'react-star-ratings';
import { v4 as uuidv4 } from 'uuid';
import { FaCaretRight, FaLink, FaCaretDown, FaCogs } from 'react-icons/fa';

import { SectionTitle } from '../';

import generateRows from './generateRows';

import './index.scss';

export const groupClasses = {
  business: 'business',
  collaboration: 'collaboration',
  communicate: 'communicate',
  customer: 'customer',
  drive: 'drive',
  financial: 'financial',
  influence: 'influence',
  manages: 'manages',
  navigates: 'navigates',
  strategic: 'strategic'
};

export default function CompetencyOutcomes({ admin, output, panResult }) {
  if (!output) return null;

  const rows = generateRows(output).filter((f) => (admin ? f.admin : true));

  const groups = Object.keys(groupClasses)
    .map((m) => ({
      name: m,
      rows: rows.filter((f) => f.cls === m)
    }))
    .map((m, i) => ({
      ...m,
      key: i,
      group_rating: m.rows.find((f) => f.header)
        ? m.rows.find((f) => f.header).value
        : 0
    }))
    .sort((a, b) => b.group_rating - a.group_rating);

  const headerRows = rows
    .filter((f) => f.header)
    .sort((a, b) => b.value - a.value)
    .map((m) => ({ ...m, value: m.value }));
  const topHeaderRows = [...headerRows].slice(0, 3);
  const bottomHeaderRows = [...headerRows]
    .slice(-3)
    .sort((a, b) => a.value - b.value);

  return (
    <React.Fragment>
      <div className="competency-outcomes">
        {/* <div className="competency-group">
          <div className={`competency-outcome header`}>
            <div className="title">{header.title}</div>
            <SectionTitle
              title={<React.Fragment>Overall</React.Fragment>}
              icon={<FaCogs />}
            />
            <div className="values">
              <div className="traits">
                <StarDisplay value={header.a} />
                <p>traits/preferences</p>
              </div>
              <div className="actions">
                <p>Your Overall Result (traits & behaviors)</p>
                <StarDisplay
                  value={output.pan_result.overall_comp_assess}
                  starSize={36}
                />
                <p>actions/behaviors</p>
              </div>
            </div>
          </div>
          <div className="top-bottom-wrapper">
            <TopBottom items={topHeaderRows} top />
            <TopBottom items={bottomHeaderRows} />
          </div>
        </div> */}
        <div className="competency-group">
          <SectionTitle
            title={<React.Fragment>Your Competency Results</React.Fragment>}
            icon={<FaCogs />}
          />
          <div className="top-bottom-wrapper">
            <TopBottom items={topHeaderRows} top />
            <TopBottom items={bottomHeaderRows} />
          </div>
        </div>
      </div>
      <p className="tip">Competency Ranking (ranked top to bottom)</p>
      <div className="competency-outcomes">
        {groups.map((g) => (
          <Group key={g.name} rows={g.rows} admin={admin} />
        ))}
      </div>
    </React.Fragment>
  );
}

CompetencyOutcomes.defaultProps = {
  admin: false,
  output: null,
  panResult: null
};

const Group = ({ rows, admin }) => {
  const [isOpen, setOpen] = useState(false);
  const header = rows.find((f) => f.header);
  const nonHeaderRows = rows
    .filter((f) => !f.header)
    .sort((a, b) => b.value - a.value);

  const sortedRows = nonHeaderRows
    .filter((f) => !f.header)
    .reduce((acc, cur) => {
      // acc.push({
      //   id: uuidv4(),
      //   value: cur.a,
      //   title: cur.title,
      //   description: 'traits/preferences',
      //   link: cur.link
      // });
      acc.push({
        id: uuidv4(),
        value: cur.value,
        title: cur.title,
        description: 'actions/behaviors',
        link: cur.link
      });
      return acc;
    }, [])
    .sort((a, b) => b.value - a.value);

  const top3 = [...sortedRows].slice(0, 3).map((m, i) => ({ ...m, key: i }));
  const bottom3 = [...sortedRows]
    .slice(-3)
    .map((m, i) => ({ ...m, key: i }))
    .sort((a, b) => a.value - b.value);

  console.log(rows);

  return (
    <div className="competency-group">
      <div className="group-meta">
        {header && (
          <div
            className={`competency-outcome header${
              header.cls ? ` ${header.cls}` : ''
            }`}
          >
            {/* <div className="title">{header.title}</div> */}
            <SectionTitle
              title={<React.Fragment>{header.title}</React.Fragment>}
              icon={<FaCogs />}
            />
            <div className="values">
              <div className="actions">
                <p>Based on your trait analysis</p>
                <StarDisplay value={header.value} starSize={36} />
              </div>
              <div className="traits">
                <p>Based on your behavioral analysis</p>
                <StarDisplay value={header.behavior_value} starSize={36} />
              </div>
            </div>
          </div>
        )}
        {header && !admin && (
          <button onClick={() => setOpen(!isOpen)} className="outcome-toggle">
            See more of your behavioral analysis
            {/* {isOpen ? 'Viewing' : 'View All'} {header.title} Results{' '} */}
            {isOpen ? <FaCaretDown /> : <FaCaretRight />}
          </button>
        )}
        {isOpen && nonHeaderRows.length > 0 && (
          <div className="top-bottom-wrapper">
            <TopBottom items={top3} top />
            <TopBottom items={bottom3} />
          </div>
        )}
        {header && header.link && (
          <a href={header.link} target="_blank" className="header-section-link">
            <FaLink /> View {header.title} Resources
          </a>
        )}
      </div>
      {/* {isOpen && (
        <div className="group-outcomes">
          {nonHeaderRows.map((r) => {
            return (
              <div
                key={r.key}
                className={`competency-outcome${r.cls ? ` ${r.cls}` : ''}${
                  r.header ? ' header' : ''
                }`}
              >
                <div className="title">
                  {r.title}{' '}
                  {r.link && (
                    <a href={r.link} target="_blank">
                      <FaLink />
                    </a>
                  )}
                </div>
                <div className="values">
                  <div className="traits">
                    <StarDisplay value={r.a} />
                    <p>traits/preferences</p>
                  </div>
                  <div className="actions">
                    <StarDisplay value={r.value} />
                    <p>actions/behaviors</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

Group.defaultProps = {
  admin: false,
  rows: []
};

const StarDisplay = ({ value, starSize }) => (
  <div className="star-display">
    <StarRatings
      rating={value}
      starDimension={`${starSize}px`}
      starRatedColor="#F56600"
      starSpacing="1px"
    />
    <span>{value} / 5 stars</span>
  </div>
);

StarDisplay.defaultProps = {
  starSize: 24
};

const TopBottom = ({ items, top }) => {
  if (!items.length) return null;

  return (
    <div className="top-bottom">
      <h5>{top ? 'Best Performances' : 'Opportunities for Improvement'}</h5>
      {items.map((item) => (
        <div key={item.key} className="top-bottom-item">
          <div className="wrapper">
            <div className="title">
              <p>
                {item.title}
                {/* <span>{item.description}</span> */}
              </p>
            </div>
            <StarDisplay value={item.value} />
          </div>
          {/* {item.link && (
            <a href={item.link} target="_blank">
              <FaLink />
            </a>
          )} */}
        </div>
      ))}
    </div>
  );
};

TopBottom.defaultProps = {
  items: [],
  top: false
};
