import React, { createContext, useEffect, useContext, useState } from 'react';
import { FaBan, FaExternalLinkAlt } from 'react-icons/fa';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import queryString from 'query-string';

import { Filters, Matrix, QuestionView } from './components';
import StarDisplay from './components/Matrix/StarDisplay';
import PrintButton from 'components/Reports/components/PdfDownload/PrintButton';

import { ActionButton } from 'components/shared/Buttons';

import './index.scss';

const MIN_EMPLOYEE_VALUE = ['dev'].includes(process.env.ENV) ? 0 : 5;
const PAY_SALARY = 1;
const MAX_RESPONSE_VALUE = 5;

const QUESTION_VIEW = 'question';
const MATRIX_VIEW = 'matrix';

const viewBtns = [
  { text: 'Question View', value: QUESTION_VIEW },
  { text: 'Matrix View', value: MATRIX_VIEW }
];

export const EngagementContext = createContext(null);

export default function EngagementProvider({ reportId, isAdmin, pathname }) {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState({
    company: {},
    employees: [],
    questions: [],
    ethnicities: [],
    genders: [],
    races: [],
    departments: [],
    positions: [],
    surveyName: ''
  });

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/engagement-reports/${reportId}/`)
      .then(({ data }) => {
        const employeeEthnicities = [
          ...new Set(data.employees.map((emp) => emp.data.ethnicity))
        ];
        const employeeGenders = [
          ...new Set(data.employees.map((emp) => emp.data.gender))
        ];
        const employeeRaces = [
          ...new Set(data.employees.map((emp) => emp.data.race))
        ];
        const employeeDepartments = [
          ...new Set(data.employees.map((emp) => emp.data.department_id))
        ];
        const employeePositions = [
          ...new Set(data.employees.map((emp) => emp.data.company_position_id))
        ];

        setReportData({
          completion_percentage: data.completion_progress
            ? (data.completion_progress.complete /
                (data.completion_progress.incomplete +
                  data.completion_progress.complete)) *
              100
            : 0,
          employees: data.employees.map((emp) => ({
            ...emp.data,
            salary: emp.data.salary_in_pennies / 100,
            answers: emp.responses.map((r) => ({
              question_id: r.parent_id,
              response: scoreResponse(r),
              comment: r.respondant_comment || null
            }))
          })),
          questions: data.questions,
          ethnicities: Object.keys(data.ethnicities)
            .map((r) => ({
              key: r,
              text: data.ethnicities[r],
              value: parseInt(r)
            }))
            .filter((f) => employeeEthnicities.includes(f.value)),
          genders: Object.keys(data.genders)
            .map((r) => ({
              key: r,
              text: data.genders[r],
              value: parseInt(r)
            }))
            .filter((f) => employeeGenders.includes(f.value)),
          races: Object.keys(data.races)
            .map((r) => ({
              key: r,
              text: data.races[r],
              value: parseInt(r)
            }))
            .filter((f) => employeeRaces.includes(f.value)),
          departments: data.departments
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name
            }))
            .filter((f) => employeeDepartments.includes(f.value)),
          positions: data.company_positions
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name
            }))
            .filter((f) => employeePositions.includes(f.value)),
          company: data.company,
          surveyName: data.survey_name
        });
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {});
  }

  return (
    <EngagementContext.Provider value={reportData}>
      {loading && <Loader active content="Loading Engagement Report..." />}
      {!loading && (
        <EngagementReport
          isAdmin={isAdmin}
          surveyContainerId={reportId}
          pathname={pathname}
        />
      )}
    </EngagementContext.Provider>
  );
}

function EngagementReport({ isAdmin, surveyContainerId, pathname }) {
  const [filters, setFilters] = useState(defaultFilterState);
  const reportData = useContext(EngagementContext);
  const { employees, questions } = reportData;
  const [view, setView] = useState(MATRIX_VIEW);

  useEffect(() => {
    const params = queryString.parseUrl(window.location.href);

    if (params.query && params.query.filters) {
      setFilters({
        ...defaultFilterState,
        ...JSON.parse(params.query.filters)
      });
    }
  }, []);

  function onFilterReset() {
    setFilters(defaultFilterState);
  }

  function onAutoRespondClick() {
    if (
      window.confirm(
        `You are about to auto answer every question for every employee. This will take some time and this app will not be usable until all of the questions are answered. Are you sure you want to do this?`
      )
    ) {
      axios
        .post(`/engagement-reports/${surveyContainerId}/auto-respond/`)
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          location.reload();
        });
    }
  }

  const filteredEmployees = [...employees].filter((emp) => {
    if (filters.ageMin && emp.age < filters.ageMin) return;
    if (filters.ageMax && emp.age > filters.ageMax) return;
    if (filters.department.length) {
      if (!filters.department.includes(emp.department_id)) return;
    }
    if (filters.position.length) {
      if (!filters.position.includes(emp.company_position_id)) return;
    }
    if (filters.race.length) {
      if (!filters.race.includes(emp.race)) return;
    }
    if (filters.ethnicity.length) {
      if (!filters.ethnicity.includes(emp.ethnicity)) return;
    }
    if (filters.gender.length) {
      if (!filters.gender.includes(emp.gender)) return;
    }
    if (filters.salaryMin && emp.salary < parseFloat(filters.salaryMin)) return;
    if (filters.salaryMax && emp.salary > parseFloat(filters.salaryMax)) return;
    if (filters.tenureMin && emp.tenure < parseFloat(filters.tenureMin)) return;
    if (filters.tenureMax && emp.tenure > parseFloat(filters.tenureMax)) return;

    return true;
  });

  const minsAndMaxs = {
    ageMin: filteredEmployees
      .map((e) => e.age)
      .reduce((a, b) => Math.min(a, b), 0),
    ageMax: filteredEmployees
      .map((e) => e.age)
      .reduce((a, b) => Math.max(a, b), 0),
    salaryMin: filteredEmployees
      .map((e) => e.salary)
      .reduce((a, b) => Math.min(a, b), 0),
    salaryMax: filteredEmployees
      .map((e) => e.salary)
      .reduce((a, b) => Math.max(a, b), 0),
    tenureMin: filteredEmployees
      .map((e) => e.tenure)
      .reduce((a, b) => Math.min(a, b), 0),
    tenureMax: filteredEmployees
      .map((e) => e.tenure)
      .reduce((a, b) => Math.max(a, b), 0)
  };

  const filteredAnswers = filteredEmployees
    // get all employee answers
    .map((f) => f.answers.filter((ans) => ans.response))
    // combine employee answer arrays
    .reduce((acc, cur) => acc.concat(cur), [])
    // reduce employee answers to a single question with responses
    .reduce((acc, cur) => {
      const question = acc.find((q) => q.question_id === cur.question_id);

      if (!question) {
        acc.push({
          question_id: cur.question_id,
          responses: [cur.response],
          comments: [cur.comment]
        });
      } else {
        question.responses = [...question.responses, cur.response];
        question.comments = [...question.comments, cur.comment];
      }

      return acc;
    }, [])
    .map((q) => ({
      question_id: q.question_id,
      responses: q.responses,
      comments: q.comments,
      // response values added together
      response_total: q.responses.reduce((acc, cur) => acc + cur, 0),
      // how many total points can be accumulated from the responses?
      response_total_max: q.responses.length * MAX_RESPONSE_VALUE,
      // how many employees have answered this question?
      employee_response_count: q.responses.length,
      // how many employees are there?
      employee_count: filteredEmployees.length
    }));

  const overallScore =
    Math.round(
      (filteredAnswers.reduce((acc, cur) => acc + cur.response_total, 0) /
        filteredAnswers.reduce((acc, cur) => acc + cur.response_total_max, 0)) *
        100 *
        100
    ) / 100;

  const stringFilters = [
    {
      key: 'dept',
      text: 'Department',
      value: formatList(
        filters.department.map((f) => {
          const obj = reportData.departments.find((d) => d.value === f);
          if (!obj) return;
          return obj.text;
        })
      )
    },
    {
      key: 'pos',
      text: 'Position',
      value: formatList(
        filters.position.map((f) => {
          const obj = reportData.positions.find((d) => d.value === f);
          if (!obj) return;
          return obj.text;
        })
      )
    },
    {
      key: 'eth',
      text: 'Ethnicity',
      value: formatList(
        filters.ethnicity.map((f) => {
          const obj = reportData.ethnicities.find((d) => d.value === f);
          if (!obj) return;
          return obj.text;
        })
      )
    },
    {
      key: 'race',
      text: 'Race',
      value: formatList(
        filters.race.map((f) => {
          const obj = reportData.races.find((d) => d.value === f);
          if (!obj) return;
          return obj.text;
        })
      )
    },
    {
      key: 'gender',
      text: 'Gender',
      value: formatList(
        filters.gender.map((f) => {
          const obj = reportData.genders.find((d) => d.value === f);
          if (!obj) return;
          return obj.text;
        })
      )
    },
    {
      key: 'minTen',
      text: 'Min Tenure',
      value: filters.tenureMin
    },
    {
      key: 'maxTen',
      text: 'Max Tenure',
      value: filters.tenureMax
    },
    {
      key: 'minAge',
      text: 'Min Age',
      value: filters.ageMin
    },
    {
      key: 'maxAge',
      text: 'Max Age',
      value: filters.ageMax
    },
    {
      key: 'minSal',
      text: 'Min Salary',
      value: filters.salaryMin
    },
    {
      key: 'maxSal',
      text: 'Max Salary',
      value: filters.salaryMax
    }
  ].filter((f) => f.value);

  const filteredCompletionPercentage =
    (filteredEmployees
      // get all employee answers
      .map((f) => f.answers.filter((ans) => ans.response))
      // combine employee answer arrays
      .reduce((acc, cur) => acc.concat(cur), []).length /
      (questions.length * filteredEmployees.length)) *
    100;

  return (
    <div className="engagement-report">
      <div className="engagement-meta">
        <h1 style={{ marginBottom: '0' }}>{reportData.surveyName}</h1>
        {reportData.company && (
          <p>
            Engagement Survey for <b>{reportData.company.name}</b>{' '}
            {isAdmin && (
              <Link
                to={`/companies/${reportData.company.id}`}
                style={{ color: 'inherit' }}
              >
                <FaExternalLinkAlt />
              </Link>
            )}
          </p>
        )}
        <p className="engagement-time">
          Generated: {new Date().toLocaleString()}
        </p>
        {stringFilters.length > 0 && (
          <p className="engagement-string-filters">
            Filters Applied:{' '}
            {stringFilters.map(({ text, key, value }) => (
              <span>
                <b>{text}</b>: {value}
              </span>
            ))}
          </p>
        )}
      </div>
      <ActionButton
        text={
          view === QUESTION_VIEW
            ? 'Switch to Matrix View'
            : 'Switch to Question View'
        }
        onClick={() =>
          setView(view === QUESTION_VIEW ? MATRIX_VIEW : QUESTION_VIEW)
        }
        style={{ fontWeight: 600, marginRight: '5px' }}
      />
      {/* {isAdmin && ['qa', 'dev'].includes(process.env.ENV) && (
        <div>
          <Button
            content="Auto Respond"
            onClick={onAutoRespondClick}
            basic
            size="mini"
            style={{ margin: '0 0 1.5em 0' }}
          />
        </div>
      )} */}
      {view === QUESTION_VIEW && (
        <React.Fragment>
          <QuestionView />
        </React.Fragment>
      )}
      {view === MATRIX_VIEW && (
        <React.Fragment>
          <PrintButton
            label="Download"
            id="engagementReport"
            filename={`${
              reportData.surveyName
            } ${new Date().toLocaleDateString()}`}
            pathname={
              stringFilters.length
                ? `${pathname}?filters=${JSON.stringify(filters)}`
                : pathname
            }
            cls={`engagement-dl-btn`}
            landscape
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          />
          <div className="display-wrapper">
            <Filters
              minsAndMaxs={minsAndMaxs}
              values={filters}
              onChange={(newFilters) => setFilters(newFilters)}
              employeeCount={filteredEmployees.length}
              filteredCompletionPercentage={filteredCompletionPercentage}
              completionPercentage={reportData.completion_percentage || 0}
              totalEmployeeCount={employees.length}
              totalResponsePercentage={
                (filteredAnswers.reduce(
                  (acc, cur) => acc + cur.responses.length,
                  0
                ) /
                  (filteredEmployees.length * filteredAnswers.length)) *
                  100 || 0
              }
              onResetClick={onFilterReset}
            />
            <div className="content" id="engagementReport">
              {filteredEmployees.length >= MIN_EMPLOYEE_VALUE && (
                <React.Fragment>
                  <div className="engagement-overall">
                    <h1>Overall Rating</h1>
                    <StarDisplay value={overallScore} starSize={24} />
                  </div>
                  <Matrix answers={filteredAnswers} />
                </React.Fragment>
              )}
              {filteredEmployees.length < MIN_EMPLOYEE_VALUE && (
                <div className="empty">
                  <FaBan />
                  <p>There are fewer than {MIN_EMPLOYEE_VALUE} employees.</p>
                  <p>Please remove some filters.</p>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const defaultFilterState = {
  ageMax: '',
  ageMin: '',
  department: [],
  ethnicity: [],
  payType: PAY_SALARY,
  position: [],
  gender: [],
  race: [],
  salaryMax: '',
  salaryMin: '',
  tenureMax: '',
  tenureMin: ''
};

function scoreResponse(r) {
  if (r.response_a) {
    return 1;
  }

  if (r.response_b) {
    return 2;
  }

  if (r.response_c) {
    return 3;
  }

  if (r.response_d) {
    return 4;
  }

  if (r.response_e) {
    return MAX_RESPONSE_VALUE;
  }

  return null;
}

function formatList(arr) {
  if (!arr.length) return '';

  return new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
  }).format(arr);
}
