import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';
import { ToggleButton } from 'components/shared/Buttons';

export default function Resolver({
  addable,
  label,
  loading,
  originalText,
  options,
  title,
  value,
  onChange,
  onCreate
}) {
  const [isOpen, setOpen] = useState(false);
  const [newValue, setNewValue] = useState(originalText);

  useEffect(() => {
    setOpen(false);
  }, [JSON.stringify(options)]);

  function onClose() {
    setOpen(false);
  }

  return (
    <div className="resolver-item">
      <Modal open={isOpen} onClose={onClose} size="tiny">
        <Modal.Header content={`Create ${title}`} />
        <Modal.Content>
          <Form>
            <Form.Input
              label={label}
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" basic onClick={onClose} />
          <Button
            content="Create"
            primary
            onClick={() => onCreate(newValue)}
            disabled={!newValue}
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
      <Form size="small">
        <Form.Select
          options={options}
          onChange={(e, d) => {
            const item = options.find((i) => i.value === d.value);
            if (!item) return;

            onChange({ value: d.value, text: item.text });
          }}
          label={
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ textTransform: 'capitalize', marginRight: '5px' }}>
                {originalText}
              </span>
              {addable && (
                <ToggleButton
                  onClick={() => setOpen(true)}
                  style={{ marginBottom: '2px' }}
                />
              )}
            </label>
          }
          search
          value={value}
          placeholder="Select One"
        />
      </Form>
    </div>
  );
}

Resolver.defaultProps = {
  addable: false,
  label: 'Label',
  originalText: '',
  title: 'Title'
};
