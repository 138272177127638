import React, { useState } from 'react';

import { EngagementContext } from '../../';

import Cell from './Cell';
import QuestionList from './QuestionList';

import populateRows, {
  DIMENSION_ORG,
  DIMENSION_TEAM,
  DIMENSION_SELF
} from './populateRows';

import './index.scss';

import mapAnswersToQuestions from '../../helpers/mapAnswersToQuestions';

const MATRIX_VIEW = 'matrix';
const LIST_VIEW = 'list';

export default function Matrix({ answers }) {
  const [view, setView] = useState(MATRIX_VIEW);
  const reportData = React.useContext(EngagementContext);
  const { questions } = reportData;

  const questionAndAnswers = mapAnswersToQuestions(questions, answers);
  const rows = populateRows(questionAndAnswers);

  const organizationQuestions = questionAndAnswers.filter(
    (f) => f.dimension === DIMENSION_ORG
  );
  const teamQuestions = questionAndAnswers.filter(
    (f) => f.dimension === DIMENSION_TEAM
  );
  const selfQuestions = questionAndAnswers.filter(
    (f) => f.dimension === DIMENSION_SELF
  );

  const headers = [
    {
      key: 1,
      title: 'Organization',
      response_total: organizationQuestions.reduce(
        (acc, cur) => acc + cur.response_total,
        0
      ),
      response_total_max: organizationQuestions.reduce(
        (acc, cur) => acc + cur.response_total_max,
        0
      ),
      employee_count: organizationQuestions.reduce(
        (acc, cur) => acc + cur.employee_count,
        0
      ),
      employee_response_count: organizationQuestions.reduce(
        (acc, cur) => acc + cur.employee_response_count,
        0
      ),
      subtitle: 'Views and perceptions of the organization-at-large.'
    },
    {
      key: 2,
      title: 'Team',
      response_total: teamQuestions.reduce(
        (acc, cur) => acc + cur.response_total,
        0
      ),
      response_total_max: teamQuestions.reduce(
        (acc, cur) => acc + cur.response_total_max,
        0
      ),
      employee_count: teamQuestions.reduce(
        (acc, cur) => acc + cur.employee_count,
        0
      ),
      employee_response_count: teamQuestions.reduce(
        (acc, cur) => acc + cur.employee_response_count,
        0
      ),
      subtitle:
        'Localized identity and connection with colleagues and the work itself.'
    },
    {
      key: 3,
      title: 'Self',
      response_total: selfQuestions.reduce(
        (acc, cur) => acc + cur.response_total,
        0
      ),
      response_total_max: selfQuestions.reduce(
        (acc, cur) => acc + cur.response_total_max,
        0
      ),
      employee_count: selfQuestions.reduce(
        (acc, cur) => acc + cur.employee_count,
        0
      ),
      employee_response_count: selfQuestions.reduce(
        (acc, cur) => acc + cur.employee_response_count,
        0
      ),
      subtitle:
        'Feelings toward others and the work itself; reflection of emotional state.'
    }
  ];

  const viewBtns = [
    { key: 1, content: 'Matrix View', icon: 'table', view: MATRIX_VIEW },
    { key: 2, content: 'List View', icon: 'list', view: LIST_VIEW }
  ];

  return (
    <div>
      <div className="engagement-view-buttons">
        {viewBtns.map((b) => (
          <button
            key={b.key}
            onClick={() => setView(b.view)}
            className={view === b.view ? 'active' : ''}
          >
            {b.content}
          </button>
          // <Button
          //   key={b.key}
          //   icon={b.icon}
          //   // content={b.content}
          //   // labelPosition="left"
          //   color={view === b.view ? 'blue' : null}
          //   onClick={() => setView(b.view)}
          //   size="mini"
          // />
        ))}
      </div>
      {view === LIST_VIEW && (
        <div className="engagement-list">
          <QuestionList questions={questionAndAnswers} />
        </div>
      )}
      {view === MATRIX_VIEW && (
        <div className="engagement-matrix">
          <div className="cell intro">
            <h4>Work Climate</h4>
            <h4>Matrix</h4>
          </div>
          {headers.map((h) => (
            <Cell
              key={h.key}
              title={h.title}
              subtitle={h.subtitle}
              employeeCount={h.employee_count}
              employeeResponseCount={h.employee_response_count}
              responseTotal={h.response_total}
              responseTotalMax={h.response_total_max}
              cls="header"
            />
          ))}
          {rows.map((r) => (
            <React.Fragment key={r.key}>
              {r.columns.map((c, i) => (
                <Cell
                  key={c.key}
                  cls={i === 0 ? 'row-title' : ''}
                  employeeCount={c.employee_count}
                  employeeResponseCount={c.employee_response_count}
                  questions={c.questions}
                  responseTotal={c.response_total}
                  responseTotalMax={c.response_total_max}
                  subtitle={c.subtitle}
                  groupTitle={c.group_title}
                  text={c.text}
                  title={c.title}
                  // value={i !== 0 ? 90 : null}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

Matrix.defaultProps = {
  answers: []
};
