import React, { useState } from 'react';
import { Wrapped } from 'components/Layouts';
import { ToggleButton } from 'components/shared/Buttons';

import { EngagementList, EngagementRequest } from 'components/Engagement';

export default function EngagementIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Engagement"
        button={
          <ToggleButton
            closedText="Request Engagement Report"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {!isOpen ? (
          <div className="segment">
            <EngagementList />
          </div>
        ) : (
          <div className="segment">
            <EngagementRequest onSuccess={() => setOpen(!isOpen)} />
          </div>
        )}
      </Wrapped>
    </div>
  );
}
