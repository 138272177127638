export const SET_TOAST = 'SET_TOAST';

function toastReducer(state, action) {
  switch (action.type) {
    case SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      }
    default:
      return state;
  }

  return state;
}

export default toastReducer;
