import CompanyDetail from './admin/CompanyDetail';
import CompanyIndex from './admin/CompanyIndex';
import CompareIndex from './admin/CompareIndex';
import EmployeeDetail from './admin/EmployeeDetail';
import EmployeeIndex from './admin/EmployeeIndex';
import EngagementDetail from './admin/EngagementDetail';
import EvaluationDetail from './admin/EvaluationDetail';
import EvaluationIndex from './admin/EvaluationIndex';
import ManagerDetail from './admin/ManagerDetail';
import ManagerIndex from './admin/ManagerIndex';
import ProfileDetail from './admin/ProfileDetail';
import ProfileIndex from './admin/ProfileIndex';
import Report360 from './admin/Report360';
import ReportCompetencyAssessment from './admin/ReportCompetencyAssessment';
import ReportDetail from './admin/ReportDetail';
import QuestionDetail from './admin/QuestionDetail';
import QuestionIndex from './admin/QuestionIndex';
import SurveyDetail from './admin/SurveyDetail';
import SurveyIndex from './admin/SurveyIndex';
import SurveyRequestDetail from './admin/SurveyRequestDetail';
import SurveyRequestIndex from './admin/SurveyRequestIndex';

import Dashboard from './manager/Dashboard';

export {
  CompanyDetail,
  CompanyIndex,
  CompareIndex,
  Dashboard,
  EmployeeDetail,
  EmployeeIndex,
  EngagementDetail,
  EvaluationDetail,
  EvaluationIndex,
  ManagerDetail,
  ManagerIndex,
  ProfileDetail,
  ProfileIndex,
  QuestionDetail,
  QuestionIndex,
  Report360,
  ReportCompetencyAssessment,
  ReportDetail,
  SurveyDetail,
  SurveyIndex,
  SurveyRequestDetail,
  SurveyRequestIndex
};
