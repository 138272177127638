import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaCheck, FaTimes } from 'react-icons/fa';

export default function AssetsAndLimitations({ assets, limitations }) {
  return (
    <div className="side-by-side-lists">
      <div>
        <h3>Assets</h3>
        <ul className="side-by-side-list">
          {assets.map((asset) => (
            <li key={uuidv4()}>
              <FaCheck className="green" /> {asset}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Limitations</h3>
        <ul className="side-by-side-list">
          {limitations.map((limitation) => (
            <li key={uuidv4()}>
              <FaTimes className="red" /> {limitation}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

AssetsAndLimitations.defaultProps = {
  assets: [],
  limitations: []
};
