import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';

export default function CompanySelect({ value, onChange }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/company-list/`)
      .then(({ data }) => {
        setOptions(
          data.companies
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((d) => ({
              key: d.id,
              text: d.name,
              value: d.id
            }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setOptions([]);
        setLoading(false);
      });
  }

  return (
    <Form.Select
      label="Company"
      value={value}
      onChange={(e, d) => onChange(d.value)}
      placeholder="Select one..."
      loading={loading}
      options={options}
      search
    />
  );
}
