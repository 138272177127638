import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.influences_others_factors) return [];

  const best = output.influences_others_factors.best
    ? Object.keys(output.influences_others_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.influence,
        value: output.influences_others_factors.best[b]
      }))
    : [];
  const worst = output.influences_others_factors.worst
    ? Object.keys(output.influences_others_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.influence,
        value: output.influences_others_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Influences Others',
      value: output.influences_others,
      behavior_value: output.pan_result.influences_others_score,
      cls: groupClasses.influence,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Levers',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Vision & Strategy',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Roles',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Duties',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Timelines',
    //   cls: groupClasses.influence,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Influences-Others-Courses.aspx'
  }));
}
