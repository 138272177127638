import React from 'react';

export default function Intro() {
  return (
    <div className="intro-360">
      <p>
        This 360 is designed using the 4P Competency Model. This model,
        hierarchical in its structure, focuses on the characteristics inherent
        in and required for success in various positions. It encompasses the
        full spectrum of leadership behaviors and is applicable at all
        organizational levels. This model provides the building blocks of
        leadership and organizes behavior.
      </p>
      <p>
        This 360 is designed to help you understand how your behavior impacts
        others within the body of the organization.
      </p>
    </div>
  );
}
