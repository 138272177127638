import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const windowWidth = window.innerWidth;

const width =
  windowWidth * (windowWidth > 800 ? 0.75 : 0.95) - margin.left - margin.right;
// const width = 800 - margin.left - margin.right;
const height = 60 - margin.top - margin.bottom;
const transformStyle = `translate(${50}, ${25})`;

const markerSize = 40;

const SkillSection = ({
  sample,
  pGroup,
  id,
  title,
  skillTotal,
  skillInt,
  importanceTotal,
  gap
}) => {
  const svgDiv = useRef(null);

  const onSectionClick = () => {
    const correspondingGridItem = document.getElementById(`grid${skillInt}`);

    if (correspondingGridItem) {
      correspondingGridItem.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      // correspondingGridItem.classList.add('scrolled');
      correspondingGridItem.click();

      // setTimeout(() => {
      //   correspondingGridItem.classList.remove('scrolled');
      // }, 1000);
    }
  };

  useEffect(() => {
    if (svgDiv.current) {
      const sliderDiv = d3.select(svgDiv.current);
      sliderDiv.selectAll('*').remove();
      // const sliderDiv = d3.select(svgDiv.current);

      const svg = sliderDiv
        .append('svg')
        .attr('class', `chart${sample && ' sample'}`)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom);

      const scale = d3
        .scaleLinear()
        .domain([1, 5])
        .range([0, width - 100]);

      let xAxis = d3
        .axisBottom()
        .tickFormat('')
        .tickSize(12)
        .ticks(40)
        .scale(scale);

      if (sample) {
        xAxis = d3
          .axisBottom()
          .tickFormat((d) => {
            switch (d) {
              case 1:
                return 'Deficient';
              case 2:
                return 'Weak';
              case 3:
                return 'Competent';
              case 4:
                return 'Strong';
              case 5:
                return 'Exceptional';
              default:
                return '';
            }
          })
          .tickSize(12)
          .ticks(5)
          .scale(scale);
      }

      svg.append('g').attr('transform', transformStyle).call(xAxis);

      if (!sample) {
        svg
          .append('rect')
          .attr('class', 'optimal-range')
          .attr('x', () =>
            skillTotal > importanceTotal
              ? scale(importanceTotal)
              : scale(skillTotal)
          )
          .attr('y', () => -1)
          .attr('width', () => {
            return Math.abs(
              scale(skillTotal - 0.9) - scale(importanceTotal - 0.9)
            );
          })
          .attr('height', 15)
          .attr('transform', transformStyle);

        svg
          .append('text')
          .attr('x', () => scale(skillTotal - 0.03))
          .attr('class', 'skill-icon')
          .attr('y', markerSize / 2)
          .attr('font-size', () => `${markerSize}px`)
          .attr('fill', '#000')
          .attr('transform', transformStyle)
          .text(() => `\u2666`);
        svg
          .append('text')
          .attr('x', () => scale(importanceTotal - 0.055))
          .attr('class', 'importance-icon')
          .attr('y', markerSize / 2)
          .attr('font-size', () => `${markerSize}px`)
          .attr('fill', '#000')
          .attr('transform', transformStyle)
          .text(() => `\u2605`);
      }
    }
  }, [skillTotal, importanceTotal, gap]);

  return (
    <div
      className={`skill-section-viz${!sample ? ' live' : ''}${
        pGroup ? ` ${pGroup}` : ''
      }`}
      onClick={() => (sample ? null : onSectionClick())}
    >
      <div className="text">
        {!sample && (
          <React.Fragment>
            <h4>
              ({skillInt + 1}) {title}
            </h4>
          </React.Fragment>
        )}
      </div>
      <div ref={svgDiv}></div>
    </div>
  );
};

export default SkillSection;

SkillSection.defaultProps = {
  sample: false
};
