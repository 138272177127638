import React from 'react';

import { FaCircle } from 'react-icons/fa';

import { TableLinkButton } from 'components/shared/Buttons';
import { PaginatedTable } from 'components/shared/Tables';

const columns = [
  {
    Header: '',
    id: 'view',
    width: 40,
    sortable: false,
    accessor: (d) => <TableLinkButton url={`/companies/${d.id}`} />
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    filterable: true,
    sortable: false
  },
  {
    Header: 'City',
    accessor: 'city',
    sortable: false
  },
  {
    Header: 'State',
    id: 'state_provence',
    accessor: 'state_provence',
    filterable: true,
    sortable: false,
    width: 80
  },
  {
    Header: 'Zip',
    accessor: 'zip',
    sortable: false,
    width: 100
  }
  // {
  //   Header: 'Managers',
  //   accessor: 'manager_count',
  //   sortable: false,
  //   width: 100
  // },
  // {
  //   Header: 'Employees',
  //   accessor: 'employee_count',
  //   sortable: false,
  //   width: 100
  // },
];

const filterOptions = [
  { id: 'name', object: 'self' },
  { id: 'state_provence', object: 'self' }
];

const statusFilterButtons = [
  { text: 'Active', value: 0, icon: <FaCircle /> },
  { text: 'Inactive', value: 1, icon: <FaCircle /> }
];

const filterButtons = [
  {
    name: 'status',
    text: 'Status',
    default: 0,
    buttons: statusFilterButtons
  }
];

export default function Table() {
  return (
    <div className="segment">
      {/*
          <div className="filter-button-header">
            <IconFilterButtons
              active={this.state.status}
              buttons={statusFilterButtons}
              loading={this.state.loading}
              onClick={this.onStatusButtonClick}
              text="Status"
            />
          </div>
        */}
      <PaginatedTable
        columns={columns}
        entityName="Companies"
        filterButtons={filterButtons}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-companies/"
      />
    </div>
  );
}
