import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { FaStar } from 'react-icons/fa';

import StarDisplay from '../Matrix/StarDisplay';

export default function QuestionItem({
  title,
  dimension,
  factor,
  departments
}) {
  const [isOpen, setOpen] = useState(false);

  const topDepts = [...departments]
    .sort((a, b) => b.response_rating - a.response_rating)
    .slice(0, 3);
  const bottomDepts = [...departments]
    .sort((a, b) => a.response_rating - b.response_rating)
    .slice(0, 3);

  return (
    <div className="question-dept-list-item">
      <div className="meta">
        <div className="meta-top">
          {departments.length > 0 && (
            <Button
              circular
              size="mini"
              onClick={() => setOpen(!isOpen)}
              icon={isOpen ? 'caret down' : 'caret right'}
              basic
            />
          )}
          <div className="title">
            <p>{title}</p>
            <div className="tags">
              <span className="tag dimension">{dimension}</span>
              <span className="tag factor">{factor}</span>
            </div>
          </div>
        </div>
        <div className="meta-bottom">
          {departments.length > 0 && (
            <React.Fragment>
              <Viz
                title="Top Raters"
                names={topDepts.map((t) => ({
                  id: t.id,
                  text: t.text,
                  value: Math.round((t.response_rating / 20) * 100) / 100,
                  comments: t.answers
                    .filter((f) => f.comment)
                    .map((m) => m.comment)
                }))}
                positive
              />
              <Viz
                title="Lowest Raters"
                names={bottomDepts.map((t) => ({
                  id: t.id,
                  text: t.text,
                  value: Math.round((t.response_rating / 20) * 100) / 100,
                  comments: t.answers
                    .filter((f) => f.comment)
                    .map((m) => m.comment)
                }))}
              />
              {/* <HighchartsReact highcharts={Highcharts} options={hcOptions} /> */}
            </React.Fragment>
          )}
          {departments.length === 0 && <p>No department data</p>}
        </div>
      </div>
      {isOpen && <QuestionContent departments={departments} />}
    </div>
  );
}

QuestionItem.defaultProps = {
  departments: [],
  dimension: '',
  factor: '',
  title: ''
};

const Viz = ({ names, title, positive }) => {
  return (
    <div className="meta-viz">
      <h4>{title}</h4>
      <ul className={positive ? 'positive' : ''}>
        {names.map((n) => (
          <VizItem
            key={n.id}
            text={n.text}
            value={n.value}
            comments={n.comments}
          />
        ))}
      </ul>
    </div>
  );
};

Viz.defaultProps = {
  title: [],
  names: [],
  positive: false
};

const VizItem = ({ text, value, comments }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        onClose={onClose}
        size="small"
        className="engagement-question-modal"
      >
        <Modal.Header content={`${text} comments`} />
        <Modal.Content>
          <div className="comments">
            {comments.map((c, i) => (
              <p key={i}>{c}</p>
            ))}
            {comments.length === 0 && <p>No comments</p>}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic onClick={onClose} />
        </Modal.Actions>
      </Modal>
      <li onClick={() => setOpen(true)}>
        <span className="text">{text}</span>
        <span className="value">
          <FaStar style={{ marginRight: '3px' }} /> {value}
        </span>
      </li>
    </React.Fragment>
  );
};

VizItem.defaultProps = {
  text: '',
  value: '',
  comments: []
};

const QuestionContent = ({ departments }) => {
  return (
    <div className="content">
      <h4>All raters for this question</h4>
      {departments
        .sort((a, b) => b.response_rating - a.response_rating)
        .map((d) => (
          <div key={d.id} className="dept">
            <h5>{d.text}</h5>
            <StarDisplay value={d.response_rating} />
          </div>
        ))}
    </div>
  );
};
