import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import { Message } from '../';

export default function PasswordForm(props) {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  function onSubmit(e) {
    e.preventDefault();
    props.onSubmit({ password, confirmedPassword })
  }

  return (
    <div>
      <form className="auth-form">
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          name="confirmedPassword"
          placeholder="Password Confirmation"
          value={confirmedPassword}
          onChange={(e) => setConfirmedPassword(e.target.value)}
        />
        {
          props.error &&
            <Fade bottom>
              <Message
                negative
                text={props.error}
              />
            </Fade>
        }
        <button
          disabled={!password || !confirmedPassword || password !== confirmedPassword || props.disabled}
          onClick={onSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
