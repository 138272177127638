import React from 'react';
import { Radio, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { BasicTable } from 'components/shared/Tables';

export default function ExistingEmployees({ actionable, data, onChange }) {
  const employeeColumns = [
    { Header: 'Row', accessor: 'row', width: 50 },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Company', accessor: 'company', show: !actionable },
    {
      Header: 'Matches',
      id: 'matches',
      sortable: false,
      show: actionable,
      filterable: false,
      accessor: (d) => {
        const inputName = `${d.uuid}`;

        if (!actionable) return '';

        return (
          <div>
            {d.matches.map((match) => (
              <Item
                key={match.id}
                email={match.email}
                name={`${match.first_name} ${match.last_name}`}
                id={match.id}
                inputName={inputName}
                onChange={() => onChange(d.uuid, match)}
                selected={d.value ? d.value.id === match.id : false}
              />
            ))}
            <div className="ee-item">
              <Radio
                onChange={() => onChange(d.uuid, null)}
                label="None of these"
                name={inputName}
                checked={d.value === null}
              />
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <BasicTable
      fetch={false}
      data={data}
      columns={employeeColumns}
      entityName="Employees"
    />
  );
}

ExistingEmployees.defaultProps = {
  actionable: true,
  data: []
};

const Item = ({ name, email, id, inputName, onChange, selected }) => {
  return (
    <div className="ee-item">
      <div className="meta">
        <Radio name={inputName} checked={selected} onChange={onChange} />{' '}
        <span>{name}</span>{' '}
        <Link to={`/employees/${id}`} target="_blank">
          <Icon
            name="external alternate"
            style={{ marginLeft: '5px', color: '#2caeba' }}
          />
        </Link>
      </div>
      <div className="extra">{email}</div>
    </div>
  );
};

Item.defaultProps = {
  selected: false
};
