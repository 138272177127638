import React from 'react';
import QuestionItem from './QuestionItem';

export default function SampleQuestion(props) {
  const showMeta = props.header || props.instructionText ? true : false;

  return (
    <React.Fragment>
      {
        showMeta &&
          <div className="sample-meta">
            {
              props.header &&
              <h2>{props.header}</h2>
            }
            {
              props.instructionText &&
                <p>{props.instructionText}</p>
            }
          </div>
      }
      <QuestionItem
        multipleChoice={true}
        answerA={props.answerA}
        answerB={props.answerB}
        answerC={props.answerC}
        answerD={props.answerD}
        answerE={props.answerE}
        next={props.next}
        inactive={props.inactive}
        text={props.text}
        onClick={props.onClick}
        onAnswerClick={props.onAnswerClick}
        showAnswers={props.showAnswers}
        selected={props.selected}
      />
    </React.Fragment>
  );
}

SampleQuestion.defaultProps = {
  answerA: '',
  answerB: '',
  answerC: '',
  answerD: '',
  answerE: '',
  inactive: false,
  header: '',
  instructionText: '',
  next: false,
  selected: null,
  showAnswers: true,
  text: '',
}
