import React from 'react';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

export default function ToggleButton(props) {
  return (
    <button
      onClick={props.onClick}
      className="button toggle"
      className={`button toggle${props.open ? ' open' : ''}`}
      style={{ marginBottom: '10px', ...props.style }}
      type="button"
    >
      {!props.open && <FaPlusCircle size={18} />}
      {props.open && <FaMinusCircle size={18} />}
      {/*
          {!props.open && props.closedText}
          {props.open && 'Close'}
        */}
    </button>
  );
}

ToggleButton.defaultProps = {
  closedText: 'Add',
  open: false,
  text: 'Basic',
  style: {}
};
