import { groupClasses } from '../';

export default function (output) {
  if (!output || !output.collaboration_factors) return [];

  const best = output.collaboration_factors.best
    ? Object.keys(output.collaboration_factors.best).map((b) => ({
        title: b,
        cls: groupClasses.collaboration,
        value: output.collaboration_factors.best[b]
      }))
    : [];
  const worst = output.collaboration_factors.worst
    ? Object.keys(output.collaboration_factors.worst).map((b) => ({
        title: b,
        cls: groupClasses.collaboration,
        value: output.collaboration_factors.worst[b]
      }))
    : [];

  return [
    {
      title: 'Collaboration',
      value: output.collaboration,
      behavior_value: output.pan_result.collaboration_score,
      cls: groupClasses.collaboration,
      header: true,
      admin: true
    },
    ...best,
    ...worst
    // {
    //   title: 'Knowledge',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Skill',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Behavior',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Teamwork',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Command',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Outreach',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Growth',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // },
    // {
    //   title: 'Inclusion',
    //   cls: groupClasses.collaboration,
    //   a: panResult.foobar,
    //   b: panResult.foobar
    // }
  ].map((m) => ({
    ...m,
    link:
      'https://louisianapacific.sharepoint.com/sites/LPSU/SitePages/Collaborates-with-Others-Courses.aspx'
  }));
}
