import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Wrapped } from 'components/Layouts';

import { ToggleButton } from 'components/shared/Buttons';

import { QuestionForm, Table } from 'components/Questions';

export default function QuestionIndex(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Questions"
      button={
        <ToggleButton
          closedText="Add Question"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {
        isOpen
          ? (
            <QuestionForm
              onClose={() => setOpen(false)}
              showCancel
            />
          )
          : <Table />
      }
    </Wrapped>
  );
}
