import React from 'react';

export default function DownloadButton(props) {
  return (
    <a
      href={props.href}
      download={props.filename}
      className="button action download"
    >
      Download
    </a>
  );
}

DownloadButton.defaultProps = {
  loading: false,
  text: 'Basic',
}
