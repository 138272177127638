import React from 'react';
import { FaRegThumbsUp } from 'react-icons/fa';
import List from './List';

import ProgressLine from './ProgressLine';
import StarDisplay from './StarDisplay';

const Cell = ({
  cls,
  employeeCount,
  employeeResponseCount,
  groupTitle,
  questions,
  responseTotal,
  responseTotalMax,
  subtitle,
  title,
  value
}) => {
  const percentage = (responseTotal / responseTotalMax) * 100;
  const decimalPercentage = responseTotal / responseTotalMax;

  const cellQuestionTotal = questions.length
    ? questions.reduce(
        (acc, cur) => acc + (cur.response_total / cur.response_total_max) * 100,
        0
      ) / questions.length
    : null;

  const cellColor = getCellColorClass(cellQuestionTotal);

  return (
    <div className={`cell ${cellColor} ${cls}`}>
      {title && (
        <h4>
          {/* <FaRegThumbsUp
            size={20}
            style={{
              transform: `rotate(${decimalPercentage * -180 + 180}deg)`
            }}
          /> */}
          {title}{' '}
        </h4>
      )}
      {subtitle && <p>{subtitle}</p>}
      {questions.length > 0 && <List items={questions} />}
      {value && <small className="value">{value}%</small>}
      {title && percentage > 0 && <StarDisplay value={percentage} />}
      {cellQuestionTotal > 0 && (
        // <p className="cell-total">
        //   {Math.round(cellQuestionTotal * 100) / 100}% of employees agree
        // </p>
        <div className="cell-total">
          {groupTitle && <h5>{groupTitle}</h5>}
          <div className="cell-total-wrapper">
            <StarDisplay value={(cellQuestionTotal * 100) / 100} />
          </div>
        </div>
      )}
      {/* {title && percentage > 0 && (
        <p className="cell-total" style={{ marginTop: '1em' }}>
          {Math.round(percentage * 100) / 100}% of employees agree
        </p>
      )} */}
    </div>
  );
};

Cell.defaultProps = {
  cls: '',
  employeeCount: 0,
  employeeResponseCount: 0,
  questions: [],
  groupTitle: '',
  responseTotal: 0,
  responseTotalMax: 0,
  subtitle: '',
  title: ''
};

export default Cell;

function getCellColorClass(value) {
  if (!value) return '';

  if (value < 60) {
    return 'low';
  }

  if (value >= 60 && value < 70) {
    return 'medium';
  }

  if (value >= 71) {
    return 'high';
  }
}
