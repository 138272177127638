import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import logo from 'assets/images/logo.png';

import './index.scss';

function Header({ manager }) {
  function logout(e) {
    e.preventDefault();

    const url = manager ? `/company-manager-logout/` : `/admin-logout/`;

    axios
      .post(url)
      .then(() => {
        window.location = '/';
      })
      .catch(() => {
        window.location = '/';
      });
  }

  return (
    <header className="site-header">
      <NavLink to="/">
        <img src={logo} alt="BCK" />
      </NavLink>
      <div className="links">
        {manager && (
          <React.Fragment>
            <NavLink to="/employees">Employees</NavLink>
            <NavLink to="/compare">Compare</NavLink>
            <NavLink to="/engagement">Engagement</NavLink>
          </React.Fragment>
        )}
        {!manager && (
          <React.Fragment>
            <NavLink to="/companies">Companies</NavLink>
            <NavLink to="/employees">Employees</NavLink>
            <NavLink to="/managers">Managers</NavLink>
            <NavLink to="/surveys">Surveys</NavLink>
            <NavLink to="/questions">Questions</NavLink>
            <NavLink to="/requests">Requests</NavLink>
            <NavLink to="/profiles">Profiles</NavLink>
            <NavLink to="/evaluations">Evaluations</NavLink>
            <NavLink to="/compare">Compare</NavLink>
          </React.Fragment>
        )}
        <button onClick={logout}>Logout</button>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    // name: state.admin.full_name,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

Header.defaultProps = {
  manager: false
};

Header.propTypes = {
  manager: PropTypes.bool
};
