import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div>
      <h1>Not Found!</h1>
      <p>The page you were looking for couldn't be located.</p>
      <div className="auth-other">
        <Link to="/">Take me to login</Link>
      </div>
    </div>
  );
}