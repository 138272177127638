import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { FaPencilAlt } from 'react-icons/fa';

export default function Question({ category, responses, text, onUpdate }) {
  return (
    <div className="perf-question">
      <div className={`box`}>
        <p style={{ margin: '0 0 3px 0' }} className="response">
          <b>{category}</b>
        </p>
        <p>{text}</p>
      </div>
      {responses.map((r) => (
        <ResponseBox
          key={r.id}
          answer={r.answer}
          comment={r.comment}
          editable={r.editable}
          hasComment={r.has_respondant_comment}
          multipleChoice={r.multiple_choice}
          multipleChoiceAnswers={[
            { text: r.a, answerKey: 'response_a' },
            { text: r.b, answerKey: 'response_b' },
            { text: r.c, answerKey: 'response_c' },
            { text: r.d, answerKey: 'response_d' },
            { text: r.e, answerKey: 'response_e' }
          ]}
          onUpdate={onUpdate}
          openResponse={r.open_response}
          questionId={r.id}
          responseYes={r.response_yes}
          responseNo={r.response_no}
          trueFalse={r.true_false}
          yesNo={r.yes_no}
        />
      ))}
    </div>
  );
}

Question.defaultProps = {
  category: '',
  responses: [],
  text: ''
};

function ResponseBox({
  answer,
  comment,
  editable,
  hasComment,
  openResponse,
  multipleChoice,
  multipleChoiceAnswers,
  questionId,
  responseYes,
  responseNo,
  trueFalse,
  yesNo,
  onUpdate
}) {
  const [isOpen, setOpen] = useState(false);

  function onButtonClick(answerObj) {
    const req = {
      ...answerObj,
      question_id: questionId
    };

    axios
      .post(`/questions/${questionId}/record-response/`, req)
      .then(() => onUpdate())
      .catch((err) => {});
  }

  // this could be for a open response or a comment
  // the key name decides which one it is
  function onTextAreaBlur(keyName, value) {
    const req = {
      question_id: questionId,
      [keyName]: value
    };

    let url = `/questions/${questionId}/record-response/`;

    if (keyName === 'respondant_comment') {
      url = `/questions/${questionId}/record-comment/`;
    }

    axios
      .post(url, req)
      .then(() => onUpdate())
      .catch((err) => {});
  }

  const trueFalseButtons = [
    {
      text: 'True',
      onClick: () => onButtonClick({ response_true_false: true }),
      active: answer === 'True'
    },
    {
      text: 'False',
      onClick: () => onButtonClick({ response_true_false: false }),
      active: answer === 'False'
    }
  ];

  const multipleChoiceButtons = multipleChoiceAnswers.map((a) => ({
    text: a.text,
    onClick: () => onButtonClick({ [a.answerKey]: true }),
    active: answer === a.text
  }));

  const yesNoButtons = [
    {
      text: 'Yes',
      onClick: () => onButtonClick({ response_yes: true }),
      active: responseYes
    },
    {
      text: 'No',
      onClick: () => onButtonClick({ response_no: true }),
      active: responseNo
    }
  ];

  return (
    <div className="box">
      {!isOpen && (
        <React.Fragment>
          {!openResponse && (
            <p className="response" style={{ marginBottom: '3px' }}>
              {answer && <b>{answer}</b>}
              {responseYes && <b>Yes</b>}
              {responseNo && <b>No</b>}
              {!answer && !responseYes && !responseNo && 'Unanswered'}
            </p>
          )}
          {openResponse && (
            <React.Fragment>
              <p className="response" style={{ marginBottom: '3px' }}>
                {answer ? answer : 'Unanswered'}
              </p>
            </React.Fragment>
          )}
          {comment && <p>{comment}</p>}
        </React.Fragment>
      )}
      {isOpen && (
        <div>
          {openResponse && (
            <ResponseTextArea
              onBlur={(val) => onTextAreaBlur('open_response_reply', val)}
              value={answer}
            />
          )}
          {trueFalse && <ResponseButtons buttons={trueFalseButtons} />}
          {multipleChoice && (
            <ResponseButtons buttons={multipleChoiceButtons} />
          )}
          {yesNo && <ResponseButtons buttons={yesNoButtons} />}
          {hasComment && (
            <ResponseTextArea
              label="Comment"
              onBlur={(val) => onTextAreaBlur('respondant_comment', val)}
              value={comment}
            />
          )}
        </div>
      )}
      {editable && !isOpen && (
        <button
          className="response-edit-btn"
          onClick={() => setOpen(!isOpen)}
          title={isOpen ? 'Close' : 'Edit Answer'}
        >
          <FaPencilAlt /> <span>Edit Answer</span>
        </button>
      )}
      {isOpen && (
        <button className="response-edit-save" onClick={() => setOpen(false)}>
          Save & Close
        </button>
      )}
    </div>
  );
}

ResponseBox.defaultProps = {
  answer: '',
  comment: '',
  editable: false,
  hasComment: false,
  multipleChoice: false,
  multipleChoiceAnswers: [],
  openResponse: false,
  questionId: null,
  responseYes: false,
  responseNo: false,
  trueFalse: false,
  yesNo: false
};

function ResponseButtons({ buttons }) {
  return (
    <div className="response-edit-btns">
      {buttons.map(({ text, onClick, active }) => (
        <button
          key={uuidv4()}
          onClick={onClick}
          className={active ? 'active' : ''}
        >
          {text}
        </button>
      ))}
    </div>
  );
}

ResponseButtons.defaultProps = {
  buttons: []
};

function ResponseTextArea({ label, value, onBlur }) {
  const [val, setVal] = useState('');

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div className="response-edit-textarea">
      {label && <label>{label}</label>}
      <textarea
        rows="5"
        onBlur={(e) => onBlur(e.target.value)}
        value={val}
        onChange={(e) => setVal(e.target.value)}
      />
    </div>
  );
}

ResponseTextArea.defaultProps = {
  value: ''
};
