import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { SET_ADMIN } from './duck/admin';
import { SET_MANAGER } from './duck/manager';

import Admin from './containers/Admin';
import Auth from './containers/Auth';
import Manager from './containers/Manager';

class BckApp extends Component {
  state = {
    error: null,
    loading: true
  };

  componentDidMount() {
    axios
      .get('/status/')
      .then(({ data }) => {
        if (data.admin) {
          return this.props.dispatch({
            type: SET_ADMIN,
            payload: data.admin
          });
        }

        if (data.company_manager) {
          return this.props.dispatch({
            type: SET_MANAGER,
            payload: data.company_manager
          });
        }

        if (data.headless_id) {
          return this.props.dispatch({
            type: SET_MANAGER,
            payload: {
              id: 8675309,
              email: 'headlesssession@websuasion.com',
              first_name: 'Headless',
              last_name: 'Session'
            }
          });
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false, error: 'Status Error' });
      });
  }

  render() {
    const { error, loading } = this.state;
    const { admin, manager } = this.props;

    if (error) {
      return <Auth error={true} />;
    }

    if (loading) {
      return <div></div>;
    }

    if (!loading && !admin && !manager) {
      return <Auth />;
    }

    if (admin) {
      return <Admin />;
    }

    if (manager) {
      return <Manager />;
    }

    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    manager: state.manager
  };
}

export default connect(mapStateToProps)(BckApp);
