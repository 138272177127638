import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';

import BasicForm from 'components/shared/Forms/BasicForm';
import { SET_TOAST } from 'duck/toast';
import { BasicButton } from 'components/shared/Buttons';

import phoneRegExp from '@util/validators/phone';
import usStates from '@util/data/usStates';

function CompanyManagerForm({
  companyManagerId,
  companyId,
  firstName,
  lastName,
  email,
  address1,
  address2,
  city,
  hrAdmin,
  phone,
  state,
  status,
  zip,
  country,
  onClose,
  onUpdate,
  create,
  dispatch
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let fields = [
    {
      grouped: true,
      fields: [
        {
          name: 'first_name',
          label: ' First Name',
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'last_name',
          label: ' Last Name',
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      grouped: true,
      fields: [
        {
          name: 'email',
          label: ' Email',
          initialValue: email,
          required: true,
          schema: () =>
            Yup.string().email('Not a valid email...').required('Required')
        },
        {
          name: 'phone',
          initialValue: phone,
          label: 'Phone',
          required: true,
          schema: () =>
            Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid')
              .required('Required')
        }
      ]
    },
    {
      name: 'address_line_1',
      label: 'Address',
      initialValue: address1,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'address_line_2',
      label: 'Address Line 2',
      initialValue: address2,
      required: false,
      schema: () => null
    },
    {
      grouped: true,
      fields: [
        {
          name: 'city',
          label: 'City',
          initialValue: city,
          required: true,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'state_provence',
          label: 'State',
          initialValue: state,
          required: true,
          select: true,
          search: true,
          options: usStates,
          schema: () => Yup.string().required('Required')
        },
        {
          name: 'zip',
          label: 'Zip',
          initialValue: zip,
          required: true,
          schema: () => Yup.string().required('Required')
        }
      ]
    },
    {
      name: 'country',
      label: 'Country',
      initialValue: country,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'status',
      label: 'Status',
      initialValue: status,
      required: true,
      select: true,
      options: [
        { key: 0, text: 'Active', value: 0 },
        { key: 1, text: 'Inactive', value: 1 }
      ],
      schema: () => Yup.string().nullable('Required').required('Required')
    },
    {
      name: 'hr_admin',
      label: 'HR Admin',
      radio: true,
      initialValue: hrAdmin,
      schema: () => null
    }
  ];

  if (!companyManagerId) fields = fields.filter((f) => f.name !== 'status');

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (create) return onCreate(formData);

    axios
      .put(`/company-managers/${companyManagerId}/`, { ...formData })
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Manager Updated!'
          }
        });
      })
      .then(() => setLoading(false))
      .then(() => onUpdate())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onCreate(formData) {
    axios
      .post(`/companies/${companyId}/managers/`, {
        ...formData,
        company_id: companyId
      })
      .then(() => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Company Manager Created!'
          }
        });
      })
      .then(() => onClose())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <Fade>
      <div className="segment">
        <BasicForm
          buttonText="Save"
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onSubmit}
        >
          {onClose && <BasicButton onClick={onClose} text="Cancel" />}
        </BasicForm>
      </div>
    </Fade>
  );
}

CompanyManagerForm.defaultProps = {
  companyManagerId: null,
  companyId: null,
  email: '',
  firstName: '',
  hrAdmin: false,
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  phone: '',
  state: '',
  status: null,
  zip: '',
  country: 'USA',
  create: false
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CompanyManagerForm);
