import React from 'react';
import Loader from 'react-loader-spinner';

const loaderConfig = {
  type: 'ThreeDots',
  height: 15,
  width: 15,
  color: '#fff'
};

export default function SubmitButton(props) {
  return (
    <button
      type="submit"
      disabled={props.loading || props.disabled}
      onClick={props.onClick ? props.onClick : null}
      style={props.style}
      className="submit-btn"
    >
      {props.loading ? <Loader {...loaderConfig} /> : props.text}
    </button>
  );
}

SubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  style: {},
  text: 'Submit'
};
