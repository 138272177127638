import React from 'react';
import { CompareTable } from './';

export default function CompareDerailers({ employees }) {
  return (
    <CompareTable
      headers={['Candidate', 'Present?', 'Derailers']}
      rows={employees.map((emp, i) => (
        <tr key={i}>
          <td>{emp.name}</td>
          <td>{emp.present}</td>
          <td>{emp.derailers}</td>
        </tr>
      ))}
    />
  );
}
