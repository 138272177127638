import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import BasicForm from 'components/shared/Forms/BasicForm';
import { SET_TOAST } from 'duck/toast';
import surveyVariety, {
  CLEARSCREEN_VALUE,
  ENGAGEMENT_VALUE,
  VALUE_360,
  HOL_VALUE,
  COMPETENCY_ASSESSMENT_VALUE
} from '@util/data/surveyVariety';

import { CompanySelect } from 'components/Company';

function SurveyForm({
  id,
  allowMultipleEvaluators,
  allowMultipleTargets,
  name,
  instructionText,
  variety,
  onSuccess,
  dispatch
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [varietyValue, setVarietyValue] = useState(null);
  const [companyValue, setCompanyValue] = useState(null);

  useEffect(() => {
    setVarietyValue(variety);
  }, [variety]);

  useEffect(() => {}, []);

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate({ ...formData, variety: varietyValue });
  }

  function onCreate(formData) {
    const req = {
      ...formData
    };

    if (varietyValue === ENGAGEMENT_VALUE) {
      req.company_id = companyValue;
    }

    if (varietyValue === VALUE_360) {
      req.allow_multiple_targets = false;
      req.allow_multiple_evaluators = true;
    }

    axios
      .post(`/surveys/`, req)
      .then(({ data }) => {
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Survey Created!'
          }
        });
        onSuccess(data.id);
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id
    };

    axios
      .put(`/surveys/${id}/`, req)
      .then(() =>
        dispatch({
          type: SET_TOAST,
          payload: {
            positive: true,
            message: 'Survey Updated!'
          }
        })
      )
      .then(() => setLoading(false))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  let fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'instruction_text',
      label: 'Instructions',
      initialValue: instructionText,
      textarea: true,
      required: true,
      schema: () => Yup.string().required('Required')
    },
    // {
    //   name: 'variety',
    //   label: 'Variety',
    //   initialValue: variety,
    //   select: true,
    //   options: surveyVariety,
    //   required: true,
    //   schema: () => Yup.number().nullable().required('Required')
    // },
    {
      name: 'version_name',
      label: 'First Version Name',
      initialValue: '',
      required: true,
      schema: () => Yup.string().required('Required')
    },
    {
      name: 'allow_multiple_evaluators',
      label: 'Allow Multiple Evaluators',
      checkbox: true,
      initialValue: allowMultipleEvaluators,
      schema: () => null
    },
    {
      name: 'allow_multiple_targets',
      label: 'Allow Multiple Targets',
      checkbox: true,
      initialValue: allowMultipleTargets,
      schema: () => null
    }
  ];

  if (id) {
    fields = fields.filter(
      (f) => f.name !== 'variety' && f.name !== 'version_name'
    );
  }

  if (
    [
      ENGAGEMENT_VALUE,
      HOL_VALUE,
      VALUE_360,
      COMPETENCY_ASSESSMENT_VALUE,
      CLEARSCREEN_VALUE
    ].includes(varietyValue)
  ) {
    fields = fields.filter(
      (f) =>
        ![
          'variety',
          'allow_multiple_evaluators',
          'allow_multiple_targets'
        ].includes(f.name)
    );
  }

  return (
    <div>
      {!id && (
        <Form style={{ marginBottom: '1em' }}>
          <Form.Select
            label="Variety"
            value={varietyValue}
            onChange={(e, d) => setVarietyValue(d.value)}
            placeholder="Select one..."
            options={surveyVariety.map((v) => ({
              key: v.value,
              text: v.text,
              value: v.value
            }))}
          />
        </Form>
      )}
      {!id && varietyValue === ENGAGEMENT_VALUE && (
        <Form style={{ marginBottom: '1em' }}>
          <CompanySelect
            value={companyValue}
            onChange={(e) => setCompanyValue(e)}
          />
        </Form>
      )}
      {varietyValue !== null && varietyValue !== ENGAGEMENT_VALUE && (
        <BasicForm
          loading={loading}
          error={error}
          onSubmit={onSubmit}
          fields={fields}
        />
      )}
      {varietyValue !== null &&
        varietyValue === ENGAGEMENT_VALUE &&
        companyValue && (
          <BasicForm
            loading={loading}
            error={error}
            onSubmit={onSubmit}
            fields={fields}
          />
        )}
      {id && varietyValue === ENGAGEMENT_VALUE && (
        <BasicForm
          loading={loading}
          error={error}
          onSubmit={onSubmit}
          fields={fields}
        />
      )}
    </div>
  );
}

SurveyForm.defaultProps = {
  allowMultipleEvaluators: false,
  allowMultipleTargets: false,
  id: null,
  instructionText: '',
  name: '',
  variety: null,
  onSuccess: () => {}
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SurveyForm);
