import React from 'react';
import {
  FaCrown,
  FaAngry,
  FaBatteryEmpty,
  FaTheaterMasks,
  FaPalette,
  FaExclamationTriangle,
  FaSnowflake,
  FaBomb,
  FaCut,
  FaStopwatch,
  FaHeadphones
} from 'react-icons/fa';

const iconProps = {
  size: 60
};

export const derailers = {
  arrogant: {
    text: `Conveys a sense of "intellectual superiority" and likely to be perceived as authoritative, condescending, and "sometimes wrong but rarely in doubt".`,
    hol: `Strong ability to Think, to Challenge, and to Anticipate yet with relatively little Optimism & Trust.`,
    icon: <FaCrown {...iconProps} />
  },
  callous: {
    text: `Demonstrating an emotionally reserved demeanor which may include a lack of emotional sensitivity to events or people.`,
    hol: `An Elevating nature combined with a relative lack of Humility & Love.`,
    icon: <FaAngry {...iconProps} />
  },
  deflated: {
    text: `Prone to becoming prematurely discouraged, lacking in enthusiasm and energy, and emotionally reserved.`,
    hol: ` Combination of lack of Mission focus, lack of Challenging nature, lack of Inspiring demeanor, lack of Elevating attitude, lack of Resoluteness, and lack of Courage.`,
    icon: <FaBatteryEmpty {...iconProps} />
  },
  detached: {
    text: `Displaying a lack of engagement characterized by being overly self-reliant and inconsistent in communicating with others.`,
    hol: `Relative lack of Resolve combined with the inability or unwillingness to Teach & Learn.`,
    icon: <FaCut {...iconProps} />
  },
  dramatic: {
    text: `Characterized by reactions to either positive of negative events by over-expression of emotionality.`,
    hol: `Combination of an Inspiring social style with a relative lack of Humility & Love.`,
    icon: <FaTheaterMasks {...iconProps} />
  },
  eccentric: {
    text: `Often intelligent and creative yet may exhibit behaviors others consider to be unconventional. May be inconsistent in focus, sense of urgency, and ability to "stay on track."`,
    hol: `Strong ability to Think with clear Mission focus yet lacking Values, Engagement, and Courage.`,
    icon: <FaPalette {...iconProps} />
  },
  guarded: {
    text: `Keeps thoughts and emotional reactions "close to the vest." Can be slow to trust.`,
    hol: `Relative lack of a Mission focus in combination with a relative lack of an Elevating nature.`,
    icon: <FaExclamationTriangle {...iconProps} />
  },
  hesitant: {
    text: `Characterized by reluctance to take action due to apprehensiveness and (often) shyness.`,
    hol: `Relative lack of a Mission focus combined with a relative lack of Resolve.`,
    icon: <FaSnowflake {...iconProps} />
  },
  hostile: {
    text: `Often have a "rough edge" and can be perceived as overly critical and lacking in warmth. Lack of impact awareness can negatively affect communication and undermine relationships.`,
    hol: `Appear to exhibit Humility and Love as well as Anticipation, yet may lack clear Mission, Values, and Goals (i.e., the three Evangelize factors).`,
    icon: <FaBomb {...iconProps} />
  },
  obsessive: {
    text: `Perfectionistic tendencies coupled with elevated rule-consciousness, detail orientation, and deference to authority. Will tend to self-reliance and a heads-down approach when stressed and can become overly self-critical.`,
    hol: `Combination of clear Values and Goals yet with a relative lack of a Challenging demeanor.`,
    icon: <FaStopwatch {...iconProps} />
  },
  respondent: {
    text: `Tends to be more reactive than proactive and can be undisciplined and/or overly tolerant of disorder.`,
    hol: `Relative lack of clear Mission, Values, and Goals (i.e., the three Evangelize factors).`,
    icon: <FaHeadphones {...iconProps} />
  }
};

export default function derailerDataFormatter(data) {
  if (!data.calculation_container || !data.calculation_container.derailers)
    return [];

  return data.calculation_container.derailers
    .filter((d) => derailers[d.name])
    .map((derailer) => ({
      ...derailer,
      ...derailers[derailer.name]
    }));
}
