import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

function CompanySelectList({
  admin,
  companyOptions,
  companies,
  label,
  onChange,
  multiple,
  value
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // * Set selected to the first and only company a manager has
    if (companies.length === 1) {
      onChange([companies[0].id]);
    }
  }, []);

  useEffect(() => {
    setOptions(companyOptions);
  }, [JSON.stringify(companyOptions)]);

  useEffect(() => {
    if (admin) {
      return fetchCompanies();
    }

    if (!admin) {
      if (companyOptions && companyOptions.length) {
        setOptions(companyOptions);
      } else {
        setOptions(
          companies
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((company) => ({
              text: company.name,
              key: company.id,
              value: company.id
            }))
        );
      }
    }
  }, []);

  function fetchCompanies() {
    axios.get(`/company-list/`).then(({ data }) => {
      setOptions(
        data.companies
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((d) => ({
            key: d.id,
            text: d.name,
            value: d.id
          }))
      );
    });
  }

  return (
    <Form>
      <Form.Select
        label={label}
        options={options}
        // options={
        //   companyOptions && companyOptions.length
        //     ? companyOptions
        //     : companies
        //         .sort((a, b) => (a.name > b.name ? 1 : -1))
        //         .map((company) => ({
        //           text: company.name,
        //           key: company.id,
        //           value: company.id
        //         }))
        // }
        multiple={multiple}
        search
        onChange={(e, d) => onChange(d.value)}
        value={value}
        placeholder={
          multiple ? 'Select some companies...' : 'Select a company...'
        }
      />
    </Form>
  );
}

CompanySelectList.defaultProps = {
  admin: false,
  label: 'Companies',
  multiple: true
};

function mapStateToProps(state) {
  return {
    // companyOptions: [],
    companies: state.manager ? state.manager.active_companies : []
  };
}

export default connect(mapStateToProps)(CompanySelectList);
